function removeEmptyAndUndefined(obj: any) {
  return Object.keys(obj)
    .filter(
      (key) => obj[key] !== null && obj[key] !== undefined && obj[key] !== ""
    )
    .reduce((result, key) => {
      result[key] = obj[key];
      return result;
    }, {});
}

export default removeEmptyAndUndefined;
