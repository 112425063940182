import { PieChart, Pie, Cell } from "recharts";

function AttendanceChart({ data }) {
  return (
    <PieChart width={180} height={180}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        innerRadius={60}
        outerRadius={80}
        fill="#8884d8"
        paddingAngle={3}
        dataKey="value"
      >
        {data.map((_, index) => (
          <Cell
            key={`cell-${index}`}
            fill={data.map((e) => e.color)[index % data.length]}
          />
        ))}
      </Pie>
    </PieChart>
  );
}

export default AttendanceChart;
