import * as React from "react";
import type { ComboBoxProps } from "@react-types/combobox";
import { useComboBoxState } from "react-stately";
import { useComboBox, useFilter, useButton } from "react-aria";

import { ListBox } from "./ListBox";
import { Popover } from "./Popover";
import { ChevronDown } from "react-feather";
import { cn } from "@/utils/cn";

export { Item, Section } from "react-stately";

export function ComboBox<T extends object>(props: ComboBoxProps<T>) {
  let { contains } = useFilter({ sensitivity: "base" });
  let state = useComboBoxState({ ...props, defaultFilter: contains });

  const { errorMessage } = props;

  let buttonRef = React.useRef(null);
  let inputRef = React.useRef(null);
  let listBoxRef = React.useRef(null);
  let popoverRef = React.useRef(null);

  let {
    buttonProps: triggerProps,
    inputProps,
    listBoxProps,
    labelProps,
  } = useComboBox(
    {
      ...props,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef,
    },
    state
  );

  let { buttonProps }: any = useButton(triggerProps, buttonRef);

  return (
    <div className="inline-flex w-full flex-col relative py-[6px]">
      <label
        {...labelProps}
        className="block text-[13px] mb-1 capitalize font-medium text-slate-700"
      >
        {props.label}
      </label>
      <div
        className={cn(
          "shadow-sm relative text-slate-600 placeholder:text-slate-500 font-medium outline-none text-[12.5px] border px-3 py-[10px]  block w-full  rounded-md",
          {
            "focus:ring-red-500 focus:border-red-500 border border-red-500":
              errorMessage,
            "focus:ring-primary focus:border-primary border-gray-300 ":
              !errorMessage,
          }
        )}
      >
        <input {...inputProps} ref={inputRef} className="outline-none w-full" />
        <a {...buttonProps} ref={buttonRef}>
          <ChevronDown
            className={cn(
              `w-4 top-3 h-4 cursor-pointer absolute transition-all right-3`,
              {
                "rotate-180": state.isOpen,
              }
            )}
            aria-hidden="true"
          />
        </a>
      </div>
      {state.isOpen && (
        <Popover
          popoverRef={popoverRef}
          triggerRef={inputRef}
          state={state}
          isNonModal
          placement="bottom start"
          className="w-52"
        >
          <ListBox {...listBoxProps} listBoxRef={listBoxRef} state={state} />
        </Popover>
      )}
    </div>
  );
}
