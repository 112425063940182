import { useFormikContext } from "formik";
import Textarea from "@/components/ui/Textarea";
import { get } from "lodash";

export default function AppFormTextarea({ name, ...other }) {
  const { setFieldValue, values, errors, handleBlur, touched }: any =
    useFormikContext();
  return (
    <Textarea
      errorMessage={
        get(errors, name) && get(touched, name) ? get(errors, name) : ""
      }
      onChange={(e: any) => {
        setFieldValue(name, e.target.value);
      }}
      onBlur={handleBlur}
      value={get(values, name)}
      name={name}
      {...other}
    />
  );
}
