import { useEffect, useState } from "react";
import Modal from "../ui/Modal";
import Button from "../ui/Button";
import { api } from "@/lib/api";
import toast from "react-hot-toast";
import { useEmployee } from "@/context/employee.context";
import formatPreviewLink from "@/utils/formatPreviewLink";

function isLink(str) {
  try {
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
}

function RequisitionModal({
  requisition: data,
  onClose,
  open,
  onAction,
  hideActions,
}: any) {
  const [requisition, setRequisition] = useState(data);

  const [rejecting, setrejecting] = useState(false);
  const [accepting, setaccepting] = useState(false);
  const { employee } = useEmployee();

  useEffect(() => {
    if (data) {
      setRequisition(data);
    }
  }, [data]);

  const handleApprove = () => {
    let approve_reason = prompt("Approve reason");
    if (!approve_reason) return onClose();
    setaccepting(true);
    const newD = {
      ...requisition,
      status: "approved",
      status_reason: approve_reason,
      approvedById: employee?.id,
      employeeId: requisition?.employee?.id,
    };
    delete newD["id"];
    delete newD["employee"];
    delete newD["created_at"];
    delete newD["updated_at"];
    delete newD["approvedBy"];
    delete newD["days"];
    delete newD["attachment"];

    return api
      .put(`/requisitions/${data.id}`, newD)
      .then(() => {
        toast.success("Requisition approved success");
        onAction("approved");
        setaccepting(false);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message || e?.message);
      })
      .finally(() => {
        setaccepting(false);
      });
  };
  const handleReject = () => {
    let reject_reason = prompt("Reject reason");
    if (!reject_reason) return onClose();
    setrejecting(true);
    const newD = {
      ...requisition,
      status: "rejected",
      status_reason: reject_reason,
      rejectedById: employee?.id,
      employeeId: requisition?.employee?.id,
    };
    delete newD["id"];
    delete newD["employee"];
    delete newD["created_at"];
    delete newD["rejectedBy"];
    delete newD["updated_at"];
    delete newD["days"];
    delete newD["attachment"];
    return api
      .put(`/requisitions/${data.id}`, newD)
      .then(() => {
        toast.success("Requisition rejected success");
        onAction("rejected");
        setrejecting(false);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message || e?.message);
      })
      .finally(() => {
        setrejecting(false);
      });
  };

  return (
    <Modal
      title="Requisition details."
      size="xl"
      showDividers={false}
      close={onClose}
      open={open}
    >
      <div className="grid px-[6px] gap-4 my-0 pt-0 grid-cols-2">
        {[
          {
            key: "Employee",
            value:
              requisition?.employee["first_name"] +
              " " +
              requisition?.employee["last_name"],
          },
          { key: "Title", value: requisition?.title },
          { key: "Type", value: requisition?.type },
          { key: "status", value: requisition?.status },
          {
            key: "Requested at",
            value: new Date(requisition?.created_at).toLocaleDateString(
              "en-US",
              {
                year: "numeric",
                month: "short",
                day: "numeric",
              }
            ),
          },
          {
            key: "Approved by",
            value: requisition?.approvedBy
              ? requisition?.approvedBy["first_name"] +
                " " +
                requisition?.approvedBy["last_name"]
              : "N/A",
          },
          {
            key: "Rejected by",
            value: requisition?.rejectedBy
              ? requisition?.rejectedBy["first_name"] +
                " " +
                requisition?.rejectedBy["last_name"]
              : "N/A",
          },
          {
            key: "Attachment",
            value: requisition?.attachment || "N/A",
          },
        ].map((e, i) => {
          return (
            <div className="flex  items-center gap-4" key={i}>
              <span className="text-[12.5px] font-medium capitalize text-slate-500">
                {e.key}:
              </span>
              {isLink(e.value) ? (
                <a
                  href={formatPreviewLink(e.value)}
                  target="_blank"
                  className="text-[12.5px] underline truncate font-semibold text-slate-700"
                >
                  {formatPreviewLink(e.value)}
                </a>
              ) : (
                <span className="text-[12.5px] truncate font-semibold capitalize text-slate-700">
                  {e.value}
                </span>
              )}
            </div>
          );
        })}
      </div>
      <div className="px-2 pb-2 mt-5">
        <h4 className="uppercase text-[12.5px] font-semibold text-slate-600">
          Requisition Reason
        </h4>
        <p className="text-[13px] leading-7 font-medium text-slate-500 mt-1">
          {requisition?.reason}
        </p>
      </div>

      {requisition?.status_reason && (
        <div className="px-2 pb-2 mt-5">
          <h4 className="uppercase text-[12.5px] font-semibold text-slate-600">
            Reject/accept Reason
          </h4>
          <p className="text-[13px] leading-7 font-medium text-slate-500 mt-1">
            {requisition?.status_reason}
          </p>
        </div>
      )}

      {requisition?.status === "pending" && !hideActions && (
        <div className="mt-3 gap-3 px-1 flex justify-start">
          <Button
            loading={accepting}
            onClick={handleApprove}
            color="success"
            size="sm"
          >
            Approve
          </Button>
          <Button
            loading={rejecting}
            onClick={handleReject}
            color="danger"
            size="sm"
          >
            Reject
          </Button>
        </div>
      )}
    </Modal>
  );
}

export default RequisitionModal;
