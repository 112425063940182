import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { Login, ForgotPassword, ResetPassword } from "@/pages/auth";
import { AuthLayout, DashboardLayout } from "@/components/layouts";
import { Page404 } from "@/pages";
import { Employees, HrOverview, Leaves } from "./pages/hr";
import { Trainings } from "./pages/trainings";
import Account from "./pages/Account";
import { ProtectedRoute } from "./components/ProtectedRoutes";
import App from "./App";
import { Dashboard } from "./pages/dashboard";
import Departments from "./pages/hr/Departments";
import Attendances from "./pages/hr/Attendances";
import DailyAttendance from "./pages/hr/DailyAttendance";
import AttendanceReport from "./pages/hr/AttendanceReport";
import Projects from "./pages/projects/Projects";
import Tenders from "./pages/projects/Tenders";
import Perfomance from "./pages/hr/Perfomance";
import EmployeePortal from "./pages/EmployeePortal";
import ProjectDetails from "./pages/projects/ProjcetDetails";
import EmployeeNotFound from "./pages/EmployeeNotFound";
import TrainingDetails from "./pages/trainings/TrainingDetails";
import TenderDetails from "./pages/projects/TenderDetails";
import Requisitions from "./pages/finance/Requisitions";

const EmptyLayout = () => {
  return <Outlet />;
};

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "auth",
        element: <AuthLayout />,
        children: [
          {
            path: "",
            element: <Navigate replace to="/auth/login" />,
          },
          {
            path: "login",
            element: <Login />,
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
          },
          {
            path: "reset-password",
            element: <ResetPassword />,
          },
        ],
      },

      {
        path: "/",
        element: <Navigate to={"/dashboard"} />,
      },
      {
        element: <DashboardLayout />,
        path: "",
        children: [
          {
            path: "dashboard",
            element: (
              <ProtectedRoute
                roles={[
                  "admin",
                  "project-manager",
                  "hr-manager",
                  "trainings-manager",
                  "finance-manager",
                  "employee",
                ]}
              >
                <Dashboard />
              </ProtectedRoute>
            ),
          },
          {
            path: "portal",
            element: (
              <ProtectedRoute roles={["*"]}>
                <EmployeePortal />
              </ProtectedRoute>
            ),
          },
          {
            path: "hr",
            element: (
              <ProtectedRoute roles={["admin", "hr-manager"]}>
                <EmptyLayout />
              </ProtectedRoute>
            ),
            children: [
              {
                path: "",
                element: <HrOverview />,
              },
              {
                path: "attendance",
                element: <Attendances />,
                children: [
                  {
                    path: "",
                    element: <DailyAttendance />,
                  },
                  {
                    path: "view",
                    element: <AttendanceReport />,
                  },
                ],
              },
              {
                path: "employees",
                element: <Employees />,
              },
              {
                path: "leaves",
                element: <Leaves />,
              },
              {
                path: "departments",
                element: <Departments />,
              },
              {
                path: "perfomance",
                element: <Perfomance />,
              },
            ],
          },
          {
            path: "projects",
            element: (
              // <ProtectedRoute roles={["admin", "project-manager"]}>
              <EmptyLayout />
              // </ProtectedRoute>
            ),
            children: [
              {
                path: "",
                element: <Projects />,
              },
              {
                path: ":id",
                element: <ProjectDetails />,
              },
            ],
          },
          {
            path: "tenders",
            element: (
              <ProtectedRoute roles={["admin", "project-manager"]}>
                <EmptyLayout />
              </ProtectedRoute>
            ),
            children: [
              {
                path: "",
                element: <Tenders />,
              },
              {
                path: ":id",
                element: <TenderDetails />,
              },
            ],
          },
          {
            path: "trainings",
            element: (
              <ProtectedRoute roles={["admin", "training-manager"]}>
                <EmptyLayout />
              </ProtectedRoute>
            ),
            children: [
              {
                path: "",
                element: <Trainings />,
              },
              {
                path: ":id",
                element: <TrainingDetails />,
              },
              {
                path: "participants",
                element: <div>Participants</div>,
              },
            ],
          },
          {
            path: "finance",
            element: (
              <ProtectedRoute roles={["admin", "finance-manager"]}>
                <EmptyLayout />
              </ProtectedRoute>
            ),
            children: [
              {
                path: "requisitions",
                element: <Requisitions />,
              },
            ],
          },
          {
            path: "account",
            element: <Account />,
          },
          {
            path: "employee-not-found",
            element: <EmployeeNotFound />,
          },
          {
            path: "*",
            element: <Page404 />,
          },
        ],
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
]);

export default router;
