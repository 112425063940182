import type { AriaListBoxProps } from "react-aria";
import { Item, useAsyncList, useListState } from "react-stately";
import { mergeProps, useFocusRing, useListBox, useOption } from "react-aria";
import { useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

export default function DropDownSelect({
  items,
  OptionItem,
  onClose,
  open,
  value,
  loader,
  onChange,
}: any) {
  const ref = useRef();

  useOnClickOutside(ref, onClose);

  const itemsOptions = useAsyncList({
    load: loader,
  });

  const itemsToShow = loader ? itemsOptions.items : items;

  function isStringANumber(inputString) {
    const parsedInt = parseInt(inputString, 10);
    return !isNaN(parsedInt);
  }

  return (
    <>
      {open ? (
        <div
          ref={ref}
          className="absolute top-8 z-40 min-h-[120px] bg-white w-[180px] shadow-md border border-slate-200 rounded-md"
        >
          <ListBox
            defaultSelectedKeys={value}
            onSelectionChange={(e: any) => {
              const item = itemsToShow.find(
                ({ value }) =>
                  value ===
                  (isStringANumber(e.currentKey)
                    ? parseInt(e.currentKey)
                    : e.currentKey)
              );
              onChange(item);
              onClose();
            }}
            selectionMode="single"
          >
            {itemsToShow.map((e) => (
              <Item key={e.value} textValue={e.value}>
                {OptionItem ? <OptionItem item={e} /> : e.label}
              </Item>
            ))}
          </ListBox>
        </div>
      ) : null}
    </>
  );
}

function ListBox<T extends object>(props: AriaListBoxProps<T>) {
  let state = useListState(props);
  let ref = useRef(null);
  let { listBoxProps, labelProps } = useListBox(props, state, ref);

  return (
    <>
      <div {...labelProps}>{props.label}</div>
      <ul className="p-1 gap-1" {...listBoxProps} ref={ref}>
        {[...state.collection].map((item) => (
          <Option key={item.key} item={item} state={state} />
        ))}
      </ul>
    </>
  );
}

function Option({ item, state }) {
  let ref = useRef(null);
  let { optionProps } = useOption({ key: item.key }, state, ref);
  let { isFocusVisible, focusProps } = useFocusRing();

  return (
    <li
      {...mergeProps(optionProps, focusProps)}
      ref={ref}
      className="text-[12.3px] text-slate-600 px-2 cursor-pointer hover:bg-slate-100 py-2 font-medium"
      data-focus-visible={isFocusVisible}
    >
      {item.rendered}
    </li>
  );
}
