import { useEffect, useState } from "react";
import Modal from "../ui/Modal";
import formatPreviewLink from "@/utils/formatPreviewLink";
import markdownit from "markdown-it";
const md = markdownit();

function TenderModal({ tender: data, onClose, open }) {
  const [tender, setTender] = useState(data);

  useEffect(() => {
    if (data) {
      setTender(data);
    }
  }, [data]);

  return (
    <Modal
      title="Tender details."
      size="xl"
      showDividers={false}
      close={onClose}
      open={open}
    >
      <div className="grid px-[6px] gap-4 gap-x-8 my-0 pt-0 grid-cols-2">
        {[
          {
            key: "Title",
            value: tender?.title,
          },
          {
            key: "Created by",
            value:
              tender?.created_by?.first_name +
              " " +
              tender?.created_by?.last_name,
          },
          {
            key: "Created at",
            value: new Date(tender?.created_at).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            }),
          },
          {
            key: "Status",
            value: tender?.status,
          },
          { key: "Client", value: tender?.company_name },
          {
            key: "Client email",
            value: tender?.email,
          },
          {
            key: "Client phone",
            value: tender?.phone,
          },
          {
            key: "Source",
            value: tender?.source,
          },
          {
            key: "attachment",
            value: tender?.attachments?.length ? tender?.attachments[0] : "N/A",
            link: tender?.attachments?.length ? tender?.attachments[0] : "#",
          },
          {
            key: "Link",
            value: tender?.link || "N/A",
            link: tender?.link || "#",
          },
        ].map((e, i) => {
          return (
            <div className="flex  items-center gap-4" key={i}>
              <span className="text-[12.5px] font-medium capitalize text-slate-500">
                {e.key}:
              </span>
              {e.link ? (
                <a
                  href={formatPreviewLink(e.value)}
                  target="_blank"
                  className="text-[12.2px] truncate font-semibold underline text-slate-700"
                >
                  {formatPreviewLink(e.value)}
                </a>
              ) : (
                <span className="text-[12.2px] truncate font-semibold capitalize text-slate-700">
                  {e.value}
                </span>
              )}
            </div>
          );
        })}
      </div>
      <div className="px-2 pb-2 mt-5">
        <h4 className="uppercase text-[12.5px] font-semibold text-slate-600">
          Description
        </h4>
        {tender && (
          <p className="mt-2 text-[13px] prose max-w-3xl font-medium leading-7 text-slate-600">
            <div
              dangerouslySetInnerHTML={{
                __html: md.render(tender?.description),
              }}
            ></div>
          </p>
        )}
      </div>
    </Modal>
  );
}

export default TenderModal;
