import { useEffect, useMemo, useState } from "react";
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove, useSortable } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import { Clock, MessageSquare, PlusCircle } from "react-feather";
import { CSS } from "@dnd-kit/utilities";
import { cn } from "@/utils/cn";
import SimpleBar from "simplebar-react";
import Avatar from "./ui/Avatar";
import { Link } from "react-router-dom";
import TaskPannel from "./TaskPannel";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { tasks_status, tenders_status } from "@/data";
dayjs.extend(relativeTime);

function TaskBoard({
  handleChange,
  type,
  members,
  tasks = [],
  createTask,
  handleRefetch,
  cols,
}) {
  const [localTasks, setLocalTasks] = useState(tasks);

  useEffect(() => {
    setLocalTasks(tasks);
  }, [JSON.stringify(tasks)]);

  const [columns, setColumns] = useState<any[]>(cols);
  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);

  const [activeColumn, setActiveColumn] = useState<any | null>(null);

  const [activeTask, setActiveTask] = useState<any | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  function onDragStart(event: DragStartEvent) {
    if (event.active.data.current?.type === "Column") {
      setActiveColumn(event.active.data.current.column);
      return;
    }

    if (event.active.data.current?.type === "Task") {
      setActiveTask(event.active.data.current.task);
      return;
    }
  }

  function onDragEnd(event: DragEndEvent) {
    setActiveColumn(null);
    setActiveTask(null);

    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    handleChange(active.data.current.task);

    if (activeId === overId) return;

    const isActiveAColumn = active.data.current?.type === "Column";
    if (!isActiveAColumn) return;

    console.log("DRAG END");

    setColumns((columns) => {
      const activeColumnIndex = columns.findIndex((col) => col.id === activeId);

      const overColumnIndex = columns.findIndex((col) => col.id === overId);

      return arrayMove(columns, activeColumnIndex, overColumnIndex);
    });
  }

  function onDragOver(event: DragOverEvent) {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveATask = active.data.current?.type === "Task";
    const isOverATask = over.data.current?.type === "Task";

    if (!isActiveATask) return;

    // Im dropping a Task over another Task
    if (isActiveATask && isOverATask) {
      console.log("dropping a Task over another Task");
      const activeIndex = localTasks.findIndex((t) => t.id === activeId);
      const overIndex = localTasks.findIndex((t) => t.id === overId);
      let d = undefined;
      if (localTasks[activeIndex].status != localTasks[overIndex].status) {
        // Fix introduced after video recording
        const newTasks = localTasks.map((e, i) =>
          i === activeIndex
            ? { ...e, status: over.data.current.task.status }
            : e
        );
        d = arrayMove(newTasks, activeIndex, overIndex - 1);
      } else {
        d = arrayMove(localTasks, activeIndex, overIndex);
      }
      setLocalTasks(d);
    }

    const isOverAColumn = over.data.current?.type === "Column";

    // Im dropping a Task over a column
    if (isActiveATask && isOverAColumn) {
      console.log("DROPPING TASK OVER COLUMN");
      const activeIndex = localTasks.findIndex((t) => t.id === activeId);
      const newTasks = localTasks.map((e, i) =>
        i === activeIndex ? { ...e, status: overId } : e
      );
      const d = arrayMove(newTasks, activeIndex, activeIndex);
      setLocalTasks(d);
    }
  }

  return (
    <>
      <div className="flex overflow-hidden my-3 rounded-md border border-slate-200 bg-white w-full items-center overflow-x-auto- overflow-y-hidden">
        <SimpleBar style={{ overflowY: "hidden", width: "100%" }}>
          <DndContext
            sensors={sensors}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
          >
            <div className="flex px-3 pb-2 gap-4">
              <div className="flex my-3 gap-4">
                <SortableContext items={columnsId}>
                  {columns.map((col) => (
                    <ColumnContainer
                      key={col.id}
                      createTask={createTask}
                      column={col}
                      tasks={localTasks.filter(
                        (task) => task.status === col.id
                      )}
                    />
                  ))}
                </SortableContext>
              </div>
            </div>

            {createPortal(
              <DragOverlay>
                {activeColumn && (
                  <ColumnContainer
                    createTask={createTask}
                    column={activeColumn}
                    tasks={localTasks.filter(
                      (task) => task.status === activeColumn?.id
                    )}
                  />
                )}
                {activeTask && <TaskCard task={activeTask} />}
              </DragOverlay>,
              document.body
            )}
          </DndContext>
        </SimpleBar>
      </div>
      <TaskPannel
        tasks_status={type === "tender" ? tenders_status : tasks_status}
        handleChange={handleRefetch}
        type={type}
        members={members}
      />
    </>
  );
}

export default TaskBoard;

function ColumnContainer({ column, tasks, createTask }: any) {
  const tasksIds = useMemo(() => {
    return tasks.map((task) => task.id);
  }, [tasks]);

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: column?.id,
    data: {
      type: "Column",
      column,
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        style={style}
        className={cn(
          "opacity-40 border-2 border-pink-500 w-[330px] h-full rounded-md flex flex-col",
          column.bg_color,
          "bg-opacity-20",
          column.border_color,
          {
            // "bg-gray-50 border-slate-300": column?.id === "todo",
            // "bg-orange-50 border-orange-300": column?.id === "in-progress",
            // "bg-blue-50 border-blue-300": column?.id === "in-review",
            // "bg-green-50  border-green-300": column?.id === "done",
            "!border-[3px]": isDragging,
          }
        )}
      ></div>
    );
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={cn(
        "bg-columnBackgroundColor border w-[330px] h-auto max-h-[500px]- rounded-md flex flex-col",
        column.bg_color,
        "bg-opacity-5",
        column.border_color,
        {
          // "bg-gray-50 border-slate-300": column?.id === "todo",
          // "bg-orange-50 border-orange-300": column?.id === "in-progress",
          // "bg-blue-50 border-blue-300": column?.id === "in-review",
          // "bg-green-50  border-green-300": column?.id === "done",
          "!border-[3px]": isDragging,
        }
      )}
    >
      <div
        {...attributes}
        {...listeners}
        className=" bg-white px-3 text-md h-[60px]- py-2 cursor-grab rounded-md rounded-b-none border-columnBackgroundColor border-1 flex items-center justify-between "
      >
        <div className="flex items-center gap-3">
          <div
            className={cn(
              "flex items-center bg-green-100 px-2 py-1 rounded-md gap-2",
              column.bg_color,
              "bg-opacity-5"
              // {
              //   "bg-gray-200": column?.id === "todo",
              //   "bg-orange-100": column?.id === "in-progress",
              //   "bg-blue-100": column?.id === "in-review",
              //   "bg-green-100": column?.id === "done",
              // }
            )}
          >
            <div>
              <div
                className={cn(
                  "w-2 h-2 rounded-full",
                  column.bg_color,
                  column.text_color,
                  {
                    // "bg-gray-500": column?.id === "todo",
                    // "bg-orange-500": column?.id === "in-progress",
                    // "bg-blue-500": column?.id === "in-review",
                    // "bg-green-500": column?.id === "done",
                  }
                )}
              ></div>
            </div>
            <span
              className={cn(
                "text-[12px] font-medium capitalize",
                column.text_color,
                {
                  // "text-gray-500": column?.id === "todo",
                  // "text-orange-500": column?.id === "in-progress",
                  // "text-blue-500": column?.id === "in-review",
                  // "text-green-500": column?.id === "done",
                }
              )}
            >
              {column?.title}
            </span>
          </div>
          <span className="text-[11px] font-semibold bg-slate-200 h-[23px] w-[20px] flex items-center justify-center rounded-sm text-slate-500">
            {tasks.length}
          </span>
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={() => {
              createTask();
            }}
            className="h-7 w-7 hover:bg-slate-200 rounded-md flex items-center justify-center"
          >
            <PlusCircle className="text-slate-600" size={16} />
          </button>
          {/* <button
            onClick={() => {
              //  create new task
            }}
            className="h-7 w-7 hover:bg-slate-200 rounded-md flex items-center justify-center"
          >
            <MoreHorizontal className="text-slate-600" size={16} />
          </button> */}
        </div>
      </div>

      {/* Column task container */}
      <div className="h-full">
        <SimpleBar style={{ height: "600px" }}>
          <div className="flex h-full  pb-3 flex-col bg-red-200- gap-4 py-2 px-[10px]">
            <SortableContext items={tasksIds}>
              {tasks.map((task, i) => (
                <TaskCard key={i} task={task} column={column} />
              ))}
              {tasks.length === 0 && (
                <div className="w-full flex items-center justify-center h-[400px]">
                  <span className="text-[13px] font-medium text-slate-500">
                    No tasks available.
                  </span>
                </div>
              )}
            </SortableContext>
          </div>
        </SimpleBar>
      </div>
      {/* Column footer */}
      <button
        className="flex gap-2 items-center border-slate-200 border-t px-4 py-3"
        onClick={() => {
          createTask();
        }}
      >
        <PlusCircle size={15} className="text-slate-500" />
        <span className="text-[12.5px] text-slate-500 font-medium">
          Add new task
        </span>
      </button>
    </div>
  );
}

function TaskCard({ task, column }: any) {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: task.id,
    data: {
      type: "Task",
      task,
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        style={style}
        className={cn(
          "opacity-50 bg-white  border-[2px] p-2.5 h-[175px] min-h-[175px] items-center flex text-left rounded-md cursor-grab relative",
          column.bg,
          "bg-opacity-10",
          column.border_color
          // {
          //   "border-slate-400 bg-slate-100": column?.id === "todo",
          //   "border-orange-400 bg-orange-100": column?.id === "in-progress",
          //   "border-blue-400 bg-blue-100": column?.id === "in-review",
          //   "border-green-400 bg-green-100": column?.id === "done",
          // }
        )}
      >
        <div className="w-full flex items-center justify-center">
          <span className="text-[13px] text-center text-slate-500 font-medium">
            Drop here
          </span>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={cn(
        "bg-white border-slate-200 !border-t-[3px] border p-2.5 items-center flex text-left rounded-md cursor-grab relative task",
        // "hover:border-gray-300 !border-t-slate-300",
        column?.border_color,
        {
          // "hover:border-gray-300 !border-t-slate-300": column?.id === "todo",
          // "hover:border-orange-300 !border-t-orange-500":
          //   column?.id === "in-progress",
          // "hover:border-blue-300 !border-t-blue-500":
          //   column?.id === "in-review",
          // "hover:border-green-300 !border-t-green-500": column?.id === "done",
        }
      )}
    >
      <div className="whitespace-pre-wrap w-full">
        <div>
          <Link
            to={`?show_task=${task.id}`}
            className="text-[12.5px]  hover:underline cursor-pointer leading-6 font-medium line-clamp-2 text-slate-800"
          >
            {task.title}
          </Link>
        </div>
        <div className="mt-1 text-[12px]  font-medium text-slate-500 line-clamp-2 leading-[26px] w-full">
          <Link
            to={`?show_task=${task.id}`}
            dangerouslySetInnerHTML={{ __html: task.description }}
          ></Link>
        </div>
        <div className="flex mt-3 items-center justify-between">
          <div>
            <div className="flex -space-x-2 relative z-0 overflow-hidden">
              {/* {members.map((e, i) => {
                return ( */}
              <div className="p-[1px] bg-white rounded-full">
                <Avatar
                  size="xs"
                  rounded="full"
                  name={
                    task.assignedTo.first_name + " " + task.assignedTo.last_name
                  }
                  src={task.assignedTo.photo}
                />
              </div>
              {/* );
              })} */}
            </div>
          </div>
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-1">
              <MessageSquare className="text-slate-500" size={14} />
              <span className="text-[11px] font-medium text-slate-500">
                {task?.comments?.length}
              </span>
            </div>
            {/* <div className="flex items-center gap-1">
              <Paperclip className="text-slate-500" size={14} />
              <span className="text-[11px] font-medium text-slate-500">6</span>
            </div> */}
          </div>
        </div>
        <div className="flex items-center justify-between mt-4">
          <div className="flex gap-2 items-center">
            <div className="px-2 py-[3px] flex items-center gap-2 rounded-sm cursor-pointer text-[11px] font-medium border text-slate-500 border-slate-200 bg-slate-50">
              <span>#{task.id}</span>
            </div>
            <div
              className={cn(
                "px-3 py-[3px] capitalize flex items-center gap-2 rounded-sm text-xs font-medium ",
                {
                  "text-gray-500 bg-gray-100": task?.priority === "low",
                  "text-orange-500 bg-orange-100": task?.priority === "medium",
                  "text-red-500 bg-red-100": task?.priority === "high",
                }
              )}
            >
              <span
                className={cn("w-2 h-2 rounded-full", {
                  "bg-gray-500": task?.priority === "low",
                  "bg-orange-500": task?.priority === "medium",
                  "bg-red-500": task?.priority === "high",
                })}
              ></span>
              <span>{task.priority}</span>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Clock className="text-slate-500 whitespace-pre-wrap" size={13} />
            <span className="text-[11px] mt-[2px] font-medium text-slate-500">
              {dayjs(task.start_date).fromNow()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
