import { useEffect, useState } from "react";
import Modal from "../ui/Modal";
import formatPreviewLink from "@/utils/formatPreviewLink";

function isLink(str) {
  try {
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
}

function IssueModalDetails({ issue: data, onClose, open }: any) {
  const [issue, setIssue] = useState(data);

  useEffect(() => {
    if (data) {
      setIssue(data);
    }
  }, [data]);

  return (
    <Modal
      title="Issue details."
      size="lg"
      showDividers={false}
      close={onClose}
      open={open}
    >
      <div className="grid px-[6px] gap-4 my-0 pt-0 grid-cols-2">
        {[
          {
            key: "Created by",
            value:
              issue?.createdBy["first_name"] +
              " " +
              issue?.createdBy["last_name"],
          },
          { key: "Department", value: issue?.department.name },
          {
            key: "Status",
            value: issue?.status,
          },
          {
            key: "Reported at",
            value: new Date(issue?.created_at).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            }),
          },
          {
            key: "Attachment",
            value: issue?.attachment,
          },
        ].map((e, i) => {
          return (
            <div className="flex  items-center gap-4" key={i}>
              <span className="text-[12.5px] font-medium capitalize text-slate-500">
                {e.key}:
              </span>
              {isLink(e.value) ? (
                <a
                  href={formatPreviewLink(e.value)}
                  target="_blank"
                  className="text-[12.5px] underline truncate font-semibold text-slate-700"
                >
                  {formatPreviewLink(e.value)}
                </a>
              ) : (
                <span className="text-[12.5px] truncate font-semibold capitalize text-slate-700">
                  {e.value}
                </span>
              )}
            </div>
          );
        })}
      </div>
      <div className="px-2 pb-2 mt-5">
        <h4 className="uppercase text-[12.5px] font-semibold text-slate-600">
          Issue description
        </h4>
        <span className="text-[12.5px] font-medium leading-7 mt-2 block capitalize text-slate-500">
          {issue?.description}
        </span>
      </div>
    </Modal>
  );
}

export default IssueModalDetails;
