import { cn } from "@/utils/cn";
import { Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

function Menu({ close, items, open, meta, customClass }: any) {
  const ref = useRef(null);

  const handleClickOutside = () => {
    close();
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <Transition
      show={open}
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div
        ref={ref}
        className={cn(
          "absolute z-50 w-[240px] top-[20px] shadow-sm rounded-[3px] p-[6px] right-[8px] border border-slate-200  bg-white",
          customClass
        )}
      >
        <ul>
          {items
            .filter((e) => !e.hidden)
            .map((action) => (
              <li key={action.title}>
                <a
                  onClick={() => {
                    close();
                    action.onClick(meta);
                  }}
                  className={`${
                    action.variant === "danger"
                      ? "text-red-500  hover:bg-red-100 "
                      : "text-slate-600  hover:bg-slate-100 "
                  } flex cursor-pointer border border-transparent hover:border-slate-200 rounded-[3px] my-[2px] items-center gap-3 py-2 px-3`}
                >
                  <action.icon size={16} />
                  <span className="text-[12.5px] font-medium capitalize">
                    {action.title}
                  </span>
                </a>
              </li>
            ))}
        </ul>
      </div>
    </Transition>
  );
}

export default Menu;
