import { useQuery } from "react-query";
import Modal from "../ui/Modal";
import { api } from "@/lib/api";
import { Fragment, useState } from "react";
import Loader from "../ui/Loader";
import { cn } from "@/utils/cn";
import IssueModalDetails from "./IssueModalDetails";

function IssuesModalDetails({ onClose, open, department }: any) {
  async function fetchData() {
    const { data } = await api.get("/issues", {
      params: {
        page_size: 10,
        departmentId: department?.id,
      },
    });
    return data;
  }

  const {
    data: issues,
    status,
    error,
  } = useQuery(["issues", department?.id], fetchData, {
    keepPreviousData: false,
    retry: false,
    staleTime: Infinity,
    enabled: Boolean(department),
  });

  const [issueToShow, setissueToShow] = useState(null);

  return (
    <>
      <IssueModalDetails
        onClose={() => {
          setissueToShow(null);
        }}
        issue={issueToShow}
        open={Boolean(issueToShow)}
      />

      <Modal
        title="Department issues"
        size="xl"
        showDividers={false}
        close={onClose}
        open={open}
        noPadding
      >
        <div className="min-h-[200px]">
          <table className="table-fixed w-full">
            <thead>
              <tr>
                <th className="text-left text-[12px] px-5 bg-slate-50 py-[12px] text-slate-600 border-t-  border-y border-slate-200 border-y-slate-200 font-semibold">
                  Title
                </th>

                <th className="text-left text-[12px] px-5 bg-slate-50 py-[12px] text-slate-600 border-t-  border-y border-slate-200 border-y-slate-200 font-semibold">
                  Department
                </th>
                <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[12px] text-slate-600 border-t-  border-y border-slate-200 border-y-slate-200 font-semibold">
                  Status
                </th>
                <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[12px] text-slate-600 border-t-  border-y border-slate-200 border-y-slate-200 font-semibold">
                  Reported at
                </th>
                <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[12px] text-slate-600 border-t-  border-y border-slate-200 border-y-slate-200 font-semibold">
                  Reported by
                </th>
              </tr>
            </thead>
            {status === "success" && (
              <tbody className="">
                {issues.map((e, i) => {
                  return (
                    <tr
                      onClick={() => {
                        setissueToShow(e);
                        onClose();
                      }}
                      className="hover:bg-slate-100 cursor-pointer"
                      key={i}
                    >
                      <td className="text-left truncate leading-6 text-[12px] px-5 py-[12px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        {e.title}
                      </td>

                      <td className="text-left capitalize truncate leading-6 text-[12px] px-5 py-[12px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        {e.department?.name || "N/A"}
                      </td>
                      <td
                        className={cn(
                          "text-left capitalize truncate text-[12px] px-5 py-[12px] border-t  border-b border-slate-100 border-b-slate-100 font-medium",
                          {
                            "text-red-500": e.status === "closed",
                            "text-green-500": e.status === "open",
                          }
                        )}
                      >
                        {e.status}
                      </td>
                      <td className="text-left truncate text-[12px] px-5 py-[12px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        {new Date(e.created_at).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </td>
                      <td className="text-left capitalize truncate leading-6 text-[12px] px-5 py-[12px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        {e.createdBy?.first_name + " " + e.createdBy?.last_name}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
          {status === "success" && !issues.length ? (
            <Fragment>
              <div className="flex items-center  justify-center py-20  w-full">
                <span className="text-[13px] text-center leading-7 font-medium text-slate-600">
                  There are no issues available
                </span>
              </div>
            </Fragment>
          ) : null}
          {status === "loading" && (
            <Fragment>
              <div className="w-full flex items-center py-24 justify-center h-full-">
                <Loader />
              </div>
            </Fragment>
          )}
          {status === "error" && (
            <Fragment>
              <div className="w-full flex items-center py-24 justify-center h-full-">
                <span className="text-[12.5px] font-medium text-slate-600">
                  {error["message"]}
                </span>
              </div>
            </Fragment>
          )}
        </div>
      </Modal>
    </>
  );
}

export default IssuesModalDetails;
