import { Formik } from "formik";
import Modal from "../ui/Modal";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { api } from "@/lib/api";
import toast from "react-hot-toast";
import AppFormField from "../forms/AppFormField";
import AppFormTextarea from "../forms/AppFormTextarea";
import AppFormSelect from "../forms/AppFormSelect";
import { cn } from "@/utils/cn";
import Button from "../ui/Button";
import SubmitButton from "../forms/SubmitButton";
import { useMemo, useRef } from "react";
import AppFileUpload from "../forms/AppFileUpload";
import { useEmployee } from "@/context/employee.context";

function IssueModal({ open, onClose, issue, onComplete }: any) {
  const schema = Yup.object().shape({
    title: Yup.string().required().label("title"),
    description: Yup.string().required().label("description"),
    status: Yup.string().required().label("status"),
    department: Yup.string().required().label("department"),
    attachment: Yup.string().required().label("attachment"),
  });

  const initialValues = useMemo(() => {
    return {
      title: issue?.title || "",
      description: issue?.description || "",
      status: issue?.status || "open",
      attachment: issue?.attachment || "",
      department: issue?.department?.id || "",
    };
  }, [issue]);

  const mutation = useMutation({
    mutationFn: (record) =>
      issue
        ? api.put(`/issues/${issue.id}`, record)
        : api.post(`/issues`, record),
  });

  const { employee } = useEmployee();

  const handleSubmit = async (values: any, { resetForm }) => {
    const data = {
      ...values,
      departmentId: values.department,
      createdById: employee?.id,
    };
    delete data["department"];
    await mutation.mutateAsync(data, {
      onError: (error: any) => {
        toast.error(error.response.data.message || error.message);
      },
      onSuccess: () => {
        toast.success(`Task saved sucess`);
        onClose();
        onComplete();
        resetForm();
      },
    });
  };
  const formRef = useRef<any>();

  async function departmentsLoader() {
    const { data } = await api.get("/departments");
    return {
      items: data.map((e) => ({ label: e.name, value: e.id })),
    };
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      <Modal
        title="Report a new issue"
        size="md"
        showDividers={true}
        close={() => {
          onClose();
          formRef.current.resetForm();
        }}
        open={open}
        Actions={() => {
          return (
            <div className="w-full gap-3 justify-end flex">
              <Button
                onClick={() => {
                  onClose();
                  formRef.current.resetForm();
                }}
                size="sm"
                variant="default"
              >
                Cancel
              </Button>
              <SubmitButton size="sm">
                {issue ? "Update" : "Report"} issue
              </SubmitButton>
            </div>
          );
        }}
      >
        <div className="px-1 pb-2 mt-0">
          <div>
            <AppFormField
              name={"title"}
              placeholder="Enter Issue title"
              label="Add issue title"
            />
          </div>
          <div>
            <AppFormSelect
              name={"status"}
              placeholder="Enter Issue status"
              label="Enter issue status"
              OptionItem={({ item }) => {
                const getColor = () => {
                  switch (item?.value) {
                    case "open":
                      return "bg-green-500";
                    case "closed":
                      return "bg-red-500";
                  }
                };
                return (
                  <div className="flex items-center gap-2">
                    <div
                      className={cn("h-3 w-3 rounded-full", getColor())}
                    ></div>
                    <span>{item?.label}</span>
                  </div>
                );
              }}
              options={[
                {
                  label: "open",
                  value: "open",
                },
                {
                  label: "closed",
                  value: "closed",
                },
              ]}
            />
          </div>
          <AppFormSelect
            name="department"
            label="Department"
            placeholder="Choose department"
            loader={departmentsLoader}
          />
          <div className="mt-2">
            <AppFormTextarea
              name={"description"}
              placeholder="Enter Issue description"
              label="Add issue description"
            />
          </div>

          <div className="mt-4">
            <h4 className="text-[12px] uppercase font-medium text-slate-500">
              Attachment file(optional)
            </h4>
            <div>
              <AppFileUpload
                label={"Drag and drop attachment file here"}
                name={"attachment"}
              />
            </div>
          </div>
        </div>
      </Modal>
    </Formik>
  );
}

export default IssueModal;
