import { useQuery } from "react-query";
import { useMemo } from "react";

const useLocations = ({ province, district, sector, cell }) => {
  const fetchLocations = async () => {
    const res = await fetch("/locations.json");
    const data = await res.json();
    return data;
  };

  const { data: locations, status } = useQuery(
    ["locations"],
    () => {
      return fetchLocations();
    },
    {
      keepPreviousData: true,
      retry: false,
      staleTime: Infinity,
    }
  );

  const getProvinces = () => {
    return Object.keys(locations);
  };

  const getDistricts = (province: string) => {
    try {
      const data = locations[province];
      return Object.keys(data);
    } catch (error) {
      return [];
    }
  };

  const getSectors = (province: string, district: string) => {
    try {
      const data = locations[province][district];
      return Object.keys(data);
    } catch (error) {
      return [];
    }
  };

  const getCells = (province: string, district: string, sector: string) => {
    try {
      const data = locations[province][district][sector];
      return Object.keys(data);
    } catch (error) {
      return [];
    }
  };

  const getVillages = (
    province: string,
    district: string,
    sector: string,
    cell: string
  ) => {
    try {
      const data = locations[province][district][sector][cell];
      return data;
    } catch (error) {
      return [];
    }
  };

  const contextValue = useMemo(
    () =>
      locations
        ? {
            loading: status === "loading",
            provinces: getProvinces() || [],
            districts: getDistricts(province) || [],
            sectors: getSectors(province, district) || [],
            cells: getCells(province, district, sector) || [],
            villages: getVillages(province, district, sector, cell) || [],
          }
        : {
            provinces: [],
            districts: [],
            sectors: [],
            cells: [],
            villages: [],
          },
    [province, district, sector, cell, locations]
  );
  return contextValue;
};

export default useLocations;
