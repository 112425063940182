import { api } from "@/lib/api";

class AuthService {
  onAuthStateChangedCallback: any;
  constructor() {
    this.onAuthStateChangedCallback = null;
  }

  async signIn({ email, password }) {
    try {
      const response = await api.post("/users/login", { email, password });
      const user = response.data;
      this.notifyAuthStateChanged({ user, event: "login" });
      return user;
    } catch (error) {
      console.log(error);

      throw new Error(
        error?.response?.data.message ||
          error.message ||
          "Error occurred while logging in"
      );
    }
  }

  onAuthStateChanged(callback: any) {
    this.onAuthStateChangedCallback = callback;
    return () => {
      this.onAuthStateChangedCallback = null;
    };
  }

  private notifyAuthStateChanged({ user, event }: any) {
    if (user) {
      if (event === "update") {
        const existingUserJson = localStorage.getItem("user");
        const existingUser = JSON.parse(existingUserJson);
        localStorage.setItem(
          "user",
          JSON.stringify({ ...existingUser, ...user })
        ); // Store user in localStorage
      } else {
        localStorage.setItem("user", JSON.stringify(user)); // Store user in localStorage
      }
    } else {
      localStorage.removeItem("user"); // Remove user from localStorage
    }

    if (this.onAuthStateChangedCallback) {
      this.onAuthStateChangedCallback({ user, event });
    }
  }

  async refresh_token() {
    const userItem = localStorage.getItem("user");
    const session = userItem
      ? JSON.parse(localStorage.getItem("user") || "")
      : undefined;
    if (!session?.accessToken)
      throw new Error("No access token found, please login again");

    const refreshToken = session.refreshToken;
    if (!refreshToken)
      throw new Error("No refresh token found, please login again");
    return await api
      .post(
        "/users/refresh-token",
        {},
        {
          headers: {
            refresh_token: refreshToken,
          },
        }
      )
      .then((e) => {
        const user = e.data;
        this.notifyAuthStateChanged({ user, event: "refresh" });
        return user;
      })
      .catch(async (e) => {
        if (e.response?.status === 401) {
          await this.logout();
        }
        return null;
      });
  }

  async getCurrentUser() {
    try {
      const userItem = localStorage.getItem("user");
      const session = userItem
        ? JSON.parse(localStorage.getItem("user") || "")
        : undefined;
      if (session?.accessToken) {
        const expiresAt = session.expires_at;
        if (new Date() < new Date(expiresAt)) {
          return session;
        } else {
          return await this.refresh_token();
        }
      }
    } catch (error) {
      console.log(error);
      await this.logout();
    }
  }

  async updateProfile(data: any) {
    try {
      const response = await api.put("/users/profile", data);
      const user = response.data;
      this.notifyAuthStateChanged({ user, event: "update" });
      return user;
    } catch (error) {
      console.log(error.message);
      throw new Error(
        error?.response?.data.message ||
          error.message ||
          "Error occurred updating the profile"
      );
    }
  }

  async changePassword({ currentPassword, newPassword }) {
    try {
      const response = await api.put("/users/change-password", {
        currentPassword,
        newPassword,
      });
      return response.data;
    } catch (error) {
      console.log(error.message);
      throw new Error(
        error?.response?.data.message ||
          error.message ||
          "Error occurred updating the profile"
      );
    }
  }

  async getToken() {
    const localUser = localStorage.getItem("user");
    if (!localUser) return null;
    const session = JSON.parse(localUser);
    if (!session) return null;
    return session?.accessToken;
  }

  async getUser() {
    try {
      const localUser = localStorage.getItem("user");
      if (localUser) Error("No user session available");
      const session = JSON.parse(localUser || "");
      const token = session?.accessToken;

      if (!token)
        throw new Error(
          "No access token found, please login again to get a new access token"
        );

      const response = await api.get("/users/current-user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const user = response.data;
      return user;
    } catch (error) {
      console.log(error);
      throw new Error(
        error?.response?.data.message ||
          error.message ||
          "Error occurred while getting user"
      );
    }
  }

  async forgotPassword({ email }) {
    try {
      const response = await api.post("/users/forgot-password", {
        email,
      });
      const user = response.data;
      return user;
    } catch (error) {
      console.log(error.message);
      throw new Error(
        error?.response?.data.message ||
          error.message ||
          "Error occurred while reseting password"
      );
    }
  }

  async resetPassword({ password, token }) {
    try {
      const response = await api.post("/users/reset-password", {
        password,
        resetToken: token,
      });
      const user = response.data;
      return user;
    } catch (error) {
      throw new Error(
        error?.response?.data.message ||
          error.message ||
          "Error occurred while reseting password"
      );
    }
  }

  async logout() {
    const userItem = localStorage.getItem("user");
    const session = userItem
      ? JSON.parse(localStorage.getItem("user") || "")
      : undefined;
    if (!session?.accessToken)
      throw new Error("No access token found, please login again");

    const refreshToken = session.refreshToken;

    try {
      await api.post(
        "/users/logout",
        {},
        {
          headers: {
            refresh_token: refreshToken,
          },
        }
      );
      localStorage.removeItem("user");
      this.notifyAuthStateChanged({ user: null, event: "logout" });
    } catch (error) {
      throw new Error(
        error?.response?.data.message ||
          error.message ||
          "Error occurred while logging out"
      );
    }
  }
}

const authService = new AuthService();

export default authService;
