import { Formik } from "formik";
import Pannel from "../ui/Pannel";
import * as Yup from "yup";
import removeEmptyAndUndefined from "@/utils/removeEmptyAndUndefined";
import { useMutation } from "react-query";
import { api } from "@/lib/api";
import toast from "react-hot-toast";
import { useMemo, useRef } from "react";
import useConfirm from "@/hooks/useConfirm";
import Button from "../ui/Button";
import SubmitButton from "../forms/SubmitButton";
import DiscardModal from "../modals/DiscardModal";
import AppFormTextarea from "../forms/AppFormTextarea";
import { requizition_types } from "@/data";
import AppFileUpload from "../forms/AppFileUpload";
import AppFormCreatableSelect from "../forms/AppFormCreatableSelect";
import AppFormAsyncSelect from "../forms/AppFormAsyncSelect";
import employeesLoader from "@/utils/employeesLoader";
import UserLabel from "../UserLabel";
import AppFormField from "../forms/AppFormField";
export default function RequisitionsFormPannel({
  requisitions,
  employee,
  children,
  onClose,
  onComplete,
  ...other
}: any) {
  const mutation = useMutation({
    mutationFn: (record) => {
      return requisitions
        ? api.put(`/requisitions/${requisitions.id}`, record)
        : api.post("/requisitions", record);
    },
  });

  const handleSubmit = async (values: any, { resetForm }) => {
    const data: any = removeEmptyAndUndefined({
      employeeId: values?.employee?.value || "",
      reason: values?.reason || "",
      type: values?.type || "",
      amount: values?.amount || "",
      title: values?.title || "",
      attachment: values?.attachment || "",
    });

    await mutation.mutateAsync(data, {
      onError: (error: any) => {
        toast.error(error.response.data.message || error.message);
      },
      onSuccess: () => {
        toast.success(`requisitions saved sucess`);
        onClose();
        onComplete();
        resetForm();
      },
    });
  };

  const schema = Yup.object().shape({
    employee: Yup.object({
      value: Yup.number().required().label("employee"),
      label: Yup.string().required(),
    }),
    reason: Yup.string().required(),
    type: Yup.string().required(),
    title: Yup.string().required(),
    amount: Yup.string().required(),
    attachment: Yup.string(),
  });

  const initialValues = useMemo(() => {
    return {
      employee: employee?.id
        ? {
            label: employee.first_name + " " + employee.last_name,
            value: employee.id,
            component: () => <UserLabel user={employee} />,
          }
        : requisitions
        ? {
            label:
              requisitions.employee.first_name +
              " " +
              requisitions.employee.last_name,
            value: requisitions.employee.id,
            component: () => <UserLabel user={requisitions.employee} />,
          }
        : "",
      reason: requisitions?.reason || "",
      type: requisitions?.type || "",
      amount: requisitions?.amount || "",
      title: requisitions?.title || "",
      attachment: requisitions?.attachment || "",
    };
  }, [requisitions]);

  const formRef = useRef<any>();

  const confirmDiscard = useConfirm({
    onAccept: () => {
      onClose();
      formRef.current?.resetForm();
    },
  });

  const handleClose = ({ touched }) => {
    if (Object.keys(touched)?.length) {
      confirmDiscard.open();
    } else {
      onClose();
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      {(e) => (
        <>
          <Pannel
            onClose={() => handleClose(e)}
            {...other}
            title={"Create a new requisitions"}
          >
            <div className="h-0 flex-1 overflow-y-auto">
              <div className="flex flex-1 flex-col justify-between">
                <div className="divide-y pb-4 divide-gray-200 px-4 sm:px-6">
                  <div className="mt-3 px-[2px]">
                    <div>
                      <div>
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          requisitions Information
                        </h4>
                      </div>
                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormAsyncSelect
                          name="employee"
                          label="Choose employee"
                          placeholder="Employee"
                          disabled={Boolean(employee)}
                          loadOptions={employeesLoader}
                        />
                      </div>
                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormField
                          name="title"
                          label="title"
                          placeholder="Enter requisitions title"
                        />
                      </div>

                      <div className="grid mt-1 grid-cols-1 gap-3">
                        <AppFormCreatableSelect
                          name="type"
                          label="requisitions type"
                          placeholder="requisitions type"
                          options={requizition_types}
                        />
                      </div>
                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormTextarea
                          name="reason"
                          label="Reason"
                          placeholder="Enter requisitions reason"
                          rows={4}
                        />
                      </div>
                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormField
                          name="amount"
                          label="amount"
                          placeholder="Enter requisition amount"
                          type="number"
                        />
                      </div>

                      <div className="mt-4">
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          requisitions attachment
                        </h4>
                        <div>
                          <AppFileUpload
                            label={"Drag and drop requisitions Attachment here"}
                            name={"attachment"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-2 flex-shrink-0 justify-end px-4 py-2">
              <Button
                onClick={() => {
                  e.resetForm();
                }}
                variant="default"
                size="sm"
              >
                Reset
              </Button>
              <SubmitButton size="sm">Save requisitions</SubmitButton>
            </div>
          </Pannel>

          <DiscardModal state={confirmDiscard} />
        </>
      )}
    </Formik>
  );
}
