import Avatar from "./ui/Avatar";

export default function UserLabel({ user }) {
  return (
    <div className="flex py-[3px] items-center gap-2">
      <Avatar
        rounded="full"
        size="xs"
        name={user?.first_name + " " + user?.last_name}
      />
      <span className="text-[13px] !leading-3 capitalize font-medium text-slate-600">
        {user?.first_name + " " + user?.last_name}
      </span>
    </div>
  );
}
