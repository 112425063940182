import { useAuth } from "@/context/auth.context";
import AdminDashboard from "./AdminDashboard";
import { Navigate } from "react-router-dom";

export default function Dashboard() {
  const { user }: any = useAuth();
  return (
    <>
      {user.role === "admin" ? <AdminDashboard /> : <Navigate to={"/portal"} />}
    </>
  );
}
