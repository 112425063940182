import { useFormikContext } from "formik";
import Dakepicker from "../ui/Datepicker";

export default function AppFormDakepicker({ name, ...other }) {
  const { setFieldValue, values, errors, handleBlur, touched }: any =
    useFormikContext();
  return (
    <Dakepicker
      errorMessage={errors[name] && touched[name] ? errors[name] : ""}
      onChange={(e) => {
        setFieldValue(name, e);
      }}
      onBlur={handleBlur}
      value={values[name]}
      name={name}
      {...other}
    />
  );
}
