import { useState, useEffect } from "react";

const Avatar = ({
  src = "",
  name = "",
  size = "md",
  variant = "filled",
  rounded = "sm",
}) => {
  const colorWithLetters = [
    { color: "#F44336", letters: ["a", "b"] },
    { color: "#E91E63", letters: ["c", "d"] },
    { color: "#9C27B0", letters: ["e", "f"] },
    { color: "#673AB7", letters: ["g", "h"] },
    { color: "#3F51B5", letters: ["i", "j"] },
    { color: "#2196F3", letters: ["k", "l"] },
    { color: "#03A9F4", letters: ["m", "n"] },
    { color: "#00BCD4", letters: ["o", "p"] },
    { color: "#009688", letters: ["q", "r"] },
    { color: "#4CAF50", letters: ["s", "t"] },
    { color: "#8BC34A", letters: ["u", "v", "z"] },
    { color: "#FF9800", letters: ["w", "x", "y"] },
  ];

  const [color, setColor] = useState("");

  useEffect(() => {
    const getColor = () => {
      const foundColor = colorWithLetters.find((item) =>
        item.letters.includes(name.toLowerCase().trim()[0] || "g")
      )?.color;
      return variant === "light" ? foundColor + "40" : foundColor;
    };

    setColor(getColor());
  }, [name, variant]);

  const variantClass = {
    filled: `bg-opacity-100`,
    light: "bg-opacity-30",
    outline: "border",
  }[variant];

  const sizeClass = {
    xs: "h-[20px] w-[20px] text-[8px]",
    sm: "h-[28px] w-[28px] text-[10px]",
    md: "h-[38px] w-[38px] text-[13px]",
    lg: "h-[60px] w-[60px] text-[17px]",
    xl: "h-[74px] w-[74px] text-[20px]",
  }[size];

  const roundedClass = {
    xs: "rounded-[2px]",
    sm: "rounded-[4px]",
    md: "rounded-[8px]",
    lg: "rounded-[16px]",
    xl: "rounded-[32px]",
    full: "rounded-full",
  }[rounded];

  const avatarClass =
    "flex items-center border border-slate-200 justify-center overflow-hidden capitalize";

  const [error, seterror] = useState(false);

  return (
    <div className={`${avatarClass} ${roundedClass} ${sizeClass}`}>
      {src && !error ? (
        <img
          alt="user profile"
          src={src}
          onLoad={() => {
            seterror(false);
          }}
          onError={() => {
            seterror(true);
          }}
          className="h-full bg-gray-200 object-cover w-full"
        />
      ) : (!src || error) && name ? (
        <div
          style={{
            color: variant === "light" ? color.slice(0, 7) : "white",
            background: color,
          }}
          className={`h-full w-full uppercase flex font-medium tracking-[1px] items-center justify-center ${variantClass}`}
        >
          <span>
            {name.split(" ")[0][0] +
              (name?.split(" ")[1] ? name?.split(" ")[1][0] : "")}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default Avatar;
