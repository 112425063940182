import { cn } from "@/utils/cn";
import React from "react";
import { useTextField } from "react-aria";

export default function Input(props: any) {
  let { label, errorMessage, hideErrorMessage } = props;
  let ref = React.useRef(null);
  let { labelProps, inputProps, errorMessageProps } = useTextField(props, ref);

  return (
    <div className={cn("py-[6px]", props.inputContainer)}>
      <label
        className="block text-[13px] mb-1 capitalize font-medium text-slate-700"
        {...labelProps}
      >
        {label}
      </label>
      <input
        autoFocus={false}
        className={cn(
          "shadow-sm text-slate-600 placeholder:text-slate-500 font-medium outline-none placeholder:text-[12.5px] text-[12.5px] border px-3 py-[10px]  block w-full  rounded-md",
          {
            "focus:ring-red-500 focus:border-red-500 border border-red-500":
              errorMessage,
            "focus:ring-primary focus:border-primary border-gray-300 ":
              !errorMessage,
          },
          props.inputClass
        )}
        {...inputProps}
        ref={ref}
      />

      {errorMessage && !hideErrorMessage && (
        <div
          {...errorMessageProps}
          className="block text-[11.7px] truncate mt-1 capitalize font-medium text-red-500"
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
}
