import authService from "@/services/auth.service";
import authClient from "@/services/auth.service";
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import { useEffectOnce } from "usehooks-ts";

interface User {
  id: any;
  phone: any;
  names: any;
  photo: any;
  email: any;
  role: any;
}

interface AuthContextValue {
  user: User | null;
  setCurrentUser: (userData: User) => void;
  logout: () => void;
  loading: boolean;
}

const AuthContext = createContext<AuthContextValue | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setloading] = useState(true);

  const setCurrentUser = (user) => {
    if (user) {
      setUser({
        id: user?.id,
        phone: user?.phone,
        names: user?.names,
        email: user?.email,
        photo: user?.photo,
        role: user.role,
      });
    }
    setTimeout(() => {
      setloading(false);
    }, 500);
  };

  const logout = () => {
    setUser(null);
  };

  const contextValue = useMemo(
    () => ({
      user,
      setCurrentUser,
      logout,
      loading,
    }),
    [user, loading]
  );

  const loaderUser = async () => {
    const user = await authClient.getCurrentUser();
    setCurrentUser(user);
  };

  useEffectOnce(() => {
    loaderUser();
  });

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged(({ user }) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    loaderUser();
  }, []);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
