import { AlertCircle, CheckCircle } from "react-feather";

export default function Alert({ danger, success, children, info }: any) {
  return (
    <div
      className={`text-[12.8px] flex items-start leading-6 gap-3 px-4 py-[10px] rounded my-2 font-medium capitalize ${
        danger ? "bg-red-50 border border-red-500 text-red-400" : undefined
      } 
   ${
     success
       ? "bg-[#73d37324] border border-green-500 text-green-500"
       : undefined
   } ${
        info
          ? "bg-yellow-600 bg-opacity-10 border border-yellow-600 text-yellow-600"
          : undefined
      }`}
    >
      <div>
        {danger && <AlertCircle size={15} className="text-red-500 mt-1" />}
        {success && <CheckCircle size={15} className="text-green-500 mt-1" />}
      </div>
      {children}
    </div>
  );
}
