import { Formik } from "formik";
import Pannel from "../ui/Pannel";
import * as Yup from "yup";
import removeEmptyAndUndefined from "@/utils/removeEmptyAndUndefined";
import { useMutation } from "react-query";
import { api } from "@/lib/api";
import toast from "react-hot-toast";
import { useMemo, useRef } from "react";
import useConfirm from "@/hooks/useConfirm";
import Button from "../ui/Button";
import SubmitButton from "../forms/SubmitButton";
import DiscardModal from "../modals/DiscardModal";
import AppFormTextarea from "../forms/AppFormTextarea";
import AppFileUpload from "../forms/AppFileUpload";
import AppFormSelect from "../forms/AppFormSelect";
import AppFormField from "../forms/AppFormField";
import AppFormDakepicker from "../forms/AppFormDatepicker";
import { cn } from "@/utils/cn";
import AppFormAsyncSelect from "../forms/AppFormAsyncSelect";
import employeesLoader from "@/utils/employeesLoader";
export default function TrainingFormPannel({
  training,
  children,
  onClose,
  onComplete,
  ...other
}: any) {
  const mutation = useMutation({
    mutationFn: (record) => {
      return training
        ? api.put(`/trainings/${training.id}`, record)
        : api.post("/trainings", record);
    },
  });

  const handleSubmit = async (values: any) => {
    const data: any = removeEmptyAndUndefined({
      name: values.name,
      description: values.description,
      assignedToId: parseInt(values.assignedTo.value),
      status: values.status,
      start_date: values.start_date,
      end_date: values.end_date,
      cost: parseInt(values.cost) || 0,
      category: values.category,
      attachments: values.attachment ? [values.attachment] : [],
      modules: [],
      cover_photo: "",
    });

    await mutation.mutateAsync(data, {
      onError: (error: any) => {
        toast.error(error.response.data.message || error.message);
      },
      onSuccess: () => {
        toast.success(`training saved sucess`);
        onClose();
        onComplete();
      },
    });
  };

  const schema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
    assignedTo: Yup.object({
      value: Yup.number().required().label("AssignedTo"),
      label: Yup.string().required(),
    }),
    status: Yup.string().required(),
    start_date: Yup.string().required().label("start date"),
    end_date: Yup.string().required().label("start date"),
    cost: Yup.number().required(),
    category: Yup.string().required(),
    attachment: Yup.string(),
  });

  const initialValues = useMemo(() => {
    return {
      name: training?.name || "",
      description: training?.description || "",
      assignedTo: training?.assignedTo?.id || "",
      status: training?.status || "",
      start_date: training?.start_date || "",
      end_date: training?.end_date || "",
      cost: training?.cost || "",
      category: training?.category || "",
      attachment: training?.attachments?.length ? training?.attachments[0] : "",
    };
  }, [training]);

  const formRef = useRef<any>();

  const confirmDiscard = useConfirm({
    onAccept: () => {
      onClose();
      formRef.current?.resetForm();
    },
  });

  const handleClose = ({ touched }) => {
    if (Object.keys(touched)?.length) {
      confirmDiscard.open();
    } else {
      onClose();
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      {(e) => (
        <>
          <Pannel
            onClose={() => handleClose(e)}
            {...other}
            title={"Create a new training"}
          >
            <div className="h-0 flex-1 overflow-y-auto">
              <div className="flex flex-1 flex-col justify-between">
                <div className="divide-y pb-4 divide-gray-200 px-4 sm:px-6">
                  <div className="mt-3 px-[2px]">
                    <div>
                      <div>
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          Training Information
                        </h4>
                      </div>
                      <div className="grid mt-2 grid-cols-1 gap-2">
                        <AppFormField
                          name="name"
                          label="name"
                          placeholder="Enter name"
                        />
                      </div>
                      <div className="grid mt-0 grid-cols-2 gap-2">
                        <AppFormSelect
                          name="category"
                          label="Category"
                          placeholder="Choose category"
                          options={[
                            {
                              label: "Project & Accounting",
                              value: "project-accounting",
                            },
                            {
                              label: "Advanced project",
                              value: "advanced-project",
                            },
                          ]}
                        />
                        <AppFormAsyncSelect
                          name="assignedTo"
                          label="Assigned To"
                          placeholder="Choose Employee"
                          loadOptions={employeesLoader}
                        />
                      </div>
                      <div className="grid mt-1 grid-cols-2 gap-3">
                        <AppFormDakepicker
                          name="start_date"
                          label="Start Date"
                          placeholder="Choose start date"
                        />
                        <AppFormDakepicker
                          name="end_date"
                          label="End date"
                          placeholder="Choose end date"
                        />
                      </div>
                      <div className="grid mt-1 grid-cols-2 gap-3">
                        <AppFormField
                          name="cost"
                          label="cost"
                          placeholder="Enter cost"
                          type="number"
                        />
                        <AppFormSelect
                          name="status"
                          label="Training status"
                          placeholder="Training status"
                          options={[
                            {
                              label: "upcoming",
                              value: "upcoming",
                            },
                            {
                              label: "on going",
                              value: "on-going",
                            },
                            {
                              label: "completed",
                              value: "completed",
                            },
                            {
                              label: "cancelled",
                              value: "cancelled",
                            },
                          ]}
                          OptionItem={({ item }) => {
                            const getColor = () => {
                              switch (item?.value) {
                                case "on-going":
                                  return "bg-orange-500";
                                case "completed":
                                  return "bg-green-500";
                                case "cancelled":
                                  return "bg-red-500";
                                case "upcoming":
                                  return "bg-pink-500";
                              }
                            };
                            return (
                              <div className="flex items-center gap-2">
                                <div
                                  className={cn(
                                    "h-3 w-3 rounded-full",
                                    getColor()
                                  )}
                                ></div>
                                <span>{item?.label}</span>
                              </div>
                            );
                          }}
                        />
                      </div>
                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormTextarea
                          name="description"
                          label="description"
                          placeholder="Description"
                          rows={3}
                        />
                      </div>
                      <AppFileUpload
                        label={"Drag and drop training attachment"}
                        name={"attachment"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-2 flex-shrink-0 justify-end px-4 py-2">
              <Button
                onClick={() => handleClose(e)}
                variant="default"
                size="sm"
              >
                Cancel
              </Button>
              <SubmitButton size="sm">Save training</SubmitButton>
            </div>
          </Pannel>

          <DiscardModal state={confirmDiscard} />
        </>
      )}
    </Formik>
  );
}
