import { Formik } from "formik";
import Pannel from "../ui/Pannel";
import * as Yup from "yup";
import removeEmptyAndUndefined from "@/utils/removeEmptyAndUndefined";
import { useMutation } from "react-query";
import { api } from "@/lib/api";
import toast from "react-hot-toast";
import { useMemo, useRef } from "react";
import useConfirm from "@/hooks/useConfirm";
import Button from "../ui/Button";
import SubmitButton from "../forms/SubmitButton";
import DiscardModal from "../modals/DiscardModal";
import AppFormTextarea from "../forms/AppFormTextarea";
import AppFormDakepicker from "../forms/AppFormDatepicker";
import { leave_types } from "@/data";
import AppFileUpload from "../forms/AppFileUpload";
import AppFormCreatableSelect from "../forms/AppFormCreatableSelect";
import AppFormAsyncSelect from "../forms/AppFormAsyncSelect";
import employeesLoader from "@/utils/employeesLoader";
import UserLabel from "../UserLabel";
export default function LeaveFormPannel({
  leave,
  employee,
  children,
  onClose,
  onComplete,
  ...other
}: any) {
  const mutation = useMutation({
    mutationFn: (record) => {
      return leave
        ? api.put(`/leaves/${leave.id}`, record)
        : api.post("/leaves", record);
    },
  });

  const handleSubmit = async (values: any, { resetForm }) => {
    const data: any = removeEmptyAndUndefined({
      employeeId: values?.employee?.value || "",
      start_date: values?.start_date || "",
      end_date: values?.end_date || "",
      reason: values?.reason || "",
      type: values?.type || "",
      attachment: values?.attachment || "",
    });

    await mutation.mutateAsync(data, {
      onError: (error: any) => {
        toast.error(error.response.data.message || error.message);
      },
      onSuccess: () => {
        toast.success(`Leave saved sucess`);
        onClose();
        onComplete();
        resetForm();
      },
    });
  };

  const schema = Yup.object().shape({
    employee: Yup.object({
      value: Yup.number().required().label("employee"),
      label: Yup.string().required(),
    }),
    start_date: Yup.date().required().label("start date"),
    end_date: Yup.date()
      .required()
      .when("start_date", (startDate, schema) => {
        return startDate
          ? schema.min(
              startDate,
              "End date must be greater than or equal to the start date"
            )
          : schema;
      })
      .label("end date"),
    reason: Yup.string().required(),
    type: Yup.string().required(),
    attachment: Yup.string(),
  });

  const initialValues = useMemo(() => {
    return {
      employee: employee?.id
        ? {
            label: employee.first_name + " " + employee.last_name,
            value: employee.id,
            component: () => <UserLabel user={employee} />,
          }
        : leave
        ? {
            label: leave.employee.first_name + " " + leave.employee.last_name,
            value: leave.employee.id,
            component: () => <UserLabel user={leave.employee} />,
          }
        : "",
      start_date: leave?.start_date || "",
      end_date: leave?.end_date || "",
      reason: leave?.reason || "",
      type: leave?.type || "",
      attachment: leave?.attachment || "",
    };
  }, [leave]);

  const formRef = useRef<any>();

  const confirmDiscard = useConfirm({
    onAccept: () => {
      onClose();
      formRef.current?.resetForm();
    },
  });

  const handleClose = ({ touched }) => {
    if (Object.keys(touched)?.length) {
      confirmDiscard.open();
    } else {
      onClose();
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      {(e) => (
        <>
          <Pannel
            onClose={() => handleClose(e)}
            {...other}
            title={"Create a new leave"}
          >
            <div className="h-0 flex-1 overflow-y-auto">
              <div className="flex flex-1 flex-col justify-between">
                <div className="divide-y pb-4 divide-gray-200 px-4 sm:px-6">
                  <div className="mt-3 px-[2px]">
                    <div>
                      <div>
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          Leave Information
                        </h4>
                      </div>
                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormAsyncSelect
                          name="employee"
                          label="Choose employee"
                          placeholder="Employee"
                          disabled={Boolean(employee)}
                          loadOptions={employeesLoader}
                        />
                      </div>
                      <div className="grid mt-1 grid-cols-2 gap-3">
                        <AppFormDakepicker
                          name="start_date"
                          label="Start Date"
                          placeholder="Choose start date"
                          options={{
                            minDate: new Date(),
                          }}
                        />{" "}
                        <AppFormDakepicker
                          name="end_date"
                          label="End date"
                          placeholder="Choose end date"
                          options={{
                            minDate: new Date(),
                          }}
                        />
                      </div>
                      <div className="grid mt-1 grid-cols-1 gap-3">
                        <AppFormCreatableSelect
                          name="type"
                          label="Leave type"
                          placeholder="Leave type"
                          options={leave_types}
                        />
                      </div>
                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormTextarea
                          name="reason"
                          label="Reason"
                          placeholder="Enter leave reason"
                          rows={4}
                        />
                      </div>
                      <div className="mt-4">
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          Leave attachment
                        </h4>
                        <div>
                          <AppFileUpload
                            label={"Drag and drop leave Attachment here"}
                            name={"attachment"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-2 flex-shrink-0 justify-end px-4 py-2">
              <Button
                onClick={() => {
                  e.resetForm();
                }}
                variant="default"
                size="sm"
              >
                Reset
              </Button>
              <SubmitButton size="sm">Save leave</SubmitButton>
            </div>
          </Pannel>

          <DiscardModal state={confirmDiscard} />
        </>
      )}
    </Formik>
  );
}
