import { useRef } from "react";
import { X } from "react-feather";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { cn } from "@/utils/cn";

function Modal({
  size = "lg",
  open = false,
  title = "",
  showDividers = false,
  close = null,
  noPadding = false,
  noBackground = false,
  showCloseButton = true,
  children,
  Actions,
  blurOverlay,
}: any) {
  const sizeClass = {
    xs: "max-w-[320px]",
    sm: "max-w-[380px]",
    md: "max-w-[520px]",
    lg: "max-w-[620px]",
    xl: "max-w-[780px]",
  }[size];

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-[60] inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => close()}
      >
        <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={cn(
                "fixed inset-0 bg-black bg-opacity-50 transition-opacity",
                {
                  "backdrop-blur-md": blurOverlay,
                }
              )}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`relative overflow-hidden h-fit inline-block rounded-[4px] transform transition-all my-8 align-middle w-full ${sizeClass} ${
                noBackground ? "" : "bg-white shadow-md"
              }`}
            >
              <div
                className={`px-4 ${
                  showDividers ? "border-b py-2  border-slate-200" : "pb-2 pt-3"
                }`}
              >
                <div className="flex justify-between items-center">
                  {title && (
                    <h4 className="font-semibold text-[13.5px] text-slate-800 ">
                      {title}
                    </h4>
                  )}
                  {showCloseButton ? (
                    <a
                      onClick={() => close()}
                      className="h-7 w-7 text-slate-600 active:translate-y-[1px]  flex items-center justify-center rounded-sm cursor-pointer hover:bg-slate-100"
                    >
                      <X size={16} />
                    </a>
                  ) : (
                    <div className="h-6" />
                  )}
                </div>
              </div>

              <div
                className={cn(
                  noPadding ? "" : "px-3 py-2",
                  "max-h-[500px] overflow-y-auto"
                )}
                id="modalDescription"
              >
                {children}
              </div>
              {Actions && (
                <div
                  className={`${showDividers ? "border-t !py-1" : ""} ${
                    noPadding ? "" : "px-4 py-2"
                  } border-slate-200 `}
                >
                  <Actions />
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Modal;
