import { useToggleState } from "react-stately";
import { useCheckbox } from "react-aria";
import { useRef } from "react";
import { cn } from "@/utils/cn";

function Checkbox(props) {
  let { children, disabled } = props;
  let state = useToggleState(props);
  let ref = useRef(null);
  let { inputProps } = useCheckbox(props, state, ref);

  return (
    <label style={{ display: "block" }}>
      <div
        className={cn("flex items-start  gap-[4px]", {
          "opacity-60 pointer-events-none": disabled,
        })}
      >
        <div>
          <input
            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-transparent checked:bg-primary checked:border-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            {...inputProps}
            ref={ref}
            disabled={disabled}
          />
        </div>
        <span className="text-[12.5px] font-medium leading-6 text-slate-600">
          {children}
        </span>
      </div>
    </label>
  );
}

export default Checkbox;
