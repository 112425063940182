import AppForm from "@/components/forms/AppForm";
import AppFormField from "@/components/forms/AppFormField";
import AppFormStatus from "@/components/forms/AppFormStatus";
import SubmitButton from "@/components/forms/SubmitButton";
import Checkbox from "@/components/ui/Checkbox";
import authClient from "@/services/auth.service";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import qs from "qs";

export default function ResetPassword() {
  const token = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })?.token;

  const handleSubmit = (values: any, { setStatus, resetForm }) => {
    return authClient
      .resetPassword({
        password: values.password,
        token: token,
      })
      .then(() => {
        resetForm();
        setStatus({
          success: "Password reset successfully, You can login now.",
        });
      })
      .catch((err) => {
        setStatus({
          error: err.message || "Something went wrong",
        });
      });
  };
  return (
    <div className="max-w-lg border border-slate-200 overflow-hidden shadow-sm my-12 mx-auto bg-white rounded-md">
      <div className="flex justify-between items-center- bg-primary bg-opacity-10 gap-3">
        <div className="space-y-2 px-5 py-4">
          <h4 className="text-base font-semibold text-primary">
            Reset your password.
          </h4>
          <p className="text-[13px] leading-7 text-primary font-medium text-opacity-70">
            Enter your new password and confirm password.
          </p>
        </div>
        <img className="h-[115px] w-[190px]" src="/profile-img.png" alt="" />
      </div>
      <div className="px-6 py-4">
        <AppForm
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required("Password is required")
              .min(6, "Password is too short - should be 6 chars minimum."),
            confirm_password: Yup.string()
              .required()
              .label("Confirm password")
              .oneOf([Yup.ref("password"), null], "Passwords must match"),
          })}
          initialValues={{
            confirm_password: "",
            password: "",
          }}
          onSubmit={handleSubmit}
        >
          <div>
            <AppFormStatus />
          </div>
          <div>
            <AppFormField
              name={"password"}
              placeholder="Enter your password"
              label="Enter your password"
              type="password"
            />
            <AppFormField
              name={"confirm_password"}
              placeholder="Confirm your password"
              label="Confirm password"
              type="password"
            />
          </div>
          <div className="w-full mb-2">
            <Checkbox name="Igree">I agree to the terms & conditions.</Checkbox>
          </div>
          <div className="w-full">
            <SubmitButton fullWidth>Reset your password</SubmitButton>
          </div>
          <div className="flex items-center justify-center w-full  py-3">
            <Link
              className="text-[13.5px] hover:underline text-slate-500 font-medium"
              to="/auth/login"
            >
              Remember your password? Login.
            </Link>
          </div>
        </AppForm>
      </div>
    </div>
  );
}
