import { api } from "@/lib/api";
import React, { createContext, useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { useAuth } from "./auth.context";

const AuthContext = createContext<any>(null);

export function EmployeeProvider({ children }: { children: React.ReactNode }) {
  const { user }: any = useAuth();

  async function fetchData() {
    const { data } = await api.get(`/employees`, {
      params: {
        page_size: 1,
        email: user?.email,
      },
    });
    return data[0];
  }

  const {
    data: employee,
    status,
    error,
    refetch,
    isLoading,
  } = useQuery(["current-employee", user?.email], fetchData, {
    keepPreviousData: true,
    retry: false,
    staleTime: Infinity,
    enabled: Boolean(user),
    cacheTime: 1000 * 60 * 60 * 0.5,
  });

  const contextValue = useMemo(
    () => ({
      employee,
      status: status,
      error: error,
      loading: isLoading,
      refetch,
    }),
    [user, employee, isLoading]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export function useEmployee() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useEmployee must be used within an EmployeeProvider");
  }
  return context;
}
