import DashboardCard from "@/components/cards/DashboardCard";
import { api } from "@/lib/api";
import { cn } from "@/utils/cn";
import { LogOut, UserCheck, UserX, Users } from "react-feather";
import { useQuery } from "react-query";
import { Link, Outlet, useLocation } from "react-router-dom";

export default function Attendances() {
  const tabs = [
    { name: "Daily attendances", path: "/hr/attendance" },
    { name: "Attendance view", path: "/hr/attendance/view" },
  ];

  async function fetchAnalytics() {
    const { data } = await api.get("/analytics", {
      params: {
        show: [
          "attendance-present",
          "attendance-absent",
          "on-leave",
          "employees",
        ],
      },
    });
    return data;
  }
  const { data: analytics } = useQuery(
    ["attendances-analytics"],
    fetchAnalytics,
    {
      keepPreviousData: true,
      retry: false,
      staleTime: Infinity,
    }
  );

  const location = useLocation();

  const cards = [
    {
      name: "present",
      title: "Total present",
      value: analytics ? analytics["attendance-present"] : "---",
      icon: <UserCheck size={15} className="text-green-500" />,
      increase: 13.5,
      bgLight: "bg-green-100",
    },
    {
      name: "absent",
      title: "Today absent",
      value: analytics ? analytics["attendance-absent"] : "---",
      icon: <UserX size={15} className="text-red-500" />,
      increase: -10,
      bgLight: "bg-red-100",
    },
    {
      name: "leaves",
      title: "Employees on leave",
      value: analytics ? analytics["on-leave"] : "---",
      icon: <LogOut size={15} className="text-blue-500" />,
      increase: +70.5,
      bgLight: "bg-blue-100",
    },
    {
      name: "employees",
      title: "Today employees",
      value: analytics ? analytics["employees"] : "---",
      icon: <Users size={15} className="text-orange-500" />,
      increase: +9.5,
      bgLight: "bg-orange-100",
    },
  ];
  return (
    <div>
      <div className="my-1 flex items-center justify-between">
        <div className="space-y-1">
          <h3 className="text-[15px] font-semibold text-slate-700">
            Manage Attendances!!
          </h3>
          <p className="text-[13.5px] leading-7 font-medium text-slate-500">
            Here you can manage your employees attendances.
          </p>
        </div>
        <div>
          {/* <Button LeftIcon={PlusCircle} size="sm">
            Create Manual Attendance
          </Button> */}
        </div>
      </div>
      <div className="mt-3">
        <div className="grid grid-cols-4 gap-3">
          {cards.map((item, index) => {
            return <DashboardCard item={item} key={index} />;
          })}
        </div>
      </div>
      <div className="flex items-center mx-2 mt-4 gap-1 border-b border-slate-200">
        {tabs.map((tab, i) => (
          <Link
            key={i}
            className={cn(
              " text-center flex items-center font-semibold justify-center relative text-[13px] py-3 px-5",
              {
                "text-primary font-semibold": location.pathname === tab.path,
                " text-slate-500": location.pathname !== tab.path,
              }
            )}
            to={tab.path}
          >
            {tab.name}
            {location.pathname === tab.path && (
              <div className="h-[3px] rounded-t-3xl bg-primary bottom-0 w-full absolute"></div>
            )}
          </Link>
        ))}
      </div>
      <div className="px-2 py-4">
        <Outlet />
      </div>
    </div>
  );
}
