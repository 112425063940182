import Button from "@/components/ui/Button";
import { useNavigate } from "react-router-dom";
import { AlertCircle } from "react-feather";

export default function Page404() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex bg-white  max-w-2xl border border-slate-200 mx-auto my-12 items-center justify-center flex-col gap-4 py-24">
        <div className="bg-red-200 border-[6px] border-red-100 h-16 w-16 rounded-full flex items-center justify-center">
          <AlertCircle className="text-red-500" size={22} />
        </div>
        <div className="flex flex-col gap-2 text-center">
          <h4 className="font-semibold text-[15px]">Opps!! - Page not found</h4>
          <h4 className="font-medium mt-1 text-slate-500 leading-7 text-[13.5px]">
            The page you are looking for does not exist. Please check the URL{" "}
            <br /> or go back to the homepage.
          </h4>
          <div className="mt-2">
            <Button
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Go back to homepage
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
