import Datatable from "@/components/Datatable";
import { Edit, Eye, PlusCircle } from "react-feather";
import { createColumnHelper } from "@tanstack/table-core";
import { api } from "@/lib/api";
import Button from "@/components/ui/Button";
import { cn } from "@/utils/cn";
import { useEditRow } from "@/hooks/useEditRow";
import { useOverlayTriggerState } from "react-stately";
import { useQueryClient } from "react-query";
import { useState } from "react";
import TrainingFormPannel from "@/components/pannels/TrainingFormPannel";
import { Link, useNavigate } from "react-router-dom";

function clearEmptyValues(obj: any) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== "") {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

async function fetchData({
  pageIndex: page,
  pageSize: page_size,
  query,
  ...rest
}: {
  pageIndex: number;
  pageSize: number;
  query: string;
  sorting: any;
}) {
  const filterObj = {};
  const filters = ["type", "status"];

  filters.forEach((item) => {
    if (Object.keys(rest).includes(item) && rest[item]) {
      filterObj[item] = rest[item];
    }
  });
  const params = clearEmptyValues({
    page: page === 0 ? 1 : page,
    page_size,
    query,
    ...filterObj,
  });
  const { data } = await api.get("/trainings", {
    params: params,
  });
  return {
    rows: data.results,
    pageCount: data.page,
    total: data.total,
  };
}

interface Leave {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  status: string;
  assigned_to: string;
  category: string;
  participants: number;
  duration: number;
}

const columnHelper = createColumnHelper<Leave>();

const columns = [
  columnHelper.accessor("name", {
    cell: (info) => {
      return <span className="truncate capitalize">{info.renderValue()}</span>;
    },
    header: () => "Name",
  }),

  columnHelper.accessor("assigned_to", {
    cell: (info) => (
      <Link
        to={`?show_employee=${info.row.original["assignedTo"]["id"]}`}
        className="truncate underline hover:text-primary capitalize"
      >
        {info.row.original["assignedTo"]["first_name"]}{" "}
        {info.row.original["assignedTo"]["last_name"]}
      </Link>
    ),
    header: () => "Assigned To",
  }),
  columnHelper.accessor("category", {
    cell: (info) => (
      <span className="truncate capitalize">{info.renderValue()}</span>
    ),
    header: () => "Category",
  }),
  columnHelper.accessor("duration", {
    cell: (info) => (
      <span className="truncate capitalize">{info.renderValue()}</span>
    ),
    header: () => "Duration",
  }),
  columnHelper.accessor("status", {
    cell: (info) => {
      const getColor = () => {
        switch (info.renderValue()) {
          case "upcoming":
            return "text-blue-500";
          case "on-going":
            return "text-orange-500";
          case "completed":
            return "text-green-500";
          case "cancelled":
            return "text-red-500";
        }
      };
      return (
        <span className={cn("capitalize truncate", getColor())}>
          {info.renderValue()}
        </span>
      );
    },
    header: () => "Status",
    meta: {
      allowFiltering: true,
      filterType: "select",
      filterOptions: [
        {
          label: "Upcoming",
          value: "upcoming",
        },
        {
          label: "Ongoing",
          value: "on-going",
        },
        {
          label: "Completed",
          value: "completed",
        },
        {
          label: "Cancelled",
          value: "cancelled",
        },
      ],
    },
  }),
  columnHelper.accessor("participants", {
    cell: (info) => {
      return (
        <span className={cn("capitalize truncate")}>{info.renderValue()}</span>
      );
    },
    header: () => "Participants",
  }),
  columnHelper.accessor("start_date", {
    cell: (info) => {
      return (
        <span className="capitalize truncate">
          {new Date(info.renderValue()).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </span>
      );
    },
    header: () => "Start Date",
  }),
  columnHelper.accessor("end_date", {
    cell: (info) => {
      return (
        <span className="capitalize truncate">
          {new Date(info.renderValue()).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </span>
      );
    },
    header: () => "End Date",
    meta: {
      hidden: true,
    },
  }),
];

const exportFormater = (e) => {
  const obj = e;

  return obj;
};
export default function Training() {
  const editRow: any = useEditRow();

  const navigate = useNavigate();

  const actions = [
    {
      title: "View Training",
      onClick: (e) => {
        navigate(`/trainings/${e.id}`);
      },
      icon: Eye,
    },
    {
      title: "Edit Training",
      onClick: (e) => {
        editRow.edit(e);
      },
      icon: Edit,
    },
  ];
  let performanceFormState = useOverlayTriggerState({});

  const queryClient = useQueryClient();

  const [key, setkey] = useState();
  return (
    <>
      <div className="px-2">
        <div className="my-1 flex items-center justify-between">
          <div className="space-y-1">
            <h3 className="text-[15px] font-semibold text-slate-700">
              Manage Trainings!!
            </h3>
            <p className="text-[13.5px] leading-7 font-medium text-slate-500">
              Manage all the trainings here, create new trainings, view and edit
              trainings.
            </p>
          </div>
          <div>
            <Button
              onClick={() => {
                performanceFormState.open();
              }}
              LeftIcon={PlusCircle}
              size="sm"
            >
              Create New Training.
            </Button>
          </div>
        </div>

        <div className="my-4">
          <Datatable
            columns={columns}
            actions={actions}
            exportFormater={exportFormater}
            name="training"
            loader={fetchData}
            onKeyChange={(e) => setkey(e)}
          />
        </div>
      </div>
      <TrainingFormPannel
        onComplete={() => {
          queryClient.invalidateQueries({
            queryKey: key,
          });
          editRow.clear();
        }}
        open={performanceFormState.isOpen || editRow.isOpen}
        onClose={() => {
          performanceFormState.close();
          editRow.clear();
        }}
        training={editRow.row}
      />
    </>
  );
}
