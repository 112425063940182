import Datatable from "@/components/Datatable";
import { Eye, PlusCircle } from "react-feather";
import { createColumnHelper } from "@tanstack/table-core";
import { api } from "@/lib/api";
import Button from "@/components/ui/Button";
import Avatar from "@/components/ui/Avatar";
import { cn } from "@/utils/cn";
import { requizition_types } from "@/data";
import { useQueryClient } from "react-query";
import { useEditRow } from "@/hooks/useEditRow";
import { useState } from "react";
import { useOverlayTriggerState } from "react-stately";
import { useViewRow } from "@/hooks/useViewRow";
import Skeleton from "@/components/ui/Skeleton";
import { Link } from "react-router-dom";
import cleanObject from "@/utils/cleanObject";
import UserLabel from "@/components/UserLabel";
import RequisitionsFormPannel from "@/components/pannels/RequisitionFormPannel";
import RequisitionModal from "@/components/modals/RequisitionModal";
function clearEmptyValues(obj: any) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== "") {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

async function fetchData(
  {
    pageIndex: page,
    pageSize: page_size,
    query,
    sorting,
    ...rest
  }: {
    pageIndex: number;
    pageSize: number;
    query: string;
    sorting: any;
  },
  { headers }
) {
  const filterObj = {
    employeeId: rest["employee"],
  };
  const filters = ["type", "status", "created_at"];
  const sort = sorting[0];
  let sort_by = sort ? `${sort.id}:${sort.desc ? "desc" : "asc"}` : undefined;

  filters.forEach((item) => {
    if (Object.keys(rest).includes(item) && rest[item]) {
      filterObj[item] = rest[item];
    }
  });
  const params = clearEmptyValues({
    page: page === 0 ? 1 : page,
    page_size,
    sort_by,
    query,
    ...filterObj,
  });
  const { data } = await api.get("/requisitions", {
    params: params,
    headers,
  });
  return {
    rows: data.results,
    pageCount: data.page,
    total: data.total,
  };
}

interface Requsition {
  id: number;
  employee: any;
  duration: any;
  type: string;
  reason: string;
  status: string;
  title: string;
  amount: number;
  approved: boolean;
  approvedBy: any;
  attachments: string[];
  created_at: string;
  start_end_date: string;
}

const columnHelper = createColumnHelper<Requsition>();

const columns = [
  columnHelper.accessor("employee", {
    cell: (info) => {
      const names =
        info.row.original?.employee["first_name"] +
        " " +
        info.row.original?.employee["last_name"];
      return (
        <div className="flex items-center gap-3">
          <Avatar
            size="xs"
            rounded="full"
            src={info.row.original?.employee["photo"]}
            name={names}
          />
          <Link
            to={`?show_employee=${info.row.original?.employee["id"]}`}
            className="capitalize hover:underline text-slate-700 truncate"
          >
            {names}
          </Link>
        </div>
      );
    },
    header: () => "Employee",
    meta: {
      skeleton: () => {
        return (
          <div className="flex items-center gap-2">
            <div>
              <Skeleton className="w-[23px] h-[23px] rounded-full" />
            </div>
            <Skeleton className="w-[120px] h-3" />
          </div>
        );
      },

      allowFiltering: true,
      filterType: "select-async",
      loadFilterOptions: (inputValue) => {
        return api
          .get(`/employees?page_size=5`, {
            params: cleanObject({
              query: inputValue ? inputValue : undefined,
            }),
          })
          .then(({ data }) =>
            data.map((e) => ({
              label: e.first_name + " " + e.last_name,
              value: e.id,
              component: () => <UserLabel user={e} />,
            }))
          );
      },
    },
  }),
  columnHelper.accessor("type", {
    cell: (info) => (
      <span className="truncate capitalize">
        {requizition_types.find((e) => e.value === info.renderValue())?.label ||
          info.renderValue()}
      </span>
    ),
    header: () => "requizition type",
    meta: {
      allowFiltering: true,
      filterType: "select",
      filterOptions: requizition_types,
    },
  }),
  columnHelper.accessor("amount", {
    cell: (info) => (
      <span className="truncate capitalize">
        {(info.renderValue().toLocaleString() || 0) + " RWF"}
      </span>
    ),
    header: () => "Amount",
    // meta: {
    //   allowFiltering: true,
    //   filterType: "select",
    //   filterOptions: requizition_types,
    // },
  }),
  columnHelper.accessor("status", {
    cell: (info) => {
      const getColor = () => {
        switch (info.row.original.status) {
          case "pending":
            return "text-yellow-500";
          case "approved":
            return "text-green-500";
          case "rejected":
            return "text-red-500";
          case "cancelled":
            return "text-orange-500";
          default:
            return "text-yellow-500";
        }
      };
      return (
        <span className={cn("capitalize truncate", getColor())}>
          {info.renderValue()}
        </span>
      );
    },
    header: () => "Status",
    meta: {
      allowFiltering: true,
      filterType: "select",
      filterOptions: [
        {
          label: "Pending",
          value: "pending",
        },
        {
          label: "Approved",
          value: "approved",
        },
        {
          label: "Rejected",
          value: "rejected",
        },
        {
          label: "Cancelled",
          value: "cancelled",
        },
      ],
    },
  }),

  columnHelper.accessor("created_at", {
    cell: (info) => (
      <span className="truncate">
        {new Date(info.renderValue() as string).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </span>
    ),
    meta: {
      allowSorting: true,
      allowFiltering: true,
      filterType: "date",
    },
    header: () => "Requested at",
  }),
];

const exportFormater = (e, original) => {
  const obj = e;
  if (Object.keys(obj).includes("Employee")) {
    obj["Employee"] =
      original["employee"]["first_name"] +
      " " +
      original["employee"]["last_name"];
  }

  return obj;
};
export default function Requisitions() {
  const editRow: any = useEditRow();

  const view: any = useViewRow();

  const actions = [
    {
      title: "View Requisition",
      onClick: (e) => {
        view.open(e);
      },
      icon: Eye,
    },
  ];

  let formState = useOverlayTriggerState({});

  const queryClient = useQueryClient();

  const [key, setkey] = useState();
  return (
    <>
      <div className="px-2">
        <div className="my-1 flex items-center justify-between">
          <div className="space-y-1">
            <h3 className="text-[15px] font-semibold text-slate-700">
              Manage Requistions!!
            </h3>
            <p className="text-[13.5px] leading-7 font-medium text-slate-500">
              Manage all requistions here, you can create, update and delete
              requistions.
            </p>
          </div>
          <div>
            <Button
              onClick={() => {
                formState.open();
              }}
              LeftIcon={PlusCircle}
              size="sm"
            >
              Create New Requistion.
            </Button>
          </div>
        </div>

        <div className="my-4">
          <Datatable
            columns={columns}
            actions={actions}
            exportFormater={exportFormater}
            name="requistions"
            loader={fetchData}
            onKeyChange={(e) => setkey(e)}
            onMainActionClick={() => {}}
          />
        </div>
      </div>
      <RequisitionsFormPannel
        size="lg"
        onComplete={() => {
          queryClient.invalidateQueries({
            queryKey: key,
          });
          editRow.clear();
        }}
        open={formState.isOpen || editRow.isOpen}
        onClose={() => {
          formState.close();
          editRow.clear();
        }}
        requisition={editRow.row}
      />

      <RequisitionModal
        onAction={() => {
          queryClient.invalidateQueries({
            queryKey: key,
          });
          view.close();
        }}
        open={view.isOpen}
        onClose={() => {
          view.close();
        }}
        requisition={view.row}
      />
    </>
  );
}
