import { useFormikContext } from "formik";
import Checkbox from "../ui/Checkbox";
import get from "lodash.get";

export default function AppFormCheckbox({ name }) {
  const { setFieldValue, values }: any = useFormikContext();

  const value = get(values, name);

  return (
    <Checkbox
      onChange={(e) => {
        console.log(e);
        setFieldValue(name, e);
      }}
      isSelected={value}
    />
  );
}
