import React from "react";

interface ProgressBarProps {
  progress: string;
  label?: string;
  color?: string;
  rounded?: string;
  size?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  label = "",
  color = "primary",
  rounded = "md",
  size = "md",
}) => {
  const roundedClass = {
    xs: "rounded-[2px]",
    sm: "rounded-[4px]",
    md: "rounded-[8px]",
    lg: "rounded-[16px]",
    xl: "rounded-[32px]",
    none: "rounded-[0px]",
  }[rounded];

  const sizeClass = {
    xs: "h-[3px]",
    sm: "h-[4.5px]",
    md: "h-[8px]",
    lg: "h-[12px]",
    xl: "h-[16px]",
  }[size];

  const colorClass = {
    primary: "bg-red-500",
    red: "bg-red-500",
    green: "bg-green-500",
    blue: "bg-blue-500",
  }[color];

  return (
    <div
      className={`w-full bg-slate-200 overflow-hidden ${roundedClass} ${sizeClass}`}
    >
      <div
        style={{ width: progress }}
        className={`h-full flex items-center justify-center transition-all ${roundedClass} ${colorClass}`}
      >
        {label && <span>{label}</span>}
      </div>
    </div>
  );
};

export default ProgressBar;
