import Datatable from "@/components/Datatable";
import Button from "@/components/ui/Button";
import Skeleton from "@/components/ui/Skeleton";
import { api } from "@/lib/api";
import { cn } from "@/utils/cn";
import formatPreviewLink from "@/utils/formatPreviewLink";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { AlertTriangle } from "react-feather";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
function isLink(str) {
  try {
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
}
function isExternalLink(url) {
  const currentHostname = window.location.hostname;
  const link = document.createElement("a");
  link.href = url;
  const linkHostname = link.hostname;

  return currentHostname !== linkHostname;
}
export default function TrainingDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const trainingId = params.id;

  async function fetchData() {
    const { data } = await api.get(`/trainings/${trainingId}`);
    return data;
  }
  const {
    data: training,
    status,
    error,
  } = useQuery(["trainings", trainingId], fetchData, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: Infinity,
    enabled: Boolean(trainingId),
  });

  const [activeTab, setactiveTab] = useState("Overview");

  return (
    <>
      <div className="w-full">
        {status === "success" && (
          <div>
            <div className="bg-white border border-slate-200 p-2- rounded-md">
              <div className="flex items-start p-4 pb-1 justify-between">
                <div>
                  <div className="flex items-center gap-3">
                    <h4 className="text-[15px] capitalize font-semibold">
                      {training.name}
                    </h4>
                    <div className="mt-2-">
                      <div className="flex bg-blue-100 font-medium text-blue-500 px-2 py-1 rounded-md items-center gap-1">
                        <div className="h-[7px] w-[7px] rounded-full bg-blue-500"></div>
                        <span className="text-xs capitalize">
                          {
                            [
                              {
                                label: "upcoming",
                                value: "upcoming",
                              },
                              {
                                label: "on going",
                                value: "on-going",
                              },
                              {
                                label: "completed",
                                value: "completed",
                              },
                              {
                                label: "cancelled",
                                value: "cancelled",
                              },
                            ].find((e) => e.value === training.status)?.label
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="text-[13px] max-w-xl line-clamp-2 mt-2 leading-7 font-medium text-slate-500">
                    {training.description}
                  </div>
                  <div className="my-3 flex  gap-6 max-w-lg">
                    <div className="flex items-center gap-2">
                      <p className="text-[13px]  truncate font-semibold capitalize text-slate-700">
                        Assigned To:
                      </p>
                      <span className="text-slate-500 truncate text-[13px] capitalize font-medium">
                        {training?.assignedTo?.first_name}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <p className="text-[13px] truncate font-semibold capitalize text-slate-700">
                        Training Categoty:
                      </p>
                      <span className="text-slate-500 truncate text-[13px] capitalize font-medium">
                        {training.category}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-1 flex items-center  justify-between  border-b border-slate-200">
                <div className="flex items-center w-full justify-start px-3 gap-3   py-1">
                  {["Overview"].map((e, i) => {
                    return (
                      <a
                        key={i}
                        className={cn(
                          "text-[13px] text-center truncate- capitalize py-[6px] px-6 cursor-pointer flex items-center justify-center relative font-medium",
                          {
                            "text-primary font-semibold": activeTab === e,
                            "text-slate-600": activeTab !== e,
                          }
                        )}
                        onClick={() => {
                          setactiveTab(e);
                        }}
                      >
                        {e}
                        {activeTab === e && (
                          <div className="w-full -bottom-1 absolute h-[4px] rounded-t-lg bg-red-600"></div>
                        )}
                      </a>
                    );
                  })}
                </div>
                <div>
                  <a
                    onClick={() => {}}
                    className={cn(
                      "border-dashed hidden cursor-pointer text-[11.5px] hover:bg-slate-100 py-[5px] px-3 font-semibold items-center gap-2 border border-slate-200 rounded-md"
                    )}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      height={18}
                      width={18}
                      className="text-slate-800 stroke-current"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        {"{"}" "{"}"}
                        <path
                          d="M8 8.5C8 9.88071 6.88071 11 5.5 11C4.11929 11 3 9.88071 3 8.5C3 7.11929 4.11929 6 5.5 6C6.88071 6 8 7.11929 8 8.5ZM8 8.5H21M16 15.5C16 16.8807 17.1193 18 18.5 18C19.8807 18 21 16.8807 21 15.5C21 14.1193 19.8807 13 18.5 13C17.1193 13 16 14.1193 16 15.5ZM16 15.5H3"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>

                    <span className="text-slate-600 truncate">
                      Filter Tasks
                    </span>
                  </a>
                </div>
              </div>
            </div>

            {activeTab === "Participants" && (
              <Participants training={training} />
            )}
            {activeTab === "Overview" && <Overview training={training} />}
          </div>
        )}
        {status === "loading" && (
          <div>
            <div className="bg-white border border-slate-200 p-2- rounded-md">
              <div className="flex items-start p-4 justify-between">
                <div>
                  <div className="flex mb-4 items-center gap-3">
                    <Skeleton className={"w-[170px] h-4"} />
                    <div className="mt-2-">
                      <Skeleton className={"w-[80px] h-4"} />
                    </div>
                  </div>
                  <div className="space-y-3 mt-4">
                    <Skeleton className={"w-[400px] h-3"} />
                    <Skeleton className={"w-[300px] h-2"} />
                    <Skeleton className={"w-[200px] h-2"} />
                  </div>
                  <div className="my-6 space-y-3">
                    <div className="flex items-center gap-2">
                      <Skeleton className={"w-[130px] h-4"} />
                    </div>
                    <div className="flex items-center gap-2">
                      <Skeleton className={"w-[100px] h-4"} />
                    </div>
                  </div>
                  <div
                    className={`w-[600px] bg-slate-200 animate-pulse overflow-hidden rounded-md h-[10px]`}
                  >
                    <div
                      style={{
                        width: "60%",
                      }}
                      className={`h-full flex animate-pulse items-center justify-center transition-all rounded-md bg-slate-300`}
                    ></div>
                  </div>
                </div>
                <div className="flex flex-col justify-between items-end"></div>
              </div>
              <div>
                <div className="px-4 flex items-center  justify-between">
                  <div className="flex items-center w-full justify-start gap-3   py-2 border-b border-slate-200">
                    {[1, 2, 3, 4].map((_, i) => {
                      return <Skeleton key={i} className="w-[130px] h-7" />;
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full border mt-3 h-[350px] bg-white border-slate-200 rounded-md"></div>
          </div>
        )}
        {status === "error" && (
          <div>
            <div className="flex bg-white  max-w-2xl border border-slate-200 mx-auto my-12 items-center justify-center flex-col gap-4 py-24">
              <div className="bg-red-200 border-[6px] border-red-100 h-16 w-16 rounded-full flex items-center justify-center">
                <AlertTriangle className="text-red-500" size={22} />
              </div>
              <div className="flex flex-col gap-2 text-center">
                <h4 className="font-semibold text-[15px]">
                  {error["response"]?.data?.message ||
                    "Something  went wrong fetching training information."}
                </h4>
                <h4 className="font-medium text-slate-500 leading-7 text-[13px]">
                  Error occured while fetching data. Please try again <br /> or
                  contact administrator.
                </h4>
                <div className="mt-2">
                  <Button
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Go to previous page
                  </Button>
                </div>
              </div>
            </div>
            <span></span>
          </div>
        )}
      </div>
    </>
  );
}

const columnHelper = createColumnHelper<any>();

const columns = [
  columnHelper.accessor("names", {
    cell: (info) => (
      <div className="flex items-center gap-3">
        <h4 className="text-slate-800 capitalize leading-5 text-[12.5px] font-semibold">
          {info.row.original["first_name"] +
            " " +
            info.row.original["last_name"]}
        </h4>
      </div>
    ),
    header: () => "Names",
  }),
  columnHelper.accessor("email", {
    cell: (info) => (
      <span className="capitalize truncate">{info.renderValue()}</span>
    ),
    header: () => "Email",
  }),
  columnHelper.accessor("phone", {
    cell: (info) => (
      <span className="capitalize truncate">{info.renderValue()}</span>
    ),
    header: () => "phone",
  }),
  columnHelper.accessor("address", {
    cell: (info) => (
      <span className="capitalize truncate">{info.getValue()}</span>
    ),
    header: () => "address",
  }),
  columnHelper.accessor("created_at", {
    cell: (info) => (
      <span className="capitalize truncate">{info.renderValue()}</span>
    ),
    header: () => "created at",
  }),
];

const exportFormater = (e, original) => {
  const obj = e;
  if (Object.keys(obj).includes("Names")) {
    obj["Names"] = original["first_name"] + " " + original["last_name"];
  }
  return obj;
};

function clearEmptyValues(obj: any) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== "") {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

function Participants({ training }) {
  async function fetchData(
    {
      pageIndex: page,
      pageSize: page_size,
    }: {
      pageIndex: number;
      pageSize: number;
    },
    { headers }
  ) {
    const params = clearEmptyValues({
      page: page === 0 ? 1 : page,
      page_size,
    });

    const { data } = await api.get(`/trainings/${training.id}`, {
      params: params,
      headers,
    });
    return {
      rows: data.results,
      pageCount: data.page,
      total: data.total,
    };
  }

  const [_, setkey] = useState();

  return (
    <div>
      <div className="my-2">
        <Datatable
          columns={columns}
          actions={[]}
          exportFormater={exportFormater}
          name="Employees"
          loader={fetchData}
          onMainActionClick={() => {}}
          onKeyChange={(e) => setkey(e)}
        />
      </div>
    </div>
  );
}

function Overview({ training }) {
  return (
    <>
      <div>
        <div className="bg-white border mt-2 p-3 border-slate-300 rounded-md">
          <div className="grid max-w-2xl px-[6px] gap-6 my-0 pt-0 grid-cols-2">
            {[
              {
                key: "training name",
                value: training.name,
              },
              {
                key: "training category",
                value: training.category,
              },
              {
                key: "Start date",
                value: new Date(training?.created_at).toLocaleDateString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  }
                ),
              },

              { key: "status", value: training?.status },

              {
                key: "Assigned to",
                value: training?.assignedTo
                  ? training?.assignedTo["first_name"] +
                    " " +
                    training?.assignedTo["last_name"]
                  : "N/A",
              },
            ].map((e: any, i) => {
              return (
                <div className="flex items-center gap-4" key={i}>
                  <span className="text-[12.5px] truncate font-medium capitalize text-slate-500">
                    {e.key}:
                  </span>
                  {isLink(e.value) || e.link ? (
                    <>
                      {!isExternalLink(e.link || e.value) ? (
                        <Link
                          to={e.link || e.value}
                          className="text-[12.5px] underline truncate font-semibold text-slate-700"
                        >
                          {e.value}
                        </Link>
                      ) : (
                        <a
                          href={formatPreviewLink(e.value)}
                          target={"_blank"}
                          className="text-[12.5px] underline truncate font-semibold text-slate-700"
                        >
                          {formatPreviewLink(e.value)}
                        </a>
                      )}
                    </>
                  ) : (
                    <span
                      className={`text-[12.5px] truncate font-semibold capitalize text-slate-700 ${
                        e.onClick ? "underline cursor-pointer" : ""
                      }`}
                    >
                      {e.value}
                    </span>
                  )}
                </div>
              );
            })}
          </div>

          <div className="px-2 mt-3 py-2">
            <h2 className="text-[13px] font-semibold capitalize text-slate-700">
              Description
            </h2>
            <p className="mt-2 text-[13px] max-w-3xl font-medium leading-8 text-slate-600">
              <Descc content={training.description} />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

function Descc({ content }) {
  const [showFullContent, setShowFullContent] = useState(false);
  const [wordCount, setWordCount] = useState(0);

  const toggleShowFullContent = () => {
    setShowFullContent(!showFullContent);
  };

  useEffect(() => {
    const words = content.split(/\s+/).filter((word) => word !== "");
    setWordCount(words.length);
  }, [content]);

  const displayContent =
    showFullContent || wordCount <= 400
      ? content
      : `${content.slice(0, 400)}...`;

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: displayContent }} />
      {wordCount > 400 && (
        <a
          className="underline cursor-pointer !text-primary"
          onClick={toggleShowFullContent}
        >
          {showFullContent ? "Show Less" : "Show More"}
        </a>
      )}
    </div>
  );
}
