import { cn } from "@/utils/cn";
import "flatpickr/dist/themes/material_red.css";
import { Calendar } from "react-feather";
import Flatpickr from "react-flatpickr";

export default function Dakepicker({
  label,
  errorMessage,
  value,
  onChange,
  ...other
}: any) {
  return (
    <div className="py-[6px]">
      <label className="block text-[13px] mb-1 capitalize font-medium text-slate-700">
        {label}
      </label>
      <div className="relative">
        <Flatpickr
          value={value}
          onChange={([date]) => {
            console.log(date);
            onChange(date);
          }}
          className={cn(
            "shadow-sm text-slate-600 placeholder:text-slate-500 font-medium outline-none text-[12.5px] border px-3 py-[10px]  block w-full  rounded-md",
            {
              "focus:ring-red-500 focus:border-red-500 border border-red-500":
                errorMessage,
              "focus:ring-primary focus:border-primary border-gray-300 ":
                !errorMessage,
            }
          )}
          {...other}
        />
        <Calendar className="absolute right-3 top-3 text-slate-500" size={15} />
      </div>
      {/* <input
        className={cn(
          "shadow-sm text-slate-600 placeholder:text-slate-500 font-medium outline-none text-[12.5px] border px-3 py-[10px]  block w-full  rounded-md",
          {
            "focus:ring-red-500 focus:border-red-500 border border-red-500":
              errorMessage,
            "focus:ring-primary focus:border-primary border-gray-300 ":
              !errorMessage,
          }
        )}
        {...inputProps}
        ref={ref}
      /> */}

      {errorMessage && (
        <div className="block text-[11.7px] truncate mt-1 capitalize font-medium text-red-500">
          {errorMessage}
        </div>
      )}
    </div>
  );
}
