import { useEffect, useState } from "react";
import Modal from "../ui/Modal";
import formatPreviewLink from "@/utils/formatPreviewLink";
function isLink(str) {
  try {
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
}

function WeekPlanDetails({ plan, onClose, open }: any) {
  const [data, setdata] = useState(plan);
  useEffect(() => {
    if (plan) {
      setdata(plan);
    }
  }, [plan]);

  return (
    <Modal
      title="Week plan details."
      size="lg"
      showDividers={false}
      close={onClose}
      open={open}
    >
      <div className="grid px-[6px] gap-4 my-0 pt-0 grid-cols-2">
        {[
          { key: "title", value: data?.title },
          {
            key: "Done",
            value: data?.status ? "Yes" : "No",
          },
          {
            key: "Attachment",
            value: data?.attachment || "N/A",
          },
          {
            key: "Created at",
            value: new Date(data?.created_at).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            }),
          },
        ].map((e, i) => {
          return (
            <div className="flex  items-center gap-4" key={i}>
              <span className="text-[12.5px] font-medium capitalize text-slate-500">
                {e.key}:
              </span>
              {isLink(e.value) ? (
                <a
                  href={formatPreviewLink(e.value)}
                  target="_blank"
                  className="text-[12.5px] underline truncate font-semibold text-slate-700"
                >
                  {formatPreviewLink(e.value)}
                </a>
              ) : (
                <span className="text-[12.5px] truncate font-semibold capitalize text-slate-700">
                  {e.value}
                </span>
              )}
            </div>
          );
        })}
      </div>
      <div className="px-2 pb-4 mt-5">
        <h4 className="uppercase mb-3 text-[12.5px] font-semibold text-slate-600">
          Plan Tasks
        </h4>
        <ul className="list-disc flex-col space-y-4 my-0 pt-0">
          {data?.tasks &&
            data?.tasks.map((e, i) => {
              return (
                <div
                  className={`flex  items-center gap-1 ${
                    e.done ? "line-through" : ""
                  }`}
                  key={i}
                >
                  <span className="text-[12.5px] font-medium capitalize text-slate-500">
                    {e.title}
                  </span>
                  <span className="text-[12px] truncate font-semibold capitalize text-slate-600">
                    (
                    {new Date(e.range.start).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      weekday: "short",
                    })}
                    -{" "}
                    {new Date(e.range.end).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      weekday: "short",
                    })}
                    )
                  </span>
                </div>
              );
            })}
        </ul>
      </div>
    </Modal>
  );
}

export default WeekPlanDetails;
