import { Formik } from "formik";
import Pannel from "../ui/Pannel";
import * as Yup from "yup";
import removeEmptyAndUndefined from "@/utils/removeEmptyAndUndefined";
import { useMutation } from "react-query";
import { api } from "@/lib/api";
import toast from "react-hot-toast";
import { useEffect, useMemo, useRef } from "react";
import useConfirm from "@/hooks/useConfirm";
import Button from "../ui/Button";
import SubmitButton from "../forms/SubmitButton";
import AppFormField from "../forms/AppFormField";
import DiscardModal from "../modals/DiscardModal";
import AppFormTextarea from "../forms/AppFormTextarea";
import AppFormSelect from "../forms/AppFormSelect";
import { cn } from "@/utils/cn";
import AppFileUpload from "../forms/AppFileUpload";
import { useEmployee } from "@/context/employee.context";
import AppFormCreatableSelect from "../forms/AppFormCreatableSelect";
import UserLabel from "../UserLabel";
import AppFormAsyncSelect from "../forms/AppFormAsyncSelect";
import employeesLoader from "@/utils/employeesLoader";
export default function TenderFormPannel({
  tender,
  children,
  onClose,
  onComplete,
  ...other
}: any) {
  const mutation = useMutation({
    mutationFn: (record) => {
      return tender
        ? api.put(`/tenders/${tender.id}`, record)
        : api.post("/tenders", record);
    },
  });

  const { employee }: any = useEmployee();

  const loadMembers = async () => {
    const { data } = await api.get("/employees", {
      params: {
        id: tender.members,
      },
    });
    formRef.current.setFieldValue(
      "members",
      data.map((e) => ({
        label: e.first_name + " " + e.last_name,
        value: e.id,
        component: () => <UserLabel user={e} />,
      }))
    );
  };

  useEffect(() => {
    if (tender && tender?.members?.length) {
      console.log("---- loading members ----");
      loadMembers();
    }
  }, [tender]);

  const handleSubmit = async (values: any) => {
    const data: any = removeEmptyAndUndefined({
      title: values.title,
      description: values.description,
      status: values.status,
      email: values.email,
      category: values.category,
      phone: values.phone,
      company_name: values.company_name,
      address: values.address,
      source: values.source,
      attachments: values.attachment ? [values.attachment] : [],
      link: values.link,
      createdById: employee.id,
      members: values.members.map((e) => parseInt(e.value)),
    });

    await mutation.mutateAsync(data, {
      onError: (error: any) => {
        toast.error(error.response.data.message || error.message);
      },
      onSuccess: () => {
        toast.success(`tender saved sucess`);
        onClose();
        onComplete();
      },
    });
  };

  const schema = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string().required(),
    status: Yup.string().required(),
    email: Yup.string().email().required(),
    phone: Yup.string().required(),
    company_name: Yup.string().required().label("company name"),
    address: Yup.string().required(),
    source: Yup.string().required(),
    category: Yup.string().required(),
    attachment: Yup.string().optional(),
    link: Yup.string(),
    members: Yup.array().min(1),
  });

  const initialValues = useMemo(() => {
    return {
      title: tender?.title || "",
      description: tender?.description || "",
      status: tender?.status || "",
      email: tender?.email || "",
      phone: tender?.phone || "",
      company_name: tender?.company_name || "",
      address: tender?.address || "",
      source: tender?.source || "",
      category: tender?.category || "",
      attachment: tender?.attachments?.length ? tender?.attachments[0] : "",
      link: tender?.link || "",
      members: [],
    };
  }, [tender]);

  const formRef = useRef<any>();

  const confirmDiscard = useConfirm({
    onAccept: () => {
      onClose();
      formRef.current?.resetForm();
    },
  });

  const handleClose = ({ touched }) => {
    if (Object.keys(touched)?.length) {
      confirmDiscard.open();
    } else {
      onClose();
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize={true}
    >
      {(e) => (
        <>
          <Pannel
            onClose={() => handleClose(e)}
            {...other}
            title={"Create a new Tender"}
          >
            <div className="h-0 flex-1 overflow-y-auto">
              <div className="flex flex-1 flex-col justify-between">
                <div className="divide-y pb-4 divide-gray-200 px-4 sm:px-6">
                  <div className="mt-3 px-[2px]">
                    <div>
                      <div>
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          Tender Information
                        </h4>
                      </div>{" "}
                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormField
                          name="title"
                          label="Tender title"
                          placeholder="Enter title"
                        />
                      </div>
                      <div className="grid mt-2 grid-cols-2 gap-3">
                        <AppFormField
                          name="company_name"
                          label="Enter a company"
                          placeholder="Company"
                        />{" "}
                        <AppFormField
                          name="email"
                          label="Enter company email"
                          placeholder="Company email"
                        />
                      </div>{" "}
                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormSelect
                          name="category"
                          label="Tender category"
                          placeholder="Tender category"
                          options={[
                            {
                              label: "audit",
                              value: "audit",
                            },
                            {
                              label: "consultancy",
                              value: "consultancy",
                            },
                            {
                              label: "Taxes",
                              value: "taxes",
                            },
                            {
                              label: "Accounting",
                              value: "accounting",
                            },
                          ]}
                        />
                      </div>
                      <div className="grid mt-2 grid-cols-2 gap-3">
                        <AppFormField
                          name="phone"
                          label="phone"
                          placeholder="phone"
                        />{" "}
                        <AppFormField
                          name="address"
                          label="address"
                          placeholder="address"
                        />
                      </div>{" "}
                      <div className="grid mt-2 grid-cols-2 gap-3">
                        <AppFormCreatableSelect
                          name="source"
                          label="Tender source"
                          placeholder="Tender source"
                          options={[
                            {
                              label: "Linked in",
                              value: "linked-in",
                            },
                            {
                              label: "job in rwanda",
                              value: "jobs-in-rwanda",
                            },
                            {
                              label: "facebook",
                              value: "facebook",
                            },
                            {
                              label: "umucyo",
                              value: "umucyo",
                            },
                            {
                              label: "other",
                              value: "other",
                            },
                          ]}
                        />
                        <AppFormSelect
                          name="status"
                          label="Tender status"
                          placeholder="Tender status"
                          options={[
                            {
                              label: "submited",
                              value: "submited",
                            },
                            {
                              label: "preparing",
                              value: "preparing",
                            },

                            {
                              label: "failed",
                              value: "failed",
                            },
                            {
                              label: "awarded",
                              value: "awarded",
                            },
                          ]}
                          OptionItem={({ item }) => {
                            const getColor = () => {
                              switch (item?.value) {
                                case "submited":
                                  return "bg-blue-500";
                                case "preparing":
                                  return "bg-orange-500";
                                case "awarded":
                                  return "bg-green-500";
                                case "failed":
                                  return "bg-red-500";
                              }
                            };
                            return (
                              <div className="flex items-center gap-2">
                                <div
                                  className={cn(
                                    "h-3 w-3 rounded-full",
                                    getColor()
                                  )}
                                ></div>
                                <span>{item?.label}</span>
                              </div>
                            );
                          }}
                        />
                      </div>
                      <div>
                        <AppFormField
                          name="link"
                          label="Tender link"
                          placeholder="Tender link"
                        />
                      </div>
                      <AppFormAsyncSelect
                        label={"Project members"}
                        placeholder={"Add Project members"}
                        name={"members"}
                        isMulti
                        def
                        loadOptions={employeesLoader}
                      />
                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormTextarea
                          name="description"
                          label="description"
                          placeholder="Description"
                          rows={3}
                        />
                      </div>
                      <AppFileUpload
                        label={"Drag and drop tender attachment"}
                        name={"attachment"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-2 flex-shrink-0 justify-end px-4 py-2">
              <Button
                onClick={() => handleClose(e)}
                variant="default"
                size="sm"
              >
                Cancel
              </Button>
              <SubmitButton size="sm">Save tender</SubmitButton>
            </div>
          </Pannel>

          <DiscardModal state={confirmDiscard} />
        </>
      )}
    </Formik>
  );
}
