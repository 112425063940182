import { useLocation, useNavigate } from "react-router-dom";
import Pannel from "../ui/Pannel";
import qs from "qs";
import { Fragment, useMemo, useState } from "react";
import Avatar from "../ui/Avatar";
import { cn } from "@/utils/cn";
import { api } from "@/lib/api";
import { useQuery } from "react-query";
import Skeleton from "../ui/Skeleton";
import Loader from "../ui/Loader";
import WeekPlanDetails from "../modals/WeekPlanDetails";
import LeavesTakenVsRemaining from "../LeavesTakenVsRemaining";
import formatPreviewLink from "@/utils/formatPreviewLink";

function formatDuration(secs) {
  const timeUnits = ["hrs", "mins", "secs"];
  const timeValues = [3600, 60, 1];
  const formattedTime = [];

  for (let i = 0; i < timeUnits.length; i++) {
    const unit = timeUnits[i];
    const unitValue = timeValues[i];
    const value = Math.floor(secs / unitValue);

    if (value > 0) {
      formattedTime.push(`${value} ${unit}`);
      secs %= unitValue;
    }
  }

  if (formattedTime.length === 0) {
    return "0 secs";
  }

  return formattedTime.join(", ");
}

function isLink(str) {
  try {
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
}

export default function EmployeeProfilePannel({
  perfomance,
  children,
  onComplete,
  ...other
}: any) {
  const location = useLocation();

  const show_employee = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }).show_employee,
    [location.search]
  );

  const isOpen = Boolean(show_employee);

  const navigate = useNavigate();

  const [activeTab, setactiveTab] = useState("Profile information");

  async function fetchData() {
    const { data } = await api.get(`/employees/${show_employee}`);
    return data;
  }

  const {
    data: employee,
    status,
    isFetching,
  } = useQuery(["employees", show_employee], fetchData, {
    keepPreviousData: true,
    retry: false,
    staleTime: Infinity,
    enabled: Boolean(show_employee),
  });

  return (
    <Pannel
      open={isOpen}
      onClose={() => {
        navigate(-1);
        setTimeout(() => {
          setactiveTab("Profile information");
        }, 700);
      }}
      {...other}
      size="xl"
      title={"Employee Profile"}
    >
      {status === "error" && !employee && (
        <div className="w-full h-[300px] flex items-center justify-center">
          <span className="text-[13px] font-medium text-slate-500">
            Employee not found
          </span>
        </div>
      )}
      <div className="p-4">
        {(status === "loading" || isFetching) && (
          <>
            <div className="flex items-center gap-4">
              <div>
                <Skeleton className="w-[60px] h-[60px] rounded-full" />
              </div>
              <div className="flex flex-col  gap-4">
                <Skeleton className="w-[150px] h-4" />
                <div className="flex items-center gap-3">
                  <Skeleton className="w-[100px] h-3" />
                  <Skeleton className="w-[80px] h-3" />
                  <Skeleton className="w-[150px] h-3" />
                </div>
              </div>
            </div>
          </>
        )}

        {status === "success" && employee && !isFetching && (
          <>
            <div className="flex items-center gap-2">
              <div>
                <Avatar
                  rounded="full"
                  size="lg"
                  src={employee.photo}
                  name={employee.first_name + " " + employee.last_name}
                />
              </div>
              <div className="space-y-2">
                <h4 className="text-sm font-semibold capitalize">
                  {employee.first_name + " " + employee.last_name}
                </h4>
                <div className="flex items-center gap-4 mt-1">
                  <p className="text-[13px] capitalize text-slate-500 font-medium">
                    {employee?.department?.name || "N/A"}
                  </p>
                  <div className="flex bg-green-100 font-medium text-green-500 px-2 py-1 rounded-md items-center gap-1">
                    <div className="h-[7px] w-[7px] rounded-full bg-green-500"></div>
                    <span className="text-xs">
                      {employee.employment_status}
                    </span>
                  </div>
                  <div className="text-[13px] text-slate-500 font-medium">
                    Joined at{" "}
                    {new Date(employee.created_at).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {(status === "loading" || isFetching) && (
        <div>
          <div className="px-2 flex items-center  justify-between">
            <div className="flex items-center w-full justify-evenly gap-3   py-2 border-b border-slate-200">
              {[1, 2, 3, 4].map((_, i) => {
                return <Skeleton key={i} className="w-[130px] h-7" />;
              })}
            </div>
          </div>
          <div className="p-5">
            <div className="space-y-5">
              <Skeleton className="w-[250px] h-[14px]" />
              <Skeleton className="w-[140px] h-[14px]" />
              <Skeleton className="w-[180px] h-[14px]" />
              <Skeleton className="w-[200px] h-[14px]" />
              <Skeleton className="w-[220px] h-[14px]" />
              <Skeleton className="w-[100px] h-[14px]" />
              <Skeleton className="w-[90px] h-[14px]" />
            </div>
          </div>
        </div>
      )}
      {status === "success" && !isFetching && (
        <div className="px-2 flex items-center  justify-between">
          <div className="flex items-center w-full justify-between px-3 gap-3   py-1 border-b border-slate-200">
            {[
              "Profile information",
              "work details",
              "attendance",
              "weekly plan",
              "leaves",
            ].map((e, i) => {
              return (
                <a
                  key={i}
                  className={cn(
                    "text-[13px] text-center truncate- capitalize py-[6px] px-3 cursor-pointer flex items-center justify-center relative font-medium",
                    {
                      "text-primary": activeTab === e,
                      "text-slate-600": activeTab !== e,
                    }
                  )}
                  onClick={() => {
                    setactiveTab(e);
                  }}
                >
                  {e}
                  {activeTab === e && (
                    <div className="w-full -bottom-1 absolute h-[3px] rounded-t-lg bg-red-600"></div>
                  )}
                </a>
              );
            })}
          </div>
        </div>
      )}
      {status === "success" && employee && !isFetching && (
        <div className="h-full relative ">
          {activeTab === "Profile information" && (
            <ProfileInformation employee={employee} />
          )}
          {activeTab === "work details" && (
            <CompanyDetails employee={employee} />
          )}
          {activeTab === "attendance" && (
            <Attendance employeeId={employee.id} />
          )}
          {activeTab === "weekly plan" && (
            <WeeklyPlan employeeId={employee.id} />
          )}

          {activeTab === "leaves" && <Leaves employeeId={employee.id} />}
        </div>
      )}
    </Pannel>
  );
}

function Leaves({ employeeId }) {
  return (
    <div className="p-4 flex items-center justify-center">
      <div className="border px-5 border-slate-200 rounded-md py-3">
        <LeavesTakenVsRemaining employeeId={employeeId} />
      </div>
    </div>
  );
}

function ProfileInformation({ employee }) {
  return (
    <div className="px-5 py-0">
      <div className="grid px-[6px] gap-y-6 my-2 pt-3 grid-cols-2">
        {[
          { key: "First name", value: employee?.first_name },
          { key: "Last name", value: employee?.last_name },
          { key: "Email", value: employee?.email },
          { key: "Gender", value: employee?.gender },
          {
            key: "Birth",
            value: new Date(employee?.birth_date).toLocaleDateString("en-US", {
              month: "long",
              day: "2-digit",
              year: "numeric",
            }),
          },
          { key: "National Id", value: employee.national_id },
          { key: "Phone", value: employee.phone },
          { key: "Country", value: employee?.permanent_address?.country },
          {
            key: "Address",
            value: employee?.permanent_address?.street_address,
          },
          {
            key: "Emergency Phone",
            value: employee?.contact_person?.phone,
          },
          { key: "Marital status", value: employee.marital_status },
        ].map((e, i) => {
          return (
            <div className="flex  items-center gap-4" key={i}>
              <span className="text-[12.5px] font-medium capitalize text-slate-500">
                {e.key}:
              </span>
              <span className="text-[12.5px] truncate font-semibold capitalize text-slate-700">
                {e.value}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
function CompanyDetails({ employee }) {
  return (
    <div className="px-4 py-2">
      <div className="grid px-[6px] gap-6 my-4- pt-3 grid-cols-2">
        {[
          { key: "Department", value: employee?.department?.name },
          {
            key: "Social security number",
            value: employee.social_security_number,
          },
          {
            key: "Joined at",
            value: new Date(employee.created_at).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            }),
          },
          { key: "Created by", value: employee.createdBy?.first_name || "N/A" },
          {
            key: "Salary",
            value: parseInt(employee.salary).toLocaleString() + " Frw",
          },
          { key: "employment type", value: employee.employment_type },
          { key: "employment status", value: employee.employment_status },
          { key: "Manager", value: employee.manager?.first_name || "N/A" },
          { key: "CV", value: employee?.cv || "N/A" },
        ].map((e, i) => {
          return (
            <div className="flex  items-center gap-4" key={i}>
              <span className="text-[12.5px] font-medium capitalize text-slate-500">
                {e.key}:
              </span>
              {isLink(e.value) ? (
                <a
                  href={formatPreviewLink(e.value)}
                  target="_blank"
                  className="text-[12.5px] underline truncate font-semibold text-slate-700"
                >
                  {formatPreviewLink(e.value)}
                </a>
              ) : (
                <span className="text-[12.5px] truncate font-semibold capitalize text-slate-700">
                  {e.value}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
function Attendance({ employeeId }) {
  async function fetchData() {
    const { data } = await api.get("/attendances", {
      params: {
        page_size: 5,
        employeeId: employeeId,
      },
    });
    console.log(data);
    return data;
  }

  const {
    data: attendances,
    status,
    error,
  } = useQuery(["attendances", employeeId], fetchData, {
    keepPreviousData: true,
    retry: false,
    staleTime: Infinity,
    enabled: Boolean(employeeId),
  });

  return (
    <Fragment>
      <table className="table-fixed w-full">
        <thead>
          <tr>
            <th className="text-left truncate text-[12px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Clock in - Clock out
            </th>
            <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Date
            </th>
            <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Duration
            </th>
            <th className="text-left truncate text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Clockin Status
            </th>
            <th className="text-right w-[100px] text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Late
            </th>
          </tr>
        </thead>
        {status === "success" && (
          <tbody>
            {attendances.map((e, i) => {
              return (
                <tr key={i}>
                  <td className="text-left truncate leading-6 text-[12.5px] px-5 py-[10px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {new Date(e.clock_in).toLocaleTimeString("en-US", {
                      minute: "numeric",
                      hour: "numeric",
                      hour12: true,
                    })}
                    {"-"}
                    {e.clock_out
                      ? new Date(e.clock_out).toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })
                      : "---"}
                  </td>
                  <td className="text-left truncate text-[12.5px] px-5 py-[10px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {new Date(e.created_at).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </td>
                  <td className="text-left truncate text-[12.5px] px-5 py-[10px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {formatDuration(e.duration)}
                  </td>
                  <td className="text-left text-[12.5px] px-5 py-[12px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    <span className={cn("capitalize truncate")}>
                      {e.clock_in_status}
                    </span>
                  </td>
                  <td className="text-right truncate capitalize text-[12.5px] px-5 py-[12px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {formatDuration(e.clock_in_late_duration)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {status === "success" && !attendances.length ? (
        <Fragment>
          <div className="w-full flex items-center py-24 justify-center h-full-">
            <span className="text-[12.5px] font-medium text-slate-600">
              There is no attendance available.
            </span>
          </div>
        </Fragment>
      ) : null}
      {status === "loading" && (
        <Fragment>
          <div className="w-full flex items-center py-24 justify-center h-full-">
            <Loader />
          </div>
        </Fragment>
      )}
      {status === "error" && (
        <Fragment>
          <div className="w-full flex items-center py-24 justify-center h-full-">
            <span className="text-[12.5px] font-medium text-slate-600">
              {error["message"]}
            </span>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
function WeeklyPlan({ employeeId }) {
  async function fetchData() {
    const { data } = await api.get("/plans", {
      params: {
        page_size: 5,
        employeeId: employeeId,
      },
    });
    return data;
  }

  const {
    data: issues,
    status,
    error,
  } = useQuery(["plans", employeeId], fetchData, {
    keepPreviousData: true,
    retry: false,
    staleTime: Infinity,
    enabled: Boolean(employeeId),
  });
  const [planToShow, setPlanToShow] = useState(null);

  return (
    <>
      <WeekPlanDetails
        onClose={() => {
          setPlanToShow(undefined);
        }}
        plan={planToShow}
        open={Boolean(planToShow)}
      />
      <table className="table-fixed w-full">
        <thead>
          <tr>
            <th className="text-left text-[12px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Title
            </th>

            <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Tasks
            </th>
            <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Created at
            </th>
          </tr>
        </thead>
        {status === "success" && (
          <tbody className="">
            {issues.map((e, i) => {
              return (
                <tr
                  onClick={() => {
                    setPlanToShow(e);
                  }}
                  key={i}
                  className={"cursor-pointer hover:bg-slate-100"}
                >
                  <td className="text-left truncate leading-6 text-[12px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {e.title}
                  </td>

                  <td
                    className={cn(
                      "text-left capitalize  text-slate-500 truncate text-[12px] px-5 py-[8px] border-t  border-b border-slate-100 border-b-slate-100 font-medium"
                    )}
                  >
                    {e?.tasks?.length} Tasks
                  </td>
                  <td className="text-left truncate text-[12px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {new Date(e.created_at).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {status === "success" && !issues.length ? (
        <Fragment>
          <div className="flex items-center  justify-center py-20  w-full">
            <span className="text-[13px] text-center leading-7 font-medium text-slate-600">
              Employee has no plans this week.
              <br />
            </span>
          </div>
        </Fragment>
      ) : null}
      {status === "loading" && (
        <Fragment>
          <div className="w-full flex items-center py-24 justify-center h-full-">
            <Loader />
          </div>
        </Fragment>
      )}
      {status === "error" && (
        <Fragment>
          <div className="w-full flex items-center py-24 justify-center h-full-">
            <span className="text-[12.5px] font-medium text-slate-600">
              {error["message"]}
            </span>
          </div>
        </Fragment>
      )}
    </>
  );
}
