const formatPreviewLink = (file) => {
  const newFileLink = file.replace(
    "https://dnr-assets.nyc3.cdn.digitaloceanspaces.com",
    "https://dnr-assets.s3.amazonaws.com"
  );
  return file?.startsWith("https://drive.google.com")
    ? newFileLink
    : `https://docs.google.com/viewer?url=${newFileLink}`;
};

export default formatPreviewLink;
