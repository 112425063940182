import { Formik } from "formik";
import Pannel from "../ui/Pannel";
import * as Yup from "yup";
import removeEmptyAndUndefined from "@/utils/removeEmptyAndUndefined";
import { useMutation } from "react-query";
import { api } from "@/lib/api";
import toast from "react-hot-toast";
import { useMemo, useRef } from "react";
import useConfirm from "@/hooks/useConfirm";
import Button from "../ui/Button";
import SubmitButton from "../forms/SubmitButton";
import AppFormField from "../forms/AppFormField";
import DiscardModal from "../modals/DiscardModal";
import AppFormTextarea from "../forms/AppFormTextarea";
import UserLabel from "../UserLabel";
import AppFormAsyncSelect from "../forms/AppFormAsyncSelect";
import employeesLoader from "@/utils/employeesLoader";
export default function DepartmentFormPannel({
  department,
  children,
  onClose,
  onComplete,
  ...other
}: any) {
  const mutation = useMutation({
    mutationFn: (record) => {
      return department
        ? api.put(`/departments/${department.id}`, record)
        : api.post("/departments", record);
    },
  });

  const handleSubmit = async (values: any) => {
    const data: any = removeEmptyAndUndefined({
      name: values.name,
      managerId: parseInt(values?.manager?.value),
      description: values.description,
    });

    console.log(values?.manager);

    await mutation.mutateAsync(data, {
      onError: (error: any) => {
        toast.error(error.response.data.message || error.message);
      },
      onSuccess: () => {
        toast.success(`department saved sucess`);
        onClose();
        onComplete();
      },
    });
  };

  const schema = Yup.object().shape({
    name: Yup.string().required().label("name"),
    manager: Yup.object({
      value: Yup.number().required(),
      label: Yup.string().required(),
    }),
    description: Yup.string().required(),
  });

  const initialValues = useMemo(() => {
    return {
      name: department?.name || "",
      manager: department?.manager
        ? {
            label:
              department.manager.first_name +
              " " +
              department.manager.last_name,
            value: department.manager.id,
            component: () => <UserLabel user={department.manager} />,
          }
        : undefined,
      description: department?.description || "",
    };
  }, [department]);

  const formRef = useRef<any>();

  const confirmDiscard = useConfirm({
    onAccept: () => {
      onClose();
      formRef.current?.resetForm();
    },
  });

  const handleClose = ({ touched }) => {
    if (Object.keys(touched)?.length) {
      confirmDiscard.open();
    } else {
      onClose();
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      {(e) => (
        <>
          <Pannel
            onClose={() => handleClose(e)}
            {...other}
            title={"Create a new Department"}
          >
            <div className="h-0 flex-1 overflow-y-auto">
              <div className="flex flex-1 flex-col justify-between">
                <div className="divide-y pb-4 divide-gray-200 px-4 sm:px-6">
                  <div className="mt-3 px-[2px]">
                    <div>
                      <div>
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          Department Information
                        </h4>
                      </div>
                      <div className="grid mt-2 grid-cols-2 gap-3">
                        <AppFormField
                          name="name"
                          label="Department name"
                          placeholder="Department name"
                        />
                        <AppFormAsyncSelect
                          name="manager"
                          label="Department manager"
                          placeholder="Department manager"
                          loadOptions={employeesLoader}
                        />
                      </div>
                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormTextarea
                          name="description"
                          label="description"
                          placeholder="Description"
                          rows={4}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-2 flex-shrink-0 justify-end px-4 py-2">
              <Button
                onClick={() => handleClose(e)}
                variant="default"
                size="sm"
              >
                Cancel
              </Button>
              <SubmitButton size="sm">Save department</SubmitButton>
            </div>
          </Pannel>

          <DiscardModal state={confirmDiscard} />
        </>
      )}
    </Formik>
  );
}
