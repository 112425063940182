import AppForm from "@/components/forms/AppForm";
import AppFormField from "@/components/forms/AppFormField";
import AppFormStatus from "@/components/forms/AppFormStatus";
import SubmitButton from "@/components/forms/SubmitButton";
import Checkbox from "@/components/ui/Checkbox";
import authClient from "@/services/auth.service";
import { Link } from "react-router-dom";
import * as Yup from "yup";

export default function ForgotPassword() {
  const handleSubmit = (values: any, { setStatus }) => {
    return authClient
      .forgotPassword({
        email: values.email,
      })
      .then(() => {
        setStatus({
          success: "Check your email for a link to reset your password.",
        });
      })
      .catch((err) => {
        setStatus({
          error: err.message || "Something went wrong",
        });
      });
  };

  return (
    <div className="max-w-lg border border-slate-200 overflow-hidden shadow-sm my-12 mx-auto bg-white rounded-md">
      <div className="flex justify-between items-center- bg-primary bg-opacity-10 gap-3">
        <div className="space-y-2 px-5 py-4">
          <h4 className="text-base font-semibold text-primary">
            Forgot your password?
          </h4>
          <p className="text-[13px] text-primary leading-7 font-medium text-opacity-70">
            Enter your email address and we will send you a link to reset your
          </p>
        </div>
        <img className="h-[115px] w-[190px]" src="/profile-img.png" alt="" />
      </div>
      <div className="px-6 py-4">
        <AppForm
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Invalid email address")
              .required("Email is required"),
          })}
          initialValues={{
            email: "",
          }}
          onSubmit={handleSubmit}
        >
          <div>
            <AppFormStatus />
          </div>
          <div>
            <AppFormField
              name={"email"}
              placeholder="Enter your email"
              label="Enter your email"
            />
          </div>
          <div className="w-full mb-2">
            <Checkbox name="rememberMe">I agree terms of service.</Checkbox>
          </div>
          <div className="w-full">
            <SubmitButton fullWidth>Send password reset link.</SubmitButton>
          </div>
          <div className="flex items-center justify-center w-full  py-3">
            <Link
              className="text-[13.5px] hover:underline text-slate-500 font-medium"
              to="/auth/login"
            >
              Remember your password? Login.
            </Link>
          </div>
        </AppForm>
      </div>
    </div>
  );
}
