import Datatable from "@/components/Datatable";
import { CheckCircle, Eye, LogOut, PlusCircle, Users, X } from "react-feather";
import { createColumnHelper } from "@tanstack/table-core";
import { api } from "@/lib/api";
import Button from "@/components/ui/Button";
import Avatar from "@/components/ui/Avatar";
import { cn } from "@/utils/cn";
import { leave_types } from "@/data";
import DashboardCard from "@/components/cards/DashboardCard";
import { useQuery, useQueryClient } from "react-query";
import LeaveFormPannel from "@/components/pannels/LeaveFormPannel";
import { useEditRow } from "@/hooks/useEditRow";
import { useState } from "react";
import { useOverlayTriggerState } from "react-stately";
import { useViewRow } from "@/hooks/useViewRow";
import LeaveModal from "@/components/modals/LeaveModal";
import Skeleton from "@/components/ui/Skeleton";
import { Link } from "react-router-dom";
import cleanObject from "@/utils/cleanObject";
import UserLabel from "@/components/UserLabel";
function clearEmptyValues(obj: any) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== "") {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

async function fetchData(
  {
    pageIndex: page,
    pageSize: page_size,
    query,
    sorting,
    ...rest
  }: {
    pageIndex: number;
    pageSize: number;
    query: string;
    sorting: any;
  },
  { headers }
) {
  const filterObj = {
    employeeId: rest["employee"],
  };
  const filters = ["type", "status", "created_at"];
  const sort = sorting[0];
  let sort_by = sort ? `${sort.id}:${sort.desc ? "desc" : "asc"}` : undefined;

  filters.forEach((item) => {
    if (Object.keys(rest).includes(item) && rest[item]) {
      filterObj[item] = rest[item];
    }
  });
  const params = clearEmptyValues({
    page: page === 0 ? 1 : page,
    page_size,
    sort_by,
    query,
    ...filterObj,
  });
  const { data } = await api.get("/leaves", {
    params: params,
    headers,
  });
  return {
    rows: data.results,
    pageCount: data.page,
    total: data.total,
  };
}

interface Leave {
  id: number;
  employee: any;
  duration: any;
  type: string;
  start_date: string;
  end_date: string;
  reason: string;
  status: string;
  approved: boolean;
  approvedBy: any;
  attachments: string[];
  created_at: string;
  start_end_date: string;
}

const columnHelper = createColumnHelper<Leave>();
function calculateDuration(start_date, end_date) {
  const startDate: any = new Date(start_date);
  const endDate: any = new Date(end_date);

  // Calculate the time difference in milliseconds
  const timeDifference = endDate - startDate;

  // Calculate the duration in days
  const daysDifference = timeDifference / (1000 * 3600 * 24);

  // If the duration is less than 1 day, calculate it in hours
  if (daysDifference < 1) {
    const hoursDifference = timeDifference / (1000 * 3600);
    return `${hoursDifference.toFixed(0)} hours`;
  }

  // If the duration is less than 30 days, calculate it in days
  if (daysDifference < 30) {
    return `${daysDifference.toFixed(0)} days`;
  }

  // If the duration is greater than or equal to 30 days, calculate it in months
  const monthsDifference = Math.floor(daysDifference / 30);
  return `${monthsDifference} months`;
}
const columns = [
  columnHelper.accessor("employee", {
    cell: (info) => {
      const names =
        info.row.original?.employee["first_name"] +
        " " +
        info.row.original?.employee["last_name"];
      return (
        <div className="flex items-center gap-3">
          <Avatar
            size="xs"
            rounded="full"
            src={info.row.original?.employee["photo"]}
            name={names}
          />
          <Link
            to={`?show_employee=${info.row.original?.employee["id"]}`}
            className="capitalize hover:underline text-slate-700 truncate"
          >
            {names}
          </Link>
        </div>
      );
    },
    header: () => "Employee",
    meta: {
      skeleton: () => {
        return (
          <div className="flex items-center gap-2">
            <div>
              <Skeleton className="w-[23px] h-[23px] rounded-full" />
            </div>
            <Skeleton className="w-[120px] h-3" />
          </div>
        );
      },

      allowFiltering: true,
      filterType: "select-async",
      loadFilterOptions: (inputValue) => {
        return api
          .get(`/employees?page_size=5`, {
            params: cleanObject({
              query: inputValue ? inputValue : undefined,
            }),
          })
          .then(({ data }) =>
            data.map((e) => ({
              label: e.first_name + " " + e.last_name,
              value: e.id,
              component: () => <UserLabel user={e} />,
            }))
          );
      },
    },
  }),
  columnHelper.accessor("duration", {
    cell: (info) => {
      return (
        <span className="capitalize text-[13px] truncate">
          {calculateDuration(
            info.row.original.start_date,
            info.row.original.end_date
          )}
        </span>
      );
    },
    header: () => "Leave duration",
  }),
  columnHelper.accessor("type", {
    cell: (info) => (
      <span className="truncate capitalize">
        {leave_types.find((e) => e.value === info.renderValue())?.label ||
          info.renderValue()}
      </span>
    ),
    header: () => "Leave type",
    meta: {
      allowFiltering: true,
      filterType: "select",
      filterOptions: leave_types,
    },
  }),
  columnHelper.accessor("status", {
    cell: (info) => {
      const getColor = () => {
        switch (info.row.original.status) {
          case "pending":
            return "text-yellow-500";
          case "approved":
            return "text-green-500";
          case "rejected":
            return "text-red-500";
          case "cancelled":
            return "text-orange-500";
          default:
            return "text-yellow-500";
        }
      };
      return (
        <span className={cn("capitalize truncate", getColor())}>
          {info.renderValue()}
        </span>
      );
    },
    header: () => "Status",
    meta: {
      allowFiltering: true,
      filterType: "select",
      filterOptions: [
        {
          label: "Pending",
          value: "pending",
        },
        {
          label: "Approved",
          value: "approved",
        },
        {
          label: "Rejected",
          value: "rejected",
        },
        {
          label: "Cancelled",
          value: "cancelled",
        },
      ],
    },
  }),
  columnHelper.accessor("start_end_date", {
    cell: (info) => (
      <span className="capitalize truncate">
        {new Date(info.row.original.start_date).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }) +
          " - " +
          new Date(info.row.original.end_date).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}
      </span>
    ),
    header: () => "Start/End date",
  }),
  columnHelper.accessor("created_at", {
    cell: (info) => (
      <span className="truncate">
        {new Date(info.renderValue() as string).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </span>
    ),
    meta: {
      allowSorting: true,
      allowFiltering: true,
      filterType: "date",
    },
    header: () => "Requested at",
  }),
];

const exportFormater = (e, original) => {
  const obj = e;
  if (Object.keys(obj).includes("Employee")) {
    obj["Employee"] =
      original["employee"]["first_name"] +
      " " +
      original["employee"]["last_name"];
  }
  if (Object.keys(obj).includes("Leave duration")) {
    obj["Leave duration"] = calculateDuration(
      original.start_date,
      original.end_date
    );
  }
  if (Object.keys(obj).includes("Start/End date")) {
    obj["Start/End date"] =
      new Date(original.start_date).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }) +
      " - " +
      new Date(original.end_date).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
  }
  return obj;
};
export default function Leaves() {
  async function fetchAnalytics() {
    const { data } = await api.get("/analytics", {
      params: {
        show: [
          "on-leave",
          "rejected-leaves",
          "pending-leaves",
          "approved-leaves",
        ],
      },
    });
    return data;
  }
  const { data: analytics } = useQuery(["leaves-analytics"], fetchAnalytics, {
    keepPreviousData: true,
    retry: false,
    staleTime: Infinity,
  });

  const editRow: any = useEditRow();

  const view: any = useViewRow();

  const actions = [
    {
      title: "View Leave",
      onClick: (e) => {
        view.open(e);
      },
      icon: Eye,
    },
  ];

  const cards = [
    {
      name: "on leave",
      title: "Employees on leave",
      value: analytics ? analytics["on-leave"] : "---",
      icon: <Users size={15} className="text-yellow-600" />,
      increase: 13.5,
      bgLight: "bg-yellow-100",
    },
    {
      name: "rejected leaves",
      title: "Rejected leaves",
      value: analytics ? analytics["rejected-leaves"] : "---",
      icon: <X size={15} className="text-red-500" />,
      increase: 2.4,
      bgLight: "bg-red-100",
    },
    {
      name: "pending leaves",
      title: "Pending leaves",
      value: analytics ? analytics["pending-leaves"] : "---",
      icon: <LogOut size={15} className="text-orange-500" />,
      increase: -10,
      bgLight: "bg-orange-100",
    },
    {
      name: "approved leaves",
      title: "approved leaves",
      value: analytics ? analytics["approved-leaves"] : "---",
      icon: <CheckCircle size={15} className="text-green-500" />,
      increase: +9.5,
      bgLight: "bg-green-100",
    },
  ];
  let formState = useOverlayTriggerState({});

  const queryClient = useQueryClient();

  const [key, setkey] = useState();
  return (
    <>
      <div className="px-2">
        <div className="my-1 flex items-center justify-between">
          <div className="space-y-1">
            <h3 className="text-[15px] font-semibold text-slate-700">
              Manage Leaves!!
            </h3>
            <p className="text-[13.5px] leading-7 font-medium text-slate-500">
              Manage all leaves here, you can create, update and delete leaves.
            </p>
          </div>
          <div>
            <Button
              onClick={() => {
                formState.open();
              }}
              LeftIcon={PlusCircle}
              size="sm"
            >
              Create New leave.
            </Button>
          </div>
        </div>
        <div>
          {" "}
          <div className="mt-3">
            <div className="grid grid-cols-4 gap-3">
              {cards.map((item, index) => {
                return <DashboardCard item={item} key={index} />;
              })}
            </div>
          </div>
        </div>

        <div className="my-4">
          <Datatable
            columns={columns}
            actions={actions}
            exportFormater={exportFormater}
            name="leaves"
            loader={fetchData}
            onKeyChange={(e) => setkey(e)}
            onMainActionClick={() => {}}
          />
        </div>
      </div>
      <LeaveFormPannel
        size="lg"
        onComplete={() => {
          queryClient.invalidateQueries({
            queryKey: key,
          });
          editRow.clear();
        }}
        open={formState.isOpen || editRow.isOpen}
        onClose={() => {
          formState.close();
          editRow.clear();
        }}
        leave={editRow.row}
      />

      <LeaveModal
        onAction={() => {
          queryClient.invalidateQueries({
            queryKey: key,
          });
          view.close();
        }}
        open={view.isOpen}
        onClose={() => {
          view.close();
        }}
        leave={view.row}
      />
    </>
  );
}
