/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { X } from "react-feather";
import { cn } from "@/utils/cn";

export default function Pannel({
  open,
  onClose,
  size = "lg",
  title,
  children,
}: any) {
  const sizes = {
    md: "w-[440px]",
    lg: "w-[620px]",
    xl: "w-[780px]",
    "2xl": "w-[980px]",
  };

  return (
    <Fragment>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-[51] inset-0 overflow-hidden"
          onClose={onClose}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-50 transition-opacity" />
            </Transition.Child>

            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div
                  className={cn("pointer-events-auto w-screen", sizes[size])}
                >
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    {title && (
                      <div className="bg-white border-b- border-slate-200 py-[9px] px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-[13.5px] font-semibold text-slate-800">
                            {title}
                          </Dialog.Title>
                          <button
                            type="button"
                            className="rounded-md text-slate-700 h-6 w-6 hover:bg-slate-100 flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <X size={14} aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    )}
                    {children}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
}
