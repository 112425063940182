import { ArrowRight, Eye, MoreVertical } from "react-feather";
import Button from "../ui/Button";
import Avatar from "../ui/Avatar";
import Menu from "../ui/Menu";
import { Link, useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import Skeleton from "../ui/Skeleton";

function RecentProjects({ projects = [], status }) {
  const [recordToMenu, setrecordToMenu] = useState(null);
  const navigate = useNavigate();
  return (
    <div className="bg-white h-full border border-slate-200 rounded-md">
      <div className="flex items-center px-3 justify-between py-1">
        <h4 className="text-[13.2px] px-2 font-semibold">On Going Projects</h4>
        <Button
          onClick={() => {
            navigate("/projects");
          }}
          RightIcon={ArrowRight}
          variant="subtle"
          size="sm"
        >
          View All Projects
        </Button>
      </div>
      <div>
        <table className="table-fixed w-full">
          <thead>
            <tr>
              <th className="text-left text-[13px] px-5 bg-slate-50 py-[10px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                Company
              </th>
              <th className="text-left text-[13px] px-5 bg-slate-50 py-[10px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                Duration
              </th>
              <th className="text-left text-[13px] px-5 bg-slate-50 py-[10px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                Client
              </th>
              <th className="text-left text-[13px] px-5 bg-slate-50 py-[10px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                Assigned To
              </th>
              <th className="text-right w-[100px] text-[13px] px-5 bg-slate-50 py-[10px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                Action
              </th>
            </tr>
          </thead>
          {status === "success" && (
            <tbody>
              {projects.map((e, i) => {
                return (
                  <tr key={i}>
                    <td className="text-left capitalize truncate leading-6 text-[13px] px-5 py-[12px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                      {e.name}
                    </td>
                    <td className="text-left truncate text-[13px] px-5 py-[12px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                      {e.duration}
                    </td>
                    <td className="text-left truncate text-[13px] px-5 py-[12px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                      {e.client}
                    </td>
                    {/* <td className="text-left truncate text-[13px] px-5 py-[12px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                      <div className="capitalize w-[100px] truncate flex items-center gap-2">
                        <span className="text-xs font-medium">
                          {e.progress}%
                        </span>
                        <ProgressBar size="sm" progress={`${e.progress}%`} />
                      </div>
                    </td> */}
                    <td className="text-left text-[13px] px-5 py-[14px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                      <div className="flex items-center gap-2">
                        <div className="flex">
                          <Avatar
                            rounded="full"
                            name={e.assignedTo.first_name}
                            size="xs"
                          />
                        </div>
                        <Link
                          className="hover:underline"
                          to={`?show_employee=${e.assignedTo.id}`}
                        >
                          <span className="truncate text-[12px] capitalize">
                            {e.assignedTo.first_name} {e.assignedTo.last_name}
                          </span>
                        </Link>
                      </div>
                    </td>
                    <td className="flex relative justify-end w-[100px] text-[13px] px-5 py-[12px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                      <a
                        className="h-7 w-6 flex items-center justify-center hover:bg-slate-100 cursor-pointer rounded-md"
                        onClick={(i) => {
                          i.stopPropagation();
                          setrecordToMenu(e.id);
                        }}
                      >
                        <MoreVertical size={16} className="text-slate-500" />
                      </a>
                      <Menu
                        items={[
                          {
                            title: "View project",
                            icon: Eye,
                            onClick: () => {
                              navigate("/projects/" + e.id);
                            },
                          },
                        ]}
                        close={() => {
                          setrecordToMenu(null);
                        }}
                        open={Boolean(recordToMenu === e.id)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {status === "loading" && (
          <Fragment>
            <table className="table-auto pl-3 my-[6px] w-full">
              <tbody>
                {[1, 2, 3, 4, 5].map((_, i) => {
                  return (
                    <tr key={i}>
                      <td className="py-3 max-w-[130px] pl-5">
                        <Skeleton className="w-[120px] h-5" />
                      </td>
                      <td>
                        <Skeleton className="w-[100px] -pl-8 h-5" />
                      </td>
                      <td>
                        <div
                          className={`w-[90px] bg-slate-200 animate-pulse overflow-hidden rounded-md h-[8px]`}
                        >
                          <div
                            style={{
                              width: Math.floor(Math.random() * 70) + 20,
                            }}
                            className={`h-full flex animate-pulse items-center justify-center transition-all rounded-md bg-slate-300`}
                          ></div>
                        </div>
                      </td>
                      <td>
                        <div className="flex items-center gap-2">
                          <div>
                            <Skeleton className="w-[23px] h-[23px] rounded-full" />
                          </div>
                          <Skeleton className="w-[120px] h-3" />
                        </div>
                      </td>
                      <td className=" w-[40px] flex items-center justify-end py-3 px-1 h-full">
                        <div>
                          <Skeleton className="w-[25px] h-[25px] rounded-full" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Fragment>
        )}
        {status === "success" && !projects.length ? (
          <Fragment>
            <div className="w-full flex items-center justify-center h-[250px]">
              <span className="text-[12.5px] font-medium text-slate-600">
                There is no ongoing project.
              </span>
            </div>
          </Fragment>
        ) : null}

        {status === "error" && (
          <Fragment>
            <div className="w-full flex items-center justify-center h-[280px]">
              <span className="text-[12.5px] font-medium text-slate-600">
                {"Something went wrong."}
              </span>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default RecentProjects;
