import useLocations from "@/hooks/useLocations";
import AppFormField from "./AppFormField";
import AppFormSelect from "./AppFormSelect";
import { useFormikContext } from "formik";
import { countries } from "@/data/countries";

function AddressForm({ name }) {
  const { values } = useFormikContext();
  const { provinces, districts, sectors, cells, villages, loading } =
    useLocations({
      province: values[name]["province"],
      district: values[name]["district"],
      sector: values[name]["sector"],
      cell: values[name]["cell"],
    });

  return (
    <>
      <div className="grid mt-2 grid-cols-2 gap-3">
        <AppFormSelect
          name={`${name}.country`}
          label="Country"
          placeholder="Choose country"
          options={countries.map((e) => ({
            label: e.name,
            value: e.name,
          }))}
        />
        <AppFormSelect
          name={`${name}.province`}
          label="Province"
          placeholder="Choose province"
          options={provinces.map((e) => ({
            label: e,
            value: e,
          }))}
          disabled={loading || !provinces?.length}
        />
      </div>{" "}
      <div className="grid mt-1 grid-cols-3 gap-3">
        <AppFormSelect
          name={`${name}.district`}
          label="district"
          placeholder="Choose district"
          options={districts.map((e) => ({
            label: e,
            value: e,
          }))}
          disabled={loading || !districts?.length}
        />
        <AppFormSelect
          name={`${name}.sector`}
          label="Sector"
          placeholder="Choose sector"
          options={sectors.map((e) => ({
            label: e,
            value: e,
          }))}
          disabled={loading || !sectors?.length}
        />
        <AppFormSelect
          name={`${name}.cell`}
          label="Cell"
          placeholder="Choose cell"
          options={cells.map((e) => ({
            label: e,
            value: e,
          }))}
          disabled={loading || !cells?.length}
        />
      </div>
      <div className="grid mt-2 grid-cols-2 gap-3">
        <AppFormSelect
          name={`${name}.village`}
          label="village"
          placeholder="Choose village"
          options={villages.map((e) => ({
            label: e,
            value: e,
          }))}
          disabled={loading || !villages?.length}
        />
        <AppFormField
          name={`${name}.street_address`}
          label="Street address"
          placeholder="Street address"
        />
      </div>{" "}
    </>
  );
}

export default AddressForm;
