import { useAuth } from "@/context/auth.context";
import { Briefcase, Calendar, GitPullRequest, Tv, Users } from "react-feather";
import AttendanceChart from "../../components/charts/AttendanceChart";
import RecentProjects from "@/components/sections/RecentProjects";
import EmployeesOverview from "@/components/sections/EmployeesOverview";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_red.css";
import { useRef, useState } from "react";
import DashboardCard from "@/components/cards/DashboardCard";
import { useQuery } from "react-query";
import { api } from "@/lib/api";
import { useMemo } from "react";

function isWorkingDay(date) {
  const dayOfWeek = date.getDay();
  return dayOfWeek >= 1 && dayOfWeek <= 5;
}

export default function AdminDashboard() {
  const { user }: any = useAuth();
  const getGreetings = () => {
    const date = new Date();
    const hours = date.getHours();
    if (hours < 12) {
      return "Good Morning";
    } else if (hours < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  async function fetchDashboardData() {
    const data = await Promise.all([
      api.get("/analytics", {
        params: {
          show: [
            "employees",
            "on-going-projects",
            "on-going-trainings",
            "pending-tenders",
          ],
        },
      }),
      api.get("/analytics", {
        params: {
          show: ["attendance-absent", "attendance-present"],
        },
      }),
      api.get("/employees", {
        params: {
          page_size: 5,
        },
      }),
      api.get("/projects", {
        params: {
          page_size: 5,
          status: "on-going",
        },
      }),
    ]);
    return data;
  }

  const { data: dashboardAnalytics, status: dashboardAnalyticsStatus } =
    useQuery(["dashboard-analytics"], fetchDashboardData, {
      keepPreviousData: true,
      retry: false,
      staleTime: Infinity,
    });

  const memoizedValues = useMemo(() => {
    if (dashboardAnalyticsStatus === "success") {
      return {
        analytics: dashboardAnalytics[0].data,
        attendanceAnalytics: dashboardAnalytics[1].data,
        employees: dashboardAnalytics[2]?.data,
        projects: dashboardAnalytics[3]?.data,
      };
    } else {
      return {
        analytics: undefined,
        attendanceAnalytics: undefined,
        employees: undefined,
        projects: undefined,
      };
    }
  }, [dashboardAnalyticsStatus, dashboardAnalytics]);

  const { analytics, attendanceAnalytics, employees, projects } =
    memoizedValues;

  const cards = [
    {
      name: "employees",
      title: "Total Employees",
      value: analytics ? analytics["employees"] : "---",
      icon: <Users size={15} className="text-blue-500" />,
      increase: 13.5,
      link: "/hr/employees",
      bgLight: "bg-blue-100",
    },
    {
      name: "projects",
      title: "On going projects",
      value: analytics ? analytics["on-going-projects"] : "---",
      icon: <Briefcase size={15} className="text-green-500" />,
      increase: -30.5,
      link: "/projects",
      bgLight: "bg-green-100",
    },
    {
      name: "trainings",
      title: "On going Trainings",
      value: analytics ? analytics["on-going-trainings"] : "---",
      icon: <Tv size={15} className="text-pink-500" />,
      increase: +70.5,
      link: "/trainings",
      bgLight: "bg-pink-100",
    },
    {
      name: "tenders",
      title: "Pending tenders",
      value: analytics ? analytics["pending-tenders"] : "---",
      icon: <GitPullRequest size={15} className="text-indigo-500" />,
      increase: +70.5,
      link: "/projects/tenders",
      bgLight: "bg-indigo-100",
    },
  ];

  const fp = useRef(null);

  const [dates, setdates] = useState([new Date()]);

  const attendance = [
    {
      name: "Present",
      value: attendanceAnalytics
        ? attendanceAnalytics["attendance-present"]
        : 0,
      color: "#22c55e",
    },
    {
      name: "Absent",
      value: attendanceAnalytics ? attendanceAnalytics["attendance-absent"] : 0,
      color: "red",
    },
  ];
  return (
    <div className="px-2">
      <div className="my-1 flex items-center justify-between">
        <div className="space-y-1">
          <h3 className="text-[15px] font-semibold text-slate-700">
            {getGreetings()}, <span className="capitalize">{user.names}</span>!
          </h3>
          <p className="text-[13.5px] leading-7 font-medium text-slate-500">
            Here 's what ' happening within the organization.
          </p>
        </div>
        <div>
          <div className="relative">
            <a
              onClick={() => {
                if (!fp?.current?.flatpickr) return;
                fp.current.flatpickr.open();
              }}
              className="text-sm gap-3 opacity-90 pointer-events-none font-medium cursor-pointer bg-white px-4 py-2 border border-slate-200 rounded-md flex items-center"
            >
              <Calendar size={16} className="text-slate-600" />
              <span className="flex items-center text-[13.2px] text-slate-600 gap-2">
                {dates?.length &&
                  dates
                    .filter(function (item, pos, self) {
                      return self.indexOf(item) == pos;
                    })
                    .map((e) =>
                      new Date(e).toLocaleDateString("en-US", {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        minute: "2-digit",
                        hour: "numeric",
                      })
                    )
                    .join(" - ")}
              </span>
            </a>
            <div className="absolute top-5 left-0 z-[-1]">
              <Flatpickr
                className="opacity-0"
                value={dates.map((e) => new Date(e))}
                options={{ mode: "range" }}
                onChange={(str) => {
                  setdates(
                    str.map((e) => new Date(e).toLocaleDateString("en-CA"))
                  );
                }}
                ref={fp}
              />
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <div className="mt-3">
        <div className="grid grid-cols-4 gap-3">
          {cards.map((item, index) => {
            return <DashboardCard item={item} key={index} />;
          })}
        </div>
      </div>
      <div className="grid my-4 gap-4 grid-cols-8">
        <div className="col-span-6">
          <RecentProjects
            projects={projects}
            status={dashboardAnalyticsStatus}
          />
        </div>
        <div className="col-span-2">
          <AttendanceView
            status={dashboardAnalyticsStatus}
            attendance={attendance}
          />
        </div>
      </div>
      <div className="">
        <EmployeesOverview
          employees={employees}
          status={dashboardAnalyticsStatus}
        />
      </div>
    </div>
  );
}

function AttendanceView({ attendance, status }) {
  const attendacesValues = attendance.reduce((a, b) => a + b.value, 0);

  return (
    <div className="bg-white border h-full border-slate-200 rounded-md p-3">
      <div className="px-2">
        <h3 className="text-[13.5px] font-semibold text-slate-700">
          Toady Attendance
        </h3>
      </div>
      <div className="px-2 flex flex-col justify-between  h-full py-2">
        {status === "loading" && (
          <div className="w-full flex h-full justify-center items-center">
            <div className="h-[170px] animate-pulse flex items-center relative  justify-center w-[170px] bg-slate-200 rounded-full">
              <div className="h-[130px] w-[130px] bg-white rounded-full"></div>
              <div className="w-[100px] right-0 bg-white h-[7px] absolute"></div>
            </div>
          </div>
        )}
        {status === "success" && attendacesValues ? (
          <div className="flex py-4 flex-col items-center justify-center">
            <AttendanceChart data={attendance} />
          </div>
        ) : null}
        {((status === "success" && !attendacesValues) ||
          !isWorkingDay(new Date())) && (
          <div className="w-full flex  items-center justify-center h-full">
            <span className="text-[13px] font-medium text-slate-500">
              {" "}
              No attendance available
            </span>
          </div>
        )}

        {status === "loading" && (
          <div className="h-full- pb-3 space-y-3 mt-0">
            {attendance.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex items-center justify-between mt-2"
                >
                  <div className="flex items-center gap-3">
                    <div
                      className="h-3 w-3 rounded-full"
                      style={{ backgroundColor: item.color }}
                    ></div>
                    <span className="text-[13px] text-slate-600 capitalize font-medium">
                      {item.name}
                    </span>
                  </div>
                  <span className="text-[12.5px]">{item.value}</span>
                </div>
              );
            })}
          </div>
        )}
        {status === "success" && (
          <div className="h-full- pb-3 space-y-3 mt-0">
            {attendance.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex items-center justify-between mt-2"
                >
                  <div className="flex items-center gap-3">
                    <div
                      className="h-3 w-3 rounded-full"
                      style={{ backgroundColor: item.color }}
                    ></div>
                    <span className="text-[13px] text-slate-600 capitalize font-medium">
                      {item.name}
                    </span>
                  </div>
                  <span className="text-[12.5px]">{item.value}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
