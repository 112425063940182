import { Item, useAsyncList } from "react-stately";
import { Select } from "../ui/Select";
import { useFormikContext } from "formik";
import { Fragment } from "react";
import { ComboBox } from "../ui/ComboBox";
import get from "lodash.get";

export default function AppFormSelect({
  name,
  label,
  options,
  placeholder,
  disabled,
  loader,
  OptionItem,
  isCombobox,
}: any) {
  const { setFieldValue, errors, touched, setFieldTouched, values } =
    useFormikContext();

  const employeesOptions = useAsyncList({
    load: loader,
  });

  const value = get(values, name);
  const selectProps = {
    isDisabled: disabled || employeesOptions?.isLoading,
    isLoading: employeesOptions?.isLoading,
    selectedKey: typeof value === "number" ? value.toString() : value,
    errorMessage:
      get(errors, name) && get(touched, name) ? get(errors, name) : "",
    onSelectionChange: (e) => {
      setFieldValue(name, e);
    },
    onBlur: () => {
      setFieldTouched(name);
    },
    label: label,
    placeholder: placeholder,
    items: loader ? employeesOptions.items : options,
    selectionMode: "single",
  };

  return (
    <Fragment>
      {isCombobox ? (
        <ComboBox {...selectProps}>
          {(item: any) => (
            <Item textValue={item.label} key={item.value}>
              {OptionItem ? <OptionItem item={item} /> : item.label}
            </Item>
          )}
        </ComboBox>
      ) : (
        <Select {...selectProps}>
          {(item: any) => (
            <Item textValue={item.label} key={item.value}>
              {OptionItem ? <OptionItem item={item} /> : item.label}
            </Item>
          )}
        </Select>
      )}
    </Fragment>
  );
}
