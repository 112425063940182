import { ArrowRight, MoreVertical, User } from "react-feather";
import Button from "../ui/Button";
import Avatar from "../ui/Avatar";
import { Fragment, useState } from "react";
import Menu from "../ui/Menu";
import { useNavigate } from "react-router-dom";
import { employment_types } from "@/data";
import Skeleton from "../ui/Skeleton";

function EmployeesOverview({ employees = [], status }) {
  const navigate = useNavigate();

  const [recordToMenu, setrecordToMenu] = useState(null);

  return (
    <div className="bg-white border border-slate-200 rounded-md">
      <div className="flex items-center px-3 justify-between py-1">
        <h4 className="text-[13.5px] px-2 font-semibold">Employees overview</h4>
        <Button
          onClick={() => {
            navigate("/hr/employees");
          }}
          RightIcon={ArrowRight}
          variant="subtle"
          size="sm"
        >
          View employees
        </Button>
      </div>
      <div className="min-h-[320px]">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="text-left text-[13px] px-5 bg-slate-50 py-[10px] text-slate-600 border-t border-b border-slate-200 border-b-slate-200 font-semibold">
                Name
              </th>
              <th className="text-left text-[13px] px-5 bg-slate-50 py-[10px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                Employment type
              </th>
              <th className="text-left text-[13px] px-5 bg-slate-50 py-[10px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                Gender
              </th>
              <th className="text-left text-[13px] px-5 bg-slate-50 py-[10px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                Contact
              </th>

              <th className="text-left text-[13px] px-5 bg-slate-50 py-[10px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                Department
              </th>
              <th className="text-left text-[13px] px-5 bg-slate-50 py-[10px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                Joined at
              </th>
              <th className="text-right w-[100px] text-[13px] px-5 bg-slate-50 py-[10px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {status === "success" && (
              <Fragment>
                {employees.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td className="text-left truncate leading-6 text-[13px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        <div className="flex  items-center gap-3">
                          <Avatar
                            rounded="full"
                            variant="light"
                            name={e.first_name + " " + e.last_name}
                            src={e.photo}
                          />
                          <div className="space-y-[0px]">
                            <h4 className="text-[13px] capitalize font-semibold text-slate-800">
                              {e.first_name} {e.last_name}
                            </h4>
                            <p className="text-[12px]"> {e.email}</p>
                          </div>
                        </div>
                      </td>
                      <td className="text-left capitalize font-medium truncate text-[12.5px] px-5 py-[16px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100">
                        {employment_types.find(
                          (i) => i.value === e.employment_type
                        )?.label || e.employment_type}
                      </td>
                      <td className="text-left capitalize font-medium truncate text-[12.5px] px-5 py-[16px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100">
                        {e.gender}
                      </td>
                      <td className="text-left truncate text-[13px] px-5 py-[16px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        {e.phone}
                      </td>
                      <td className="text-left truncate text-[13px] px-5 py-[16px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        {e.department?.name}
                      </td>
                      <td className="text-left truncate text-[13px] px-5 py-[16px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        {new Date(e.created_at).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </td>
                      <td className="flex relative justify-end w-[100px] text-[13px] px-5 py-[16px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        <a
                          className="h-7 w-6 flex items-center justify-center hover:bg-slate-100 cursor-pointer rounded-md"
                          onClick={(i) => {
                            i.stopPropagation();
                            setrecordToMenu(e.id);
                          }}
                        >
                          <MoreVertical size={16} className="text-slate-500" />
                        </a>
                        <Menu
                          items={[
                            {
                              title: "View profile",
                              icon: User,
                              onClick: () => {
                                navigate({
                                  search: `?show_employee=${e.id}`,
                                });
                              },
                            },
                          ]}
                          close={() => {
                            setrecordToMenu(null);
                          }}
                          open={Boolean(recordToMenu === e.id)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </Fragment>
            )}
          </tbody>
        </table>
        {status === "loading" && (
          <Fragment>
            <table className="table-auto pl-3 my-[6px] w-full">
              <tbody>
                {[1, 2, 3, 4, 5].map((_, i) => {
                  return (
                    <tr key={i}>
                      <td className="py-3 max-w-[130px] pl-5">
                        <div className="flex items-center gap-2">
                          <div>
                            <Skeleton className="w-[40px] h-[40px] rounded-full" />
                          </div>
                          <div className="flex flex-col  gap-3">
                            <Skeleton className="w-[150px] h-3" />
                            <Skeleton className="w-[110px] h-2" />
                          </div>
                        </div>
                      </td>
                      <td>
                        <Skeleton className="w-[100px] -pl-8 h-4" />
                      </td>
                      <td>
                        <Skeleton className="w-[100px] -pl-8 h-4" />
                      </td>
                      <td>
                        <Skeleton className="w-[100px] -pl-8 h-4" />
                      </td>
                      <td>
                        <div className="flex items-center gap-2">
                          <Skeleton className="w-[120px] h-3" />
                        </div>
                      </td>
                      <td className=" w-[20px] flex items-center justify-end py-3 px-1 h-full">
                        <div>
                          <Skeleton className="w-[25px] h-[25px] rounded-full" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Fragment>
        )}

        {status === "error" && (
          <Fragment>
            <div className="w-full flex items-center justify-center h-[300px]">
              <span className="text-[12.5px] font-medium text-slate-600">
                {"Something went wrong."}
              </span>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default EmployeesOverview;
