import { useEffect, useState } from "react";
import Modal from "../ui/Modal";

function EvaluationModal({ evaluation: data, onClose, open }: any) {
  const [evaluation, setEvaluation] = useState(data);

  useEffect(() => {
    if (data) {
      setEvaluation(data);
    }
  }, [data]);

  return (
    <Modal
      title="Evaluation details."
      size="lg"
      showDividers={false}
      close={onClose}
      open={open}
    >
      <div className="grid px-[6px] gap-4 my-0 pt-0 grid-cols-2">
        {[
          {
            key: "Created by",
            value:
              evaluation?.created_by["first_name"] +
              " " +
              evaluation?.created_by["last_name"],
          },
          { key: "Period", value: evaluation?.period },
          {
            key: "Score",
            value: evaluation?.score + "%",
          },
          {
            key: "Created  at",
            value: new Date(evaluation?.created_at).toLocaleDateString(
              "en-US",
              {
                year: "numeric",
                month: "short",
                day: "numeric",
              }
            ),
          },
          {
            key: "Employee",
            value:
              evaluation?.employee["first_name"] +
              " " +
              evaluation?.employee["last_name"],
          },
        ].map((e, i) => {
          return (
            <div className="flex  items-center gap-4" key={i}>
              <span className="text-[12.5px] font-medium capitalize text-slate-500">
                {e.key}:
              </span>
              <span className="text-[12.2px] truncate font-semibold capitalize text-slate-700">
                {e.value}
              </span>
            </div>
          );
        })}
      </div>
      <div className="px-2 pb-2 mt-5">
        <h4 className="uppercase text-[12.5px] font-semibold text-slate-600">
          Evalution criterias
        </h4>
        <div className="grid px-[0px] mt-4 gap-4 my-0 pt-0 grid-cols-2">
          {evaluation?.scores?.map((e, i) => {
            return (
              <div className="flex  items-center gap-4" key={i}>
                <span className="text-[12.5px] font-medium capitalize text-slate-500">
                  {e.criteria}:
                </span>
                <span className="text-[12.2px] truncate font-semibold capitalize text-slate-700">
                  {e.score_value}/10
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}

export default EvaluationModal;
