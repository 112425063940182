import { QueryClient, QueryClientProvider } from "react-query";
import { Outlet } from "react-router-dom";
import { AuthProvider } from "@/context/auth.context";
import { Toaster } from "react-hot-toast";
import { EmployeeProvider } from "./context/employee.context";
import { ReactQueryDevtools } from "react-query/devtools";
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster
        toastOptions={{
          duration: 4000,
          className:
            "text-[13px] font-medium border !rounded-[5px] border-slate-200",
        }}
        position="top-right"
        reverseOrder={false}
      />

      <AuthProvider>
        <EmployeeProvider>
          <Outlet />
        </EmployeeProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
