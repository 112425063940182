import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useMemo, useState, Fragment, useRef, useEffect } from "react";
import { useQuery } from "react-query";
import { useDebounce, useOnClickOutside } from "usehooks-ts";

import {
  Download,
  MoreVertical,
  Plus,
  Search,
  ArrowRight,
  X,
  Check,
} from "react-feather";
import Checkbox from "./ui/Checkbox";
import Loader from "./ui/Loader";
import Pagination from "./ui/Pagination";
import Menu from "./ui/Menu";
import { cn } from "@/utils/cn";
import ArrowUpDown from "./vectors/ArrowUpDown";
import SimpleBar from "simplebar-react";
import { Transition } from "@headlessui/react";
import { mkConfig, generateCsv, download } from "export-to-csv";
import ReportToPrint from "./ReportToPrint";
import { usePDF } from "react-to-pdf";
import Button from "./ui/Button";
import DropdownTransition from "./DropdownTransition";
import { NewDatePicker } from "./ui/NewDatePicker";
import Skeleton from "./ui/Skeleton";
import PopoutSelect from "./ui/PopoutSelect";

function checkDateOrder(dates) {
  if (dates.length <= 1) {
    // If there's only one date or no dates, consider it sorted
    return "sorted";
  }

  let ascending = true;
  let descending = true;

  for (let i = 1; i < dates.length; i++) {
    const prevDate = new Date(dates[i - 1]);
    const currentDate = new Date(dates[i]);

    if (currentDate < prevDate) {
      ascending = false;
    }

    if (currentDate > prevDate) {
      descending = false;
    }
  }

  if (ascending && !descending) {
    return "asc";
  } else if (!ascending && descending) {
    return "desc";
  } else {
    return "unsorted";
  }
}

function checkStringOrder(arr) {
  if (arr.length <= 1) {
    // If there's only one string or no strings, consider it sorted
    return "sorted";
  }

  let ascending = true;
  let descending = true;

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] < arr[i - 1]) {
      ascending = false;
    }

    if (arr[i] > arr[i - 1]) {
      descending = false;
    }
  }

  if (ascending && !descending) {
    return "asc";
  } else if (!ascending && descending) {
    return "desc";
  } else {
    return "unsorted";
  }
}

function checkNumberOrder(arr) {
  if (arr.length <= 1) {
    // If there's only one number or no numbers, consider it sorted
    return "sorted";
  }

  let ascending = true;
  let descending = true;

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] < arr[i - 1]) {
      ascending = false;
    }

    if (arr[i] > arr[i - 1]) {
      descending = false;
    }
  }

  if (ascending && !descending) {
    return "asc";
  } else if (!ascending && descending) {
    return "desc";
  } else {
    return "unsorted";
  }
}

function isDate(str) {
  // Regular expression for ISO 8601 date format (YYYY-MM-DDTHH:MM:SS.sssZ)
  const iso8601DateRegex =
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}\.\d{3})Z$/;

  return iso8601DateRegex.test(str);
}
export default function Datatable({
  loader,
  name,
  actions,
  columns,
  Action,
  exportFormater,
  filterable = true,
  onKeyChange,
  default_filters,
  meta,
}: {
  loader: any;
  name: any;
  actions: any;
  columns: any;
  onMainActionClick?: any;
  Action?: any;
  exportFormater?: any;
  filterable?: boolean;
  onKeyChange?: any;
  meta?: any;
  default_filters?: any;
}) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    default_filters || []
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: 15,
  });
  const convertFiltersToObj = (filters) => {
    const obj = {};
    filters.forEach((e) => {
      obj[e.id] = e.value.value || e.value;
    });
    return obj;
  };

  const [searchText, setsearchText] = useState("");

  const debouncedSearchValue = useDebounce<string>(searchText, 500);

  const [sorting, setSorting] = useState<SortingState>([]);

  const fetchDataOptions = useMemo(
    () => ({
      pageIndex,
      pageSize,
      query: debouncedSearchValue,
      sorting,
      meta,
      ...convertFiltersToObj(columnFilters),
    }),
    [pageIndex, pageSize, debouncedSearchValue, columnFilters, sorting]
  );

  useEffect(() => {
    if (onKeyChange) {
      onKeyChange(fetchDataOptions);
    }
  }, [fetchDataOptions]);

  const dataQuery = useQuery(
    [name, fetchDataOptions],
    (e) => {
      const options = e.queryKey[1];
      return loader(options, {
        headers: {
          "cache-control": options.query ? "no-cache" : "cache",
        },
      });
    },
    { keepPreviousData: true, retry: false, staleTime: Infinity }
  );

  const defaultData = useMemo(() => [], []);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const [rowSelection, setRowSelection] = useState({});

  const getVisibleCols = (columns) => {
    const obj = {};
    const colsToShow = columns.filter((e) => e?.meta?.hidden);
    colsToShow.forEach((e) => {
      obj[e.accessorKey] = false;
    });
    return obj;
  };

  const columsToFiler = columns.filter((e) => e?.meta?.allowFiltering);

  const table = useReactTable({
    columns,
    manualPagination: true,
    onPaginationChange: setPagination,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    pageCount: dataQuery.data?.pageCount ?? -1,
    initialState: {
      columnVisibility: getVisibleCols(columns),
    },
    state: {
      pagination,
      sorting,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true, //enable row selection for all rows
    onRowSelectionChange: setRowSelection,
    data: dataQuery.data?.rows ?? defaultData,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
  });

  const [seletedMenuRow, setseletedMenuRow] = useState<any>();

  const [showColumnsToggler, setshowColumnsToggler] = useState(false);

  const [showColumnsToFilter, setshowColumnsToFilter] = useState(false);

  const [showExportChoose, setshowExportChoose] = useState(false);

  const [exportingToPdf, setexportingToPdf] = useState(false);

  const csvConfig = mkConfig({
    useKeysAsHeaders: true,
    filename: `Dnr ${name} export`,
  });

  const { toPDF, targetRef: printRef } = usePDF({
    filename: `Dnr ${name} export`,
  });

  const [contentToPrint, setcontentToPrint] = useState<any>(null);

  const handleExport = (format) => {
    const data = table.getRowModel().rows.map((e) => {
      const obj = {};
      e.getVisibleCells().forEach((e) => {
        // @ts-ignore
        obj[e.column.columnDef.header()] = e.getValue();
      });
      return exportFormater(obj, e.original);
    });
    if (format === "CSV" || format === "Excel") {
      const csv = generateCsv(csvConfig)(data);
      download(csvConfig)(csv);
    } else if (format === "PDF") {
      setcontentToPrint(data);
      setexportingToPdf(true);
      setTimeout(() => {
        toPDF();
        setTimeout(() => {
          setexportingToPdf(false);
          setcontentToPrint(null);
        }, 200);
      }, 500);
    }
  };

  const [activeNewFilter, setactiveNewFilter] = useState<any>(null);

  return (
    <div>
      <div className="bg-white border border-slate-300 border-opacity-70 rounded-md">
        <div className="flex items-center px-[9px] py-[9px] justify-between">
          <div className="flex items-center gap-3">
            <div className="relative border focus-within:border-primary px-3 py-[6.5px] w-[230px] rounded-[3px] border-slate-200 flex items-center gap-2">
              <input
                onChange={(e) => {
                  setsearchText(e.target.value);
                }}
                className="text-[12.5px] w-full text-slate-600 font-medium outline-none"
                type="text"
                placeholder="Search here..."
              />
              <div className="absolute right-2 top-[8px]">
                {dataQuery.isFetching && dataQuery.data ? (
                  <Loader />
                ) : (
                  <Search className=" text-slate-500 mr-1" size={14} />
                )}
              </div>
            </div>
            <div className="flex items-center gap-3">
              {columnFilters.slice(-2).map((e: any, i) => {
                const column = table.getColumn(e.id);
                return <FilterViewer filter={e} key={i} column={column} />;
              })}
              {columnFilters.length ? (
                <a
                  onClick={() => {
                    table.resetColumnFilters();
                  }}
                  className="text-[13px] cursor-pointer flex items-center gap-2 hover:bg-slate-100 px-2 py-1 rounded-md text-slate-500 font-medium"
                >
                  <span>Reset</span>
                  <X className="text-slate-500" size={14} />
                </a>
              ) : null}
            </div>
          </div>
          <div className="flex items-center gap-3">
            {" "}
            <div className="relative">
              {filterable && (
                <a
                  onClick={() => {
                    setshowColumnsToFilter(true);
                  }}
                  className={cn(
                    "flex border-dashed cursor-pointer text-[11.5px] hover:bg-slate-100 py-[5px] px-3 font-semibold items-center gap-2 border border-slate-200 rounded-md",
                    {
                      "pointer-events-none opacity-80": dataQuery.isFetching,
                    }
                  )}
                >
                  <svg
                    viewBox="0 0 24 24"
                    height={18}
                    width={18}
                    fill="none"
                    className="text-slate-600 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3 7C3 6.44772 3.44772 6 4 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H4C3.44772 8 3 7.55228 3 7ZM6 12C6 11.4477 6.44772 11 7 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H7C6.44772 13 6 12.5523 6 12ZM9 17C9 16.4477 9.44772 16 10 16H14C14.5523 16 15 16.4477 15 17C15 17.5523 14.5523 18 14 18H10C9.44772 18 9 17.5523 9 17Z"
                      />
                    </g>
                  </svg>

                  <span className="text-slate-600">Filter</span>
                </a>
              )}

              <NewFilter
                onSelect={(e) => {
                  const accessorKey = e.field;
                  const column = table.getColumn(accessorKey);
                  column.setFilterValue(() => {
                    return e.value;
                  });
                }}
                column={
                  activeNewFilter?.column.accessorKey
                    ? table.getColumn(activeNewFilter?.column.accessorKey)
                    : undefined
                }
                open={Boolean(activeNewFilter)}
                close={() => {
                  setactiveNewFilter(null);
                }}
                activeNewFilter={activeNewFilter}
              />

              <ColumnsToFilter
                columns={columsToFiler}
                close={() => {
                  setshowColumnsToFilter(false);
                }}
                onChoose={(e) => {
                  const { filterType, filterOptions, loadFilterOptions } =
                    e?.meta;
                  if (filterType === "select") {
                    setactiveNewFilter({
                      title: "choose " + e.header(),
                      type: filterType,
                      options: filterOptions,
                      optionsLoader: loadFilterOptions,
                      column: e,
                    });
                  } else if (filterType === "date") {
                    setactiveNewFilter({
                      title: "choose " + e.header(),
                      type: filterType,
                      column: e,
                    });
                  } else if (filterType === "range") {
                    setactiveNewFilter({
                      title: "choose " + e.header(),
                      type: filterType,
                      column: e,
                    });
                  } else if (filterType === "select-async") {
                    setactiveNewFilter({
                      title: "choose " + e.header(),
                      type: filterType,
                      options: filterOptions,
                      optionsLoader: loadFilterOptions,
                      column: e,
                    });
                  }
                  setshowColumnsToFilter(false);
                }}
                table={table}
                open={showColumnsToFilter}
              />
            </div>
            <div className="relative">
              <a
                onClick={() => {
                  setshowExportChoose(true);
                }}
                className={cn(
                  "flex cursor-pointer text-[12px] hover:bg-slate-100 py-[5px] px-2 font-semibold items-center gap-2 border border-slate-200 rounded-md",
                  {
                    "pointer-events-none opacity-80":
                      dataQuery.isFetching || dataQuery.error,
                  }
                )}
              >
                <Download className="text-slate-600" size={15} />
                <span className="text-slate-600">Export</span>
              </a>
              <ExportChooser
                handleExport={handleExport}
                close={() => {
                  setshowExportChoose(false);
                }}
                open={showExportChoose}
              />
            </div>
            <div className="relative">
              <a
                onClick={() => {
                  setshowColumnsToggler(true);
                }}
                className={cn(
                  "flex cursor-pointer text-[12px] hover:bg-slate-100 py-[5px] px-2 font-semibold items-center gap-2 border border-slate-200 rounded-md",
                  {
                    "pointer-events-none opacity-80": dataQuery.isFetching,
                  }
                )}
              >
                <svg
                  height={16}
                  width={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  className="text-slate-600 stroke-current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M8 8.5C8 9.88071 6.88071 11 5.5 11C4.11929 11 3 9.88071 3 8.5C3 7.11929 4.11929 6 5.5 6C6.88071 6 8 7.11929 8 8.5ZM8 8.5H21M16 15.5C16 16.8807 17.1193 18 18.5 18C19.8807 18 21 16.8807 21 15.5C21 14.1193 19.8807 13 18.5 13C17.1193 13 16 14.1193 16 15.5ZM16 15.5H3"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
                <span className="text-slate-600 truncate">View columns</span>
              </a>
              <ColumnsToggler
                close={() => {
                  setshowColumnsToggler(false);
                }}
                table={table}
                open={showColumnsToggler}
              />
            </div>
            {Action && (
              <div>
                <Action />
              </div>
            )}
          </div>
        </div>
        <SimpleBar style={{ maxWidth: "100%" }}>
          <table className="table-auto w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup, i) => (
                <tr key={i}>
                  <th className="bg-slate-100  bg-opacity-70 pl-4  border-b border-t border-slate-200 rounded-tl-md  py-[9px] ">
                    <Checkbox
                      onChange={() => {
                        table.toggleAllRowsSelected();
                      }}
                      isSelected={table.getIsAllRowsSelected()}
                      indeterminate={table.getIsSomeRowsSelected()}
                    />
                  </th>
                  {headerGroup.headers.map((header) => (
                    <th
                      className="text-left border-t truncate mt-1 bg-slate-100 border-b border-slate-200  bg-opacity-70 text-[12.5px] capitalize py-[12px] font-semibold text-slate-600 px-3"
                      key={header.id}
                    >
                      <div
                        onClick={() => {
                          if (
                            header?.column?.columnDef?.meta &&
                            header?.column?.columnDef?.meta["allowSorting"]
                          ) {
                            const getCurrentSortFromColumn = (column) => {
                              const datas = dataQuery.data.rows.map(
                                (e) => e[column]
                              );
                              if (isDate(datas[0])) {
                                console.log("--- is date ---");
                                const order = checkDateOrder(datas);
                                console.log(order);
                                return order;
                              } else if (typeof datas[0] === "string") {
                                console.log("--- is string ---");
                                const order = checkStringOrder(datas);
                                return order;
                              } else if (typeof datas[0] === "number") {
                                console.log("--- is number ---");
                                const order = checkNumberOrder(datas);
                                return order;
                              } else {
                                return "none";
                              }
                            };
                            const order = getCurrentSortFromColumn(
                              header.column.columnDef.meta["default_sorting"] ||
                                header.column.id
                            );

                            const sort = {
                              id: header.column.id as string,
                              desc: order === "asc" ? true : false,
                            };

                            table.setSorting([sort]);
                          }
                        }}
                        className={cn("flex items-center  gap-3", {
                          "cursor-pointer":
                            header?.column?.columnDef?.meta &&
                            header?.column?.columnDef?.meta["allowSorting"],
                        })}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        {header?.column?.columnDef?.meta &&
                          header?.column?.columnDef?.meta["allowSorting"] && (
                            <ArrowUpDown className="text-slate-600" />
                          )}
                      </div>
                    </th>
                  ))}
                  {actions.length ? (
                    <th className=" bg-slate-100  border-t bg-opacity-70 rounded-tr-md  border-b border-slate-200 text-right text-[12.5px] px-6 capitalize font-semibold text-slate-600  py-[9px]">
                      Action
                    </th>
                  ) : null}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  <td
                    className={cn("pl-4", {
                      "bg-slate-100": row.getIsSelected(),
                    })}
                  >
                    <Checkbox
                      disabled={!row.getCanSelect()}
                      onChange={() => {
                        row.toggleSelected();
                      }}
                      isSelected={row.getIsSelected()}
                      indeterminate={row.getIsSomeSelected()}
                    />
                  </td>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      className={cn("py-[10px] px-3", {
                        "bg-slate-100": row.getIsSelected(),
                      })}
                      key={cell.id}
                    >
                      <span className="text-[12.5px] leading-7 font-medium text-slate-500">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </span>
                    </td>
                  ))}
                  {actions.length ? (
                    <td
                      className={cn(
                        "flex relative items-center py-[11px] justify-end px-4",
                        {
                          "bg-slate-100": row.getIsSelected(),
                        }
                      )}
                    >
                      <a
                        onClick={() => {
                          setseletedMenuRow(row.id);
                        }}
                        className="h-8 w-8 cursor-pointer text-slate-500 hover:bg-slate-200 rounded-md flex items-center justify-center"
                      >
                        <MoreVertical size={16} />
                      </a>
                      <Menu
                        open={seletedMenuRow === row.id}
                        items={actions}
                        close={() => {
                          setseletedMenuRow(null);
                        }}
                        meta={row.original}
                      />
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
            {dataQuery.status === "loading" && (
              <Fragment>
                <tbody className="table-auto pl-3 my-[6px] w-full">
                  {[1, 2, 3, 4, 5, 6, 7].map((_, i) => {
                    return (
                      <tr key={i}>
                        <td className="bg-opacity-70 pl-4  rounded-tl-md  py-[9px] ">
                          <Checkbox />
                        </td>
                        {table.getHeaderGroups()[0].headers.map((e: any, i) => {
                          return (
                            <td key={i} className="py-3 pl-3">
                              {e.column?.columnDef?.meta?.skeleton ? (
                                <e.column.columnDef.meta.skeleton />
                              ) : (
                                <Skeleton className="w-[120px] h-4" />
                              )}
                            </td>
                          );
                        })}
                        {actions.length ? (
                          <td className=" w-[40px]- flex items-center justify-end py-3 px-1 h-full">
                            <div className="px-5">
                              <Skeleton className="w-[25px] h-[25px] rounded-full" />
                            </div>
                          </td>
                        ) : null}
                      </tr>
                    );
                  })}
                </tbody>
              </Fragment>
            )}
          </table>
        </SimpleBar>
        {/* </div> */}
        <div className="border-b border-slate-200-">
          {dataQuery.status === "error" && (
            <div className="h-[400px] border-t border-slate-200 flex items-center justify-center">
              <p className="text-[13px] font-medium text-center capitalize text-slate-500">
                Some thing went wrong while fetching
              </p>
            </div>
          )}

          {dataQuery.status === "success" &&
            table.getRowModel()?.rows?.length === 0 && (
              <div className="h-[400px] border-t border-slate-200 flex items-center justify-center">
                <p className="text-[13px] font-medium text-center capitalize text-slate-500">
                  No results available
                </p>
              </div>
            )}
        </div>
        {dataQuery.status === "success" && table.getRowModel()?.rows?.length ? (
          <div className="w-full">
            <div className="px-3 w-full py-3 flex items-center justify-between">
              <div className="text-sm font-medium">
                <div className="text-[13px] flex items-center gap-3 px-2 text-slate-500 text-opacity-90">
                  <div className="flex items-center gap-1">
                    <div>Page</div>
                    <span className="text-slate-600 font-semibold">
                      {table.getState().pagination.pageIndex}
                    </span>
                    <span>Of</span>
                    <span className="text-slate-600 font-semibold">
                      {table.getPageCount()}
                    </span>
                  </div>
                  <select
                    className="hover:bg-slate-100 py-1 px-2 rounded-md cursor-pointer border-transparent border hover:border-slate-200"
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                      table.setPagination({
                        pageIndex: 1,
                        pageSize: parseInt(e.target.value),
                      });
                    }}
                  >
                    {[15, 50, 100, 200, 500, 1000].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <Pagination
                  page={table.getState().pagination.pageIndex}
                  total={dataQuery?.data?.total}
                  onChange={(e: any) => {
                    return table.setPagination({
                      pageIndex: e,
                      pageSize: table.getState().pagination.pageSize,
                    });
                  }}
                  size={table.getState().pagination.pageSize}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div
        className={cn({
          "opacity-0": exportingToPdf,
          hidden: !exportingToPdf,
        })}
      >
        {contentToPrint && (
          <ReportToPrint rows={contentToPrint} ref={printRef} />
        )}
      </div>
    </div>
  );
}

function NewFilter({ activeNewFilter, close, open, column, onSelect }) {
  const value = column?.getFilterValue();
  return (
    <Filter
      onClear={() => {
        column.setFilterValue(() => null);
        close();
      }}
      close={() => {
        close();
      }}
      onSelect={(e) => {
        onSelect({
          value: e,
          field: activeNewFilter.column.accessorKey,
          type: activeNewFilter.type,
        });
      }}
      value={value}
      open={open}
      type={activeNewFilter?.type}
      title={activeNewFilter?.title}
      filterOptions={activeNewFilter?.options || []}
      optionsLoader={activeNewFilter?.optionsLoader}
    />
  );
}

function ColumnsToggler({
  table,
  open,
  close,
}: {
  table: any;
  open: boolean;
  close: any;
}) {
  const ref = useRef(null);
  const handleClickOutside = () => {
    close();
  };
  useOnClickOutside(ref, handleClickOutside);
  return (
    <Transition
      show={open}
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div
        ref={ref}
        className="absolute right-0 rounded-md top-9 z-40 shadow-sm  border border-slate-200 gap-2 w-[200px] bg-white"
      >
        <div className="py-[10px] px-3 border-b border-slate-200">
          <h4 className="text-[11.8px] text-slate-700 font-semibold capitalize truncate">
            Toggle columns
          </h4>
        </div>
        <div className="max-h-[180px]">
          <SimpleBar style={{ maxHeight: "180px" }} className="">
            <ul className="flex pt-1 pb-2 space-y-2 flex-col">
              {table.getAllLeafColumns().map((e, i) => {
                return (
                  <li key={i} className="px-3">
                    <Checkbox
                      {...{
                        isSelected: e.getIsVisible(),
                        onChange: () => {
                          e.toggleVisibility();
                        },
                        disabled:
                          table.getHeaderGroups()[0]?.headers?.length <= 3 &&
                          e.getIsVisible(),
                      }}
                    >
                      <span className="capitalize">{e.columnDef.header()}</span>
                    </Checkbox>
                  </li>
                );
              })}
            </ul>
          </SimpleBar>
        </div>
      </div>
    </Transition>
  );
}

function ColumnsToFilter({
  open,
  close,
  columns,
  onChoose,
}: {
  table: any;
  open: boolean;
  close: any;
  columns: any;
  onChoose?: any;
}) {
  const ref = useRef(null);
  const handleClickOutside = () => {
    close();
  };
  useOnClickOutside(ref, handleClickOutside);

  return (
    <Transition
      show={open}
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div
        ref={ref}
        className="absolute left-0 rounded-md top-9 z-40 shadow-sm  border border-slate-200 gap-2 w-[200px] bg-white"
      >
        <div className="py-[10px] px-3 border-b border-slate-200">
          <h4 className="text-[11.8px] text-slate-700 font-semibold capitalize truncate">
            Filter columns
          </h4>
        </div>
        <div className="max-h-[180px]">
          <SimpleBar style={{ maxHeight: "180px" }} className="">
            <div className="flex py-1 px-[6px] space-y-[2px] flex-col">
              {columns.map((e, i) => {
                return (
                  <a
                    key={i}
                    onClick={() => {
                      onChoose(e);
                    }}
                    className="hover:bg-slate-50 flex group justify-between border border-transparent hover:border-slate-100 px-2 py-[8px] cursor-pointer rounded-md w-full"
                  >
                    <span className="capitalize text-[12px] font-medium text-slate-500">
                      {e.header()}
                    </span>
                    <Plus
                      className="text-slate-500 opacity-0 group-hover:opacity-100"
                      size={15}
                    />
                  </a>
                );
              })}
            </div>
          </SimpleBar>
        </div>
      </div>
    </Transition>
  );
}

function ExportChooser({
  open,
  close,
  handleExport,
}: {
  open: boolean;
  close: any;
  handleExport: any;
}) {
  const ref = useRef(null);
  const handleClickOutside = () => {
    close();
  };
  useOnClickOutside(ref, handleClickOutside);

  const formats = [
    {
      title: "Excel",
    },
    {
      title: "CSV",
    },
    {
      title: "PDF",
    },
  ];
  return (
    <Transition
      show={open}
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div
        ref={ref}
        className="absolute left-0 rounded-md top-9 z-40 shadow-sm  border border-slate-200 gap-2 w-[150px] bg-white"
      >
        <div className="h-[110px]- px-1 py-1">
          {formats.map((e, i) => {
            return (
              <a
                key={i}
                onClick={() => {
                  handleExport(e.title);
                  close();
                }}
                className="hover:bg-slate-50 flex group justify-between border border-transparent hover:border-slate-100 px-3 py-[8px] cursor-pointer rounded-md w-full"
              >
                <span className="capitalize text-[12px] font-medium text-slate-500">
                  {e.title}
                </span>
              </a>
            );
          })}
        </div>
      </div>
    </Transition>
  );
}

const SelectFilter = React.forwardRef(
  (
    {
      filterOptions,
      title,
      close,
      optionsLoader,
      value,
      onSelect,
      open,
      clear,
    }: any,
    ref: any
  ) => {
    const { data: optionsLoaded, status } = useQuery(
      [title, filterOptions],
      () => {
        return optionsLoader();
      },
      {
        keepPreviousData: true,
        retry: false,
        staleTime: Infinity,
        enabled: Boolean(optionsLoader),
      }
    );

    const optionsToShow =
      (filterOptions?.length ? filterOptions : undefined) ||
      optionsLoaded ||
      [];
    return (
      <DropdownTransition open={open}>
        <div
          ref={ref}
          className="absolute left-0 rounded-md top-9 z-50 shadow-sm  border border-slate-200 gap-2 w-[200px] bg-white"
        >
          <div className="py-[10px] px-3 border-b border-slate-200">
            <h4 className="text-[12px] text-slate-700 font-semibold capitalize truncate">
              {title}
            </h4>
          </div>
          <div className="max-h-[180px]">
            {status === "loading" && (
              <div className="w-full h-[120px] flex items-center  justify-center">
                <Loader />
              </div>
            )}
            <SimpleBar style={{ maxHeight: "180px" }} className="">
              <div className="flex py-1 px-[6px] space-y-[2px] flex-col">
                {optionsToShow.map((e, i) => {
                  return (
                    <a
                      key={i}
                      onClick={() => {
                        onSelect(e);
                        close();
                      }}
                      className={cn(
                        "flex group justify-between border border-transparent px-2 py-[8px] cursor-pointer rounded-md w-full",
                        {
                          "border border-slate-200 bg-slate-100":
                            value === e.value,
                          "hover:bg-slate-50 hover:border-slate-100 ":
                            value !== e.value,
                        }
                      )}
                    >
                      <span className="capitalize text-[12px] font-medium text-slate-500">
                        {e.label}
                      </span>
                      {value === e.value && (
                        <Check className="text-primary" size={15} />
                      )}
                    </a>
                  );
                })}
              </div>
            </SimpleBar>
          </div>
          <div className="py-[4px] px-1 border-t border-slate-200">
            <a
              onClick={() => {
                clear();
              }}
              className={cn(
                "text-[12.5px] cursor-pointer text-slate-500 font-medium px-2 flex items-center justify-center text-center w-full hover:bg-slate-100 py-[6px] rounded-md",
                {
                  "pointer-events-none opacity-75": !value,
                }
              )}
            >
              Clear filters
            </a>
          </div>
        </div>
      </DropdownTransition>
    );
  }
);

const DateFilter = React.forwardRef(
  ({ onSelect, value, open, onClose }: any) => {
    return (
      <NewDatePicker
        isOpen={open}
        value={value}
        onClear={() => {
          onSelect(undefined);
          onClose();
        }}
        onClose={onClose}
        onChange={(e) => {
          onSelect(e);
          onClose();
        }}
      />
    );
  }
);
const RangeFilter = React.forwardRef(
  ({ onSelect, open, close, value }: any, ref: any) => {
    const [min, setmin] = useState(undefined);
    const [max, setmax] = useState(undefined);

    useEffect(() => {
      if (open && value) {
        const { gt = null, lt = null } = JSON.parse(value || "{}");
        setmin(gt || undefined);
        setmax(lt || undefined);
      }
    }, [value, open]);

    return (
      <DropdownTransition open={open}>
        <div
          ref={ref}
          className="absolute left-0 rounded-md top-9 z-40 shadow-sm  border border-slate-200 gap-2 w-[250px] bg-white"
        >
          <div className="py-[10px] px-3 border-b border-slate-200">
            <h4 className="text-[12px] text-slate-700 font-semibold capitalize truncate">
              Choose salary
            </h4>
          </div>
          <div className="max-h-[180px] px-3 py-3">
            <div className="flex flex-col bg-white gap-3">
              <div className="">
                <div className="flex items-start flex-col gap-1 justify-start">
                  <label
                    className="text-[12px] font-medium text-slate-500"
                    htmlFor=""
                  >
                    Min
                  </label>
                  <input
                    value={min}
                    onChange={(e: any) => {
                      setmin(e.target.value);
                    }}
                    className="border outline-none rounded-md focus:border-red-500 px-3 py-[7px] w-full font-medium text-[12.5px] border-slate-200"
                    type="number"
                    placeholder="Min"
                  />
                </div>
              </div>{" "}
              <div>
                <div className="flex items-start flex-col gap-1 justify-start">
                  <label
                    className="text-[12px] font-medium text-slate-500"
                    htmlFor=""
                  >
                    Max
                  </label>
                  <input
                    value={max}
                    onChange={(e: any) => {
                      setmax(e.target.value);
                    }}
                    className="border outline-none rounded-md focus:border-red-500 px-3 py-[7px] w-full font-medium text-[12.5px] border-slate-200"
                    type="number"
                    placeholder="Max"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex border-t border-slate-200 px-3 py-1 items-center justify-center">
            <Button
              disabled={Boolean(!min || !max)}
              onClick={() => {
                close();
                onSelect(JSON.stringify({ gt: Number(min), lt: Number(max) }));
              }}
              RightIcon={ArrowRight}
              size={"sm"}
              fullWidth
            >
              <span className="text-[12px] font-medium text-white">
                Apply Salary
              </span>
            </Button>
          </div>
        </div>
      </DropdownTransition>
    );
  }
);

const Filter = ({
  open,
  close,
  type,
  onSelect,
  value,
  onClear,
  ...props
}: any) => {
  const ref = useRef(null);

  const handleClickOutside = () => close();
  useOnClickOutside(ref, handleClickOutside);

  console.log(type, open);

  return (
    <Fragment>
      <SelectFilter
        ref={ref}
        close={() => {
          close();
        }}
        value={value}
        clear={onClear}
        open={type === "select" && open}
        onSelect={onSelect}
        {...props}
      />

      <SelectAsyncFilter
        value={value}
        clear={onClear}
        onSelect={onSelect}
        close={() => {
          close();
        }}
        open={type === "select-async" && open}
        {...props}
      />

      <DateFilter
        onSelect={(e) => {
          // close();
          onSelect(e);
        }}
        open={type === "date" && open}
        ref={ref}
        clear={onClear}
        value={type === "date" && value}
        onClose={() => {
          close();
          console.log("close");
        }}
        {...props}
      />
      <RangeFilter
        ref={ref}
        onSelect={onSelect}
        close={() => {
          close();
        }}
        value={value}
        open={type === "range" && open}
        {...props}
      />
    </Fragment>
  );
};

function SelectAsyncFilter({ close, open, optionsLoader, onSelect, value }) {
  return (
    <>
      <PopoutSelect
        loader={optionsLoader}
        onClose={close}
        isOpen={open}
        onChange={onSelect}
        value={value}
      />
    </>
  );
}

function FilterViewer({ column, filter }) {
  const [showFilter, setshowFilter] = useState(false);

  const { filterType, filterOptions, loadFilterOptions } =
    column.columnDef.meta;

  const type = column?.columnDef?.meta.filterType;

  return (
    <div className="relative">
      <div
        onClick={() => {
          setshowFilter(true);
        }}
        className="flex cursor-pointer items-center gap-2 border border-dashed border-slate-200 rounded-md px-3 hover:bg-slate-50 py-1"
      >
        <span className="text-slate-600 truncate font-medium text-[12px]">
          {
            // @ts-ignore
            column.columnDef.header()
          }
        </span>
        <div className="h-[15px] w-[1px] bg-slate-300"></div>
        <span className="text-slate-600 bg-slate-200 bg-opacity-50 px-2 py-1 rounded-md  capitalize truncate font-medium text-[11.5px]">
          {(type === "select" || type === "select-async") && (
            <Fragment>
              <SelectValue value={filter?.value} />
            </Fragment>
          )}
          {type === "date" && (
            <Fragment>
              <span>
                {new Date(filter.value).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </span>
            </Fragment>
          )}
          {type === "range" && (
            <Fragment>
              <span>
                {JSON.parse(filter.value).gt?.toLocaleString()}{" "}
                <span className="mx-2">-</span>
                {JSON.parse(filter.value).lt?.toLocaleString()}
              </span>
            </Fragment>
          )}
        </span>
      </div>
      <Filter
        onClear={() => {
          column.setFilterValue(() => {
            return undefined;
          });
        }}
        value={column.getFilterValue()}
        open={showFilter}
        close={() => {
          setshowFilter(false);
        }}
        type={filterType}
        title={`Choose ${column.columnDef.header()}`}
        filterOptions={filterOptions}
        optionsLoader={loadFilterOptions}
        onSelect={(e) => {
          console.log(e);
          column.setFilterValue(() => {
            return e;
          });
          // setshowFilter(false);
        }}
      />
    </div>
  );
}

function SelectValue({ value }) {
  return <>{value.label}</>;
}
