import { Trash, UploadCloud } from "react-feather";
import { useDropzone } from "react-dropzone";
import { useCallback, useState } from "react";
import { handleUpload } from "@/utils/handleUpload";
import Loader from "./Loader";
import formatPreviewLink from "@/utils/formatPreviewLink";

const isFileValid = (file) => {
  // Maximum file size in bytes (5MB)
  const maxSize = 20 * 1024 * 1024;

  // Allowed file extensions
  const allowedExtensions = [
    ".pdf",
    ".csv",
    ".xls",
    ".xlsx",
    ".doc",
    ".docx",
    ".txt",
  ];

  // Check file size
  if (file.size > maxSize) {
    return {
      code: "file-too-large",
      message: `The file  is large than 20 MB`,
    };
  }

  // Check file type by extension
  const fileExtension = file?.name?.split(".").pop().toLowerCase();
  if (!allowedExtensions.includes(`.${fileExtension}`)) {
    return {
      code: "file-not-allowed",
      message: `The file you upload is not allowed.`,
    };
  }

  return null;
};
export default function Dropzone({ onChange, file, error, label }: any) {
  const [fileError, setfileError] = useState("");
  const onDrop = useCallback(async (acceptedFiles: any) => {
    if (acceptedFiles[0]) {
      setuploading(true);
      setfileError("");
      await handleUpload({
        file: acceptedFiles[0],
        key: acceptedFiles[0].name,
      })
        .then((url) => {
          onChange(url);
          setuploading(false);
        })
        .catch((e) => {
          setuploading(false);
          console.log(e);
        });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: file,
    validator: isFileValid,
    onDropRejected: (e) => {
      setfileError(e.map((e) => e.errors[0].message)[0]);
    },
  });

  const [uploading, setuploading] = useState(false);
  return (
    <div
      {...getRootProps()}
      className={`w-full border-dashed cursor-pointer py-4 ${
        error || fileError
          ? "hover:bg-red-100 hover:bg-opacity-70 bg-red-50 border-red-200"
          : "hover:bg-gray-100 border-gray-200 "
      } border-2 flex-col  min-h-[100px] my-4 rounded-md flex items-center justify-center gap-3`}
    >
      {uploading && <Loader />}
      <input {...getInputProps()} />
      {!uploading && (
        <>
          {file ? (
            <a
              className="cursor-pointer"
              onClick={() => {
                onChange("");
              }}
            >
              <Trash size={20} className="text-red-800" />
            </a>
          ) : (
            <UploadCloud size={16} className="text-primary" />
          )}
          <span className="text-sm text-center font-medium   text-gray-600">
            {file ? (
              <a
                target="_blank"
                href={formatPreviewLink(file)}
                className="text-[13px] cursor-pointer underline"
              >
                {formatPreviewLink(file)}
              </a>
            ) : (
              <span className="text-[13px]">{label}</span>
            )}
            {(error || fileError) && (
              <span className="text-red-500 capitalize mt-3 text-[13px] font-medium block text-center">
                {error ? "* " + error : ""}
                {fileError ? "* " + fileError : ""}
              </span>
            )}
          </span>
        </>
      )}
    </div>
  );
}
