import * as React from "react";
import type { AriaSelectProps } from "@react-types/select";
import { useSelectState } from "react-stately";
import {
  useSelect,
  HiddenSelect,
  useButton,
  mergeProps,
  useFocusRing,
} from "react-aria";

import { ListBox } from "./ListBox";
import { Popover } from "./Popover";
import { ChevronDown } from "react-feather";
import { cn } from "@/utils/cn";
import Loader from "./Loader";

export { Item } from "react-stately";

export function Select<T extends object>(props: AriaSelectProps<T>) {
  let state = useSelectState(props);
  const { errorMessage, placeholder }: any = props;
  let ref = React.useRef(null);
  let { labelProps, triggerProps, valueProps, menuProps } = useSelect(
    props,
    state,
    ref
  );
  let { buttonProps } = useButton(triggerProps, ref);

  let { focusProps } = useFocusRing();

  return (
    <div className="inline-flex w-full flex-col relative py-[6px]">
      <div
        {...labelProps}
        className="block text-[13px] mb-1 capitalize font-medium text-slate-700"
      >
        {props.label}
      </div>
      <HiddenSelect
        state={state}
        triggerRef={ref}
        label={props.label}
        name={props.name}
      />
      <button
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        className={cn(
          "shadow-sm text-slate-600 flex items-center placeholder:text-slate-500 font-medium outline-none text-[12.5px] border px-3 py-[10px] w-full  rounded-md",
          {
            "focus:ring-red-500 focus:border-red-500 border border-red-500":
              errorMessage,
            "focus:ring-primary focus:border-primary border-gray-300 ":
              !errorMessage,
          }
        )}
      >
        <span
          {...valueProps}
          className={`text-md capitalize ${
            state.selectedItem ? "text-gray-800" : "text-gray-500"
          }`}
        >
          {state.selectedItem ? state.selectedItem.rendered : placeholder}
        </span>
        {props["isLoading"] ? (
          <div className="absolute right-3">
            <Loader />
          </div>
        ) : (
          <ChevronDown
            className={cn(`w-4 h-4 absolute transition-all right-3`, {
              "rotate-180": state.isOpen,
            })}
          />
        )}
      </button>
      {errorMessage && (
        <div className="block text-[11.8px] mt-1 capitalize font-medium text-red-500">
          {errorMessage}
        </div>
      )}
      {state.isOpen && (
        <Popover
          state={state}
          triggerRef={ref}
          placement="bottom start"
          className="w-fit min-w-[230px]"
        >
          <ListBox {...menuProps} state={state} />
        </Popover>
      )}
    </div>
  );
}
