import { Formik } from "formik";
import Pannel from "../ui/Pannel";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { api } from "@/lib/api";
import toast from "react-hot-toast";
import { useRef } from "react";
import useConfirm from "@/hooks/useConfirm";
import Button from "../ui/Button";
import SubmitButton from "../forms/SubmitButton";
import DiscardModal from "../modals/DiscardModal";
import AppFormTextarea from "../forms/AppFormTextarea";
import AppFormDakepicker from "../forms/AppFormDatepicker";
import AppFormSelect from "../forms/AppFormSelect";
import Avatar from "../ui/Avatar";
import AppFormField from "../forms/AppFormField";
import { cn } from "@/utils/cn";
import { useEmployee } from "@/context/employee.context";
import { useParams } from "react-router";
export default function NewTaskPannel({
  leave,
  children,
  onClose,
  onComplete,
  type,
  members,
  tasks_status,
  ...other
}: any) {
  const namespace = type === "project" ? "projects" : "tenders";

  console.log(namespace);

  const params = useParams();

  const mutation = useMutation({
    mutationFn: (record) =>
      api.post(`/${namespace}/${params.id}/tasks`, record),
  });

  const { employee } = useEmployee();

  const handleSubmit = async (values: any, { resetForm }) => {
    const data = {
      ...values,
      assignedToId: values.assignedTo,
      createdById: employee.id,
    };
    delete data["assignedTo"];
    await mutation.mutateAsync(data, {
      onError: (error: any) => {
        toast.error(error.response.data.message || error.message);
      },
      onSuccess: () => {
        toast.success(`Task saved sucess`);
        onClose();
        onComplete();
        resetForm();
      },
    });
  };

  const schema = Yup.object().shape({
    start_date: Yup.string().required().label("start date"),
    due_date: Yup.string().required().label("end date"),
    assignedTo: Yup.string().required().label("Assigned to"),
    status: Yup.string().required(),
    priority: Yup.string().required(),
    title: Yup.string().required(),
    tags: Yup.string().required(),
    description: Yup.string().required(),
  });

  const initialValues = {
    start_date: "",
    due_date: "",
    assignedTo: "",
    status: "",
    priority: "",
    title: "",
    tags: "",
    description: "",
  };

  async function employeesLoader() {
    const { data } = await api.get("/employees?page_size=10", {
      params: {
        id: members,
      },
    });
    return {
      items: data.map((e) => ({
        label: e.first_name + " " + e.first_name,
        value: e.id,
        ...e,
      })),
    };
  }

  const formRef = useRef<any>();

  const confirmDiscard = useConfirm({
    onAccept: () => {
      onClose();
      formRef.current?.resetForm();
    },
  });

  const handleClose = ({ touched }) => {
    if (Object.keys(touched)?.length) {
      confirmDiscard.open();
    } else {
      onClose();
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      {(e) => (
        <>
          <Pannel
            onClose={() => handleClose(e)}
            {...other}
            title={"Create a new task"}
          >
            <div className="h-0 flex-1 overflow-y-auto">
              <div className="flex flex-1 flex-col justify-between">
                <div className="divide-y pb-4 divide-gray-200 px-4 sm:px-6">
                  <div className="mt-3 px-[2px]">
                    <div>
                      <div>
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          Task Information
                        </h4>
                      </div>

                      <div className="mt-2">
                        <AppFormField
                          name="title"
                          label="Task title"
                          placeholder="Task title"
                        />
                      </div>

                      <div className="grid grid-cols-2 gap-2">
                        <AppFormSelect
                          name="status"
                          label="Task status"
                          placeholder="Task status"
                          options={tasks_status.map((e) => ({
                            label: e.title,
                            value: e.id,
                            ...e,
                          }))}
                          OptionItem={({ item }) => {
                            return (
                              <div className="flex items-center gap-2">
                                <div
                                  className={cn(
                                    "h-3 w-3 rounded-full",
                                    item?.bg_color
                                  )}
                                ></div>
                                <span>{item?.label}</span>
                              </div>
                            );
                          }}
                        />
                        <AppFormSelect
                          name="priority"
                          label="Task priority"
                          placeholder="Task priority"
                          options={[
                            {
                              label: "low",
                              value: "low",
                            },
                            {
                              label: "medium",
                              value: "medium",
                            },
                            {
                              label: "high",
                              value: "high",
                            },
                          ]}
                          OptionItem={({ item }) => {
                            const getColor = () => {
                              switch (item?.value) {
                                case "low":
                                  return "bg-slate-400";
                                case "medium":
                                  return "bg-orange-500";
                                case "high":
                                  return "bg-green-500";
                              }
                            };
                            return (
                              <div className="flex items-center gap-2">
                                <div
                                  className={cn(
                                    "h-3 w-3 rounded-full",
                                    getColor()
                                  )}
                                ></div>
                                <span>{item?.label}</span>
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormSelect
                          name="assignedTo"
                          label="Task assinged"
                          placeholder="assigned to"
                          loader={employeesLoader}
                          OptionItem={({ item }) => {
                            return (
                              <div className="flex items-center gap-2">
                                <Avatar
                                  rounded="full"
                                  size="xs"
                                  src={item.photo}
                                  name={
                                    item?.first_name + " " + item?.last_name
                                  }
                                />
                                <span>
                                  {item?.first_name} {item?.last_name}
                                </span>
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="grid mt-1 grid-cols-2 gap-3">
                        <AppFormDakepicker
                          name="start_date"
                          label="Start Date"
                          placeholder="Choose start date"
                        />{" "}
                        <AppFormDakepicker
                          name="due_date"
                          label="due date"
                          placeholder="Choose due date"
                        />
                      </div>

                      <div className="mt-2">
                        <AppFormField
                          name="tags"
                          label="Add tags"
                          placeholder="Tags are separated by commas"
                        />
                      </div>

                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormTextarea
                          name="description"
                          label="description"
                          placeholder="Enter description"
                          rows={3}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-2 flex-shrink-0 justify-end px-4 py-2">
              <Button
                onClick={() => {
                  e.resetForm();
                }}
                variant="default"
                size="sm"
              >
                Reset
              </Button>
              <SubmitButton size="sm">Save task</SubmitButton>
            </div>
          </Pannel>

          <DiscardModal state={confirmDiscard} />
        </>
      )}
    </Formik>
  );
}
