const employment_statuses = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
  {
    label: "Suspended",
    value: "suspended",
  },
  {
    label: "Terminated",
    value: "terminated",
  },
  {
    label: "Retired",
    value: "retired",
  },
  {
    label: "Resigned",
    value: "resigned",
  },
  {
    label: "on leave",
    value: "on-leave",
  },
];

const requizition_types = [
  {
    label: "Mission",
    value: "mission",
  },
  {
    label: "transport",
    value: "transport",
  },
  {
    label: "commision",
    value: "commision",
  },
  {
    label: "loan",
    value: "loan",
  },
];

const marital_statuses = [
  {
    label: "Single",
    value: "single",
  },
  {
    label: "Married",
    value: "married",
  },
  {
    label: "Divorced",
    value: "divorced",
  },
  {
    label: "Widowed",
    value: "widowed",
  },
];

const employment_types = [
  {
    label: "full time",
    value: "full-time",
  },
  {
    label: "Permanent",
    value: "permanent",
  },
  {
    label: "Part time",
    value: "part-time",
  },
  {
    label: "Temporary",
    value: "temporary",
  },
  {
    label: "Contract",
    value: "contract",
  },
  {
    label: "Intern",
    value: "intern",
  },
];

const leave_types = [
  { value: "vacation_leave", label: "Vacation Leave" },
  { value: "sick_leave", label: "Sick Leave" },
  { value: "personal_leave", label: "Personal Leave" },
  { value: "maternity_paternity_leave", label: "Maternity/Paternity Leave" },
  { value: "family_medical_leave", label: "Family Medical Leave (FMLA)" },
  { value: "bereavement_leave", label: "Bereavement Leave" },
  { value: "jury_duty_leave", label: "Jury Duty Leave" },
  { value: "military_leave", label: "Military Leave" },
  { value: "educational_leave", label: "Educational Leave" },
  { value: "compensatory_time_off", label: "Compensatory Time Off" },
  { value: "public_service_leave", label: "Public Service Leave" },
  { value: "unpaid_leave", label: "Unpaid Leave" },
  { value: "special_projects_leave", label: "Special Projects Leave" },
  { value: "volunteer_service_leave", label: "Volunteer Service Leave" },
  { value: "remote_work_leave", label: "Remote Work Leave" },
  { value: "religious_leave", label: "Religious Leave" },
  { value: "civic_duty_leave", label: "Civic Duty Leave" },
  { value: "administrative_leave", label: "Administrative Leave" },
  { value: "cultural_leave", label: "Cultural Leave" },
  { value: "other_specify", label: "Other (Specify)" },
];

const tasks_status = [
  {
    id: "pre-engagement",
    title: "Pre engagement",
    bg_color: "bg-gray-500",
    text_color: "text-gray-500",
    border_color: "border-gray-300",
  },
  {
    id: "planning",
    title: "planning",
    bg_color: "bg-amber-500",
    text_color: "text-amber-500",
    border_color: "border-amber-300",
  },
  {
    id: "execution",
    title: "execution",
    bg_color: "bg-blue-500",
    text_color: "text-blue-500",
    border_color: "border-blue-300",
  },
  {
    id: "in-review",
    title: "in review",
    bg_color: "bg-orange-500",
    text_color: "text-orange-500",
    border_color: "border-orange-300",
  },
  {
    id: "reporting",
    title: "reporting",
    bg_color: "bg-cyan-500",
    text_color: "text-cyan-500",
    border_color: "border-cyan-300",
  },
  {
    id: "file-closury",
    title: "File Closure and Archive",
    bg_color: "bg-indigo-500",
    text_color: "text-indigo-500",
    border_color: "border-indigo-300",
  },
];

const tenders_status = [
  {
    id: "todo",
    title: "todo",
    bg_color: "bg-gray-500",
    text_color: "text-gray-500",
    border_color: "border-gray-300",
  },
  {
    id: "in-progress",
    title: "in progress",
    bg_color: "bg-amber-500",
    text_color: "text-amber-500",
    border_color: "border-amber-300",
  },
  {
    id: "done",
    title: "done",
    bg_color: "bg-green-500",
    text_color: "text-green-500",
    border_color: "border-green-300",
  },
];
export {
  employment_statuses,
  tasks_status,
  marital_statuses,
  employment_types,
  leave_types,
  tenders_status,
  requizition_types,
};
