import Button from "../ui/Button";
import Modal from "../ui/Modal";

function DiscardModal({ state }) {
  return (
    <Modal
      title="Discard changes."
      size="sm"
      showDividers={false}
      close={() => {
        state.cancel();
      }}
      open={state.isOpen}
    >
      <div className="px-1 mb-1-">
        <span className="text-[13.5px] font-medium leading-7 text-slate-500">
          Are you shure you want to dicard changes that you made to thee form
        </span>{" "}
      </div>
      <div className="w-full flex items-center justify-end gap-3">
        <Button
          onClick={() => {
            state.reject();
          }}
          variant="default"
          size="sm"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            state.accept();
          }}
          size="sm"
        >
          Yes, Discard
        </Button>
      </div>
    </Modal>
  );
}

export default DiscardModal;
