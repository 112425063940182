import { Formik, useFormikContext } from "formik";
import Pannel from "../ui/Pannel";
import * as Yup from "yup";
import removeEmptyAndUndefined from "@/utils/removeEmptyAndUndefined";
import { useMutation } from "react-query";
import { api } from "@/lib/api";
import toast from "react-hot-toast";
import { useEffect, useMemo, useRef, useState } from "react";
import useConfirm from "@/hooks/useConfirm";
import Button from "../ui/Button";
import SubmitButton from "../forms/SubmitButton";
import AppFormSelect from "../forms/AppFormSelect";
import DiscardModal from "../modals/DiscardModal";
import Avatar from "../ui/Avatar";
import { useEmployee } from "@/context/employee.context";
import Modal from "../ui/Modal";
import { Edit, PlusCircle, Trash } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import { cn } from "@/utils/cn";

const initial_activities = [
  {
    title: "Professionalism",
    score: 0,
    id: uuidv4(),
    type: "behaviour_indicators",
  },
  {
    title: "Communication Skills",
    score: 0,
    id: uuidv4(),
    type: "behaviour_indicators",
  },
  {
    title: "Ethical Conduct",
    score: 0,
    id: uuidv4(),
    type: "behaviour_indicators",
  },
  {
    title: "Teamwork",
    score: 0,
    id: uuidv4(),
    type: "behaviour_indicators",
  },
  {
    title: "Client Service",
    score: 0,
    id: uuidv4(),
    type: "behaviour_indicators",
  },
  {
    title: "Time Managemen",
    score: 0,
    id: uuidv4(),
    type: "behaviour_indicators",
  },
  {
    title: "Attention to Detail",
    score: 0,
    id: uuidv4(),
    type: "behaviour_indicators",
  },
  {
    title: "Adaptability",
    score: 0,
    id: uuidv4(),
    type: "behaviour_indicators",
  },
  {
    title: "Leadership",
    score: 0,
    id: uuidv4(),
    type: "behaviour_indicators",
  },
  {
    title: "Problem-Solving",
    score: 0,
    id: uuidv4(),
    type: "behaviour_indicators",
  },
  {
    title: "Dependability",
    score: 0,
    id: uuidv4(),
    type: "behaviour_indicators",
  },
  {
    title: "Integrity",
    score: 0,
    id: uuidv4(),
    type: "behaviour_indicators",
  },
  {
    title: "Client Feedback",
    score: 0,
    id: uuidv4(),
    type: "behaviour_indicators",
  },
  {
    title: "Self-Improvement",
    score: 0,
    id: uuidv4(),
    type: "behaviour_indicators",
  },
  {
    title: "Adherence to Policies and Procedures",
    score: 0,
    id: uuidv4(),
    type: "behaviour_indicators",
  },
  {
    title: "Conflict Resolution",
    score: 0,
    id: uuidv4(),
    type: "behaviour_indicators",
  },
  {
    title: "",
    score: 0,
    id: uuidv4(),
    type: "core_activities",
  },
];

export default function PerfomanceFormPannel({
  perfomance,
  children,
  onClose,
  onComplete,
  employee: defaultEmployee,
  ...other
}: any) {
  const mutation = useMutation({
    mutationFn: (record) => {
      return perfomance
        ? api.put(`/perfomance/${perfomance.id}`, record)
        : api.post("/perfomance", record);
    },
  });
  const handleSubmit = async (values: any, { resetForm }) => {
    const total_score = values["evaluations"].reduce(
      (a, b) => a + Number(b.score || 0),
      0
    );
    const data: any = removeEmptyAndUndefined({
      employeeId: values.employee,
      period: values.period,
      score: total_score,
      evaluations: values.evaluations,
    });

    await mutation.mutateAsync(data, {
      onError: (error: any) => {
        toast.error(error.response.data.message || error.message);
      },
      onSuccess: () => {
        toast.success(`perfomance saved sucess`);
        onClose();
        onComplete();
        resetForm();
      },
    });
  };

  const schema = Yup.object().shape({
    employee: Yup.string().required(),
    period: Yup.string().required(),
    evaluations: Yup.array(),
  });

  const initialValues = useMemo(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month since it is zero-based
    const period = `${year}-${month}`;
    return {
      employee: defaultEmployee
        ? defaultEmployee.id
        : perfomance?.employee.id || "",
      period: period,
      evaluations: perfomance?.evaluations || [],
    };
  }, [perfomance]);

  async function employeeLoader() {
    if (defaultEmployee) {
      return {
        items: [
          {
            label:
              defaultEmployee.first_name + " " + defaultEmployee.first_name,
            value: defaultEmployee.id,
            ...defaultEmployee,
          },
        ],
      };
    } else {
      const { data } = await api.get("/employees?page_size=10");
      return {
        items: data.map((e) => ({
          label: e.first_name + " " + e.first_name,
          value: e.id,
          ...e,
        })),
      };
    }
  }

  const formRef = useRef<any>();

  const confirmDiscard = useConfirm({
    onAccept: () => {
      onClose();
      formRef.current?.resetForm();
    },
  });

  const handleClose = ({ touched }) => {
    if (Object.keys(touched)?.length) {
      confirmDiscard.open();
    } else {
      onClose();
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      {(e) => (
        <>
          <Pannel
            onClose={() => handleClose(e)}
            {...other}
            title={"Create a new evaluation"}
            size="2xl"
          >
            <div className="h-0 flex-1 overflow-y-auto">
              <div className="flex flex-1 flex-col justify-between">
                <div className="divide-y pb-4 divide-gray-200 px-4 sm:px-6">
                  <div className="mt-3 px-[2px]">
                    <div>
                      <div>
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          Employee Information
                        </h4>
                      </div>
                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormSelect
                          disabled={Boolean(perfomance)}
                          name="employee"
                          label="Choose employee"
                          placeholder="Employee"
                          loader={employeeLoader}
                          OptionItem={({ item }) => {
                            return (
                              <div className="flex items-center gap-2">
                                <Avatar
                                  rounded="full"
                                  size="xs"
                                  src={item.photo}
                                  name={
                                    item?.first_name + " " + item?.last_name
                                  }
                                />
                                <span>
                                  {item?.first_name} {item?.last_name}
                                </span>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-3">
                      <div>
                        <Evaluations />
                      </div>
                    </div>
                    <TotalScores />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-2 flex-shrink-0 justify-end px-4 py-2">
              <Button
                onClick={() => {
                  e.resetForm();
                }}
                variant="default"
                size="sm"
              >
                Reset
              </Button>
              <SubmitButton size="sm">Save perfomance</SubmitButton>
            </div>
          </Pannel>

          <DiscardModal state={confirmDiscard} />
        </>
      )}
    </Formik>
  );
}

function TotalScores() {
  const { values }: any = useFormikContext();

  return (
    <div>
      {values["evaluations"].length ? (
        <div className="flex max-w-[200px] my-3 ml-auto items-center justify-between ">
          <span className="font-medium text-sm text-slate-600">
            Total score
          </span>
          <span className="font-medium text-sm text-slate-600">
            {values["evaluations"].reduce(
              (a, b) => a + Number(b.score || 0),
              0
            )}
            %
          </span>
        </div>
      ) : null}
    </div>
  );
}

function Evaluations() {
  const [showEvaluationModal, setshowEvaluationModal] = useState(false);
  const { setFieldValue, values }: any = useFormikContext();

  const [evaluationToUpdate, setevaluationToUpdate] = useState(null);

  const { employee } = useEmployee();
  return (
    <>
      <div>
        <div className="flex items-center  mb-2 justify-between">
          <h4 className="text-[12px] uppercase font-medium text-slate-500">
            Perfomance Evaluations
          </h4>
          {!values["evaluations"].find(
            (e) => e?.created_by?.id === employee?.id
          ) ? (
            <a
              onClick={() => {
                setshowEvaluationModal(true);
              }}
              className="flex cursor-pointer text-[12px] font-medium items-center text-primary gap-2"
            >
              <PlusCircle size={14} />
              <span>Add evaluation</span>
            </a>
          ) : null}
        </div>
        <div className="pt-2">
          {values["evaluations"]?.length !== 0 && (
            <table className="border table-auto w-full">
              <thead>
                <tr>
                  <td className="border text-[13px] px-3 font-semibold border-slate-200">
                    Criteria
                  </td>
                  {values["evaluations"].map((e) => {
                    return (
                      <td className="border flex items-center gap-2 text-[12px] px-2 font-semibold capitalize p-2 border-slate-200">
                        <span>
                          {e?.created_by?.first_name +
                            " " +
                            e?.created_by?.last_name}
                        </span>
                        {e?.created_by?.id === employee?.id && (
                          <a
                            onClick={() => {
                              setevaluationToUpdate(e);
                            }}
                            className="flex text-[12px] cursor-pointer items-center text-blue-500 gap-2"
                          >
                            <Edit size={14} />
                          </a>
                        )}
                      </td>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {values["evaluations"][0]?.activities?.map((e, i) => {
                  return (
                    <tr>
                      <td className="text-[12px] px-[12px] border border-slate-200 text-slate-600 font-medium">
                        {e.title}
                      </td>
                      {values["evaluations"].map((ev) => {
                        return (
                          <td className="border p-[8px] text-[12px] border-slate-200">
                            {ev["activities"][i].score}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                <tr>
                  <td className="border text-[12px] px-2 font-semibold capitalize p-2 border-slate-200">
                    Total
                  </td>
                  {values["evaluations"].map((ev) => {
                    return (
                      <td className="border text-[12px] px-2 font-semibold capitalize p-2 border-slate-200">
                        {ev.score}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          )}
          {values["evaluations"]?.length === 0 && (
            <div className="border mt-0 border-slate-200 pb-2 px-3 py-0 rounded-md">
              <div className="py-5">
                <div className="flex items-center justify-center gap-2 flex-col">
                  <svg
                    height="50px"
                    width="50px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                    fill="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      {"{"}" "{"}"}
                      <path
                        style={{ fill: "#509FE8" }}
                        d="M427.023,59.858H84.977c-13.851,0-25.119,11.268-25.119,25.119v401.904 c0,13.851,11.268,25.119,25.119,25.119h342.046c13.851,0,25.119-11.268,25.119-25.119V84.977 C452.142,71.126,440.874,59.858,427.023,59.858z"
                      />
                      {"{"}" "{"}"}
                      <g>
                        {"{"}" "{"}"}
                        <path
                          style={{ fill: "#4D8CCF" }}
                          d="M93.528,470.313c0,4.427,3.589,8.017,8.017,8.017h290.739c4.427,0,8.017-3.589,8.017-8.017V77.934 c0-4.965-4.025-8.99-8.99-8.99H102.519c-4.965,0-8.99,4.025-8.99,8.99V470.313z"
                        />
                        {"{"}" "{"}"}
                        <path
                          style={{ fill: "#4D8CCF" }}
                          d="M436.109,503.983H94.063c-13.851,0-25.119-11.268-25.119-25.119V76.96 c0-5.296,1.651-10.211,4.46-14.267c-8.04,4.193-13.546,12.604-13.546,22.283v401.904c0,13.851,11.268,25.119,25.119,25.119h342.046 c8.554,0,16.12-4.301,20.659-10.852C444.215,502.955,440.28,503.983,436.109,503.983z"
                        />
                        {"{"}" "{"}"}
                      </g>
                      {"{"}" "{"}"}
                      <path
                        style={{ fill: "#F6F6F7" }}
                        d="M102.079,461.228c0,4.427,3.589,8.017,8.017,8.017h290.739c4.427,0,8.017-3.589,8.017-8.017V77.934 c0-4.965-4.025-8.99-8.99-8.99H111.07c-4.965,0-8.99,4.025-8.99,8.99V461.228z"
                      />
                      {"{"}" "{"}"}
                      <path
                        style={{ fill: "#D9DCDF" }}
                        d="M163.541,110.63h188.125c10.314,0,18.706-8.392,18.706-18.706V68.944H144.835v22.981 C144.835,102.239,153.227,110.63,163.541,110.63z"
                      />
                      {"{"}" "{"}"}
                      <path
                        style={{ fill: "#FFC44F" }}
                        d="M332.96,34.205h-35.502C293.703,14.743,276.542,0,256,0c-20.541,0-37.703,14.743-41.458,34.205 H179.04c-13.851,0-25.119,11.268-25.119,25.119v34.205c0,4.427,3.589,8.017,8.017,8.017h188.125c4.428,0,8.017-3.589,8.017-8.017 V59.324C358.079,45.473,346.811,34.205,332.96,34.205z"
                      />
                      {"{"}" "{"}"}
                      <g>
                        {"{"}" "{"}"}
                        <path
                          style={{ fill: "#F7B14D" }}
                          d="M260.008,0.192C258.688,0.067,257.352,0,256,0c-20.541,0-37.703,14.743-41.458,34.205h8.017 C226.067,16.024,241.275,1.966,260.008,0.192z"
                        />
                        {"{"}" "{"}"}
                        <path
                          style={{ fill: "#F7B14D" }}
                          d="M161.937,93.528V59.324c0-13.851,11.268-25.119,25.119-25.119h-8.017 c-13.851,0-25.119,11.268-25.119,25.119v34.205c0,4.427,3.589,8.017,8.017,8.017h8.017 C165.527,101.545,161.937,97.956,161.937,93.528z"
                        />
                        {"{"}" "{"}"}
                      </g>
                      {"{"}" "{"}"}
                      <path
                        style={{ fill: "#BF722A" }}
                        d="M256,50.238c-4.427,0-8.017-3.589-8.017-8.017V33.67c0-4.427,3.589-8.017,8.017-8.017 c4.428,0,8.017,3.589,8.017,8.017v8.551C264.017,46.649,260.428,50.238,256,50.238z"
                      />
                      {"{"}" "{"}"}
                      <path
                        style={{ fill: "#DF7A6E" }}
                        d="M356.808,316.761l-85.512-171.023c-2.897-5.794-8.818-9.454-15.297-9.454 c-6.479,0-12.4,3.66-15.297,9.454l-85.511,171.023c-4.224,8.447-0.8,18.721,7.649,22.945c8.445,4.223,18.721,0.799,22.945-7.649 l33.754-67.506h72.923l33.754,67.506c2.996,5.993,9.035,9.459,15.31,9.458c2.57,0,5.18-0.581,7.635-1.809 C357.608,335.482,361.033,325.209,356.808,316.761z M236.64,230.347L256,191.628l19.36,38.719H236.64z"
                      />
                      {"{"}" "{"}"}
                      <path
                        style={{ fill: "#FF8C78" }}
                        d="M350.063,145.37h-9.086v-9.086c0-4.427-3.588-8.017-8.017-8.017c-4.428,0-8.017,3.589-8.017,8.017 v9.086h-9.086c-4.428,0-8.017,3.589-8.017,8.017c0,4.427,3.588,8.017,8.017,8.017h9.086v9.086c0,4.427,3.588,8.017,8.017,8.017 c4.428,0,8.017-3.589,8.017-8.017v-9.086h9.086c4.428,0,8.017-3.589,8.017-8.017C358.079,148.959,354.491,145.37,350.063,145.37z"
                      />
                      {"{"}" "{"}"}
                      <g>
                        {"{"}" "{"}"}
                        <path
                          style={{ fill: "#B3B9BF" }}
                          d="M324.409,392.284H187.591c-4.427,0-8.017-3.589-8.017-8.017c0-4.427,3.589-8.017,8.017-8.017 h136.818c4.428,0,8.017,3.589,8.017,8.017C332.426,388.695,328.838,392.284,324.409,392.284z"
                        />
                        {"{"}" "{"}"}
                        <path
                          style={{ fill: "#B3B9BF" }}
                          d="M264.551,426.489h-76.96c-4.427,0-8.017-3.589-8.017-8.017c0-4.427,3.589-8.017,8.017-8.017h76.96 c4.428,0,8.017,3.589,8.017,8.017C272.568,422.899,268.98,426.489,264.551,426.489z"
                        />
                        {"{"}" "{"}"}
                      </g>
                      {"{"}" "{"}"}
                    </g>
                  </svg>

                  <h4 className="text-sm mt-2 font-semibold ">
                    Employee has no evaluations yet.
                  </h4>
                  <p className="text-[12.5px] font-medium text-slate-500 leading-7  text-center">
                    Start her by evaluatiing the employee buy giving scores on{" "}
                    <br />
                    depending on different factors.
                  </p>
                  <Button
                    onClick={() => {
                      setshowEvaluationModal(true);
                    }}
                    variant="outline"
                    size="sm"
                  >
                    Evaluate Employee.
                  </Button>
                </div>
              </div>
            </div>
          )}
          {/* {values["evaluations"].map((e, i) => {
            return (
              <div key={i}>
                <div className="flex items-center justify-between gap-3 capitalize py-2 border border-b-0 px-3 bg-slate-50 text-[12.5px] font-medium text-slate-600">
                  <div className="flex items-center gap-2">
                    <div>
                      <span>created by:</span>{" "}
                      <span>
                        {e?.created_by?.first_name +
                          " " +
                          e?.created_by?.last_name}
                        ,
                      </span>
                    </div>

                    <div>
                      <span>Score:</span> <span>{e.score}%</span>
                    </div>
                  </div>
                  {e?.created_by?.id === employee?.id && (
                    <a
                      onClick={() => {
                        setevaluationToUpdate(e);
                      }}
                      className="flex text-[12px] cursor-pointer items-center text-blue-500 gap-2"
                    >
                      <Edit size={14} />
                      <span>Update</span>
                    </a>
                  )}
                </div>
                <div className="border grid grid-cols-1 rounded-t-none  border-slate-200 bg-slate-50 rounded-md">
                  <div className="flex flex-col">
                    <div className="space-y-4 flex-1 px-4 py-3 mt-0">
                      {e?.activities?.map((e) => {
                        return (
                          <div className="w-full flex justify-between">
                            <span className="text-[12px] text-slate-600 font-medium">
                              {e.title}
                            </span>
                            <span className="text-[12px] text-slate-600 font-medium">
                              {e.score}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                    <div className="border-t  border-slate-200 py-2">
                      <div className="w-full px-4 flex justify-between">
                        <span className="text-[13px] uppercase text-slate-600 font-medium">
                          Total
                        </span>
                        <span className="text-[13px] text-slate-600 font-medium">
                          {e.activities_score}/70
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {values["evaluations"]?.length === 0 && (
            <div className="border mt-0 border-slate-200 pb-2 px-3 py-0 rounded-md">
              <div className="py-5">
                <div className="flex items-center justify-center gap-2 flex-col">
                  <svg
                    height="50px"
                    width="50px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                    fill="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      {"{"}" "{"}"}
                      <path
                        style={{ fill: "#509FE8" }}
                        d="M427.023,59.858H84.977c-13.851,0-25.119,11.268-25.119,25.119v401.904 c0,13.851,11.268,25.119,25.119,25.119h342.046c13.851,0,25.119-11.268,25.119-25.119V84.977 C452.142,71.126,440.874,59.858,427.023,59.858z"
                      />
                      {"{"}" "{"}"}
                      <g>
                        {"{"}" "{"}"}
                        <path
                          style={{ fill: "#4D8CCF" }}
                          d="M93.528,470.313c0,4.427,3.589,8.017,8.017,8.017h290.739c4.427,0,8.017-3.589,8.017-8.017V77.934 c0-4.965-4.025-8.99-8.99-8.99H102.519c-4.965,0-8.99,4.025-8.99,8.99V470.313z"
                        />
                        {"{"}" "{"}"}
                        <path
                          style={{ fill: "#4D8CCF" }}
                          d="M436.109,503.983H94.063c-13.851,0-25.119-11.268-25.119-25.119V76.96 c0-5.296,1.651-10.211,4.46-14.267c-8.04,4.193-13.546,12.604-13.546,22.283v401.904c0,13.851,11.268,25.119,25.119,25.119h342.046 c8.554,0,16.12-4.301,20.659-10.852C444.215,502.955,440.28,503.983,436.109,503.983z"
                        />
                        {"{"}" "{"}"}
                      </g>
                      {"{"}" "{"}"}
                      <path
                        style={{ fill: "#F6F6F7" }}
                        d="M102.079,461.228c0,4.427,3.589,8.017,8.017,8.017h290.739c4.427,0,8.017-3.589,8.017-8.017V77.934 c0-4.965-4.025-8.99-8.99-8.99H111.07c-4.965,0-8.99,4.025-8.99,8.99V461.228z"
                      />
                      {"{"}" "{"}"}
                      <path
                        style={{ fill: "#D9DCDF" }}
                        d="M163.541,110.63h188.125c10.314,0,18.706-8.392,18.706-18.706V68.944H144.835v22.981 C144.835,102.239,153.227,110.63,163.541,110.63z"
                      />
                      {"{"}" "{"}"}
                      <path
                        style={{ fill: "#FFC44F" }}
                        d="M332.96,34.205h-35.502C293.703,14.743,276.542,0,256,0c-20.541,0-37.703,14.743-41.458,34.205 H179.04c-13.851,0-25.119,11.268-25.119,25.119v34.205c0,4.427,3.589,8.017,8.017,8.017h188.125c4.428,0,8.017-3.589,8.017-8.017 V59.324C358.079,45.473,346.811,34.205,332.96,34.205z"
                      />
                      {"{"}" "{"}"}
                      <g>
                        {"{"}" "{"}"}
                        <path
                          style={{ fill: "#F7B14D" }}
                          d="M260.008,0.192C258.688,0.067,257.352,0,256,0c-20.541,0-37.703,14.743-41.458,34.205h8.017 C226.067,16.024,241.275,1.966,260.008,0.192z"
                        />
                        {"{"}" "{"}"}
                        <path
                          style={{ fill: "#F7B14D" }}
                          d="M161.937,93.528V59.324c0-13.851,11.268-25.119,25.119-25.119h-8.017 c-13.851,0-25.119,11.268-25.119,25.119v34.205c0,4.427,3.589,8.017,8.017,8.017h8.017 C165.527,101.545,161.937,97.956,161.937,93.528z"
                        />
                        {"{"}" "{"}"}
                      </g>
                      {"{"}" "{"}"}
                      <path
                        style={{ fill: "#BF722A" }}
                        d="M256,50.238c-4.427,0-8.017-3.589-8.017-8.017V33.67c0-4.427,3.589-8.017,8.017-8.017 c4.428,0,8.017,3.589,8.017,8.017v8.551C264.017,46.649,260.428,50.238,256,50.238z"
                      />
                      {"{"}" "{"}"}
                      <path
                        style={{ fill: "#DF7A6E" }}
                        d="M356.808,316.761l-85.512-171.023c-2.897-5.794-8.818-9.454-15.297-9.454 c-6.479,0-12.4,3.66-15.297,9.454l-85.511,171.023c-4.224,8.447-0.8,18.721,7.649,22.945c8.445,4.223,18.721,0.799,22.945-7.649 l33.754-67.506h72.923l33.754,67.506c2.996,5.993,9.035,9.459,15.31,9.458c2.57,0,5.18-0.581,7.635-1.809 C357.608,335.482,361.033,325.209,356.808,316.761z M236.64,230.347L256,191.628l19.36,38.719H236.64z"
                      />
                      {"{"}" "{"}"}
                      <path
                        style={{ fill: "#FF8C78" }}
                        d="M350.063,145.37h-9.086v-9.086c0-4.427-3.588-8.017-8.017-8.017c-4.428,0-8.017,3.589-8.017,8.017 v9.086h-9.086c-4.428,0-8.017,3.589-8.017,8.017c0,4.427,3.588,8.017,8.017,8.017h9.086v9.086c0,4.427,3.588,8.017,8.017,8.017 c4.428,0,8.017-3.589,8.017-8.017v-9.086h9.086c4.428,0,8.017-3.589,8.017-8.017C358.079,148.959,354.491,145.37,350.063,145.37z"
                      />
                      {"{"}" "{"}"}
                      <g>
                        {"{"}" "{"}"}
                        <path
                          style={{ fill: "#B3B9BF" }}
                          d="M324.409,392.284H187.591c-4.427,0-8.017-3.589-8.017-8.017c0-4.427,3.589-8.017,8.017-8.017 h136.818c4.428,0,8.017,3.589,8.017,8.017C332.426,388.695,328.838,392.284,324.409,392.284z"
                        />
                        {"{"}" "{"}"}
                        <path
                          style={{ fill: "#B3B9BF" }}
                          d="M264.551,426.489h-76.96c-4.427,0-8.017-3.589-8.017-8.017c0-4.427,3.589-8.017,8.017-8.017h76.96 c4.428,0,8.017,3.589,8.017,8.017C272.568,422.899,268.98,426.489,264.551,426.489z"
                        />
                        {"{"}" "{"}"}
                      </g>
                      {"{"}" "{"}"}
                    </g>
                  </svg>

                  <h4 className="text-sm mt-2 font-semibold ">
                    Employee has no evaluations yet.
                  </h4>
                  <p className="text-[12.5px] font-medium text-slate-500 leading-7  text-center">
                    Start her by evaluatiing the employee buy giving scores on{" "}
                    <br />
                    depending on different factors.
                  </p>
                  <Button
                    onClick={() => {
                      setshowEvaluationModal(true);
                    }}
                    variant="outline"
                    size="sm"
                  >
                    Evaluate Employee.
                  </Button>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>

      <EvaluationModal
        open={showEvaluationModal || Boolean(evaluationToUpdate)}
        onClose={() => {
          setshowEvaluationModal(false);
          setevaluationToUpdate(null);
        }}
        evaluation={evaluationToUpdate}
        onComplete={(e) => {
          const newevs = evaluationToUpdate
            ? values["evaluations"].map((i) =>
                i.id === evaluationToUpdate.id ? e : i
              )
            : [...values["evaluations"], { ...e, id: uuidv4() }];

          setFieldValue("evaluations", newevs);
          setevaluationToUpdate(null);
          setshowEvaluationModal(false);
        }}
      />
    </>
  );
}

function Activities({ type, activities, setActivities, errors }) {
  const { total, average } = useMemo(() => {
    const total = activities.reduce((a, b) => a + parseInt(b.score || 0), 0);
    return {
      total: (
        (total * (type === "core_activities" ? 70 : 30)) /
        (activities.length * 10)
      ).toFixed(1),
      average: Math.round(total / activities.length),
    };
  }, [activities]);

  const handleObjectChange = (id, field, value) => {
    setActivities((prev) => {
      return prev.map((obj) =>
        obj.id === id ? { ...obj, [field]: value } : obj
      );
    });
  };

  return (
    <div>
      <div className="border border-t mt-2 bg-slate-50 border-slate-200 rounded-md px-3- pb-[6px]- pt-[2px]- py-[4px]-">
        <table className="table-auto w-full">
          <tr className="">
            <th className="border text-left px-3 py-2 border-slate-200  text-slate-700 font-semibold text-[12px] ">
              ID
            </th>
            <th className="border text-left px-3  py-2 border-slate-200  text-slate-700 font-semibold text-[12px] ">
              Title
            </th>
            <th className="border text-left px-3  py-2 border-slate-200  text-slate-700 font-semibold text-[12px] ">
              Target
            </th>
            <th className="border text-left px-3  py-2 border-slate-200  text-slate-700 font-semibold text-[12px] ">
              Score/10
            </th>
            {type === "core_activities" && (
              <th className="border text-left px-3  py-2 border-slate-200  text-slate-700 font-semibold text-[12px] "></th>
            )}
          </tr>
          {activities.map((e, i) => {
            return (
              <tr key={i}>
                <td className=" text-slate-500 px-3 border  text-[12.5px] font-medium ">
                  {i + 1}
                </td>
                <td
                  className={cn("border", {
                    "bg-red-50": errors?.find((e) => e.id === `[${i}].title`),
                  })}
                >
                  <input
                    className={cn(
                      "!rounded-none text-[13px] w-full text-slate-600 py-2 px-2 font-medium border-none bg-transparent"
                    )}
                    // errorMessage={
                    //   get(errors, name) && get(touched, name)
                    //     ? get(errors, name)
                    //     : ""
                    // }
                    disabled={type === "behaviour_indicators"}
                    onChange={(p) =>
                      handleObjectChange(e.id, "title", p.target.value)
                    }
                    value={e.title || ""}
                  />
                </td>
                <td
                  className={cn("border", {
                    "bg-red-50": errors.find((e) => e.id === `[${i}].target`),
                  })}
                >
                  <input
                    className="!rounded-none text-[13px] w-full text-slate-600 py-2 px-2 !border-none !bg-transparent"
                    onChange={(p) =>
                      handleObjectChange(e.id, "target", p.target.value)
                    }
                    value={e.target || ""}
                  />
                </td>
                <td
                  className={cn("border", {
                    "bg-red-50": errors.find((e) => e.id === `[${i}].score`),
                  })}
                >
                  <input
                    className="!rounded-none text-[13px] w-full text-slate-600 py-2 px-2 !border-none !bg-transparent"
                    type="number"
                    onChange={(p) => {
                      handleObjectChange(e.id, "score", p.target.value);
                    }}
                    value={e.score || ""}
                  />
                </td>
                {type === "core_activities" && (
                  <td className="border">
                    <a
                      onClick={() => {
                        setActivities((prev) =>
                          prev.filter((i) => i.id !== e.id)
                        );
                      }}
                      className={cn(
                        "text-primary cursor-pointer px-3 flex items-center justify-center",
                        {
                          "pointer-events-none opacity-60":
                            activities.length === 1,
                        }
                      )}
                    >
                      <Trash size={14} />
                    </a>
                  </td>
                )}
              </tr>
            );
          })}
          <tr className="">
            <th className="border text-left px-3 py-2 border-slate-200  text-slate-700 font-semibold text-[12px] "></th>
            <th className="border text-left px-3  py-2 border-slate-200  text-slate-700 font-semibold text-[12px] "></th>
            <th className="border text-left px-3  py-2 border-slate-200  text-slate-700 font-semibold text-[12px] ">
              Average
            </th>
            <th className="border text-left px-3  py-2 border-slate-200  text-slate-700 font-semibold text-[12px] ">
              {average}
            </th>
            {type === "core_activities" && (
              <th className="border text-left px-3  py-2 border-slate-200  text-slate-700 font-semibold text-[12px] "></th>
            )}
          </tr>
          <tr className="">
            <th className="border text-left px-3 py-2 border-slate-200  text-slate-700 font-semibold text-[12px] "></th>
            <th className="border text-left px-3  py-2 border-slate-200  text-slate-700 font-semibold text-[12px] "></th>
            <th className="border text-left px-3  py-2 border-slate-200  text-slate-700 font-semibold text-[12px] ">
              Total/{type === "core_activities" ? 70 : 30}
            </th>
            <th className="border text-left px-3  py-2 border-slate-200  text-slate-700 font-semibold text-[12px] ">
              {total}/{type === "core_activities" ? 70 : 30}
            </th>
            {type === "core_activities" && (
              <th className="border text-left px-3  py-2 border-slate-200  text-slate-700 font-semibold text-[12px] "></th>
            )}
          </tr>
        </table>
      </div>
    </div>
  );
}

function EvaluationModal({ open, evaluation, onClose, onComplete }: any) {
  console.log(evaluation);
  const [activities, setActivities] = useState(
    evaluation?.activities || initial_activities
  );

  useEffect(() => {
    if (evaluation?.activities) {
      setActivities(evaluation?.activities);
    }
  }, [evaluation?.activities]);

  const { employee } = useEmployee();

  const activitiesSchema = Yup.array().of(
    Yup.object({
      target: Yup.string(),
      score: Yup.number()
        .min(0, "Number must be greater than or equal to 0")
        .max(10, "Number must be less than or equal to 10"),
      title: Yup.string(),
    })
  );

  const handleSubmit = async () => {
    try {
      seterrors([]);
      await activitiesSchema.validateSync(activities, {
        abortEarly: false,
      });

      const core_activities = activities.filter(
        (e) => e.type === "core_activities"
      );

      const behaviour_indicators = activities.filter(
        (e) => e.type === "behaviour_indicators"
      );

      const core_activities_marks =
        (core_activities.reduce((a, b) => a + Number(b.score || 0), 0) * 70) /
        (core_activities.length * 10);

      const behaviour_indicators_marks =
        (behaviour_indicators.reduce((a, b) => a + Number(b.score || 0), 0) *
          30) /
        (behaviour_indicators.length * 10);

      const marks = core_activities_marks + behaviour_indicators_marks;

      const ev = {
        activities: activities,
        score: marks,
        activities_score: core_activities_marks,
        behaviour_marks: behaviour_indicators_marks,
        created_by: {
          id: employee?.id,
          first_name: employee?.first_name,
          last_name: employee?.last_name,
        },
      };

      onComplete(ev);
    } catch (error) {
      seterrors(
        error.inner.map((e) => {
          return { id: e.path, ...e };
        })
      );
    }
  };

  const [errors, seterrors] = useState([]);
  console.log(errors);

  return (
    <Modal
      title="Evaluate employee"
      size="xl"
      showDividers={true}
      close={() => {
        onClose();
      }}
      open={open}
      Actions={() => {
        return (
          <div className="w-full gap-3 justify-end flex">
            <Button
              onClick={() => {
                onClose();
                // setActivities(evaluation?.activities || initial_activities);
                seterrors([]);
              }}
              size="sm"
              variant="default"
            >
              Cancel
            </Button>
            <Button onClick={() => handleSubmit()} size="sm">
              Create evaluation
            </Button>
          </div>
        );
      }}
    >
      <div>
        <div>
          <div className="flex items-center justify-between">
            <h4 className="text-[12px] truncate mb-[2px] uppercase font-medium text-slate-500">
              core activities - 70%
            </h4>
            <div className="flex">
              <a
                onClick={() => {
                  setActivities([
                    ...activities,
                    {
                      title: "",
                      score: 0,
                      id: uuidv4(),
                      type: "core_activities",
                    },
                  ]);
                }}
                className="text-primary pt-2 flex items-center gap-2 px-1 cursor-pointer text-[13px] font-medium "
              >
                <PlusCircle size={14} />
                <span>Add another</span>
              </a>
            </div>
          </div>

          <div className="px-1 pb-2 mt-0">
            <Activities
              errors={errors || []}
              setActivities={setActivities}
              activities={activities.filter(
                (e) => e.type === "core_activities"
              )}
              type={"core_activities"}
            />
          </div>
        </div>
        <div className="mt-2">
          <h4 className="text-[12px] mb-[2px] uppercase font-medium text-slate-500">
            behaviour indicators - 30%
          </h4>
          <div className="px-1 pb-2 mt-0">
            <Activities
              errors={errors || []}
              setActivities={setActivities}
              activities={activities.filter(
                (e) => e.type === "behaviour_indicators"
              )}
              type={"behaviour_indicators"}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
