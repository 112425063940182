import Datatable from "@/components/Datatable";
import { Edit3, GitPullRequest, PlusCircle } from "react-feather";
import { createColumnHelper } from "@tanstack/table-core";
import { api } from "@/lib/api";
import Button from "@/components/ui/Button";
import { Fragment } from "react";
import DepartmentFormPannel from "@/components/pannels/DepartmentFormPannel";
import { useQueryClient } from "react-query";
import { useState } from "react";
import { useEditRow } from "@/hooks/useEditRow";
import { useOverlayTriggerState } from "react-stately";
import Avatar from "@/components/ui/Avatar";
import { Link } from "react-router-dom";
import Skeleton from "@/components/ui/Skeleton";
import IssuesModalDetails from "@/components/modals/IssuesModal";
function clearEmptyValues(obj: any) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== "") {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

async function fetchData(
  {
    pageIndex: page,
    pageSize: page_size,
    query,
    sorting,
    ...other
  }: {
    pageIndex: number;
    pageSize: number;
    query: string;
    sorting: any;
  },
  { headers }
) {
  console.log(other);
  const sort = sorting[0];
  let sort_by = sort ? `${sort.id}:${sort.desc ? "desc" : "asc"}` : undefined;

  const params = clearEmptyValues({
    page: page === 0 ? 1 : page,
    page_size,
    query,
    sort_by,
  });
  const { data } = await api.get("/departments", {
    params: params,
    headers,
  });
  return {
    rows: data.results,
    pageCount: data.page,
    total: data.total,
  };
}

interface Department {
  id: number;
  name: string;
  manager: any;
  slug: string;
  email: string;
  issues: string;
  employees: number;
  description: string;
  created_at: string;
  updated_at: string;
}

const columnHelper = createColumnHelper<Department>();

function truncateInMiddle(str, maxLength) {
  if (str.length <= maxLength) {
    return str; // No need to truncate
  }

  const ellipsis = "...";
  const mid = Math.floor(maxLength / 2);
  const start = str.slice(0, mid - Math.ceil(ellipsis.length / 2));
  const end = str.slice(-mid + Math.floor(ellipsis.length / 2));

  return start + ellipsis + end;
}

const columns = [
  columnHelper.accessor("name", {
    cell: (info) => (
      <span className="capitalize truncate">{info.renderValue()}</span>
    ),
    header: () => "Name",
  }),
  columnHelper.accessor("description", {
    cell: (info) => (
      <span className="capitalize text-[12.5px] truncate">
        {truncateInMiddle(info.renderValue(), 40)}
      </span>
    ),
    header: () => "Description",
    meta: {
      hidden: true,
    },
  }),
  columnHelper.accessor("issues", {
    cell: (info) => (
      <a className="capitalize cursor-pointer hover:underline text-blue-600 text-[12.5px] truncate">
        {info.renderValue() || 0}
      </a>
    ),
    header: () => "Open Issues",
  }),
  columnHelper.accessor("created_at", {
    cell: (info) => (
      <span className="truncate">
        {new Date(info.renderValue() as string).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </span>
    ),
    meta: {
      allowSorting: true,
      allowFiltering: true,
      filterType: "date",
    },
    header: () => "Created at",
  }),
  columnHelper.accessor("employees", {
    cell: (info) => (
      <span className="capitalize truncate">{info.renderValue()}</span>
    ),
    header: () => "Employees",
  }),
  columnHelper.accessor("manager", {
    cell: (info) => {
      const names = info.row.original["manager"]
        ? info.row.original["manager"]["first_name"] +
          " " +
          info.row.original["manager"]["last_name"]
        : "";

      return info.row.original["manager"] ? (
        <div className="flex items-center gap-3">
          <Avatar
            size="xs"
            rounded="full"
            src={info.row.original?.manager["photo"]}
            name={names}
          />
          <Link
            to={`?show_employee=${info.row.original?.manager["id"]}`}
            className="hover:underline"
          >
            <span className="capitalize text-slate-700 truncate">{names}</span>
          </Link>
        </div>
      ) : (
        <span className="capitalize">N/A</span>
      );
    },
    header: () => "Manager",
    meta: {
      skeleton: () => {
        return (
          <div className="flex items-center gap-2">
            <div>
              <Skeleton className="w-[23px] h-[23px] rounded-full" />
            </div>
            <Skeleton className="w-[120px] h-3" />
          </div>
        );
      },
    },
  }),
];

const exportFormater = (e) => {
  return e;
};
export default function Departments() {
  const editRow: any = useEditRow();

  const [departmentToShowIssues, setdepartmentToShowIssues] = useState(null);

  const actions = [
    {
      title: "View issues",
      onClick: (e) => {
        setdepartmentToShowIssues(e);
      },
      icon: GitPullRequest,
    },
    {
      title: "Update Department",
      onClick: (e) => {
        editRow.edit(e);
      },
      icon: Edit3,
    },
  ];

  let formState = useOverlayTriggerState({});

  const queryClient = useQueryClient();

  const [key, setkey] = useState();

  return (
    <Fragment>
      <div className="px-2">
        <div className="my-1 flex items-center justify-between">
          <div className="space-y-1">
            <h3 className="text-[15px] font-semibold text-slate-700">
              Manage Departments!!
            </h3>
            <p className="text-[13.5px] leading-7 font-medium text-slate-500">
              Here you can manage all the departments in your company.
            </p>
          </div>
          <div>
            <Button
              onClick={() => {
                formState.open();
              }}
              LeftIcon={PlusCircle}
              size="sm"
            >
              Create New department.
            </Button>
          </div>
        </div>
        <div></div>

        <div className="my-4">
          <Datatable
            columns={columns}
            actions={actions}
            exportFormater={exportFormater}
            name="departments"
            loader={fetchData}
            filterable={false}
            onMainActionClick={() => {}}
            onKeyChange={(e) => setkey(e)}
          />
        </div>
      </div>
      <DepartmentFormPannel
        size="lg"
        onComplete={() => {
          queryClient.invalidateQueries({
            queryKey: key,
          });
          editRow.clear();
        }}
        open={formState.isOpen || editRow.isOpen}
        onClose={() => {
          formState.close();
          editRow.clear();
        }}
        department={editRow.row}
      />
      <IssuesModalDetails
        open={Boolean(departmentToShowIssues)}
        department={departmentToShowIssues}
        onClose={() => {
          setdepartmentToShowIssues(undefined);
        }}
      />
    </Fragment>
  );
}
