import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import qs from "qs";
import { useMemo, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Pannel from "./ui/Pannel";
import Skeleton from "./ui/Skeleton";
import Avatar from "./ui/Avatar";
import { ArrowRight } from "react-feather";
import Button from "./ui/Button";
import TextEditor from "./ui/TextEditor";
import { useOnClickOutside } from "usehooks-ts";
import DropDownSelect from "./ui/DropDownSelect";
import { cn } from "@/utils/cn";
import { RangeDatePicker } from "./ui/RangeDatePicker";
import toast from "react-hot-toast";
import { api } from "@/lib/api";
import { useEffect } from "react";
import cleanObject from "@/utils/cleanObject";
import { useEmployee } from "@/context/employee.context";
import { v4 as uuidv4 } from "uuid";

export default function TaskPannel({
  perfomance,
  children,
  onComplete,
  handleChange,
  type,
  tasks_status,
  members,
  ...other
}: any) {
  const location = useLocation();

  const show_task = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }).show_task,
    [location.search]
  );

  const isOpen = Boolean(show_task);

  const navigate = useNavigate();

  const params = useParams();

  const namespace = type === "project" ? "projects" : "tenders";

  async function fetchData() {
    const { data } = await api.get(
      `/${namespace}/${params.id}/tasks/${show_task}`
    );
    return data;
  }

  const queryClient = useQueryClient();

  const qk = useMemo(
    () => [namespace, params.id, "tasks", show_task],
    [show_task]
  );

  const {
    data: task,
    status,
    refetch,
  } = useQuery(qk, fetchData, {
    keepPreviousData: true,
    retry: false,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: Boolean(show_task),
  });

  useEffect(() => {
    if (show_task) {
      refetch();
    }
  }, [show_task]);

  const [description, setdescription] = useState(
    task ? task["description"] : ""
  );
  useEffect(() => {
    if (task) {
      setdescription(task?.description);
      settitle(task?.title);
      setTags(task?.tags);
    }
  }, [task]);

  const commentRef = useRef(null);
  const descRef = useRef(null);
  const titleRef = useRef(null);
  const tagsRef = useRef(null);
  const [commentEditMode, setcommentEditMode] = useState(false);
  const [descEditMode, setdescEditMode] = useState(false);
  const [titleEditMode, settitleEditMode] = useState(false);
  useOnClickOutside(commentRef, () => setcommentEditMode(!commentEditMode));
  useOnClickOutside(descRef, () => {
    handleUpdateTask({
      description,
    });
    return setdescEditMode(!descEditMode);
  });
  useOnClickOutside(titleRef, () => {
    if (title) {
      handleUpdateTask({ title });
      return settitleEditMode(!titleEditMode);
    }
  });
  useOnClickOutside(tagsRef, () => {
    if (tags) {
      handleUpdateTask({ tags });
      return settagsEditMode(!tagsEditMode);
    }
  });

  const [showAssignToPoppup, setshowAssignToPoppup] = useState(false);
  const [showStatusPoppup, setshowStatusPoppup] = useState(false);
  const [showPriority, setshowPriority] = useState(false);
  const [showDatesPickers, setshowDatesPickers] = useState(false);
  const [tagsEditMode, settagsEditMode] = useState(false);
  const [title, settitle] = useState(task ? task["title"] : "");
  const [tags, setTags] = useState(task ? task["title"] : "");

  let [value] = useState<Selection>();

  const updateMutation = useMutation({
    mutationFn: (record: any) => {
      const data = cleanObject({
        ...record,
        assignedToId: record?.assignedTo?.id,
      });
      delete data["assignedTo"];
      return api.put(`/${namespace}/${params.id}/tasks/${task.id}`, data);
    },
    onMutate: async (newRecord) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: qk });

      // Snapshot the previous value
      const previousRecord: any = queryClient.getQueryData(qk);

      // Optimistically update to the new value
      queryClient.setQueryData(qk, { ...previousRecord, ...newRecord });

      // Return a context with the previous and new Record
      return { previousRecord, newRecord: { ...previousRecord, ...newRecord } };
    },
    onSuccess: () => {
      handleChange();
    },
    onError: (err, _, context) => {
      toast.error(err["message"] || "Something went wrong");
      queryClient.setQueryData(qk, context["previousRecord"]);
    },
  });

  const handleUpdateTask = async (newValues) => {
    updateMutation.mutate(newValues);
  };

  async function employeesLoader() {
    const { data } = await api.get("/employees?page_size=10", {
      params: {
        id: members,
      },
    });
    return {
      items: data.map((e) => ({
        label: e.first_name + " " + e.last_name,
        value: e.id,
        ...e,
      })),
    };
  }

  return (
    <Pannel
      open={isOpen}
      onClose={() => {
        navigate(location.pathname);
      }}
      {...other}
    >
      <div className="h-0 flex-1 overflow-y-auto">
        {status === "error" && !task && (
          <div className="w-full h-[300px] flex items-center justify-center">
            <span className="text-[13px] font-medium text-slate-500">
              Task not found
            </span>
          </div>
        )}
        <div className="p-4">
          {status === "loading" && (
            <>
              <div className="flex items-center gap-4">
                <div>
                  <Skeleton className="w-[60px] h-[60px] rounded-full" />
                </div>
                <div className="flex flex-col  gap-4">
                  <Skeleton className="w-[150px] h-4" />
                  <div className="flex items-center gap-3">
                    <Skeleton className="w-[100px] h-3" />
                    <Skeleton className="w-[80px] h-3" />
                    <Skeleton className="w-[150px] h-3" />
                  </div>
                </div>
              </div>
            </>
          )}

          {status === "success" && task && (
            <>
              <div>
                {!titleEditMode ? (
                  <h4
                    onClick={() => {
                      settitleEditMode(true);
                      settitle(task["title"]);
                    }}
                    className="text-[15px] capitalize text-slate-800 font-semibold"
                  >
                    {task["title"]}
                  </h4>
                ) : (
                  <div ref={titleRef} className="w-full">
                    <input
                      autoFocus
                      className="border w-full focus:border-primary border-transparent py-2 px-2 rounded-md text-sm font-medium outline-none bg-slate-100"
                      value={title}
                      onKeyDown={(e) => {
                        if (title) {
                          if (e.code === "Enter") {
                            handleUpdateTask({ title });
                            settitleEditMode(false);
                          }
                        }
                      }}
                      onChange={(e) => settitle(e.target.value)}
                    />
                  </div>
                )}
                <div className="flex mt-2 items-center gap-2">
                  <span className="text-[13px] text-slate-600 font-medium capitalize">
                    created by
                  </span>
                  <Link
                    to={`?show_employee=${task.createdBy.id}`}
                    className="capitalize text-[12px] font-medium hover:underline text-primary underline truncate"
                  >
                    {task.createdBy.first_name + " " + task.createdBy.last_name}
                  </Link>
                </div>
                <div className="grid mt-4 grid-cols-3 gap-4">
                  <div>
                    <h4 className="uppercase font-medium text-[12.5px] text-slate-500">
                      Assigned to
                    </h4>
                    <div className="relative">
                      <div className="flex mt-2 items-center gap-2">
                        <Avatar
                          size="xs"
                          rounded="full"
                          name={
                            task.assignedTo.first_name +
                            " " +
                            task.assignedTo.last_name
                          }
                          src={task.assignedTo.photo}
                        />
                        <a
                          onClick={() => setshowAssignToPoppup(true)}
                          className="capitalize cursor-pointer text-[13px] font-medium hover:underline text-slate-700 truncate"
                        >
                          {task.assignedTo.first_name +
                            " " +
                            task.assignedTo.last_name}
                        </a>
                      </div>
                      <DropDownSelect
                        onChange={(e) => {
                          handleUpdateTask({
                            assignedTo: {
                              first_name: e.first_name,
                              last_name: e.last_name,
                              id: e.id,
                              photo: e.photo,
                            },
                          });
                        }}
                        value={value}
                        open={showAssignToPoppup}
                        onClose={() => setshowAssignToPoppup(false)}
                        OptionItem={({ item }) => {
                          return (
                            <div className="flex capitalize items-center gap-2">
                              <Avatar
                                rounded="full"
                                size="xs"
                                src={item.photo}
                                name={item?.first_name + " " + item?.last_name}
                              />
                              <span>
                                {item?.first_name} {item?.last_name}
                              </span>
                            </div>
                          );
                        }}
                        loader={employeesLoader}
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <h4 className="uppercase font-medium text-[12.5px] text-slate-500">
                      Created by
                    </h4>
                    <div className="flex relative items-center gap-2">
                      <div className="flex mt-2 items-center gap-2">
                        <Avatar
                          size="xs"
                          rounded="full"
                          src={task.createdBy.photo}
                          name={
                            task.createdBy.first_name +
                            " " +
                            task.createdBy.last_name
                          }
                        />
                        <Link
                          to={`?show_employee=${2}`}
                          className="capitalize text-[13px] font-medium hover:underline text-slate-700 truncate"
                        >
                          {task.createdBy.first_name +
                            " " +
                            task.createdBy.last_name}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4 className="uppercase font-medium text-[12.5px] text-slate-500">
                      Start date/due date
                    </h4>
                    <div className="relative w-fit">
                      <a
                        onClick={() => setshowDatesPickers(true)}
                        className="capitalize  cursor-pointer text-[13px] gap-2 mt-2 flex items-center font-medium hover:underline text-slate-700 truncate"
                      >
                        <span>
                          {new Date(task.start_date).toLocaleDateString("en", {
                            day: "2-digit",
                            month: "short",
                          })}
                        </span>
                        <ArrowRight size={15} />
                        <span>
                          {new Date(task.due_date).toLocaleDateString("en", {
                            day: "2-digit",
                            month: "short",
                          })}
                        </span>
                      </a>
                      <RangeDatePicker
                        className="!right-0"
                        mode="range"
                        isOpen={showDatesPickers}
                        value={{
                          start: new Date(task.start_date),
                          end: new Date(task.due_date),
                        }}
                        onClose={() => {
                          setshowDatesPickers(false);
                        }}
                        onChange={(e) => {
                          handleUpdateTask({
                            start_date: e.start,
                            due_date: e.end,
                          });
                          setshowDatesPickers(false);
                        }}
                      />
                    </div>
                  </div>{" "}
                  <div>
                    <h4 className="uppercase font-medium text-[12.5px] text-slate-500">
                      Priority
                    </h4>
                    <div className="relative">
                      <div className="flex mt-2">
                        <a
                          onClick={() => setshowPriority(true)}
                          className={cn(
                            "px-3 py-[3px] cursor-pointer capitalize flex items-center gap-2 rounded-sm text-xs font-medium ",
                            {
                              "text-gray-500 bg-gray-100":
                                task?.priority === "low",
                              "text-orange-500 bg-orange-100":
                                task?.priority === "medium",
                              "text-red-500 bg-red-100":
                                task?.priority === "high",
                            }
                          )}
                        >
                          <span
                            className={cn("w-2 h-2 rounded-full", {
                              "bg-gray-500": task?.priority === "low",
                              "bg-orange-500": task?.priority === "medium",
                              "bg-red-500": task?.priority === "high",
                            })}
                          ></span>
                          <span>{task.priority}</span>
                        </a>
                      </div>
                      <DropDownSelect
                        value={value}
                        onChange={(e) => {
                          handleUpdateTask({
                            priority: e.value,
                          });
                        }}
                        open={showPriority}
                        onClose={() => setshowPriority(false)}
                        OptionItem={({ item }) => {
                          const getColor = () => {
                            switch (item?.value) {
                              case "low":
                                return "bg-slate-400";
                              case "medium":
                                return "bg-orange-500";
                              case "high":
                                return "bg-red-500";
                            }
                          };
                          return (
                            <div className="flex capitalize items-center gap-2">
                              <div
                                className={cn(
                                  "h-3 w-3 rounded-full",
                                  getColor()
                                )}
                              ></div>
                              <span>{item?.label}</span>
                            </div>
                          );
                        }}
                        items={[
                          {
                            label: "low",
                            value: "low",
                          },
                          {
                            label: "medium",
                            value: "medium",
                          },
                          {
                            label: "high",
                            value: "high",
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div>
                    <h4 className="uppercase font-medium text-[12.5px] text-slate-500">
                      Status
                    </h4>
                    <div className="relative">
                      <div className="flex mt-2">
                        <a
                          onClick={() => setshowStatusPoppup(true)}
                          className={cn(
                            "px-3 py-[3px] cursor-pointer bg-opacity-10 capitalize flex items-center gap-2 rounded-sm text-xs font-medium ",
                            tasks_status.find((e) => e.id === task.status)
                              ?.bg_color,
                            tasks_status.find((e) => e.id === task.status)
                              ?.text_color
                          )}
                        >
                          <span
                            className={cn(
                              "w-2 h-2 rounded-full",
                              tasks_status.find((e) => e.id === task.status)
                                ?.bg_color
                            )}
                          ></span>
                          <span>
                            {
                              tasks_status.find((e) => e.id === task.status)
                                .title
                            }
                          </span>
                        </a>
                      </div>
                      <DropDownSelect
                        value={value}
                        onChange={(e) => {
                          handleUpdateTask({
                            status: e.value,
                          });
                        }}
                        open={showStatusPoppup}
                        onClose={() => setshowStatusPoppup(false)}
                        OptionItem={({ item }) => {
                          return (
                            <div className="flex capitalize items-center gap-2">
                              <div>
                                <div
                                  className={cn(
                                    "h-3 w-3 rounded-full",
                                    item.bg_color
                                  )}
                                ></div>
                              </div>
                              <span className="truncate">{item?.label}</span>
                            </div>
                          );
                        }}
                        items={tasks_status.map((e) => ({
                          label: e.title,
                          value: e.id,
                          ...e,
                        }))}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <h4 className="text-[13px] uppercase font-medium mb-2 text-slate-500 ">
                    Tags
                  </h4>
                  {tagsEditMode ? (
                    <input
                      className="border w-full max-w-xs font-medium border-slate-300 px-3 text-[13px] focus:border-primary py-2 outline-none rounded-md"
                      placeholder="Add tags separated by comma"
                      ref={tagsRef}
                      value={tags}
                      onKeyDown={(e) => {
                        if (tags) {
                          if (e.code === "Enter") {
                            handleUpdateTask({ tags });
                            settagsEditMode(false);
                          }
                        }
                      }}
                      onChange={(e) => {
                        setTags(e.target.value);
                      }}
                    />
                  ) : (
                    <div
                      onClick={() => {
                        settagsEditMode(true);
                      }}
                      className="flex-wrap gap-2 flex"
                    >
                      {tags.split(",").map((e, i) => {
                        return (
                          <a
                            key={i}
                            className="text-[12.5px] capitalize px-2 py-1 font-medium bg-slate-100 rounded-sm text-slate-600"
                          >
                            #{e}
                          </a>
                        );
                      })}
                    </div>
                  )}
                </div>

                <div className="mt-5">
                  <div className="flex items-center justify-between">
                    <h4 className="text-[13px] uppercase text-slate-700 font-medium">
                      Description
                    </h4>
                    <a
                      className="underline cursor-pointer text-primary text-[13px] font-medium"
                      onClick={() => {
                        setdescEditMode(true);
                      }}
                    >
                      Edit Description
                    </a>
                  </div>
                  <div>
                    {descEditMode ? (
                      <div ref={descRef}>
                        <div className="mt-2">
                          <TextEditor
                            value={description}
                            onChange={(e) => {
                              setdescription(e);
                            }}
                          />
                        </div>
                        <div className="mt-2">
                          <Button
                            disabled={false}
                            onClick={() => {
                              if (description) {
                                handleUpdateTask({
                                  description,
                                });
                                setdescEditMode(false);
                              }
                            }}
                            size="sm"
                          >
                            Save Desc
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={cn(
                          "text-[13px] prose-a:text-blue-600 prose prose-p:my-1 cursor-pointer- mt-2 leading-7 text-slate-500 font-medium"
                        )}
                      >
                        <Descc content={task["description"]} />
                      </div>
                    )}
                  </div>
                </div>

                <Comments
                  comments={task["comments"] || []}
                  updateComments={(comments) => {
                    handleUpdateTask({
                      comments,
                    });
                  }}
                />

                {/* <div className="mt-3">
                <div className="flex mb-2 items-center gap-2">
                  <Paperclip size={15} strokeWidth={2} />
                  <h4 className="text-[14px] font-medium">Attachments</h4>
                </div>
              </div> */}
              </div>
            </>
          )}
        </div>
        {status === "loading" && (
          <div>
            <div className="px-2 flex items-center  justify-between">
              <div className="flex items-center w-full justify-evenly gap-3   py-2 border-b border-slate-200">
                {[1, 2, 3, 4].map((_, i) => {
                  return <Skeleton key={i} className="w-[130px] h-7" />;
                })}
              </div>
            </div>
            <div className="p-5">
              <div className="space-y-5">
                <Skeleton className="w-[250px] h-[14px]" />
                <Skeleton className="w-[140px] h-[14px]" />
                <Skeleton className="w-[180px] h-[14px]" />
                <Skeleton className="w-[200px] h-[14px]" />
                <Skeleton className="w-[220px] h-[14px]" />
                <Skeleton className="w-[100px] h-[14px]" />
                <Skeleton className="w-[90px] h-[14px]" />
              </div>
            </div>
          </div>
        )}
      </div>
    </Pannel>
  );
}

function Descc({ content }) {
  const [showFullContent, setShowFullContent] = useState(false);
  const [wordCount, setWordCount] = useState(0);

  const toggleShowFullContent = () => {
    setShowFullContent(!showFullContent);
  };

  useEffect(() => {
    const words = content.split(/\s+/).filter((word) => word !== "");
    setWordCount(words.length);
  }, [content]);

  const displayContent =
    showFullContent || wordCount <= 400
      ? content
      : `${content.slice(0, 400)}...`;

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: displayContent }} />
      {wordCount > 400 && (
        <a className="underline !text-primary" onClick={toggleShowFullContent}>
          {showFullContent ? "Show Less" : "Show More"}
        </a>
      )}
    </div>
  );
}

function Comments({ updateComments, comments }) {
  const [commentEditMode, setcommentEditMode] = useState(false);
  const commentRef = useRef<any>();
  const [comment, setcomment] = useState("");
  const { employee } = useEmployee();
  const [commentToEdit, setcommentToEdit] = useState<any>(undefined);
  const [commentEdit, setcommentEdit] = useState("");

  console.log(commentToEdit);
  return (
    <div className="mt-6">
      <div>
        <h4 className="text-sm font-semibold ">Comments</h4>
      </div>
      <div className="mt-4">
        <div className="flex gap-2 mt-3 items-start">
          <div className="">
            <Avatar
              rounded="full"
              size="sm"
              src={employee.photo}
              name={employee.first_name + " " + employee.last_name}
            />
          </div>
          <div className="w-full">
            {commentEditMode ? (
              <div ref={commentRef}>
                <TextEditor
                  value={comment}
                  onChange={(e) => {
                    setcomment(e);
                  }}
                />
                <div className="mt-2 space-x-2">
                  <Button
                    onClick={() => {
                      setcommentEditMode(false);
                      setcomment("");
                      updateComments([
                        {
                          id: uuidv4(),
                          content: comment,
                          createdBy: {
                            first_name: employee.first_name,
                            last_name: employee.last_name,
                            photo: employee.photo,
                            id: employee.id,
                          },
                        },
                        ...comments,
                      ]);
                    }}
                    disabled={!comment}
                    size="sm"
                  >
                    Save Comment
                  </Button>
                  <Button
                    variant="default"
                    onClick={() => {
                      setcommentEditMode(false);
                      setcomment("");
                    }}
                    size="sm"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <div
                onClick={() => {
                  setcommentEditMode(true);
                }}
                className="bg-slate-100 cursor-pointer px-3 py-2 rounded-md border border-slate-200"
              >
                <span className="text-[12.5px] text-slate-500 leading-7 font-medium">
                  Write a comment..
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-4">
        {comments.map((e) => {
          return (
            <div className="flex gap-4 mt-3 items-start">
              <div className="mt-1">
                <Avatar
                  rounded="full"
                  size="sm"
                  src={e.createdBy.photo}
                  name={e.createdBy.first_name + " " + e.createdBy.last_name}
                />
              </div>
              <div className="flex-1">
                {commentToEdit?.id === e.id ? (
                  <div ref={commentRef}>
                    <TextEditor
                      value={commentEdit}
                      onChange={(e) => {
                        setcommentEdit(e);
                      }}
                    />
                    <div className="mt-2 space-x-2">
                      <Button
                        onClick={() => {
                          updateComments(
                            comments.map((e) =>
                              e?.id === commentToEdit?.id
                                ? {
                                    ...e,
                                    content: commentEdit,
                                  }
                                : e
                            )
                          );
                          setcommentToEdit(undefined);
                          setcommentEdit("");
                        }}
                        disabled={!commentEdit}
                        size="sm"
                      >
                        Save Comment
                      </Button>
                      <Button
                        variant="default"
                        onClick={() => {
                          setcommentToEdit(undefined);
                          setcommentEdit("");
                        }}
                        size="sm"
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div
                    className={cn(
                      "text-[13px] prose-a:text-blue-600 prose prose-p:my-1 cursor-pointer- mt-2 leading-7 text-slate-500 font-medium"
                    )}
                  >
                    <Descc content={e.content} />
                  </div>
                )}
              </div>
              {e.createdBy.id === employee.id && (
                <a
                  onClick={() => {
                    setcommentToEdit(e);
                    setcommentEdit(e.content);
                  }}
                  className="text-[13px] text-primary cursor-pointer underline font-medium"
                >
                  Edit
                </a>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
