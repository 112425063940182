// import { useEditRow } from "@/hooks/useEditRow";
import { api } from "@/lib/api";
import { Fragment, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useOverlayTriggerState } from "react-stately";
import Button from "./ui/Button";
import { Edit, Eye, MoreVertical, PlusCircle } from "react-feather";
import { cn } from "@/utils/cn";
import Menu from "./ui/Menu";
import Skeleton from "./ui/Skeleton";
import RequisitionsFormPannel from "./pannels/RequisitionFormPannel";
import RequisitionModal from "./modals/RequisitionModal";

function truncateWithEllipsis(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  } else {
    return str;
  }
}

const useViewRow = () => {
  const [row, setrow] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const close = () => {
    setIsOpen(false);
    setrow(undefined);
  };

  const open = (row) => {
    setIsOpen(true);
    setrow(row);
  };

  return { row, isOpen, close, open };
};

const useEditRow = () => {
  const [row, setrow] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const clear = () => {
    setIsOpen(false);
    setrow(undefined);
  };

  const edit = (row) => {
    setIsOpen(true);
    setrow(row);
  };

  return { row, isOpen, clear, edit };
};

function EmployeeRequisitions({ employee }) {
  async function fetchData() {
    const { data } = await api.get("/requisitions", {
      params: {
        page_size: 5,
        employeeId: employee.id,
      },
    });
    return data;
  }

  const qk = ["my-requisitions", employee?.id];

  const {
    data: requisitions,
    status,
    error,
  } = useQuery(qk, fetchData, {
    keepPreviousData: true,
    retry: false,
    staleTime: Infinity,
    enabled: Boolean(employee),
  });

  const queryClient = useQueryClient();
  const getColor = (status) => {
    switch (status) {
      case "pending":
        return "text-yellow-500";
      case "approved":
        return "text-green-500";
      case "rejected":
        return "text-red-500";
      case "cancelled":
        return "text-orange-500";
      default:
        return "text-yellow-500";
    }
  };

  const [recordToMenu, setrecordToMenu] = useState(null);

  let formState = useOverlayTriggerState({});
  const editRow: any = useEditRow();

  const view: any = useViewRow();

  return (
    <>
      <div className="bg-white h-full border border-slate-200 rounded-md">
        <div className="flex items-center px-3 justify-between py-1">
          <h4 className="text-[13.2px] px-2 font-semibold">My Requisition.</h4>
          <Button
            onClick={() => {
              formState.open();
            }}
            LeftIcon={PlusCircle}
            variant="subtle"
            size="sm"
          >
            Request Requizition
          </Button>
        </div>
        <div className="min-h-[292px] h-full">
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                  Title
                </th>
                <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                  Created at
                </th>
                <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                  Status
                </th>
                <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                  Amount
                </th>
                <th className="text-right w-[100px] text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                  Type
                </th>
                <th className="text-right w-[100px] text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                  Actions
                </th>
              </tr>
            </thead>
            {status === "success" && (
              <tbody>
                {requisitions.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td className="text-left truncate leading-6 text-[13px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        {truncateWithEllipsis(e.title, 40)}
                      </td>

                      <td className="text-left truncate text-[13px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        {new Date(e.created_at).toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </td>
                      <td className="text-left text-[13px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        <span
                          className={cn(
                            "capitalize truncate",
                            getColor(e.status)
                          )}
                        >
                          {e.status}
                        </span>
                      </td>
                      <td className="text-right capitalize text-[12px] px-3 py-[14px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        {e.amount.toLocaleString() || 0} FRW
                      </td>
                      <td className="text-right capitalize text-[12px] px-5 py-[14px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        {e.type}
                      </td>
                      <td className="flex relative justify-end w-[100px] text-[13px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        <a
                          className="h-7 w-6 flex items-center justify-center hover:bg-slate-100 cursor-pointer rounded-md"
                          onClick={(i) => {
                            i.stopPropagation();
                            setrecordToMenu(e.id);
                          }}
                        >
                          <MoreVertical size={16} className="text-slate-500" />
                        </a>
                        <Menu
                          items={[
                            {
                              title: "Update Requisition",
                              icon: Edit,
                              hidden: e.status !== "pending",
                              onClick: () => {
                                editRow.edit(e);
                              },
                            },
                            {
                              title: "View Requisition",
                              onClick: () => {
                                view.open(e);
                              },
                              icon: Eye,
                            },
                          ]}
                          close={() => {
                            setrecordToMenu(null);
                          }}
                          open={Boolean(recordToMenu === e.id)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>

          {status === "loading" && (
            <Fragment>
              <table className="table-auto pl-3 my-[6px] w-full">
                <tbody>
                  {[1, 2, 3, 4, 5].map((_, i) => {
                    return (
                      <tr key={i}>
                        <td className="py-3 max-w-[130px] pl-5">
                          <Skeleton className="w-[120px] h-5" />
                        </td>
                        <td>
                          <Skeleton className="w-[100px] -pl-8 h-5" />
                        </td>
                        <td>
                          <Skeleton className="w-[100px] -pl-8 h-5" />
                        </td>
                        <td>
                          <Skeleton className="w-[100px] -pl-8 h-5" />
                        </td>
                        <td>
                          <Skeleton className="w-[120px] h-3" />
                        </td>
                        <td className=" w-[40px] flex items-center justify-end py-3 px-1 h-full">
                          <div>
                            <Skeleton className="w-[25px] h-[25px] rounded-full" />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Fragment>
          )}
          {status === "success" && !requisitions.length ? (
            <Fragment>
              <div className="w-full flex items-center justify-center h-[280px]">
                <span className="text-[12.5px] font-medium text-slate-600">
                  You don't have any requizitions.
                </span>
              </div>
            </Fragment>
          ) : null}
          {status === "error" && (
            <Fragment>
              <div className="w-full flex items-center justify-center h-[280px]">
                <span className="text-[12.5px] font-medium text-slate-600">
                  {error["message"]}
                </span>
              </div>
            </Fragment>
          )}
        </div>
      </div>
      <RequisitionModal
        open={view.isOpen}
        onClose={() => {
          view.close();
        }}
        hideActions={true}
        requisition={view.row}
      />
      {employee && (
        <RequisitionsFormPannel
          size="lg"
          onComplete={() => {
            queryClient.invalidateQueries({
              queryKey: qk,
            });
          }}
          open={formState.isOpen || editRow.isOpen}
          onClose={() => {
            editRow.clear();
            formState.close();
          }}
          requisitions={editRow.row}
          employee={employee}
        />
      )}
    </>
  );
}

export default EmployeeRequisitions;
