import React, { useRef } from "react";
import { useDateRangePickerState } from "react-stately";

import {
  mergeProps,
  useButton,
  useDateRangePicker,
  useFocusRing,
  useLocale,
} from "react-aria";
import { parseDate } from "@internationalized/date";
import cleanObject from "@/utils/cleanObject";
import { useOnClickOutside } from "usehooks-ts";
import DropdownTransition from "../DropdownTransition";
import { cn } from "@/utils/cn";
import { isSameDay, getDayOfWeek } from "@internationalized/date";

function formatDateToYYYYMMDD(date) {
  const dataToUse = new Date(date);
  const year = dataToUse.getFullYear();
  const month = String(dataToUse.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(dataToUse.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function RangeDatePicker(props) {
  const newProps = cleanObject({
    ...props,
    value: props.value
      ? {
          start: props.value?.start
            ? parseDate(formatDateToYYYYMMDD(props.value?.start))
            : undefined,
          end: props.value?.end
            ? parseDate(formatDateToYYYYMMDD(props.value?.end))
            : undefined,
        }
      : {
          start: parseDate(formatDateToYYYYMMDD(new Date())),
        },

    onChange: (e: any) => {
      return props.onChange({
        start: e.start.toDate(),
        end: e.end.toDate(),
      });
    },
  });

  let state: any = useDateRangePickerState(newProps);

  let ref = useRef();
  let { calendarProps } = useDateRangePicker(newProps, state, ref);

  const containerRef = useRef(null);

  useOnClickOutside(containerRef, props.onClose);

  return (
    <DropdownTransition open={props.isOpen}>
      <div
        ref={containerRef}
        className={cn(
          "absolute p-2 bg-white z-50 border w-fit border-slate-200 shadow-lg rounded-md top-9 right-0",
          props.className
        )}
      >
        <RangeCalendar {...calendarProps} />

        <div className="text-center px-2 pt-1 w-full flex justify-between items-center text-[13px] my-2 font-medium">
          <span className="cursor-pointer text-slate-500 " onClick={() => {}}>
            {/* {state.value ? (
              <>
                {new Date(
                  state.value.toDate(getLocalTimeZone())
                ).toLocaleDateString("en-US", {
                  month: "long",
                  day: "2-digit",
                  year: "numeric",
                })}
              </>
            ) : (
              <></>
            )} */}
          </span>
          {/* <a
            onClick={() => {
              props.onClear(undefined);
            }}
            className="cursor-pointer underline text-slate-500 text-[13px]"
          >
            Clear values
          </a> */}
        </div>
      </div>
    </DropdownTransition>
  );
}

import { useCalendarCell, useCalendarGrid, useRangeCalendar } from "react-aria";
import { useRangeCalendarState } from "react-stately";
import { createCalendar, getWeeksInMonth } from "@internationalized/date";
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather";

function RangeCalendar(props) {
  let { locale } = useLocale();
  let state: any = useRangeCalendarState({
    ...props,
    locale,
    createCalendar,
  });

  let ref = React.useRef(null);
  let { calendarProps, prevButtonProps, nextButtonProps, title } =
    useRangeCalendar(props, state, ref);

  return (
    <div {...calendarProps} ref={ref} className="calendar">
      <div className="flex items-center justify-between pb-2">
        <CalendarButton {...prevButtonProps}>
          <ArrowLeftCircle className="h-5 w-5" />
        </CalendarButton>
        <h2 className="font-semibold text-[13px] ml-2">{title}</h2>
        <CalendarButton {...nextButtonProps}>
          <ArrowRightCircle className="h-5 w-5" />
        </CalendarButton>
      </div>
      <CalendarGrid state={state} />
    </div>
  );
}

function CalendarGrid({ state, ...props }) {
  let { locale } = useLocale();
  let { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

  // Get the number of weeks in the month so we can render the proper number of rows.
  let weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <table {...gridProps}>
      <thead {...headerProps} className="text-gray-600">
        <tr>
          {weekDays.map((day, index) => (
            <th
              className="text-center py-2 text-[13px] font-semibold"
              key={index}
            >
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) =>
                date ? (
                  <CalendarCell key={i} state={state} date={date} />
                ) : (
                  <td key={i} />
                )
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function CalendarCell({ state, date }) {
  let ref = useRef();
  let {
    cellProps,
    buttonProps,
    isSelected,
    isDisabled,
    formattedDate,
    isInvalid,
  } = useCalendarCell({ date }, state, ref);

  // The start and end date of the selected range will have
  // an emphasized appearance.
  let isSelectionStart = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.start)
    : isSelected;
  let isSelectionEnd = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.end)
    : isSelected;

  // We add rounded corners on the left for the first day of the month,
  // the first day of each week, and the start date of the selection.
  // We add rounded corners on the right for the last day of the month,
  // the last day of each week, and the end date of the selection.
  let { locale } = useLocale();
  let dayOfWeek = getDayOfWeek(date, locale);
  let isRoundedLeft =
    isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1);
  let isRoundedRight =
    isSelected &&
    (isSelectionEnd ||
      dayOfWeek === 6 ||
      date.day === date.calendar.getDaysInMonth(date));

  let { focusProps, isFocusVisible } = useFocusRing();

  return (
    <td
      {...cellProps}
      className={`p-0.5 relative ${isFocusVisible ? "z-10" : "z-0"}`}
    >
      <div
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        // hidden={isOutsideVisibleRange}
        className={`w-[38px] h-[38px] outline-none group ${
          isRoundedLeft ? "rounded-l-md" : ""
        } ${isRoundedRight ? "rounded-r-md" : ""} ${
          isSelected
            ? isInvalid
              ? "bg-red-300"
              : "bg-red-100 bg-opacity-75"
            : ""
        } ${isDisabled ? "disabled" : ""}`}
      >
        <div
          className={`w-full h-full rounded-md cursor-pointer text-[12.5px] font-medium flex items-center justify-center ${
            isDisabled && !isInvalid ? "text-gray-400" : ""
          } ${
            // Focus ring, visible while the cell has keyboard focus.
            isFocusVisible
              ? "ring-2 group-focus:z-2 ring-red-600 ring-offset-2"
              : ""
          } ${
            // Darker selection background for the start and end.
            isSelectionStart || isSelectionEnd
              ? isInvalid
                ? "bg-red-600 text-white hover:bg-red-700"
                : "bg-red-600 text-white hover:bg-red-700"
              : ""
          } ${
            // Hover state for cells in the middle of the range.
            isSelected && !isDisabled && !(isSelectionStart || isSelectionEnd)
              ? isInvalid
                ? "hover:bg-red-400"
                : "hover:bg-red-400"
              : ""
          } ${
            // Hover state for non-selected cells.
            !isSelected && !isDisabled ? "hover:bg-red-100" : ""
          } cursor-default`}
        >
          {formattedDate}
        </div>
      </div>
    </td>
  );
}

function CalendarButton(props) {
  let ref = useRef();
  let { buttonProps } = useButton(props, ref);
  let { focusProps, isFocusVisible } = useFocusRing();
  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      ref={ref}
      className={`p-2 rounded-md ${props.isDisabled ? "text-gray-400" : ""} ${
        !props.isDisabled
          ? "hover:bg-slate-200 hover:bg-opacity-60 active:bg-red-200"
          : ""
      } outline-none ${
        isFocusVisible ? "ring-2 ring-offset-2 ring-red-600" : ""
      }`}
    >
      {props.children}
    </button>
  );
}
