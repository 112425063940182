import Button from "@/components/ui/Button";
import { useAuth } from "@/context/auth.context";
import { api } from "@/lib/api";
import {
  Activity,
  Camera,
  Clock,
  Edit,
  Edit2,
  Eye,
  LogIn,
  LogOut,
  MoreVertical,
  PlusCircle,
} from "react-feather";
import { useReactToPrint } from "react-to-print";
import { useQuery, useQueryClient } from "react-query";
import { useState, Fragment, useMemo, useRef, forwardRef } from "react";
import Avatar from "@/components/ui/Avatar";
import Loader from "@/components/ui/Loader";
import { cn } from "@/utils/cn";
import toast from "react-hot-toast";
import { useStopwatch } from "react-timer-hook";
import LeaveFormPannel from "@/components/pannels/LeaveFormPannel";
import { useOverlayTriggerState } from "react-stately";
import { useEmployee } from "@/context/employee.context";
import { handleUpload } from "@/utils/handleUpload";
import Menu from "@/components/ui/Menu";
import { useEditRow } from "@/hooks/useEditRow";
import LeaveModal from "@/components/modals/LeaveModal";
import { useViewRow } from "@/hooks/useViewRow";
import Skeleton from "@/components/ui/Skeleton";
import IssueModal from "@/components/modals/IssueModal";
import PerfomanceFormPannel from "@/components/pannels/PerfomanceFormPannel";
import ProgressBar from "@/components/ui/Progress";
import WeeklyPlanModal from "@/components/modals/WeeklyPlanModal";
import EvaluationModal from "@/components/modals/EvaluationModal";
import IssueModalDetails from "@/components/modals/IssueModalDetails";
import WeekPlanDetails from "@/components/modals/WeekPlanDetails";
import LeavesTakenVsRemaining from "@/components/LeavesTakenVsRemaining";
import formatPreviewLink from "@/utils/formatPreviewLink";
import EmployeeRequisitions from "@/components/EmployeeRequisitions";

function isLink(str) {
  try {
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
}

function formatDuration(secs) {
  const timeUnits = ["hrs", "mins", "secs"];
  const timeValues = [3600, 60, 1];
  const formattedTime = [];

  for (let i = 0; i < timeUnits.length; i++) {
    const unit = timeUnits[i];
    const unitValue = timeValues[i];
    const value = Math.floor(secs / unitValue);

    if (value > 0) {
      formattedTime.push(`${value} ${unit}`);
      secs %= unitValue;
    }
  }

  if (formattedTime.length === 0) {
    return "0 secs";
  }

  return formattedTime.join(", ");
}

function isWorkingDay(date) {
  const dayOfWeek = date.getDay();
  return dayOfWeek >= 1 && dayOfWeek <= 5;
}

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function secondsToHHMMSS(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedTime = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}:${String(remainingSeconds.toFixed(0)).padStart(2, "0")}`;

  return formattedTime;
}

function renderNumberWithLeadingZero(number) {
  if (number < 10) {
    return "0" + number;
  } else {
    return String(number);
  }
}

export default function EmployeePortal() {
  const { employee, status, error } = useEmployee();

  const getGreetings = () => {
    const date = new Date();
    const hours = date.getHours();
    if (hours < 12) {
      return "Good Morning";
    } else if (hours < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  const employeeId = useMemo(() => employee?.id, [employee]);

  const { user }: any = useAuth();

  const [activeTab, setactiveTab] = useState("Profile Info");

  async function fetchAttendance() {
    const { data } = await api.get("/attendances", {
      params: {
        created_at: getCurrentDate(),
        employeeId: employeeId,
        page_size: 1,
      },
    });
    return data[0];
  }

  const attendanceQuery = useQuery(
    ["employee-attendance", employeeId],
    fetchAttendance,
    {
      keepPreviousData: true,
      retry: false,
      staleTime: Infinity,
      enabled: Boolean(employeeId),
    }
  );

  const [loadingClockin, setloadingClockin] = useState(false);
  const [loadingClockOut, setloadingClockOut] = useState(false);

  const handleClockin = async () => {
    try {
      setloadingClockin(true);
      // const address = await getUserAddress();
      return api
        .post("/attendances", {
          employeeId: employeeId,
          clock_in: new Date(),
          location: "Not available",
        })
        .then(() => {
          toast.success("Clock in success");
          setloadingClockin(false);
          attendanceQuery.refetch();
        })
        .catch((e) => {
          console.log(e);
          setloadingClockin(false);
          toast.error(e?.response?.data?.message || e.message);
        });
    } catch (error) {
      setloadingClockin(false);
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          " Failed to get user address"
      );
    }
  };

  const handleClockOut = async () => {
    setloadingClockOut(true);
    try {
      setloadingClockOut(true);
      await api.put(`/attendances/${attendanceQuery?.data?.id}`, {
        employeeId: employeeId,
        clock_out: new Date(),
      });
      attendanceQuery.refetch();
      toast.success("Clock out success");
      setloadingClockOut(false);
    } catch (error) {
      setloadingClockOut(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const getOffest = (clock_in) => {
    const now = new Date();
    return (now.getTime() - new Date(clock_in).getTime()) / 1000;
  };

  return (
    <div>
      <div className="my-1 flex items-center justify-between">
        <div className="space-y-1">
          <h3 className="text-[14.5px] capitalize font-semibold text-slate-700">
            {getGreetings()}, {user.names}
          </h3>
          <p className="text-[13.5px] leading-7 font-medium text-slate-500">
            Here you can view your attendance, leaves, perfomance and other
            details.
          </p>
        </div>
        <div></div>
      </div>
      <div className="grid mt-2 grid-cols-6 gap-4">
        <div className="col-span-2">
          {(attendanceQuery.status === "loading" || attendanceQuery.isIdle) && (
            <div className="bg-white px-3 flex-col space-y-4 pt-5 pb-2 border  w-full h-full flex items-center justify-center border-slate-200 rounded-md">
              <div>
                <Skeleton className={"w-[150px] h-3"} />
              </div>
              <div>
                <Skeleton className={"w-[120px] h-7"} />
              </div>
              <div className="mb-8">
                <Skeleton className={"w-[190px] h-3"} />
              </div>
              <div className="flex  items-center gap-4 w-full justify-center">
                <div className="flex items-center gap-2">
                  <div>
                    <Skeleton className="w-[40px] h-[40px] rounded-full" />
                  </div>
                  <div className="flex flex-col  gap-3">
                    <Skeleton className="w-[100px] h-3" />
                    <Skeleton className="w-[100px] h-2" />
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div>
                    <Skeleton className="w-[40px] h-[40px] rounded-full" />
                  </div>
                  <div className="flex flex-col  gap-3">
                    <Skeleton className="w-[100px] h-3" />
                    <Skeleton className="w-[100px] h-2" />
                  </div>
                </div>
              </div>
              <div className="mb-8 w-full max-w-[310px]">
                <Skeleton className={"w-full h-8"} />
              </div>
              <div className="mb-8 w-full max-w-[310px] flex justify-start">
                <Skeleton className={"w-[150px] h-3"} />
              </div>
            </div>
          )}

          {attendanceQuery.status === "success" && (
            <div className="bg-white relative h-full px-3 pt-6 pb-2 border border-slate-200 rounded-md">
              <div className="flex flex-col justify-center items-center">
                <p className="capitalize  text-slate-600 text-sm font-medium">
                  Your attendance
                </p>
                <h4 className="text-2xl text-slate-800 my-3 font-semibold">
                  {attendanceQuery.data && !attendanceQuery.data.clock_out ? (
                    <CountDown
                      offset={getOffest(attendanceQuery?.data?.clock_in)}
                    />
                  ) : attendanceQuery?.data?.clock_out ? (
                    secondsToHHMMSS(attendanceQuery?.data?.duration)
                  ) : (
                    "00:00:00"
                  )}
                </h4>
                <span className="text-[13px] text-slate-500 font-medium">
                  {new Date().toLocaleDateString("en-US", {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    minute: "numeric",
                    hour: "numeric",
                  })}
                </span>
              </div>
              <div className="flex justify-center my-5 items-center gap-2">
                <div className="flex items-center gap-3">
                  <div className="border border-slate-200 h-10 w-10 flex items-center justify-center rounded-full">
                    <Clock size={16} className="text-slate-600" />
                  </div>
                  <div>
                    <p className="text-[13px] font-semibold text-slate-700">
                      08:00 AM
                    </p>
                    <span className="text-[12.5px] capitalize  font-medium text-slate-500">
                      Clock in
                    </span>
                  </div>
                </div>
                <div className="px-3">
                  <svg
                    viewBox="0 0 24 24"
                    id="right-left-arrow"
                    height={20}
                    width={20}
                    data-name="Flat Line"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-slate-600 fill-current stroke-current"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      <polyline
                        id="primary"
                        points="7 13 4 16 7 19"
                        style={{
                          strokeLinecap: "round",
                          strokeLinejoin: "round",
                          strokeWidth: 2,
                        }}
                      />
                      <path
                        id="primary-2"
                        data-name="primary"
                        d="M20,16H4M4,8H20"
                        style={{
                          strokeLinecap: "round",
                          strokeLinejoin: "round",
                          strokeWidth: 2,
                        }}
                      />
                      <polyline
                        id="primary-3"
                        data-name="primary"
                        points="17 11 20 8 17 5"
                        style={{
                          strokeLinecap: "round",
                          strokeLinejoin: "round",
                          strokeWidth: 2,
                        }}
                      />
                    </g>
                  </svg>
                </div>
                <div className="flex items-center gap-3">
                  <div className="border border-slate-200 h-10 w-10 flex items-center justify-center rounded-full">
                    <Clock className="text-slate-600" size={16} />
                  </div>
                  <div>
                    <h4 className="text-[13px] font-semibold text-slate-700">
                      17:00 PM
                    </h4>
                    <span className="text-[12.5px] capitalize  font-medium text-slate-500">
                      clock out
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="max-w-[300px] mx-auto">
                  <Button
                    disabled={
                      attendanceQuery.data?.clock_out ||
                      !isWorkingDay(new Date())
                    }
                    loading={loadingClockin || loadingClockOut}
                    fullWidth
                    onClick={() => {
                      if (attendanceQuery.data) {
                        handleClockOut();
                      } else {
                        handleClockin();
                      }
                    }}
                    RightIcon={attendanceQuery.data ? LogOut : LogIn}
                  >
                    {attendanceQuery.data ? "Clock out" : "  Clock in"}
                  </Button>
                </div>
              </div>
              <div className="flex flex-col items-center  gap-2 px-7 my-2">
                <div className="flex font-medium text-slate-500 text-[13px] items-center gap-2">
                  <span>Total working hours:</span>
                  <span className="text-red-500">
                    {formatDuration(
                      attendanceQuery?.data?.total_working_hours || 28800
                    )}
                  </span>
                </div>
                {attendanceQuery?.data && (
                  <div className="flex font-medium text-slate-500 text-[13px] items-center gap-2">
                    <span>Late:</span>
                    <span className="text-red-500">
                      {formatDuration(
                        attendanceQuery.data.clock_in_late_duration
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="col-span-4">
          <div className="bg-white flex flex-col h-full pb-2 border border-slate-200 rounded-md">
            <div className="px-2 flex items-center  border-b border-slate-200 justify-between py-1-">
              <div className="flex items-center gap-3">
                {[
                  "Profile Info",
                  "company",
                  "attendance",
                  "weekly plan",
                  "Issues",
                  "Evaluation",
                ].map((e, i) => {
                  return (
                    <a
                      key={i}
                      className={cn(
                        "text-[13px] truncate text-center capitalize py-[10px] px-6 cursor-pointer flex items-center justify-center relative font-medium",
                        {
                          "text-primary": activeTab === e,
                          "text-slate-600": activeTab !== e,
                          "pointer-events-none opacity-70": !employee,
                        }
                      )}
                      onClick={() => {
                        setactiveTab(e);
                      }}
                    >
                      {e}
                      {activeTab === e && (
                        <div className="w-full -bottom-1- bottom-0 absolute h-[3px] rounded-t-lg bg-red-600"></div>
                      )}
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="h-full relative ">
              {activeTab === "Profile Info" && (
                <ProfileInformation error={error} status={status} />
              )}
              {activeTab === "company" && (
                <CompanyDetails employee={employee} />
              )}
              {activeTab === "attendance" && (
                <Attendance employeeId={employeeId} />
              )}
              {activeTab === "weekly plan" && (
                <WeeklyPlan employeeId={employeeId} />
              )}
              {activeTab === "Issues" && <Issues employeeId={employeeId} />}
              {activeTab === "Evaluation" && <Evaluation employee={employee} />}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-4 mt-4">
        <div className="col-span-5">
          <Leaves employee={employee} />
        </div>
        <div className="col-span-2">
          <div className="bg-white h-full flex flex-col px-3 pt-3 pb-2 border border-slate-200 rounded-md">
            <div className="px-2 py-1">
              <h3 className="text-[13.5px] font-semibold text-slate-700">
                Leave taken vs remaining
              </h3>
            </div>
            <LeavesTakenVsRemaining employeeId={employeeId} />
          </div>
        </div>
        <div className="mt-2 col-span-5">
          <EmployeeRequisitions employee={employee} />
        </div>
      </div>
    </div>
  );
}

function Evaluation({ employee }) {
  async function fetchData() {
    const { data } = await api.get("/perfomance", {
      params: {
        page_size: 5,
        employeeId: employee?.id,
      },
    });
    return data;
  }

  const {
    data: evaluations,
    status,
    error,
    refetch,
  } = useQuery(["perfomance", employee.id], fetchData, {
    keepPreviousData: true,
    retry: false,
    staleTime: Infinity,
    enabled: Boolean(employee.id),
  });
  const [showPerfomanceModal, setshowPerfomanceModal] = useState(false);

  const [recordToMenu, setrecordToMenu] = useState(null);

  const [rowToEdit, setrowToEdit] = useState(null);

  const [evaluationToShow, setevaluationToShow] = useState(null);
  return (
    <>
      <table className="table-fixed w-full">
        <thead>
          <tr>
            <th className="text-left text-[12px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Employee
            </th>
            <th className="text-left text-[12px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Period
            </th>
            <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              score
            </th>
            <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Created at
            </th>
            <th className="text-left w-[60px] text-[12.5px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              <a
                onClick={() => {
                  setshowPerfomanceModal(true);
                }}
                className="h-7 w-7 text-slate-600 active:translate-y-[1px]  flex items-center justify-center rounded-sm cursor-pointer hover:bg-slate-100"
              >
                <PlusCircle size={16} />
              </a>
            </th>
          </tr>
        </thead>
        {status === "success" && (
          <tbody>
            {evaluations.map((e, i) => {
              return (
                <tr key={i}>
                  <td className="text-left capitalize truncate leading-6 text-[12px] px-5 py-[10px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {e?.employee?.first_name} {e?.employee?.last_name}
                  </td>
                  <td className="text-left truncate leading-6 text-[12px] px-5 py-[10px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {e.period}
                  </td>
                  <td
                    className={cn(
                      "text-left capitalize truncate text-[12px] px-5 py-[10px] border-t  border-b border-slate-100 border-b-slate-100 font-medium"
                    )}
                  >
                    <div className="capitalize w-[100px] truncate flex items-center gap-2">
                      <span className="text-[12px] text-slate-500 font-medium">
                        {e.score}%{" "}
                      </span>
                      <ProgressBar size="sm" progress={`${e.score}%`} />
                    </div>
                  </td>
                  <td className="text-left truncate text-[12px] px-5 py-[10px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {new Date(e.created_at).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </td>
                  <td className="text-left truncate text-[12px] px-5 py-[10px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    <a
                      className="h-7 w-6 flex items-center justify-center hover:bg-slate-100 cursor-pointer rounded-md"
                      onClick={(i) => {
                        i.stopPropagation();
                        setrecordToMenu(e.id);
                      }}
                    >
                      <MoreVertical size={16} className="text-slate-500" />
                    </a>
                    <Menu
                      customClass="!top-[40px]"
                      items={[
                        {
                          title: "View evaluation",
                          icon: Edit2,
                          onClick: () => {
                            setrowToEdit(e);
                          },
                        },
                        // {
                        //   title: "View evaluation",
                        //   icon: Eye,
                        //   onClick: () => {
                        //     setevaluationToShow(e);
                        //   },
                        // },
                      ]}
                      close={() => {
                        setrecordToMenu(null);
                      }}
                      open={Boolean(recordToMenu === e.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {status === "success" && !evaluations.length ? (
        <Fragment>
          <div className="flex items-center  justify-center py-20  w-full">
            <span className="text-[13px] text-center leading-7 font-medium text-slate-600">
              You have evaluations yet.
              <br />
              {/* <a
                onClick={() => {
                  setshowPerfomanceModal(true);
                }}
                className="text-primary cursor-pointer underline"
              >
                Evaluate your self.
              </a> */}
            </span>
          </div>
        </Fragment>
      ) : null}
      {status === "loading" && (
        <Fragment>
          <div className="w-full flex items-center py-24 justify-center h-full-">
            <Loader />
          </div>
        </Fragment>
      )}
      {status === "error" && (
        <Fragment>
          <div className="w-full flex items-center py-24 justify-center h-full-">
            <span className="text-[12.5px] font-medium text-slate-600">
              {error["message"]}
            </span>
          </div>
        </Fragment>
      )}
      <EvaluationModal
        onClose={() => {
          setevaluationToShow(null);
        }}
        evaluation={evaluationToShow}
        open={Boolean(evaluationToShow)}
      />
      <PerfomanceFormPannel
        onComplete={() => {
          setshowPerfomanceModal(false);
          setrowToEdit(null);
          refetch();
        }}
        open={Boolean(rowToEdit) || showPerfomanceModal}
        onClose={() => {
          setrowToEdit(null);
          setshowPerfomanceModal(false);
        }}
        employee={employee}
        perfomance={rowToEdit}
      />
    </>
  );
}

function Issues({ employeeId }) {
  async function fetchData() {
    const { data } = await api.get("/issues", {
      params: {
        page_size: 5,
        createdById: employeeId,
      },
    });
    return data;
  }

  const {
    data: issues,
    status,
    error,
    refetch,
  } = useQuery(["issues", employeeId], fetchData, {
    keepPreviousData: true,
    retry: false,
    staleTime: Infinity,
    enabled: Boolean(employeeId),
  });

  const [showIssueModal, setshowIssueModal] = useState(false);

  const [recordToMenu, setrecordToMenu] = useState(null);

  const [rowToEdit, setrowToEdit] = useState(null);

  const [issueToShow, setissueToShow] = useState(null);

  return (
    <Fragment>
      <IssueModalDetails
        onClose={() => {
          setissueToShow(null);
        }}
        issue={issueToShow}
        open={Boolean(issueToShow)}
      />
      <IssueModal
        onComplete={() => {
          refetch();
        }}
        onClose={() => {
          setshowIssueModal(false);
          setrowToEdit(null);
        }}
        issue={rowToEdit}
        open={showIssueModal || Boolean(rowToEdit)}
      />
      <table className="table-fixed w-full">
        <thead>
          <tr>
            <th className="text-left text-[12px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Title
            </th>
            {/* <th className="text-left text-[12px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Description
            </th> */}
            <th className="text-left text-[12px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Department
            </th>
            <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Status
            </th>
            <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Reported at
            </th>
            <th className="text-left w-[60px] text-[12.5px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              <a
                onClick={() => {
                  setshowIssueModal(true);
                }}
                className="h-7 w-7 text-slate-600 active:translate-y-[1px]  flex items-center justify-center rounded-sm cursor-pointer hover:bg-slate-100"
              >
                <PlusCircle size={16} />
              </a>
            </th>
          </tr>
        </thead>
        {status === "success" && (
          <tbody className="">
            {issues.map((e, i) => {
              return (
                <tr key={i}>
                  <td className="text-left truncate leading-6 text-[12px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {e.title}
                  </td>
                  {/* <td className="text-left truncate leading-6 text-[12px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {e.description}
                  </td> */}
                  <td className="text-left capitalize truncate leading-6 text-[12px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {e.department?.name || "N/A"}
                  </td>
                  <td
                    className={cn(
                      "text-left capitalize truncate text-[12px] px-5 py-[8px] border-t  border-b border-slate-100 border-b-slate-100 font-medium",
                      {
                        "text-red-500": e.status === "closed",
                        "text-green-500": e.status === "open",
                      }
                    )}
                  >
                    {e.status}
                  </td>
                  <td className="text-left truncate text-[12px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {new Date(e.created_at).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </td>
                  <td className="text-left relative text-[12px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    <a
                      className="h-7 w-6 flex items-center justify-center hover:bg-slate-100 cursor-pointer rounded-md"
                      onClick={(i) => {
                        i.stopPropagation();
                        setrecordToMenu(e.id);
                      }}
                    >
                      <MoreVertical size={16} className="text-slate-500" />
                    </a>
                    <Menu
                      customClass="!top-[10px]"
                      items={[
                        {
                          title: "Update issue",
                          icon: Edit2,
                          onClick: () => {
                            setrowToEdit(e);
                          },
                        },
                        {
                          title: "View issue",
                          icon: Eye,
                          onClick: () => {
                            setissueToShow(e);
                          },
                        },
                      ]}
                      close={() => {
                        setrecordToMenu(null);
                      }}
                      open={Boolean(recordToMenu === e.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {status === "success" && !issues.length ? (
        <Fragment>
          <div className="flex items-center  justify-center py-20  w-full">
            <span className="text-[13px] text-center leading-7 font-medium text-slate-600">
              You have not reported any issue yet.
              <br />
              <a
                onClick={() => {
                  setshowIssueModal(true);
                }}
                className="text-primary cursor-pointer underline"
              >
                Report issuee.
              </a>
            </span>
          </div>
        </Fragment>
      ) : null}
      {status === "loading" && (
        <Fragment>
          <div className="w-full flex items-center py-24 justify-center h-full-">
            <Loader />
          </div>
        </Fragment>
      )}
      {status === "error" && (
        <Fragment>
          <div className="w-full flex items-center py-24 justify-center h-full-">
            <span className="text-[12.5px] font-medium text-slate-600">
              {error["message"]}
            </span>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

function WeeklyPlan({ employeeId }) {
  console.log(employeeId);
  const [showPlanModal, setshowPlanModal] = useState(false);

  async function fetchData() {
    const { data } = await api.get("/plans", {
      params: {
        page_size: 5,
        employeeId: employeeId,
      },
    });
    return data;
  }

  const {
    data: plans,
    status,
    error,
    refetch,
  } = useQuery(["plans", employeeId], fetchData, {
    keepPreviousData: true,
    retry: false,
    staleTime: Infinity,
    enabled: Boolean(employeeId),
  });

  const [recordToMenu, setrecordToMenu] = useState(null);

  const [rowToEdit, setrowToEdit] = useState(null);

  const [planToShow, setPlanToShow] = useState(null);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <div className="hidden">
        <WeeklyPlanReport ref={componentRef} />
      </div>
      <table className="table-fixed w-full">
        <thead>
          <tr>
            <th className="text-left text-[12px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Title
            </th>

            <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Tasks
            </th>
            <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Created at
            </th>
            <th className="text-left w-[60px] text-[12.5px] px-5 bg-slate-50 py-[4px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              <a
                onClick={() => {
                  setshowPlanModal(true);
                }}
                className="h-7 w-7 text-slate-600 active:translate-y-[1px]  flex items-center justify-center rounded-sm cursor-pointer hover:bg-slate-100"
              >
                <PlusCircle size={16} />
              </a>
            </th>
          </tr>
        </thead>
        {status === "success" && (
          <tbody className="">
            {plans.map((e, i) => {
              return (
                <tr key={i} className={e.done ? "line-through" : ""}>
                  <td className="text-left truncate leading-6 text-[12px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {e.title}
                  </td>

                  <td
                    className={cn(
                      "text-left capitalize  text-slate-500 truncate text-[12px] px-5 py-[8px] border-t  border-b border-slate-100 border-b-slate-100 font-medium"
                    )}
                  >
                    {e?.tasks?.length} Tasks
                  </td>
                  <td className="text-left truncate text-[12px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {new Date(e.created_at).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </td>
                  <td className="text-left relative text-[12px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    <a
                      className="h-7 w-6 flex items-center justify-center hover:bg-slate-100 cursor-pointer rounded-md"
                      onClick={(i) => {
                        i.stopPropagation();
                        setrecordToMenu(e.id);
                      }}
                    >
                      <MoreVertical size={16} className="text-slate-500" />
                    </a>
                    <Menu
                      customClass="!top-[10px]"
                      items={[
                        {
                          title: "Update plan",
                          icon: Edit2,
                          onClick: () => {
                            setrowToEdit(e);
                          },
                        },
                        {
                          title: "View plan",
                          icon: Eye,
                          onClick: () => {
                            setPlanToShow(e);
                          },
                        },
                        {
                          title: "Generate report",
                          icon: Activity,
                          onClick: () => {
                            handlePrint();
                          },
                        },
                      ]}
                      close={() => {
                        setrecordToMenu(null);
                      }}
                      open={Boolean(recordToMenu === e.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {status === "success" && !plans.length ? (
        <Fragment>
          <div className="flex items-center  justify-center py-20  w-full">
            <span className="text-[13px] text-center leading-7 font-medium text-slate-600">
              You have not added yet any plans this week.
              <br />
              <a
                onClick={() => {
                  setshowPlanModal(true);
                }}
                className="text-primary cursor-pointer underline"
              >
                Add one
              </a>
            </span>
          </div>
        </Fragment>
      ) : null}
      {status === "loading" && (
        <Fragment>
          <div className="w-full flex items-center py-24 justify-center h-full-">
            <Loader />
          </div>
        </Fragment>
      )}
      {status === "error" && (
        <Fragment>
          <div className="w-full flex items-center py-24 justify-center h-full-">
            <span className="text-[12.5px] font-medium text-slate-600">
              {error["message"]}
            </span>
          </div>
        </Fragment>
      )}
      <WeekPlanDetails
        onClose={() => {
          setPlanToShow(undefined);
        }}
        plan={planToShow}
        open={Boolean(planToShow)}
      />
      <WeeklyPlanModal
        onComplete={() => {
          refetch();
        }}
        onClose={() => {
          setshowPlanModal(false);
          setrowToEdit(undefined);
        }}
        plan={rowToEdit}
        open={showPlanModal || Boolean(rowToEdit)}
      />
    </>
  );
}

function calculateDuration(start_date, end_date) {
  const startDate: any = new Date(start_date);
  const endDate: any = new Date(end_date);

  // Calculate the time difference in milliseconds
  const timeDifference = endDate - startDate;

  // Calculate the duration in days
  const daysDifference = timeDifference / (1000 * 3600 * 24);

  // If the duration is less than 1 day, calculate it in hours
  if (daysDifference < 1) {
    const hoursDifference = timeDifference / (1000 * 3600);
    return `${hoursDifference.toFixed(0)} hours`;
  }

  // If the duration is less than 30 days, calculate it in days
  if (daysDifference < 30) {
    return `${daysDifference.toFixed(0)} days`;
  }

  // If the duration is greater than or equal to 30 days, calculate it in months
  const monthsDifference = Math.floor(daysDifference / 30);
  return `${monthsDifference} months`;
}
function Leaves({ employee }) {
  async function fetchData() {
    const { data } = await api.get("/leaves", {
      params: {
        page_size: 5,
        employeeId: employee.id,
      },
    });
    return data;
  }

  const qk = ["my-leaves", employee?.id];

  const {
    data: leaves,
    status,
    error,
  } = useQuery(qk, fetchData, {
    keepPreviousData: true,
    retry: false,
    staleTime: Infinity,
    enabled: Boolean(employee),
  });

  const queryClient = useQueryClient();
  const getColor = (status) => {
    switch (status) {
      case "pending":
        return "text-yellow-500";
      case "approved":
        return "text-green-500";
      case "rejected":
        return "text-red-500";
      case "cancelled":
        return "text-orange-500";
      default:
        return "text-yellow-500";
    }
  };

  const [recordToMenu, setrecordToMenu] = useState(null);

  let formState = useOverlayTriggerState({});
  const editRow: any = useEditRow();

  const view: any = useViewRow();

  return (
    <>
      <div className="bg-white h-full border border-slate-200 rounded-md">
        <div className="flex items-center px-3 justify-between py-1">
          <h4 className="text-[13.2px] px-2 font-semibold">
            Manage your Leaves!!
          </h4>
          <Button
            onClick={() => {
              formState.open();
            }}
            LeftIcon={PlusCircle}
            variant="subtle"
            size="sm"
          >
            Request leave
          </Button>
        </div>
        <div className="min-h-[292px] h-full">
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                  Duration
                </th>
                <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                  Start -End
                </th>
                <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                  Created at
                </th>
                <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                  Status
                </th>
                <th className="text-right w-[100px] text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                  Type
                </th>
                <th className="text-right w-[100px] text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t  border-b border-slate-200 border-b-slate-200 font-semibold">
                  Actions
                </th>
              </tr>
            </thead>
            {status === "success" && (
              <tbody>
                {leaves.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td className="text-left truncate leading-6 text-[13px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        {calculateDuration(e.start_date, e.end_date)}
                      </td>
                      <td className="text-left truncate text-[13px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        {new Date(e.start_date).toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })}
                        {" - "}
                        {new Date(e.end_date).toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </td>
                      <td className="text-left truncate text-[13px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        {new Date(e.created_at).toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </td>
                      <td className="text-left text-[13px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        <span
                          className={cn(
                            "capitalize truncate",
                            getColor(e.status)
                          )}
                        >
                          {e.status}
                        </span>
                      </td>
                      <td className="text-right capitalize text-[12px] px-5 py-[14px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        {e.type}
                      </td>
                      <td className="flex relative justify-end w-[100px] text-[13px] px-5 py-[8px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                        <a
                          className="h-7 w-6 flex items-center justify-center hover:bg-slate-100 cursor-pointer rounded-md"
                          onClick={(i) => {
                            i.stopPropagation();
                            setrecordToMenu(e.id);
                          }}
                        >
                          <MoreVertical size={16} className="text-slate-500" />
                        </a>
                        <Menu
                          items={[
                            {
                              title: "Update leave",
                              icon: Edit,
                              hidden: e.status !== "pending",
                              onClick: () => {
                                editRow.edit(e);
                              },
                            },
                            {
                              title: "View Leave",
                              onClick: () => {
                                view.open(e);
                              },
                              icon: Eye,
                            },
                          ]}
                          close={() => {
                            setrecordToMenu(null);
                          }}
                          open={Boolean(recordToMenu === e.id)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>

          {status === "loading" && (
            <Fragment>
              <table className="table-auto pl-3 my-[6px] w-full">
                <tbody>
                  {[1, 2, 3, 4, 5].map((_, i) => {
                    return (
                      <tr key={i}>
                        <td className="py-3 max-w-[130px] pl-5">
                          <Skeleton className="w-[120px] h-5" />
                        </td>
                        <td>
                          <Skeleton className="w-[100px] -pl-8 h-5" />
                        </td>
                        <td>
                          <Skeleton className="w-[100px] -pl-8 h-5" />
                        </td>
                        <td>
                          <Skeleton className="w-[100px] -pl-8 h-5" />
                        </td>
                        <td>
                          <Skeleton className="w-[120px] h-3" />
                        </td>
                        <td className=" w-[40px] flex items-center justify-end py-3 px-1 h-full">
                          <div>
                            <Skeleton className="w-[25px] h-[25px] rounded-full" />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Fragment>
          )}
          {status === "success" && !leaves.length ? (
            <Fragment>
              <div className="w-full flex items-center justify-center h-[280px]">
                <span className="text-[12.5px] font-medium text-slate-600">
                  You don't have any leave.
                </span>
              </div>
            </Fragment>
          ) : null}
          {status === "error" && (
            <Fragment>
              <div className="w-full flex items-center justify-center h-[280px]">
                <span className="text-[12.5px] font-medium text-slate-600">
                  {error["message"]}
                </span>
              </div>
            </Fragment>
          )}
        </div>
      </div>
      <LeaveModal
        open={view.isOpen}
        onClose={() => {
          view.close();
        }}
        hideActions={true}
        leave={view.row}
      />
      {employee && (
        <LeaveFormPannel
          size="lg"
          onComplete={() => {
            queryClient.invalidateQueries({
              queryKey: qk,
            });
          }}
          open={formState.isOpen || editRow.isOpen}
          onClose={() => {
            editRow.clear();
            formState.close();
          }}
          leave={editRow.row}
          employee={employee}
        />
      )}
    </>
  );
}

function ProfileInformation({ status, error }) {
  const { employee, refetch } = useEmployee();
  const [uploading, setuploading] = useState(false);

  const uploadRef = useRef<any>();

  const updateProfile = async (e: any) => {
    setuploading(true);

    try {
      const photoURL = await handleUpload({
        file: e.target.files[0],
        key: e.target.files[0].name,
      });

      await api.put(`/employees/${employee.id}`, {
        photo: photoURL,
      });
      refetch();
      setTimeout(() => {
        setuploading(false);
        toast.success(`Profile photo updated successfully`);
      }, 1000);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setuploading(false);
    }
  };

  return (
    <div className="px-3 py-3">
      {status === "success" && (
        <div>
          <div className="flex mt-1 gap-3 mb-1 items-center">
            <div className="relative group overflow-hidden rounded-full">
              <input
                disabled={uploading}
                type="file"
                className="hidden"
                ref={uploadRef}
                onChange={updateProfile}
              />
              <div
                className={cn(
                  "absolute  group-hover:opacity-100 transition-all overflow-hidden flex items-center justify-center h-full w-full bg-black bg-opacity-30",
                  {
                    "opacity-0": !uploading,
                    "opacity-100": uploading,
                  }
                )}
              >
                <a
                  onClick={() => {
                    uploadRef.current.click();
                  }}
                  className="h-7 w-7 cursor-pointer flex items-center justify-center bg-white rounded-full"
                >
                  {uploading ? (
                    <Loader />
                  ) : (
                    <Camera size={14} className="text-slate-600" />
                  )}
                </a>
              </div>
              <Avatar
                size="lg"
                rounded="full"
                src={employee.photo}
                name={employee.first_name + " " + employee.last_name}
              />
            </div>
            <div>
              <div className="flex items-center gap-2">
                <h4 className="text-[13px] capitalize font-semibold text-slate-700">
                  {employee.first_name + " " + employee.last_name}
                </h4>
                {/* <div className="flex items-center gap-1">
                  <span className="mx-1 text-slate-500 font-medium">-</span>
                  <span className="h-2 w-2 flex bg-green-500  rounded-full"></span>
                  <span className="text-[12px] font-medium text-slate-500">
                    Active
                  </span>
                </div> */}
              </div>
              <p className="text-[12.5px] capitalize mt-1 font-medium text-slate-500">
                {employee?.department?.name}
              </p>
            </div>
          </div>
          <div className="grid px-[6px] gap-6 my-2 pt-3 grid-cols-3">
            {[
              { key: "First name", value: employee?.first_name },
              { key: "Last name", value: employee?.last_name },
              { key: "Email", value: employee.email },
              { key: "Gender", value: employee.gender },
              {
                key: "Birth",
                value: new Date(employee.birth_date).toLocaleDateString(
                  "en-US",
                  {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  }
                ),
              },
              { key: "National Id", value: employee.national_id },
              { key: "Phone", value: employee.phone },
              { key: "Country", value: employee?.permanent_address?.country },
              {
                key: "Address",
                value: employee?.permanent_address?.street_address,
              },
              {
                key: "Emergency Phone",
                value: employee?.contact_person?.phone,
              },
              { key: "Marital status", value: employee.marital_status },
            ].map((e, i) => {
              return (
                <div className="flex  items-center gap-4" key={i}>
                  <span className="text-[12.5px] font-medium capitalize text-slate-500">
                    {e.key}:
                  </span>
                  <span className="text-[12.5px] truncate font-semibold capitalize text-slate-700">
                    {e.value}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {status === "loading" && (
        <Fragment>
          <div className="w-full flex items-center py-24 justify-center h-full-">
            <Loader />
          </div>
        </Fragment>
      )}
      {status === "error" && (
        <Fragment>
          <div className="w-full flex items-center py-24 justify-center h-full-">
            <span className="text-[12.5px] font-medium text-slate-600">
              {error["message"]}
            </span>
          </div>
        </Fragment>
      )}
    </div>
  );
}
function CompanyDetails({ employee }) {
  return (
    <div className="px-4 py-2">
      <div className="grid px-[6px] gap-6 my-4- pt-3 grid-cols-2">
        {[
          { key: "Department", value: employee?.department?.name },
          {
            key: "Social security number",
            value: employee.social_security_number,
          },
          {
            key: "Joined at",
            value: new Date(employee.created_at).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            }),
          },
          { key: "Created by", value: employee.createdBy?.first_name || "N/A" },
          {
            key: "Salary",
            value: parseInt(employee.salary).toLocaleString() + " Frw",
          },
          { key: "employment type", value: employee.employment_type },
          { key: "employment status", value: employee.employment_status },
          { key: "Manager", value: employee.manager?.first_name || "N/A" },
          { key: "CV", value: employee?.cv || "N/A" },
        ].map((e, i) => {
          return (
            <div className="flex  items-center gap-4" key={i}>
              <span className="text-[12.5px] font-medium capitalize text-slate-500">
                {e.key}:
              </span>
              {isLink(e.value) ? (
                <a
                  href={formatPreviewLink(e.value)}
                  target="_blank"
                  className="text-[12.5px] underline truncate font-semibold text-slate-700"
                >
                  {formatPreviewLink(e.value)}
                </a>
              ) : (
                <span className="text-[12.5px] truncate font-semibold capitalize text-slate-700">
                  {e.value}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
function Attendance({ employeeId }) {
  async function fetchData() {
    const { data } = await api.get("/attendances", {
      params: {
        page_size: 5,
        employeeId: employeeId,
      },
    });
    console.log(data);
    return data;
  }

  const {
    data: attendances,
    status,
    error,
    refetch,
  } = useQuery(["my-attendances", employeeId], fetchData, {
    keepPreviousData: true,
    retry: false,
    staleTime: Infinity,
    enabled: Boolean(employeeId),
  });

  const handleEnd = async (e) => {
    await api.put(`/attendances/${e.id}`, {
      employeeId: employeeId,
      clock_out: new Date(),
    });

    refetch();
  };

  return (
    <Fragment>
      <table className="table-fixed w-full">
        <thead>
          <tr>
            <th className="text-left text-[12px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Clock in
            </th>
            <th className="text-left text-[12px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Clock out
            </th>
            <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Date
            </th>
            <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Duration
            </th>
            <th className="text-left text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Clockin Status
            </th>
            <th className="text-right w-[100px] text-[12.5px] px-5 bg-slate-50 py-[8px] text-slate-600 border-t-  border-b border-slate-200 border-b-slate-200 font-semibold">
              Action
            </th>
          </tr>
        </thead>
        {status === "success" && (
          <tbody>
            {attendances.map((e, i) => {
              return (
                <tr key={i}>
                  <td className="text-left truncate leading-6 text-[12.5px] px-5 py-[10px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {new Date(e.clock_in).toLocaleTimeString("en-US", {
                      minute: "numeric",
                      hour: "numeric",
                      hour12: true,
                    })}
                  </td>
                  <td className="text-left truncate leading-6 text-[12.5px] px-5 py-[10px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {e.clock_out
                      ? new Date(e.clock_out).toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })
                      : "---"}
                  </td>
                  <td className="text-left truncate text-[12.5px] px-5 py-[10px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {new Date(e.created_at).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </td>
                  <td className="text-left truncate text-[12.5px] px-5 py-[10px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    {formatDuration(e.duration)}
                  </td>
                  <td className="text-left text-[12.5px] px-5 py-[12px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    <span className={cn("capitalize truncate")}>
                      {e.clock_in_status}
                    </span>
                  </td>
                  <td className="text-right truncate capitalize text-[12.5px] px-5 py-[12px] text-slate-500 border-t  border-b border-slate-100 border-b-slate-100 font-medium">
                    <a
                      onClick={() => {
                        handleEnd(e);
                      }}
                      className={cn(
                        "text-red-500 cursor-pointer text-[11px] underline",
                        {
                          "opacity-60 pointer-events-none": e.clock_out,
                        }
                      )}
                    >
                      End
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {status === "success" && !attendances.length ? (
        <Fragment>
          <div className="w-full flex items-center py-24 justify-center h-full-">
            <span className="text-[12.5px] font-medium text-slate-600">
              There is no attendance available.
            </span>
          </div>
        </Fragment>
      ) : null}
      {status === "loading" && (
        <Fragment>
          <div className="w-full flex items-center py-24 justify-center h-full-">
            <Loader />
          </div>
        </Fragment>
      )}
      {status === "error" && (
        <Fragment>
          <div className="w-full flex items-center py-24 justify-center h-full-">
            <span className="text-[12.5px] font-medium text-slate-600">
              {error["message"]}
            </span>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

function CountDown({ offset }) {
  console.log(offset);
  const stopwatchOffset = new Date();
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + offset);
  const { seconds, minutes, hours } = useStopwatch({
    autoStart: true,
    offsetTimestamp: stopwatchOffset,
  });
  return (
    <>
      <span>{renderNumberWithLeadingZero(hours)}</span>:
      <span>{renderNumberWithLeadingZero(minutes)}</span>:
      <span>{renderNumberWithLeadingZero(seconds)}</span>
    </>
  );
}

const WeeklyPlanReport = forwardRef((_, ref: any) => {
  return (
    <div ref={ref}>
      <h2>Weekly Plan Report - [Insert Date Range]</h2>

      <table>
        <thead>
          <tr>
            <th>Plan Name</th>
            <th>Done</th>
            <th>Start</th>
            <th>End</th>
            <th>Target/Deliverables</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Activity 1</td>
            <td>Yes</td>
            <td>[Start Time]</td>
            <td>[End Time]</td>
            <td>[Target/Deliverables]</td>
          </tr>
          <tr>
            <td>Activity 2</td>
            <td>No</td>
            <td>[Start Time]</td>
            <td>[End Time]</td>
            <td>[Target/Deliverables]</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});
