import { useFormikContext } from "formik";
import Dropzone from "../ui/Dropzone";
import { get } from "lodash";

function AppFileUpload({ name, label }) {
  const { values, errors, setFieldValue, setFieldTouched }: any =
    useFormikContext();

  return (
    <div>
      <Dropzone
        label={label}
        error={get(errors, name) ? get(errors, name) : ""}
        file={get(values, name)}
        onChange={(e: any) => {
          console.log(e);
          setFieldTouched(name, true);
          setFieldValue(name, e);
        }}
      />
    </div>
  );
}

export default AppFileUpload;
