import Avatar from "@/components/ui/Avatar";
import { cn } from "@/utils/cn";
import { Check, X } from "react-feather";
import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { api } from "@/lib/api";
import Loader from "@/components/ui/Loader";

export default function AttendanceReport() {
  const [year, setyear] = useState(new Date().getFullYear());
  const [month, setmonth] = useState(new Date().getMonth() + 1);

  const generateDaysOfMonth = (year, month) => {
    const days = [];
    const date = new Date(year, month - 1, 1);
    while (date.getMonth() === month - 1) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  async function fetchData(e) {
    const q = e.queryKey[1];
    const { data } = await api.get("/attendances/report", {
      params: q,
    });
    return data;
  }

  const daysOfMonth = generateDaysOfMonth(year, month);

  const {
    data: reports,
    status,
    isFetching,
    error,
  } = useQuery(["attendance-report", { year, month }], fetchData, {
    keepPreviousData: true,
    retry: false,
    staleTime: Infinity,
  });

  return (
    <div className="border rounded-md border-slate-200 bg-white">
      <div>
        <div className="py-2 px-3">
          <div className="flex items-center gap-3">
            <select
              value={year}
              onChange={(e: any) => setyear(e.target.value)}
              className="px-3 border outline-none  cursor-pointer rounded-md py-2 w-[250px] text-[13px] font-medium text-slate-500 border-slate-200"
              name=""
              id=""
            >
              <option selected disabled value="">
                Choose year
              </option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2029">2029</option>
              <option value="2030">2030</option>
            </select>

            <select
              value={month}
              onChange={(e: any) => setmonth(e.target.value)}
              className="px-3 border cursor-pointer outline-none rounded-md py-2 w-[250px] text-[13px] font-medium text-slate-500 border-slate-200"
              name=""
              id=""
            >
              <option selected disabled value="">
                Choose Month
              </option>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>

            <a
              onClick={() => {
                setyear(new Date().getFullYear());
                setmonth(new Date().getMonth() + 1);
              }}
              className="text-[13px] cursor-pointer flex items-center gap-2 hover:bg-slate-100 px-2 py-1 rounded-md text-slate-500 font-medium"
            >
              <span>Reset</span>
              <X className="text-slate-500" size={14} />
            </a>
            {isFetching && <Loader />}
          </div>
        </div>
      </div>
      <div className="flex border-t overflow-hidden rounded-md">
        {status === "loading" && (
          <div className="w-full flex items-center justify-center h-[300px]">
            <Loader />
          </div>
        )}
        {status === "error" && (
          <div className="w-full flex items-center justify-center h-[300px]">
            <span className="text-slate-500 font-medium">
              {error["message"] || " Failed to load data"}
            </span>
          </div>
        )}
        {status === "success" && reports && (
          <Fragment>
            <div className="w-[300px] shadow-lg border-r">
              <div className="py-2 px-3 bg-slate-50 border-b- border-slate-200">
                <h4 className="text-[12px]  text-slate-600 font-semibold capitalize">
                  Employee
                </h4>
              </div>
              <div>
                {reports.map((e) => {
                  const names = e.first_name + " " + e.last_name;
                  return (
                    <div className="px-3 border-t py-[6px]">
                      <div className="flex items-center gap-3">
                        <div>
                          <Avatar
                            src={e.photo || names}
                            rounded="full"
                            size="sm"
                            name={names}
                          />
                        </div>
                        <div>
                          <h4 className="text-slate-700 capitalize  truncate leading-5 text-[12px] font-medium">
                            {names}
                          </h4>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full no-scrollbar overflow-x-auto">
              {/* <div className="w-full ">
                <table className="w-full "></table>
              </div> */}
              <table className="w-full ">
                <tr>
                  {generateDaysOfMonth(year, month).map((_, i) => {
                    return (
                      <td className="text-[13px] border-b-  bg-slate-50 border-slate-200 text-slate-500 font-medium">
                        <span className="w-[70px] flex h-8 items-center justify-center">
                          {i + 1}
                        </span>
                      </td>
                    );
                  })}
                </tr>
                {reports.map((report, i) => {
                  return (
                    <tr key={i}>
                      {daysOfMonth.map((mothDate, index) => {
                        const status = report.attendances.find(
                          (e) =>
                            new Date(e.date).toLocaleDateString() ===
                            new Date(mothDate).toLocaleDateString()
                        )?.status;
                        return (
                          <td
                            key={index}
                            className="text-[13px] border-t border-r text-slate-500 font-medium"
                          >
                            <span
                              className={cn(
                                "w-[70px] hover:bg-slate-50 border-transparent hover:border-slate-200 border cursor-pointer flex h-[38px] items-center justify-center",
                                {
                                  "pointer-events-none select-none": !status,
                                }
                              )}
                            >
                              {status === "present" && (
                                <Check
                                  className="text-green-500"
                                  strokeWidth={2}
                                  size={16}
                                />
                              )}
                              {status === "absent" && (
                                <X
                                  className="text-red-500"
                                  strokeWidth={2}
                                  size={16}
                                />
                              )}

                              {!status && "--"}
                            </span>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </table>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}
