import Datatable from "@/components/Datatable";
import { createColumnHelper } from "@tanstack/table-core";
import { api } from "@/lib/api";
import Avatar from "@/components/ui/Avatar";
import Skeleton from "@/components/ui/Skeleton";
import { Link } from "react-router-dom";
import cleanObject from "@/utils/cleanObject";
import UserLabel from "@/components/UserLabel";
function formatDuration(secs) {
  const timeUnits = ["hrs", "mins", "secs"];
  const timeValues = [3600, 60, 1];
  const formattedTime = [];

  for (let i = 0; i < timeUnits.length; i++) {
    const unit = timeUnits[i];
    const unitValue = timeValues[i];
    const value = Math.floor(secs / unitValue);

    if (value > 0) {
      formattedTime.push(`${value} ${unit}`);
      secs %= unitValue;
    }
  }

  if (formattedTime.length === 0) {
    return "0 secs";
  }

  return formattedTime.join(", ");
}

function clearEmptyValues(obj: any) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== "") {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

async function fetchData(
  {
    pageIndex: page,
    pageSize: page_size,
    query,
    ...rest
  }: {
    pageIndex: number;
    pageSize: number;
    query: string;
    sorting: any;
  },
  { headers }
) {
  const filterObj = {
    employeeId: rest["employee"],
  };
  const filters = ["created_at"];

  filters.forEach((item) => {
    if (Object.keys(rest).includes(item) && rest[item]) {
      filterObj[item] = rest[item];
    }
  });
  const params = clearEmptyValues({
    page: page === 0 ? 1 : page,
    page_size,
    query,
    ...filterObj,
  });
  const { data } = await api.get("/attendances", {
    params: params,
    headers,
  });
  return {
    rows: data.results,
    pageCount: data.page,
    total: data.total,
  };
}

interface Attendance {
  id: number;
  clock_in: string;
  employee: any;
  status: string;
  created_at: string;
  clock_out: string;
  duration: string;
  clock_in_status: string;
  clock_out_status: string;
  clock_out_early_duration: string;
  clock_in_late_duration: string;
  location: string;
}

const columnHelper = createColumnHelper<Attendance>();

const columns = [
  columnHelper.accessor("employee", {
    cell: (info) => {
      const names =
        info.row.original?.employee["first_name"] +
        " " +
        info.row.original?.employee["last_name"];
      return (
        <div className="flex items-center gap-3">
          <Avatar
            size="sm"
            rounded="full"
            src={info.row.original?.employee["photo"]}
            name={names}
          />
          <Link
            to={`?show_employee=${info.row.original?.employee["id"]}`}
            className="capitalize hover:underline text-slate-700 truncate"
          >
            {names}
          </Link>
        </div>
      );
    },
    header: () => "Employee",
    meta: {
      skeleton: () => {
        return (
          <div className="flex items-center gap-2">
            <div>
              <Skeleton className="w-[23px] h-[23px] rounded-full" />
            </div>
            <Skeleton className="w-[120px] h-3" />
          </div>
        );
      },
      allowFiltering: true,
      filterType: "select-async",
      loadFilterOptions: (inputValue) => {
        return api
          .get(`/employees?page_size=5`, {
            params: cleanObject({
              query: inputValue ? inputValue : undefined,
            }),
          })
          .then(({ data }) =>
            data.map((e) => ({
              label: e.first_name + " " + e.last_name,
              value: e.id,
              component: () => <UserLabel user={e} />,
            }))
          );
      },
    },
  }),
  columnHelper.accessor("clock_in", {
    cell: (info) => {
      return (
        <span className="capitalize text-[13px] truncate">
          {new Date(info.renderValue()).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </span>
      );
    },
    header: () => "Clock in",
  }),
  columnHelper.accessor("clock_out", {
    cell: (info) => (
      <span className="truncate capitalize">
        {info.renderValue()
          ? new Date(info.renderValue()).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })
          : "----"}
      </span>
    ),
    header: () => "Clock out",
  }),

  columnHelper.accessor("duration", {
    cell: (info) => (
      <span className="capitalize truncate">
        {formatDuration(info.renderValue())}
      </span>
    ),
    header: () => "Duration",
  }),
  columnHelper.accessor("clock_in_status", {
    cell: (info) => (
      <span className="capitalize truncate">{info.renderValue()}</span>
    ),
    header: () => "Clock in status",
  }),
  columnHelper.accessor("clock_out_status", {
    cell: (info) => (
      <span className="capitalize truncate">{info.renderValue()}</span>
    ),
    header: () => "Clock out status",
  }),
  columnHelper.accessor("clock_out_early_duration", {
    cell: (info) => (
      <span className="capitalize truncate">
        {formatDuration(info.renderValue())}
      </span>
    ),
    header: () => "Clock out early",
    meta: {
      hidden: true,
    },
  }),
  columnHelper.accessor("clock_in_late_duration", {
    cell: (info) => (
      <span className="capitalize truncate">
        {formatDuration(info.renderValue())}
      </span>
    ),
    header: () => "Clock in late",
    meta: {
      hidden: true,
    },
  }),
  columnHelper.accessor("location", {
    cell: (info) => (
      <span className="capitalize truncate">{info.renderValue()}</span>
    ),
    header: () => "Location",
    meta: {
      hidden: true,
    },
  }),
  columnHelper.accessor("created_at", {
    cell: (info) => (
      <span className="capitalize truncate">
        {info.renderValue()
          ? new Date(info.renderValue()).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })
          : "----"}
      </span>
    ),
    header: () => "Attendance date",
    meta: {
      hidden: true,
      allowFiltering: true,
      filterType: "date",
    },
  }),
];

const exportFormater = (e, original) => {
  const obj = e;
  if (Object.keys(obj).includes("Employee")) {
    obj["Employee"] =
      original["employee"]["first_name"] +
      " " +
      original["employee"]["last_name"];
  }
  return obj;
};
export default function DailyAttendance() {
  const actions = [];

  return (
    <div className="px-0">
      <div className="my-0">
        <Datatable
          columns={columns}
          actions={actions}
          exportFormater={exportFormater}
          name="attendances"
          loader={fetchData}
          //   filterable={false}
          default_filters={[
            {
              id: "created_at",
              value: new Date(),
            },
          ]}
          onMainActionClick={() => {}}
        />
      </div>
    </div>
  );
}
