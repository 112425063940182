import { Formik, useFormikContext } from "formik";
import Pannel from "../ui/Pannel";
import * as Yup from "yup";
import removeEmptyAndUndefined from "@/utils/removeEmptyAndUndefined";
import { useMutation } from "react-query";
import { api } from "@/lib/api";
import toast from "react-hot-toast";
import { useMemo, useRef } from "react";
import useConfirm from "@/hooks/useConfirm";
import Button from "../ui/Button";
import SubmitButton from "../forms/SubmitButton";
import AppFormField from "../forms/AppFormField";
import AppFormSelect from "../forms/AppFormSelect";
import AppFormDakepicker from "../forms/AppFormDatepicker";
import { employment_statuses, employment_types } from "@/data";
import AppFileUpload from "../forms/AppFileUpload";
import useLocations from "@/hooks/useLocations";
import { countries } from "@/data/countries";
import DiscardModal from "../modals/DiscardModal";
import { v4 as uuidv4 } from "uuid";
import { MinusCircle, PlusCircle } from "react-feather";
import AppFormCreatableSelect from "../forms/AppFormCreatableSelect";
import AppFormAsyncSelect from "../forms/AppFormAsyncSelect";
import UserLabel from "../UserLabel";
import employeesLoader from "@/utils/employeesLoader";

export default function EmployeeFormPannel({
  employee,
  children,
  onClose,
  onComplete,
  ...other
}: any) {
  const mutation = useMutation({
    mutationFn: (record) => {
      return employee
        ? api.put(`/employees/${employee.id}`, record)
        : api.post("/employees", record);
    },
  });

  const handleSubmit = async (values: any) => {
    const data: any = removeEmptyAndUndefined({
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      national_id: values.national_id,
      birth_date: values.birth_date,
      father_names: values.father_names,
      mother_names: values.mother_names,
      departmentId: values.department,
      social_security_number: values.social_security_number,
      phone: values.phone,
      gender: values.gender,
      permanent_address: {
        country: values.permanent_address.country,
        province: values.permanent_address.province,
        district: values.permanent_address.district,
        sector: values.permanent_address.sector,
        cell: values.permanent_address.cell,
        village: values.permanent_address.village,
        street_address: values.permanent_address.street_address,
      },
      present_address: {
        country: values.present_address.country,
        province: values.present_address.province,
        district: values.present_address.district,
        sector: values.present_address.sector,
        cell: values.present_address.cell,
        village: values.present_address.village,
        street_address: values.present_address.street_address,
      },
      contact_person: {
        names: values.contact_person_names,
        email: values.contact_person_email,
        phone: values.contact_person_phone,
        address: values.contact_person_address,
      },
      marital_status: values.marital_status,
      job_title: values.job_title,
      role: values.role,
      salary: Number(values.salary),
      employment_type: values.employment_type,
      employment_status: values.employment_status,
      managerId: values.manager?.value,
      employee_contract: values.employee_contract,
      cv: values.cv,
      children: values?.children || [],
      birth_certificate: values?.birth_certificate || "",
      national_id_photo: values?.national_id_photo || "",
      passport_photo: values?.passport_photo || "",
      proffessional_certificate: values?.proffessional_certificate || "",
      degree_certificate: values?.degree_certificate || "",
      criminal_record: values?.criminal_record || "",
      sbn: values?.sbn || "",
    });

    await mutation.mutateAsync(data, {
      onError: (error: any) => {
        toast.error(error.response.data.message || error.message);
      },
      onSuccess: () => {
        toast.success(`Employee saved sucess`);
        onClose();
        onComplete();
      },
    });
  };

  const phone_pattern =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const schema = Yup.object().shape({
    first_name: Yup.string().required().label("first name"),
    last_name: Yup.string().required().label("last name"),
    email: Yup.string().email().required(),
    phone: Yup.string()
      .matches(phone_pattern, "Phone number is not valid")
      .required(),
    national_id: Yup.string().min(16).max(16).required().label("national id"),
    birth_date: Yup.string().required().label("birth date"),
    gender: Yup.string().required(),
    marital_status: Yup.string().required().label("marital status"),
    father_names: Yup.string().required().label("father names"),
    mother_names: Yup.string().required().label("mother names"),
    contact_person_names: Yup.string().required().label("contact person names"),
    contact_person_email: Yup.string()
      .required()
      .email()
      .label("contact person email"),
    contact_person_phone: Yup.string()
      .matches(phone_pattern, "Phone number is not valid")
      .required()
      .label("contact person phone"),
    contact_person_address: Yup.string()
      .required()
      .label("contact person address"),
    permanent_address: Yup.object().shape({
      country: Yup.string().label("country"),
      province: Yup.string().label("province"),
      district: Yup.string().label("district"),
      sector: Yup.string().label("sector"),
      cell: Yup.string().label("cell"),
      village: Yup.string().label("village"),
      street_address: Yup.string().label("street address"),
    }),
    present_address: Yup.object().shape({
      country: Yup.string().label("country"),
      province: Yup.string().label("province"),
      district: Yup.string().label("district"),
      sector: Yup.string().label("sector"),
      cell: Yup.string().label("cell"),
      village: Yup.string().label("village"),
      street_address: Yup.string().label("street address"),
    }),
    department: Yup.string(),
    job_title: Yup.string().required().label("job titlee"),
    role: Yup.string().required(),
    employment_type: Yup.string().required().label("employment type"),
    employment_status: Yup.string().required().label("employment status"),
    manager: Yup.object({
      value: Yup.number().required(),
      label: Yup.string().required(),
    }),
    social_security_number: Yup.string()
      .required()
      .label("social security number"),
    salary: Yup.number().required(),
    employee_contract: Yup.string().label("contract"),
    cv: Yup.string().label("cv"),
    birth_certificate: Yup.string(),
    national_id_photo: Yup.string(),
    passport_photo: Yup.string(),
    proffessional_certificate: Yup.string(),
    degree_certificate: Yup.string(),
    criminal_record: Yup.string(),
    sbn: Yup.string(),
    children: Yup.array().of(
      Yup.object().shape({
        names: Yup.string().required().label("names"),
        id: Yup.string().required(),
        gender: Yup.string().required().label("gender"),
        birth_certificate: Yup.string().required().label("birth certificate"),
      })
    ),
  });

  const initialValues = useMemo(() => {
    return {
      first_name: employee?.first_name || "",
      last_name: employee?.last_name || "",
      email: employee?.email || "",
      phone: employee?.phone || "",
      national_id: employee?.national_id || "",
      birth_date: employee?.birth_date || "",
      gender: employee?.gender || "",
      marital_status: employee?.marital_status || "",
      father_names: employee?.father_names || "",
      mother_names: employee?.mother_names || "",
      contact_person_names: employee?.contact_person?.names || "",
      contact_person_email: employee?.contact_person?.email || "",
      contact_person_phone: employee?.contact_person?.phone || "",
      contact_person_address: employee?.contact_person?.address || "",
      permanent_address: {
        country: employee?.permanent_address?.country || "",
        province: employee?.permanent_address?.province || "",
        district: employee?.permanent_address?.district || "",
        sector: employee?.permanent_address?.sector || "",
        cell: employee?.permanent_address?.cell || "",
        village: employee?.permanent_address?.village || "",
        street_address: employee?.permanent_address?.street_address || "",
      },
      present_address: {
        country: employee?.present_address?.country || "",
        province: employee?.present_address?.province || "",
        district: employee?.present_address?.district || "",
        sector: employee?.present_address?.sector || "",
        cell: employee?.present_address?.cell || "",
        village: employee?.present_address?.village || "",
        street_address: employee?.present_address?.street_address || "",
      },
      department: employee?.department?.id || "",
      job_title: employee?.job_title || "",
      role: employee?.role || "",
      employment_type: employee?.employment_type || "",
      employment_status: employee?.employment_status || "",
      manager: employee?.manager
        ? {
            label:
              employee.manager.first_name + " " + employee.manager.last_name,
            value: employee.manager.id,
            component: () => <UserLabel user={employee.manager} />,
          }
        : undefined,
      social_security_number: employee?.social_security_number || "",
      salary: employee?.salary || "",
      employee_contract: employee?.employee_contract || "",
      cv: employee?.cv || "",
      children: employee?.children || [],
      birth_certificate: employee?.birth_certificate || "",
      national_id_photo: employee?.national_id_photo || "",
      passport_photo: employee?.passport_photo || "",
      proffessional_certificate: employee?.proffessional_certificate || "",
      degree_certificate: employee?.degree_certificate || "",
      criminal_record: employee?.criminal_record || "",
      sbn: employee?.sbn || "",
    };
  }, [employee]);

  async function departmentsLoader() {
    const { data } = await api.get("/departments");
    return {
      items: data.map((e) => ({ label: e.name, value: e.id })),
    };
  }

  const formRef = useRef<any>();

  const confirmDiscard = useConfirm({
    onAccept: () => {
      onClose();
      formRef.current?.resetForm();
    },
  });

  const handleClose = ({ touched }) => {
    if (Object.keys(touched)?.length) {
      confirmDiscard.open();
    } else {
      onClose();
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      {(e) => (
        <>
          <Pannel
            onClose={() => handleClose(e)}
            {...other}
            title={"Create a new employee"}
          >
            <div className="h-0 flex-1 overflow-y-auto">
              <div className="flex flex-1 flex-col justify-between">
                <div className="divide-y pb-4 divide-gray-200 px-4 sm:px-6">
                  <div className="mt-3 px-[2px]">
                    <div>
                      <div>
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          Personal Information
                        </h4>
                      </div>
                      <div className="grid mt-2 grid-cols-2 gap-3">
                        <AppFormField
                          name="first_name"
                          label="First  name"
                          placeholder="First name"
                        />
                        <AppFormField
                          name="last_name"
                          label="Last  name"
                          placeholder="Last name"
                        />
                      </div>
                      <div className="grid mt-0 grid-cols-1 gap-3">
                        <AppFormField
                          name="email"
                          label="Email"
                          placeholder="Email"
                        />
                      </div>
                      <div className="grid mt-0 grid-cols-2 gap-3">
                        <AppFormField
                          name="phone"
                          label="Phone"
                          placeholder="Phone"
                        />
                        <AppFormField
                          name="national_id"
                          label="National id"
                          type="number"
                          placeholder="National id"
                        />
                      </div>
                      <div className="grid mt-0 grid-cols-2 gap-3">
                        <AppFormDakepicker
                          name="birth_date"
                          label="Birth date"
                          placeholder="Choose Birth date"
                        />

                        <AppFormSelect
                          placeholder="Choose Gender"
                          label="Gender"
                          name="gender"
                          options={[
                            { label: "Male", value: "male" },
                            {
                              label: "Female",
                              value: "female",
                            },
                            {
                              label: "Other",
                              value: "other",
                            },
                          ]}
                        />
                      </div>
                      <div className="w-full">
                        <AppFormSelect
                          name="marital_status"
                          label="marital status"
                          placeholder="Choose Marital status"
                          options={[
                            {
                              label: "Single",
                              value: "single",
                            },
                            {
                              label: "Maried",
                              value: "married",
                            },
                            {
                              label: "Divorced",
                              value: "divorced",
                            },
                            {
                              label: "Widowed",
                              value: "windowed",
                            },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <div>
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          Other Basic Information
                        </h4>
                      </div>
                      <div className="grid mt-2 grid-cols-2 gap-3">
                        <AppFormField
                          name="father_names"
                          label="Father names"
                          placeholder="Father names"
                        />
                        <AppFormField
                          name="mother_names"
                          label="Mother names"
                          placeholder="Mother names"
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <div>
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          Contact Person.
                        </h4>
                      </div>
                      <div className="grid mt-2 grid-cols-2 gap-3">
                        <AppFormField
                          name="contact_person_names"
                          label="Contact person names"
                          placeholder="Contact names"
                        />
                        <AppFormField
                          name="contact_person_phone"
                          label="Contact person Phone"
                          placeholder="contact phone"
                          // type="number"
                        />
                      </div>
                      <div className="grid mt-2 grid-cols-2 gap-3">
                        <AppFormField
                          name="contact_person_email"
                          label="contact person email"
                          placeholder="Contact email"
                        />
                        <AppFormField
                          name="contact_person_address"
                          label="contact person address"
                          placeholder="Contact address"
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <div>
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          Permanent Address
                        </h4>
                        <AddressForm name={"permanent_address"} />
                      </div>
                    </div>
                    <div className="mt-4">
                      <div>
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          Present Address
                        </h4>
                        <AddressForm name={"present_address"} />
                      </div>
                    </div>
                    <div className="mt-4">
                      <div>
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          Employment Information
                        </h4>
                        <div className=" mt-2 grid grid-cols-2 gap-3">
                          <AppFormField
                            name="job_title"
                            label="Job title"
                            placeholder="Enter your Job title"
                          />
                          <AppFormSelect
                            name="role"
                            label="Select role"
                            placeholder="Enter role"
                            options={[
                              { label: "admin", value: "admin" },
                              { label: "hr manager", value: "hr-manager" },
                              {
                                label: "project manager",
                                value: "project-manager",
                              },
                              {
                                label: "training manager",
                                value: "training-manager",
                              },
                              { label: "employee", value: "employee" },
                              {
                                label: "finance manager",
                                value: "finance-manager",
                              },
                            ]}
                          />
                        </div>
                        <div className="grid mt-2 grid-cols-2 gap-3">
                          <AppFormSelect
                            name="department"
                            label="Department"
                            placeholder="Choose department"
                            loader={departmentsLoader}
                          />
                          <AppFormSelect
                            name="employment_type"
                            label="Employment type"
                            placeholder="Employment type"
                            options={employment_types}
                          />
                        </div>
                        <div className="grid mt-2 grid-cols-2 gap-3">
                          <AppFormSelect
                            name="employment_status"
                            label="Employment status"
                            placeholder="Choose Employment status"
                            options={employment_statuses}
                          />
                          <AppFormAsyncSelect
                            name="manager"
                            label="Manager"
                            placeholder="Choose Manger"
                            loadOptions={employeesLoader}
                          />
                        </div>
                        <div className="grid mt-2 grid-cols-1 gap-3">
                          <AppFormField
                            name="social_security_number"
                            label="Social security number"
                            placeholder="Social security number"
                          />
                        </div>
                        <div className="grid mt-1 grid-cols-1 gap-3">
                          <AppFormField
                            name="salary"
                            label="Net salary"
                            placeholder="Enter Net salary"
                            type="number"
                          />
                          <AppFormField
                            name="sbn"
                            label="SBN number"
                            placeholder="SBN (number)"
                            type="number"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <Childrens />
                    </div>
                    <div className="mt-4">
                      <h4 className="text-[12px] uppercase font-medium text-slate-500">
                        Employee Contract
                      </h4>
                      <div>
                        <AppFileUpload
                          label={"Drag and drop employee contract here"}
                          name={"employee_contract"}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <h4 className="text-[12px] uppercase font-medium text-slate-500">
                        Employee CV
                      </h4>
                      <div>
                        <AppFileUpload
                          label={"Drag and drop employee CV here"}
                          name={"cv"}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <h4 className="text-[12px] uppercase font-medium text-slate-500">
                        Birth certificate
                      </h4>
                      <div>
                        <AppFileUpload
                          label={"Drag and drop Birth certificate here"}
                          name={"birth_certificate"}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <h4 className="text-[12px] uppercase font-medium text-slate-500">
                        National ID Attachment
                      </h4>
                      <div>
                        <AppFileUpload
                          label={"Drag and drop National ID Attachment here"}
                          name={"national_id_photo"}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <h4 className="text-[12px] uppercase font-medium text-slate-500">
                        Passport ID Attachment
                      </h4>
                      <div>
                        <AppFileUpload
                          label={"Drag and drop Passport ID Attachment here"}
                          name={"passport_photo"}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <h4 className="text-[12px] uppercase font-medium text-slate-500">
                        Criminal Record Attachmen
                      </h4>
                      <div>
                        <AppFileUpload
                          label={
                            "Drag and drop Criminal Record Attachment here"
                          }
                          name={"criminal_record"}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <h4 className="text-[12px] uppercase font-medium text-slate-500">
                        Degree Attachment
                      </h4>
                      <div>
                        <AppFileUpload
                          label={"Drag and drop Degree Attachment here"}
                          name={"degree_certificate"}
                        />
                      </div>
                    </div>{" "}
                    <div className="mt-4">
                      <h4 className="text-[12px] uppercase font-medium text-slate-500">
                        Professional Certificate Attachment
                      </h4>
                      <div>
                        <AppFileUpload
                          label={
                            "Drag and drop Professional Certificate Attachment here"
                          }
                          name={"proffessional_certificate"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-2 flex-shrink-0 justify-end px-4 py-2">
              <Button
                onClick={() => {
                  formRef.current.resetForm();
                  handleClose(e);
                }}
                variant="default"
                size="sm"
              >
                Cancel
              </Button>
              <SubmitButton size="sm">Save employee</SubmitButton>
            </div>
          </Pannel>

          <DiscardModal state={confirmDiscard} />
        </>
      )}
    </Formik>
  );
}

function AddressForm({ name }) {
  const { values } = useFormikContext();
  const { provinces, districts, sectors, cells, villages, loading } =
    useLocations({
      province: values[name]["province"],
      district: values[name]["district"],
      sector: values[name]["sector"],
      cell: values[name]["cell"],
    });

  return (
    <>
      <div className="grid mt-2 grid-cols-2 gap-3">
        <AppFormCreatableSelect
          name={`${name}.country`}
          label="Country"
          placeholder="Choose country"
          options={countries.map((e) => ({
            label: e.name,
            value: e.name,
          }))}
        />
        <AppFormCreatableSelect
          name={`${name}.province`}
          label="Province"
          placeholder="Choose province"
          options={provinces.map((e) => ({
            label: e,
            value: e,
          }))}
          disabled={loading || !provinces?.length}
        />
      </div>{" "}
      <div className="grid mt-1 grid-cols-3 gap-3">
        <AppFormCreatableSelect
          name={`${name}.district`}
          label="district"
          placeholder="Choose district"
          options={districts.map((e) => ({
            label: e,
            value: e,
          }))}
          disabled={loading || !districts?.length}
        />
        <AppFormCreatableSelect
          name={`${name}.sector`}
          label="Sector"
          placeholder="Choose sector"
          options={sectors.map((e) => ({
            label: e,
            value: e,
          }))}
          disabled={loading || !sectors?.length}
        />
        <AppFormCreatableSelect
          name={`${name}.cell`}
          label="Cell"
          placeholder="Choose cell"
          options={cells.map((e) => ({
            label: e,
            value: e,
          }))}
          disabled={loading || !cells?.length}
        />
      </div>
      <div className="grid mt-2 grid-cols-2 gap-3">
        <AppFormCreatableSelect
          name={`${name}.village`}
          label="village"
          placeholder="Choose village"
          options={villages.map((e) => ({
            label: e,
            value: e,
          }))}
          disabled={loading || !villages?.length}
        />
        <AppFormField
          name={`${name}.street_address`}
          label="Street address"
          placeholder="Street address"
        />
      </div>{" "}
    </>
  );
}

function Childrens() {
  const { values, setFieldValue } = useFormikContext();
  return (
    <>
      <div className="flex items-center justify-between">
        <h4 className="text-[12px] uppercase font-medium text-slate-500">
          Dependent Information (Optional - Babe)
        </h4>
        <a
          onClick={() => {
            setFieldValue("children", [
              ...values["children"],
              {
                names: "",
                id: uuidv4(),
                gender: "",
                birth_certificate: "",
              },
            ]);
          }}
          className="text-[12.5px] cursor-pointer flex items-center gap-2 font-medium text-primary"
        >
          <PlusCircle size={14} />
          <span>Add Child</span>
        </a>
      </div>
      <div className="my-3">
        {values["children"]?.map((e, i) => {
          return (
            <div className="border mt-4 border-slate-200 px-3 py-1  rounded-md">
              <div className="grid grid-cols-2 gap-2">
                <AppFormField
                  placeholder="Full names"
                  name={`children[${i}].names`}
                  label="Full names"
                />
                <AppFormSelect
                  name={`children[${i}].gender`}
                  placeholder="Choose Gender"
                  label="Gender"
                  options={[
                    { label: "Male", value: "male" },
                    {
                      label: "Female",
                      value: "female",
                    },
                    {
                      label: "Other",
                      value: "other",
                    },
                  ]}
                />
                <div className="mt-0 col-span-2">
                  <h4 className="text-[12px] uppercase font-medium text-slate-500">
                    Birth certificate
                  </h4>
                  <div>
                    <AppFileUpload
                      label={"Drag and drop Birth certificate here"}
                      name={`children[${i}].birth_certificate`}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-end">
                <a
                  onClick={() => {
                    setFieldValue("children", [
                      values["children"].filter((i) => i.id !== e.id),
                    ]);
                  }}
                  className="text-[12.5px] mb-2 cursor-pointer flex items-center gap-2 font-medium text-primary"
                >
                  <MinusCircle size={14} />
                  <span>Remove Child</span>
                </a>
              </div>
            </div>
          );
        })}
        {!values["children"].length ? (
          <div className="border rounded-md flex items-center justify-center gap-3 flex-col border-slate-200 p-3">
            <h4 className="text-[13px] font-semibold leading-7">
              Add Employee children(Optional)
            </h4>
            <p className="text-[12.5px] font-medium text-center leading-7 text-slate-600">
              You can add the children of the employee by adding <br /> there
              info here.
            </p>
            <Button
              onClick={() => {
                setFieldValue("children", [
                  ...values["children"],
                  {
                    names: "",
                    id: uuidv4(),
                    gender: "",
                    birth_certificate: "",
                  },
                ]);
              }}
              variant="outline"
              size="sm"
            >
              Add children
            </Button>
          </div>
        ) : null}
      </div>
    </>
  );
}
