import { useAuth } from "@/context/auth.context";
import { cn } from "@/utils/cn";
import {
  Grid,
  User,
  Users,
  LogOut,
  Table,
  CheckSquare,
  Monitor,
  GitPullRequest,
  BarChart,
  Archive,
  Box,
} from "react-feather";
import { Link, useLocation } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { Fragment, useState } from "react";

import Avatar from "../ui/Avatar";
import ProfileDropdown from "../dropdowns/ProfileDropdown";
import { useOverlayTriggerState } from "react-stately";
import LogoutModal from "../modals/LogoutModal";
export default function Sidebar() {
  const groupLinks = [
    {
      name: "Menu",
      roles: [
        "admin",
        "hr-manager",
        "project-manager",
        "training-manager",
        "finance-manager",
        "employee",
      ],
      children: [
        {
          name: "Dashboard",
          icon: Grid,
          link: "/dashboard",
          roles: ["admin"],
        },
        {
          name: "Employee portal",
          icon: User,
          link: "/portal",
          roles: ["*"],
        },
      ],
    },
    {
      name: "HR Management",
      roles: ["admin", "hr-manager"],
      children: [
        {
          name: "Employees",
          icon: Users,
          link: "/hr/employees",
        },
        {
          name: "Departments",
          icon: Table,
          link: "/hr/departments",
        },
        {
          name: "Leaves & Holidays",
          icon: LogOut,
          link: "/hr/leaves",
        },

        {
          name: "Time & Attendance",
          icon: CheckSquare,
          link: "/hr/attendance",
        },
        {
          name: "Staff Appraisal",
          icon: BarChart,
          link: "/hr/perfomance",
        },
      ],
    },
    {
      roles: [
        "admin",
        "hr-manager",
        "project-manager",
        "training-manager",
        "employee",
      ],
      name: "Project Management",
      children: [
        {
          name: "Projects",
          icon: Archive,
          link: "/projects",
        },
        {
          name: "Tenders",
          icon: GitPullRequest,
          link: "/tenders",
          roles: ["admin", "project-manager"],
        },
      ],
    },
    {
      name: "Training Management",
      roles: ["admin", "training-manager"],
      children: [
        {
          name: "Trainings",
          icon: Monitor,
          link: "/trainings",
        },
      ],
    },
    {
      name: "Finance",
      roles: ["admin", "finance-manager"],
      children: [
        {
          name: "Requisitions",
          icon: Box,
          link: "/finance/requisitions",
        },
      ],
    },
  ];

  const location = useLocation();

  const [showProfile, setshowProfile] = useState(false);

  const { user }: any = useAuth();

  let logoutModal = useOverlayTriggerState({});

  console.log(user);

  return (
    <Fragment>
      <div className="fixed z-50 flex flex-col border-r border-slate-200 h-screen w-[280px] bg-white ">
        <div className="px-4 py-4">
          <Link to={"/dashboard"}>
            <img className="h-6" src="/dnr-logo.jpeg" alt="" />
          </Link>
        </div>
        <div className="flex-1 border-t pt-1 border-slate-200 max-h-[90vh]">
          <SimpleBar style={{ maxHeight: "83vh" }} className="">
            {groupLinks
              .filter((e) => {
                return e.roles.includes(user.role);
              })
              .map((group, index) => (
                <div key={index}>
                  <h1 className="px-3 tracking-wide py-2 text-xs font-medium text-slate-500 uppercase">
                    {group.name}
                  </h1>
                  <div className="border-t- mx-2 border-slate-200">
                    {group.children.map((link, index) => {
                      const show = link.roles
                        ? link.roles.includes(user.role) ||
                          link.roles.includes("*")
                        : true;
                      return show ? (
                        <Link
                          to={link.link}
                          key={index}
                          className={cn(
                            "px-3 py-3 my-1 cursor-pointer text-[13px] border-transparent border font-medium text-slate-700 capitalize flex items-center gap-3 ",
                            {
                              "bg-primary bg-opacity-5 border text-primary text-opacity-75 rounded-md border-primary border-opacity-20":
                                location.pathname
                                  .split("/")
                                  .slice(0, 3)
                                  .join("/") === link.link,
                              "hover:bg-slate-100 hover:border-slate-200 ":
                                location.pathname
                                  .split("/")
                                  .slice(0, 3)
                                  .join("/") !== link.link,
                            }
                          )}
                        >
                          <link.icon size={17} />
                          <span>{link.name}</span>
                        </Link>
                      ) : null;
                    })}
                  </div>
                </div>
              ))}
          </SimpleBar>
        </div>

        <div className="border-t  relative border-slate-200 shadow-md">
          <ProfileDropdown
            onLogout={() => {
              logoutModal.open();
            }}
            close={() => setshowProfile(false)}
            open={showProfile}
          />
          <div className="flex px-[6px] py-[6px] gap-2">
            <div
              onClick={() => {
                setshowProfile(true);
              }}
              className="hover:bg-slate-100 px-[6px] hover:bg-opacity-60 cursor-pointer hover:border-slate-200 rounded-md border border-transparent py-[6px] items-center justify-between w-full flex "
            >
              <div className="flex items-center gap-2">
                <Avatar
                  name={user.names}
                  src={user.photo}
                  rounded="full"
                  size="md"
                />
                <div className="space-y-[2px]">
                  <h4 className="text-[13px] font-semibold capitalize text-slate-700">
                    {user.names}
                  </h4>
                  <p className="text-[12px]  text-slate-500 capitalize font-medium">
                    {user.role}
                  </p>
                </div>
              </div>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setshowProfile(!showProfile);
                }}
                className="h-8 w-8 border border-transparent hover:border-slate-200 cursor-pointer hover:bg-slate-100 rounded-md flex justify-center items-center"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  className="text-slate-400 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.1029 7.30379C15.3208 7.5974 15.2966 8.01406 15.0303 8.28033C14.7374 8.57322 14.2626 8.57322 13.9697 8.28033L10 4.31066L6.03033 8.28033L5.94621 8.35295C5.6526 8.5708 5.23594 8.5466 4.96967 8.28033C4.67678 7.98744 4.67678 7.51256 4.96967 7.21967L9.46967 2.71967L9.55379 2.64705C9.8474 2.4292 10.2641 2.4534 10.5303 2.71967L15.0303 7.21967L15.1029 7.30379ZM4.89705 12.6962C4.6792 12.4026 4.7034 11.9859 4.96967 11.7197C5.26256 11.4268 5.73744 11.4268 6.03033 11.7197L10 15.6893L13.9697 11.7197L14.0538 11.6471C14.3474 11.4292 14.7641 11.4534 15.0303 11.7197C15.3232 12.0126 15.3232 12.4874 15.0303 12.7803L10.5303 17.2803L10.4462 17.3529C10.1526 17.5708 9.73594 17.5466 9.46967 17.2803L4.96967 12.7803L4.89705 12.6962Z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <LogoutModal state={logoutModal} />
    </Fragment>
  );
}
