import { useRef } from "react";
import { Search } from "react-feather";
import DropdownTransition from "../DropdownTransition";
import { useOnClickOutside } from "usehooks-ts";
import { cn } from "@/utils/cn";
import AsyncSelect from "react-select/async";
import debounce from "lodash.debounce";
import { components } from "react-select";

const controlStyles = {
  base: "border rounded-md bg-white hover:cursor-pointer px-1 py-[2px]",
  focus: "border-primary ring-1- ring-primary-",
  nonFocus: "border-gray-300 hover:border-gray-400",
};
const placeholderStyles =
  "text-gray-500 !text-[12.3px] font-medium pl-1 py-0.5";
const selectInputStyles =
  "pl-1 !text-[12.3px] text-slate-500 font-medium py-0.5";
const valueContainerStyles = "p-1 !pl-[2px] gap-1";
const singleValueStyles =
  "leading-7 text-[13px] text-slate-500 font-medium ml-1";
const multiValueStyles =
  "bg-gray-100 rounded items-center py-0.5 pl-2 pr-1 gap-1.5";
const multiValueLabelStyles = "leading-6 py-0.5";
const multiValueRemoveStyles =
  "border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md";
const indicatorsContainerStyles = "p-1 flex items-center gap-2";
const clearIndicatorStyles =
  "text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800";
const indicatorSeparatorStyles = "bg-gray-300 mt-[4px] h-[18px]";
const dropdownIndicatorStyles =
  "p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black";
const menuStyles = "px-2";
const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
const optionStyles = {
  base: "hover:cursor-pointer my-1 flex text-slate-500 capitalize font-medium !text-[12.3px] px-3 py-[6px] rounded",
  focus: "bg-gray-100 active:bg-gray-200",
  selected:
    "after:content-['✔'] !flex items-center !bg-slate-100 after:ml-2 after:text-green-500 text-gray-500",
};
const noOptionsMessageStyles =
  "text-gray-500 p-2 text-[12.5px] font-medium py-3 rounded-sm";

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: 240,
    margin: 8,
  }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
};

function Option(props) {
  return (
    <>
      {props.data.component ? (
        <components.Option {...props}>
          <props.data.component />
        </components.Option>
      ) : (
        <components.Option {...props} />
      )}
    </>
  );
}
const PopoutSelect = ({ isOpen, onClose, loader, value, onChange }) => {
  const ref = useRef(null);

  const handleClickOutside = () => {
    onClose();
  };
  useOnClickOutside(ref, handleClickOutside);

  const loaderDebounced = loader ? debounce(loader, 1000) : undefined;

  return (
    <DropdownTransition open={isOpen}>
      <div
        ref={ref}
        className="absolute left-0 w-fit rounded-md top-9 z-50 shadow-sm  border border-slate-200 gap-2 bg-white"
      >
        <AsyncSelect
          autoFocus
          cacheOptions
          defaultOptions
          backspaceRemovesValue={false}
          components={{ DropdownIndicator, IndicatorSeparator: null, Option }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onChange={(newValue: any) => {
            onChange(newValue);
            onClose();
          }}
          loadOptions={(inputValue) => {
            return inputValue
              ? loaderDebounced(inputValue)
              : loader(inputValue);
          }}
          placeholder="Search here.."
          styles={selectStyles}
          tabSelectsValue={false}
          value={value}
          classNames={{
            control: ({ isFocused }) =>
              cn(
                isFocused ? controlStyles.focus : controlStyles.nonFocus,
                controlStyles.base
              ),
            placeholder: () => placeholderStyles,
            input: () => selectInputStyles,
            valueContainer: () => valueContainerStyles,
            singleValue: () => singleValueStyles,
            multiValue: () => multiValueStyles,
            multiValueLabel: () => multiValueLabelStyles,
            multiValueRemove: () => multiValueRemoveStyles,
            indicatorsContainer: () => indicatorsContainerStyles,
            clearIndicator: () => clearIndicatorStyles,
            indicatorSeparator: () => indicatorSeparatorStyles,
            dropdownIndicator: () => dropdownIndicatorStyles,
            menu: () => menuStyles,
            loadingMessage: () => "text-[12.5px] font-medium",
            groupHeading: () => groupHeadingStyles,
            option: ({ isFocused, isSelected }) =>
              cn(
                isFocused && optionStyles.focus,
                isSelected && optionStyles.selected,
                optionStyles.base
              ),
            noOptionsMessage: () => noOptionsMessageStyles,
          }}
        />
      </div>
    </DropdownTransition>
  );
};

export default PopoutSelect;

const DropdownIndicator = () => (
  <>
    <Search size={16} className="text-slate-700" />
  </>
);
