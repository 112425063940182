import Datatable from "@/components/Datatable";
import DashboardCard from "@/components/cards/DashboardCard";
import {
  Edit3,
  LogOut,
  PlusCircle,
  User,
  UserCheck,
  UserX,
  Users,
} from "react-feather";
import { createColumnHelper } from "@tanstack/table-core";
import { api } from "@/lib/api";
import Avatar from "@/components/ui/Avatar";
import Button from "@/components/ui/Button";
import { employment_types, marital_statuses } from "@/data";
import { useQuery, useQueryClient } from "react-query";
import { Fragment, useState } from "react";
import { useOverlayTriggerState } from "react-stately";
import { useEditRow } from "@/hooks/useEditRow";
import EmployeeFormPannel from "@/components/pannels/EmployeeFormPannel";
import Skeleton from "@/components/ui/Skeleton";
import { useNavigate } from "react-router-dom";

function truncateInMiddle(str, maxLength) {
  if (str.length <= maxLength) {
    return str; // No need to truncate
  }

  const ellipsis = "...";
  const mid = Math.floor(maxLength / 2);
  const start = str.slice(0, mid - Math.ceil(ellipsis.length / 2));
  const end = str.slice(-mid + Math.floor(ellipsis.length / 2));

  return start + ellipsis + end;
}

// creare a function to clear empty values from object
function clearEmptyValues(obj: any) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== "") {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

async function fetchData(
  {
    pageIndex: page,
    pageSize: page_size,
    sorting,
    query,
    ...rest
  }: {
    pageIndex: number;
    pageSize: number;
    query: string;
    sorting: any;
  },
  { headers }
) {
  const sort = sorting[0];
  let sort_by = sort ? `${sort.id}:${sort.desc ? "desc" : "asc"}` : undefined;
  if (sort?.id === "names") sort_by = sort_by.replace("names", "first_name");

  const filterObj = {
    permanent_address: {
      country: rest["permanent_address_country"],
    },
  };
  const filters = [
    "employment_type",
    "department",
    "employment_status",
    "created_at",
    "marital_status",
    "gender",
    "salary",
  ];

  filters.forEach((item) => {
    if (Object.keys(rest).includes(item) && rest[item]) {
      filterObj[item] = rest[item];
    }
  });

  const params = clearEmptyValues({
    page: page === 0 ? 1 : page,
    page_size,
    sort_by,
    query,
    ...filterObj,
    salary: filterObj["salary"] ? JSON.parse(filterObj["salary"]) : undefined,
  });
  const { data } = await api.get("/employees", {
    params: params,
    headers,
  });
  return {
    rows: data.results,
    pageCount: data.page,
    total: data.total,
  };
}

interface Employee {
  id: number;
  names: string;
  department: string;
  email: string;
  phone: string;
  address: string;
  created_at: string;
  role: string;
  updated_at: string;
  employment_status: string;
  employment_type: string;
  country: string;
  gender: string;
  marital_status: string;
  job_title: string;
  salary: number;
  permanent_address_country: string;
}

const columnHelper = createColumnHelper<Employee>();

const columns = [
  columnHelper.accessor("names", {
    cell: (info) => (
      <div className="flex items-center gap-3">
        <div>
          <Avatar
            src={info.row.original["photo"]}
            rounded="full"
            name={
              info.row.original["first_name"] +
              " " +
              info.row.original["last_name"]
            }
          />
        </div>
        <div>
          <h4 className="text-slate-800 capitalize leading-5 text-[12.5px] font-semibold">
            {info.row.original["first_name"] +
              " " +
              info.row.original["last_name"]}
          </h4>
          <span className="text-[12.5px]">
            {truncateInMiddle(info.row.original["email"], 25)}
          </span>
        </div>
      </div>
    ),
    header: () => "Names",
    meta: {
      allowSorting: true,
      default_sorting: "first_name",
      skeleton: () => {
        return (
          <div className="flex items-center gap-2">
            <div>
              <Skeleton className="w-[40px] h-[40px] rounded-full" />
            </div>
            <div className="flex flex-col  gap-3">
              <Skeleton className="w-[150px] h-3" />
              <Skeleton className="w-[110px] h-2" />
            </div>
          </div>
        );
      },
    },
  }),
  columnHelper.accessor("employment_type", {
    cell: (info) => (
      <span className="capitalize truncate">
        {employment_types.find((e) => e.value === info.renderValue())?.label}
      </span>
    ),
    header: () => "Employee type",
    meta: {
      allowFiltering: true,
      filterType: "select",
      filterOptions: employment_types,
    },
  }),
  columnHelper.accessor("department", {
    cell: (info) => (
      <span className="capitalize truncate">
        {(info.row.original["department"] &&
          info.row.original["department"]["name"]) ||
          "N/A"}
      </span>
    ),
    header: () => "Department",
    meta: {
      allowFiltering: true,
      filterType: "select",
      isAsync: true,
      loadFilterOptions: () => {
        console.log("loading departments");
        return api.get("/departments").then(({ data }) => {
          return data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
        });
      },
    },
  }),
  columnHelper.accessor("phone", {
    cell: (info) => (
      <span className="capitalize truncate">{info.getValue()}</span>
    ),
    header: () => "Phone number",
  }),
  columnHelper.accessor("address", {
    cell: (info) => (
      <span className="capitalize truncate">{info.renderValue()}</span>
    ),
    header: () => "Address",
    meta: {
      hidden: true,
    },
  }),
  columnHelper.accessor("role", {
    cell: (info) => (
      <span className="capitalize truncate">{info.renderValue() || "---"}</span>
    ),
    header: () => "Role",
  }),

  columnHelper.accessor("created_at", {
    cell: (info) => (
      <span className="truncate">
        {new Date(info.renderValue() as string).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </span>
    ),
    meta: {
      allowSorting: true,
      allowFiltering: true,
      filterType: "date",
    },
    header: () => "Joined at",
  }),
  columnHelper.accessor("permanent_address_country", {
    cell: (info) => (
      <span className="capitalize truncate">
        {info.row.original["permanent_address"]["country"]}
      </span>
    ),
    header: () => "country",
    meta: {
      hidden: true,
      allowFiltering: true,
      filterType: "select",
      filterOptions: [
        {
          label: "rwanda",
          value: "rwanda",
        },
        {
          label: "burundi",
          value: "burundi",
        },
      ],
    },
  }),
  columnHelper.accessor("marital_status", {
    cell: (info) => (
      <span className="capitalize truncate">{info.renderValue()}</span>
    ),
    header: () => "Marital status",
    meta: {
      hidden: true,
      allowFiltering: true,
      filterType: "select",
      filterOptions: marital_statuses,
    },
  }),
  columnHelper.accessor("job_title", {
    cell: (info) => (
      <span className="capitalize truncate">{info.renderValue()}</span>
    ),
    header: () => "Job title",
    meta: {
      hidden: true,
    },
  }),
  columnHelper.accessor("salary", {
    cell: (info) => (
      <span className="capitalize truncate">
        {info.renderValue()?.toLocaleString() || "---"} Frw
      </span>
    ),
    header: () => "Salary",
    meta: {
      allowSorting: true,
      hidden: true,
      allowFiltering: true,
      filterType: "range",
    },
  }),
  columnHelper.accessor("gender", {
    cell: (info) => (
      <span className="capitalize truncate">{info.renderValue()}</span>
    ),
    header: () => "Gender",
    meta: {
      hidden: true,
      allowFiltering: true,
      filterType: "select",
      filterOptions: [
        {
          label: "male",
          value: "male",
        },
        {
          label: "female",
          value: "female",
        },
      ],
    },
  }),
];

const exportFormater = (e, original) => {
  const obj = e;
  if (Object.keys(obj).includes("Names")) {
    obj["Names"] = original["first_name"] + " " + original["last_name"];
  }
  if (Object.keys(obj).includes("Department")) {
    obj["Department"] = original["department"]["name"];
  }
  return obj;
};
export default function Employees() {
  const editRow: any = useEditRow();

  const navigate = useNavigate();

  const actions = [
    {
      title: "View profile",
      onClick: (e) => {
        navigate({
          search: `?show_employee=${e.id}`,
        });
      },
      icon: User,
    },
    {
      title: "Update employee",
      onClick: (e) => {
        editRow.edit(e);
      },
      icon: Edit3,
    },
  ];

  async function fetchAnalytics() {
    const { data } = await api.get("/analytics", {
      params: {
        show: [
          "employees",
          "on-leave",
          "attendance-present",
          "attendance-absent",
        ],
      },
    });
    return data;
  }

  const { data: analytics } = useQuery(
    ["employees-analytics"],
    fetchAnalytics,
    {
      keepPreviousData: true,
      retry: false,
      staleTime: Infinity,
    }
  );

  const cards = [
    {
      name: "employees",
      title: "Total Employees",
      value: analytics ? analytics["employees"] : "---",
      icon: <Users size={15} className="text-blue-500" />,
      increase: 13.5,
      bgLight: "bg-blue-100",
    },
    {
      name: "leaves",
      title: "Employees on leave",
      value: analytics ? analytics["on-leave"] : "---",
      icon: <LogOut size={15} className="text-orange-500" />,
      increase: -10,
      link: "/hr/leaves",
      bgLight: "bg-orange-100",
    },
    {
      name: "present",
      title: "Today present",
      value: analytics ? analytics["attendance-present"] : "---",
      icon: <UserCheck size={15} className="text-green-500" />,
      increase: +70.5,
      bgLight: "bg-green-100",
    },
    {
      name: "absent",
      title: "Today absent",
      value: analytics ? analytics["attendance-absent"] : "---",
      icon: <UserX size={15} className="text-red-500" />,
      increase: +9.5,
      bgLight: "bg-red-100",
    },
  ];

  let employeeFormState = useOverlayTriggerState({});

  const queryClient = useQueryClient();

  const [key, setkey] = useState();

  return (
    <Fragment>
      <div className="px-2">
        <div className="my-1 flex items-center justify-between">
          <div className="space-y-1">
            <h3 className="text-[15px] font-semibold text-slate-700">
              Manage Employees!!
            </h3>
            <p className="text-[13.5px] leading-7 font-medium text-slate-500">
              Here you can manage all the employees.
            </p>
          </div>
          <div>
            <Button
              onClick={() => {
                employeeFormState.open();
              }}
              LeftIcon={PlusCircle}
              size="sm"
            >
              Create New Employee
            </Button>
          </div>
        </div>
        <div></div>
        <div className="mt-3">
          <div className="grid grid-cols-4 gap-3">
            {cards.map((item, index) => {
              return <DashboardCard item={item} key={index} />;
            })}
          </div>
        </div>

        <div className="my-4">
          <Datatable
            columns={columns}
            actions={actions}
            exportFormater={exportFormater}
            name="Employees"
            loader={fetchData}
            onMainActionClick={() => {}}
            onKeyChange={(e) => setkey(e)}
          />
        </div>
      </div>

      <EmployeeFormPannel
        onComplete={() => {
          queryClient.invalidateQueries({
            queryKey: key,
          });
          editRow.clear();
        }}
        open={employeeFormState.isOpen || editRow.isOpen}
        onClose={() => {
          employeeFormState.close();
          editRow.clear();
        }}
        employee={editRow.row}
      />
    </Fragment>
  );
}
