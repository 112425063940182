import { api } from "@/lib/api";
import axios from "axios";

export const handleUpload = async ({ file, key }: any) => {
  try {
    const signedURL = await api.post("/signed-url", {
      key: key,
      type: file?.type,
    });
    const formData = new FormData();
    // formData.append("Content-Type", file.type);
    Object?.entries(signedURL.data.fields).forEach(([key, value]: any) => {
      formData.append(key, value);
    });
    formData.append("file", file);

    return await axios
      .post(signedURL.data.url, formData)
      .then(() => {
        return "https://dnr-assets.s3.us-east-1.amazonaws.com/" + key;
      })
      .catch((e) => {
        console.log(e);
        throw Error(e.message);
      });
  } catch (error) {
    console.log(error);
    throw Error(error.message);
  }
};
