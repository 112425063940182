import { useFormikContext } from "formik";
import { RangeDatePicker } from "../ui/RangeDatePicker";
import { useMemo, useState } from "react";
import Input from "../ui/Input";
import { Calendar } from "react-feather";
import { get } from "lodash";

export default function AppFormRangeDatePicker({
  name,
  placeholder,
  ...other
}) {
  const { setFieldValue, values, errors, handleBlur, touched }: any =
    useFormikContext();
  const [focused, setFocused] = useState(false);

  const range = get(values, name);

  console.log(range);

  const valueToShow = useMemo(
    () =>
      range.start && range.end
        ? `${new Date(range?.start).toLocaleDateString()} - ${new Date(
            range?.end
          ).toLocaleDateString()}`
        : "",
    [range]
  );

  return (
    <div className="relative">
      <div className="relative">
        <Calendar size={16} className="absolute text-slate-600 right-3 top-6" />
        <Input
          value={valueToShow}
          onFocus={() => {
            setFocused(true);
          }}
          placeholder={placeholder}
        />
      </div>
      <RangeDatePicker
        isOpen={focused}
        onClose={() => {
          setFocused(false);
        }}
        className="top-14 left-0"
        errorMessage={errors[name] && touched[name] ? errors[name] : ""}
        onChange={(e) => {
          setFieldValue(name, e);
          setFocused(false);
        }}
        onBlur={handleBlur}
        value={range}
        name={name}
        {...other}
      />
    </div>
  );
}
