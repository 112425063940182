import { Formik, useFormikContext } from "formik";
import Modal from "../ui/Modal";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { api } from "@/lib/api";
import toast from "react-hot-toast";
import AppFormField from "../forms/AppFormField";
import Button from "../ui/Button";
import SubmitButton from "../forms/SubmitButton";
import { useMemo, useRef } from "react";
import AppFileUpload from "../forms/AppFileUpload";
import { useEmployee } from "@/context/employee.context";
import { PlusCircle, Trash } from "react-feather";
import AppFormCheckbox from "../forms/AppFormCheckbox";
import AppFormTextarea from "../forms/AppFormTextarea";
import AppFormRangeDatePicker from "../forms/AppFormRangeDatePicker";

function WeeklyPlanModal({ open, onClose, plan, onComplete }: any) {
  const schema = Yup.object().shape({
    title: Yup.string().required().label("title"),
    tasks: Yup.array(
      Yup.object({
        done: Yup.boolean().required(),
        title: Yup.string().required().label("Task title"),
        range: Yup.object({
          start: Yup.string().required(),
          end: Yup.string().required(),
        }),
        target: Yup.string().required().label("target"),
      })
    ),
    attachment: Yup.string().label("attachment"),
  });

  const { employee } = useEmployee();

  const initialValues = useMemo(() => {
    return {
      title: plan?.title || "",
      attachment: plan?.attachment || "",
      tasks: plan?.tasks
        ? plan?.tasks
        : [
            {
              title: "",
              range: { start: "", end: "" },
              target: "",
              done: false,
            },
          ],
    };
  }, [plan]);

  const mutation = useMutation({
    mutationFn: (record) =>
      plan ? api.put(`/plans/${plan.id}`, record) : api.post(`/plans`, record),
  });

  const handleSubmit = async (values: any, { resetForm }) => {
    const data = {
      ...values,
      employeeId: employee.id,
    };
    await mutation.mutateAsync(data, {
      onError: (error: any) => {
        toast.error(error.response.data.message || error.message);
      },
      onSuccess: () => {
        toast.success(`Plan saved sucess`);
        onClose();
        onComplete();
        resetForm();
      },
    });
  };
  const formRef = useRef<any>();

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      <Modal
        title="Create a week plan"
        size="xl"
        showDividers={true}
        close={() => {
          onClose();
          formRef.current.resetForm();
        }}
        open={open}
        Actions={() => {
          return (
            <div className="w-full gap-3 justify-end flex">
              <Button
                onClick={() => {
                  onClose();
                  formRef.current.resetForm();
                }}
                size="sm"
                variant="default"
              >
                Cancel
              </Button>
              <SubmitButton size="sm">
                {plan ? "Update" : "Create"} plan
              </SubmitButton>
            </div>
          );
        }}
      >
        <div className="px-1 pb-2 mt-0">
          <div>
            <AppFormField
              name={"title"}
              placeholder="Enter Plan title"
              label="Plan title"
            />
          </div>
          <Tasks />

          <div className="mt-4">
            <h4 className="text-[12px] uppercase font-medium text-slate-500">
              Attachment file(optional)
            </h4>
            <div>
              <AppFileUpload
                label={"Drag and drop attachment file here"}
                name={"attachment"}
              />
            </div>
          </div>
        </div>
      </Modal>
    </Formik>
  );
}

export default WeeklyPlanModal;

function Tasks() {
  const { setFieldValue, values, errors }: any = useFormikContext();

  console.log(errors);
  console.log(values);
  const today = new Date();
  const currentDay = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

  // Calculate the minimum date (Monday) and maximum date (Friday) for the current week
  const minDate = new Date(today);
  minDate.setDate(today.getDate() - currentDay + 1);

  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() + (5 - currentDay));
  return (
    <div className="mt-1">
      <h4 className="text-[13px] font-medium text-slate-600 mb-2">Tasks</h4>
      <div className="border border-t bg-slate-50 border-slate-200 rounded-md pb-[6px] pt-[2px] py-[4px]">
        {values["tasks"].map((_, i) => {
          return (
            <div
              key={i}
              className="flex pb-3 px-3 first-of-type:border-t-0 border-t pt-2 items-start gap-1"
            >
              <div className="py-3">
                <AppFormCheckbox name={`tasks[${i}].done`} />
              </div>
              <div className="w-full">
                <div className="grid grid-cols-2 w-full gap-3  items-start">
                  <AppFormField
                    name={`tasks[${i}].title`}
                    placeholder="Enter Task title"
                    hideErrorMessage={true}
                  />
                  <AppFormRangeDatePicker
                    name={`tasks[${i}].range`}
                    placeholder="Start date - End date"
                    options={{ minDate, maxDate }}
                  />
                </div>
                <AppFormTextarea
                  name={`tasks[${i}].target`}
                  placeholder="Activity Target/deliverable"
                />
              </div>
              {values["tasks"].length > 1 ? (
                <a
                  className="text-primary py-2 cursor-pointer hover:bg-slate-200 rounded-md px-2 my-2"
                  onClick={() => {
                    setFieldValue(
                      "tasks",
                      values["tasks"].filter((_, ind) => ind !== i)
                    );
                  }}
                >
                  <Trash size={16} />
                </a>
              ) : null}
            </div>
          );
        })}
      </div>
      <div className="w-full flex justify-end">
        <a
          onClick={() => {
            setFieldValue("tasks", [
              ...values["tasks"],
              {
                title: "",
                range: { start: "", end: "" },
                target: "",
                done: false,
              },
            ]);
          }}
          className="text-primary pt-2 flex items-center gap-2 px-1 cursor-pointer text-[13px] font-medium "
        >
          <PlusCircle size={14} />
          <span>Add another</span>
        </a>
      </div>
    </div>
  );
}
