import AppForm from "@/components/forms/AppForm";
import AppFormField from "@/components/forms/AppFormField";
import AppFormStatus from "@/components/forms/AppFormStatus";
import SubmitButton from "@/components/forms/SubmitButton";
import Checkbox from "@/components/ui/Checkbox";
import authClient from "@/services/auth.service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import qs from "qs";
export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const redirect = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })?.redirect;
  const handleSubmit = (values: any, { setStatus }) => {
    return authClient
      .signIn({
        email: values.email,
        password: values.password,
      })
      .then(() => {
        navigate(redirect || "/dashboard");
      })
      .catch((err) => {
        setStatus({
          error: err.message || "Something went wrong",
        });
      });
  };
  return (
    <div className="max-w-lg border border-slate-200 overflow-hidden shadow-sm my-12 mx-auto bg-white rounded-md">
      <div className="flex justify-between items-center- bg-primary bg-opacity-10 gap-3">
        <div className="space-y-2 px-5 py-4">
          <h4 className="text-base font-semibold text-primary">
            Welcome Back !
          </h4>
          <p className="text-[13px] text-primary font-medium text-opacity-70">
            Sign in to continue to you DNR account.
          </p>
        </div>
        <img className="h-[115px] w-[190px]" src="/profile-img.png" alt="" />
      </div>
      <div className="px-6 py-4">
        <AppForm
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Invalid email address")
              .required("Email is required"),
            password: Yup.string()
              .required("Password is required")
              .min(6, "Password is too short - should be 6 chars minimum."),
          })}
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={handleSubmit}
        >
          <div>
            <AppFormStatus />
          </div>
          <div>
            <AppFormField
              name={"email"}
              placeholder="Enter your email"
              label="Enter your email"
            />

            <AppFormField
              name={"password"}
              placeholder="Enter your password"
              label="Enter your password"
              type="password"
            />
          </div>
          <div className="w-full mb-2">
            <Checkbox name="rememberMe">Remember me</Checkbox>
          </div>
          <div className="w-full">
            <SubmitButton fullWidth>Login your account</SubmitButton>
          </div>
          <div className="flex items-center justify-center w-full  py-3">
            <Link
              className="text-[13.5px] hover:underline text-slate-500 font-medium"
              to="/auth/forgot-password"
            >
              Forgot your password?
            </Link>
          </div>
        </AppForm>
      </div>
    </div>
  );
}
