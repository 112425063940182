import { cn } from "@/utils/cn";
import React from "react";

const getColumnsFromRows = (rows: any) => {
  return Object.keys(rows[0]);
};
export const ReportToPrint = React.forwardRef(({ rows }: any, ref: any) => {
  const columns = getColumnsFromRows(rows);
  return (
    <div className="p-3 " ref={ref}>
      <table className="w-full mt-8 bg-white border-black  border-collapse border">
        <thead>
          <tr className="bg-transparent">
            {columns.map((header, i) => {
              return (
                <th
                  className="text-left border-t truncate mt-1 border border-black  bg-opacity-70 text-[12px] capitalize py-[8px] font-semibold text-black px-3"
                  key={i}
                >
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((record, i) => (
            <tr key={i}>
              {columns.map((cell, i) => (
                <td className={cn("py-[7px] border border-black px-3")} key={i}>
                  <span className="text-[12px] font-medium text-black">
                    {record[cell]}
                  </span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default ReportToPrint;
