import { useRef } from "react";
import {
  useDatePickerState,
  useCalendarState,
  useDateFieldState,
} from "react-stately";

import { ArrowLeftCircle, ArrowRightCircle } from "react-feather";
import {
  useFocusRing,
  mergeProps,
  useCalendarGrid,
  useCalendar,
  useLocale,
  useCalendarCell,
  useButton,
  useDateField,
  useDatePicker,
  useDateSegment,
} from "react-aria";
import { usePopover, DismissButton, Overlay } from "@react-aria/overlays";
import {
  isSameDay,
  getDayOfWeek,
  getWeeksInMonth,
  parseDate,
  createCalendar,
} from "@internationalized/date";
import { useDialog } from "react-aria";
import React from "react";
import cleanObject from "@/utils/cleanObject";
import { getLocalTimeZone } from "@internationalized/date";
import { useOnClickOutside } from "usehooks-ts";
import DropdownTransition from "../DropdownTransition";
import { cn } from "@/utils/cn";

function formatDateToYYYYMMDD(date) {
  const dataToUse = new Date(date);
  const year = dataToUse.getFullYear();
  const month = String(dataToUse.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(dataToUse.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function NewDatePicker(props) {
  const newProps = cleanObject({
    ...props,
    value: props.value
      ? parseDate(formatDateToYYYYMMDD(props.value))
      : parseDate(formatDateToYYYYMMDD(new Date())),
    onChange: (e: any) => props.onChange(e.toDate()),
  });

  let state: any = useDatePickerState(newProps);

  let ref = useRef();
  let { calendarProps } = useDatePicker(newProps, state, ref);

  const containerRef = useRef(null);

  useOnClickOutside(containerRef, props.onClose);

  return (
    <DropdownTransition open={props.isOpen}>
      <div
        ref={containerRef}
        className={cn(
          "absolute p-2 w-fit bg-white z-50 border border-slate-200 shadow-lg rounded-md top-9 right-0",
          props.className
        )}
      >
        <Calendar {...calendarProps} />
        <div className="text-center px-2 pt-1 w-full flex justify-between items-center text-[13px] my-2 font-medium">
          <span className="cursor-pointer text-slate-500 " onClick={() => {}}>
            {state.value ? (
              <>
                {new Date(
                  state.value.toDate(getLocalTimeZone())
                ).toLocaleDateString("en-US", {
                  month: "long",
                  day: "2-digit",
                  year: "numeric",
                })}
              </>
            ) : (
              <></>
            )}
          </span>
          <a
            onClick={() => {
              props.onClear(undefined);
            }}
            className="cursor-pointer underline text-slate-500 text-[13px]"
          >
            Clear values
          </a>
        </div>
      </div>
    </DropdownTransition>
  );
}

export function Calendar(props) {
  let { locale } = useLocale();
  let state = useCalendarState({
    ...props,
    locale,
    createCalendar,
  });

  let ref: any = useRef();
  let { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(
    props,
    state,
    // @ts-ignore
    ref
  );

  return (
    <div {...calendarProps} ref={ref} className="inline-block text-gray-800">
      <div className="flex items-center justify-between pb-2">
        <CalendarButton {...prevButtonProps}>
          <ArrowLeftCircle className="h-5 w-5" />
        </CalendarButton>
        <h2 className="font-semibold text-[13px] ml-2">{title}</h2>
        <CalendarButton {...nextButtonProps}>
          <ArrowRightCircle className="h-5 w-5" />
        </CalendarButton>
      </div>
      <CalendarGrid state={state} />
    </div>
  );
}

export function DateField(props) {
  let { locale } = useLocale();
  let state: any = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  });

  let ref = useRef();
  let { fieldProps } = useDateField(props, state, ref);

  return (
    <div {...fieldProps} ref={ref} className="flex">
      {state.segments.map((segment, i) => (
        <DateSegment key={i} segment={segment} state={state} />
      ))}
    </div>
  );
}

function DateSegment({ segment, state }) {
  let ref = useRef();
  let { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div
      {...segmentProps}
      ref={ref}
      style={{
        ...segmentProps.style,
        minWidth:
          segment.maxValue != null && String(segment.maxValue).length + "ch",
      }}
      className={`px-0.5 box-content tabular-nums text-right outline-none rounded-sm focus:bg-violet-600 focus:text-white group ${
        !segment.isEditable ? "text-gray-500" : "text-gray-800"
      }`}
    >
      {/* Always reserve space for the placeholder, to prevent layout shift when editing. */}
      <span
        aria-hidden="true"
        className="block w-full text-center italic text-gray-500 group-focus:text-white"
        style={{
          visibility: segment.isPlaceholder ? "visible" : "hidden",
          height: segment.isPlaceholder ? "" : 0,
          pointerEvents: "none",
        }}
      >
        {segment.placeholder}
      </span>
      {segment.isPlaceholder ? "" : segment.text}
    </div>
  );
}

export function Popover(props) {
  let ref = React.useRef(null);
  let { state, children } = props;

  let { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      popoverRef: ref,
    },
    state
  );

  return (
    <Overlay>
      <div {...underlayProps} className="fixed inset-0" />
      <div
        {...popoverProps}
        ref={ref}
        className="absolute top-full bg-white border border-gray-300 rounded-md shadow-lg mt-2 p-8 z-10"
      >
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
}

export function Dialog({ title, children, ...props }: any) {
  let ref = React.useRef();
  let { dialogProps } = useDialog(props, ref);

  return (
    <div {...dialogProps} ref={ref}>
      {children}
    </div>
  );
}

function CalendarButton(props) {
  let ref = useRef();
  let { buttonProps } = useButton(props, ref);
  let { focusProps, isFocusVisible } = useFocusRing();
  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      ref={ref}
      className={`p-2 rounded-md ${props.isDisabled ? "text-gray-400" : ""} ${
        !props.isDisabled
          ? "hover:bg-slate-200 hover:bg-opacity-60 active:bg-red-200"
          : ""
      } outline-none ${
        isFocusVisible ? "ring-2 ring-offset-2 ring-red-600" : ""
      }`}
    >
      {props.children}
    </button>
  );
}

export function CalendarGrid({ state, ...props }) {
  let { locale } = useLocale();
  let { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

  // Get the number of weeks in the month so we can render the proper number of rows.
  let weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <table {...gridProps} cellPadding="0" className="flex-1">
      <thead {...headerProps} className="text-gray-600">
        <tr>
          {weekDays.map((day, index) => (
            <th
              className="text-center py-2 text-[13px] font-semibold"
              key={index}
            >
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) =>
                date ? (
                  <CalendarCell key={i} state={state} date={date} />
                ) : (
                  <td className="text-sm" key={i} />
                )
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function CalendarCell({ state, date }) {
  let ref = useRef();
  let {
    cellProps,
    buttonProps,
    isSelected,
    isDisabled,
    formattedDate,
    isInvalid,
  } = useCalendarCell({ date }, state, ref);

  // The start and end date of the selected range will have
  // an emphasized appearance.
  let isSelectionStart = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.start)
    : isSelected;
  let isSelectionEnd = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.end)
    : isSelected;

  // We add rounded corners on the left for the first day of the month,
  // the first day of each week, and the start date of the selection.
  // We add rounded corners on the right for the last day of the month,
  // the last day of each week, and the end date of the selection.
  let { locale } = useLocale();
  let dayOfWeek = getDayOfWeek(date, locale);
  let isRoundedLeft =
    isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1);
  let isRoundedRight =
    isSelected &&
    (isSelectionEnd ||
      dayOfWeek === 6 ||
      date.day === date.calendar.getDaysInMonth(date));

  let { focusProps, isFocusVisible } = useFocusRing();

  return (
    <td
      {...cellProps}
      className={`p-0.5 relative ${isFocusVisible ? "z-10" : "z-0"}`}
    >
      <div
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        // hidden={isOutsideVisibleRange}
        className={`w-[38px] h-[38px] outline-none group ${
          isRoundedLeft ? "rounded-l-full" : ""
        } ${isRoundedRight ? "rounded-r-full" : ""} ${
          isSelected ? (isInvalid ? "bg-red-300" : "bg-violet-300") : ""
        } ${isDisabled ? "disabled" : ""}`}
      >
        <div
          className={`w-full h-full rounded-md cursor-pointer text-[12.5px] font-medium flex items-center justify-center ${
            isDisabled && !isInvalid ? "text-gray-400" : ""
          } ${
            // Focus ring, visible while the cell has keyboard focus.
            isFocusVisible
              ? "ring-2 group-focus:z-2 ring-red-600 ring-offset-2"
              : ""
          } ${
            // Darker selection background for the start and end.
            isSelectionStart || isSelectionEnd
              ? isInvalid
                ? "bg-red-600 text-white hover:bg-red-700"
                : "bg-red-600 text-white hover:bg-red-700"
              : ""
          } ${
            // Hover state for cells in the middle of the range.
            isSelected && !isDisabled && !(isSelectionStart || isSelectionEnd)
              ? isInvalid
                ? "hover:bg-red-400"
                : "hover:bg-red-400"
              : ""
          } ${
            // Hover state for non-selected cells.
            !isSelected && !isDisabled ? "hover:bg-red-100" : ""
          } cursor-default`}
        >
          {formattedDate}
        </div>
      </div>
    </td>
  );
}
