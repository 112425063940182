import { AriaTextFieldProps, useTextField } from "react-aria";
import React from "react";
import { cn } from "@/utils/cn";

export default function Textarea(props: AriaTextFieldProps) {
  let { label, errorMessage, ...other }: any = props;
  let ref = React.useRef(null);
  let { labelProps, inputProps, errorMessageProps } = useTextField(
    {
      ...props,
      inputElementType: "textarea",
    },
    ref
  );

  return (
    <div>
      <label
        className="block text-[13px] mb-1 capitalize font-medium text-slate-700"
        {...labelProps}
      >
        {label}
      </label>
      <div className="mt-1">
        <textarea
          ref={ref}
          className={cn(
            "shadow-sm text-slate-600 !leading-7 placeholder:text-slate-500 font-medium outline-none text-[12.5px] border px-3 py-[6px]  block w-full  rounded-md",
            {
              "focus:ring-red-500 focus:border-red-500 border border-red-500":
                errorMessage,
              "focus:ring-primary focus:border-primary border-gray-300 ":
                !errorMessage,
            }
          )}
          {...inputProps}
          {...other}
        />
      </div>

      {errorMessage && (
        <div
          {...errorMessageProps}
          className="block text-[11.8px] mt-1 capitalize font-medium text-red-500"
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
}
