import { Outlet } from "react-router";
import { Navigate } from "react-router-dom";
import { useAuth } from "@/context/auth.context";
import Sidebar from "../sections/Sidebar";
import { useEmployee } from "@/context/employee.context";
import EmployeeNotFound from "@/pages/EmployeeNotFound";
import EmployeeProfilePannel from "../pannels/EmployeeProfilePannel";
import { useLocation } from "react-router-dom";

export default function Layout() {
  const { user, loading } = useAuth();
  const { employee, loading: loadingEmployee } = useEmployee();

  const location = useLocation();

  if (loading || loadingEmployee) {
    return (
      <div className=" w-screen h-screen flex items-center justify-center flex-col gap-8">
        <img className="h-8 w-18" src="/dnr-logo.jpeg" alt="" />
        <div className="w-[150px]">
          <div className="w-full m-auto">
            <div className="progress-bar h-1 rounded-md bg-primary bg-opacity-25 w-full overflow-hidden">
              <div className="progress-bar-value w-full h-full bg-primary " />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (user && !loading) {
    return (
      <>
        {!employee && !loadingEmployee ? (
          <EmployeeNotFound />
        ) : (
          <>
            <Sidebar />
            <div className="pl-[280px] pt-0">
              <main className="p-3  max-w-7xl mx-auto min-w-[1000px]">
                <Outlet />
              </main>
            </div>
            <EmployeeProfilePannel />
          </>
        )}
      </>
    );
  }

  if (!user && !loading) {
    return <Navigate to={`/auth/login?redirect=${location.pathname}`} />;
  }
}
