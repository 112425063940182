import Modal from "../ui/Modal";
import Button from "../ui/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import authClient from "@/services/auth.service";
import { useQueryClient } from "react-query";

export default function LogoutModal({ state }) {
  const [logingOut, setlogingOut] = useState(false);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const handleLogout = () => {
    setlogingOut(true);
    return authClient
      .logout()
      .then(() => {
        setlogingOut(false);
        state.close();
        queryClient.clear();
        navigate("/auth/login");
      })
      .catch(() => {
        setlogingOut(false);
      });
  };
  return (
    <Modal
      Actions={() => {
        return (
          <div className="w-full flex items-center justify-end gap-3">
            <Button
              onClick={() => {
                state.close();
              }}
              variant="default"
              size="sm"
            >
              Cancel
            </Button>
            <Button onClick={handleLogout} loading={logingOut} size="sm">
              Yes, Logout
            </Button>
          </div>
        );
      }}
      title="Logout your account."
      size="md"
      showDividers={false}
      close={() => {
        state.close();
      }}
      open={state.isOpen}
    >
      <div className="px-1 mb-1">
        <span className="text-[13.5px] font-medium leading-8 text-slate-500">
          Are you shure you want to logout? click the <br /> button below to
          logout.
        </span>
      </div>
    </Modal>
  );
}
