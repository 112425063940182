import { api } from "@/lib/api";
import cleanObject from "./cleanObject";
import UserLabel from "@/components/UserLabel";

async function employeesLoader(inputValue, callback) {
  console.log(inputValue);
  const { data } = await api.get(`/employees`, {
    params: cleanObject({
      query: inputValue ? inputValue : undefined,
      page_size: inputValue ? 5 : 25,
    }),
  });
  return callback(
    data.map((e) => ({
      label: e.first_name + " " + e.last_name,
      value: e.id,
      component: () => <UserLabel user={e} />,
    }))
  );
}

export default employeesLoader;
