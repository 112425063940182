import { cn } from "@/utils/cn";
import { Link } from "react-router-dom";
import Skeleton from "../ui/Skeleton";

export default function DashboardCard({ item }) {
  const isLoading = item.value === "---";
  return (
    <div className="bg-white px-3 py-2 border rounded-md border-slate-200">
      <div className="flex items-center justify-between">
        <h4 className="text-xs uppercase font-semibold text-slate-500">
          {item.title}
        </h4>
      </div>
      <div className=" my-1 mt-3">
        {isLoading ? (
          <Skeleton className="w-12 mt-4 h-6" />
        ) : (
          <h3 className="text-[16px] font-semibold text-xl">{item.value}</h3>
        )}
      </div>

      <div className="flex items-center -mt-3 justify-between">
        <Link
          className={cn(
            "text-[13px] capitalize mt-4 text-slate-500 font-medium"
          )}
          to={item.link}
        >
          <span
            className={cn("capitalize", {
              underline: item.link,
            })}
          >
            {item.link && "View All"} {item.name}
          </span>
        </Link>
        <div
          className={cn(
            "h-9 w-9 rounded-md flex items-center justify-center",
            item.bgLight
          )}
        >
          {item.icon}
        </div>
      </div>
    </div>
  );
}
