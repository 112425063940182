import { useEffect, useState } from "react";
import Modal from "../ui/Modal";
import Button from "../ui/Button";
import { api } from "@/lib/api";
import toast from "react-hot-toast";
import { useEmployee } from "@/context/employee.context";
import formatPreviewLink from "@/utils/formatPreviewLink";

function isLink(str) {
  try {
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
}

function LeaveModal({
  leave: data,
  onClose,
  open,
  onAction,
  hideActions,
}: any) {
  const [leave, setLeave] = useState(data);

  const [rejecting, setrejecting] = useState(false);
  const [accepting, setaccepting] = useState(false);
  const { employee } = useEmployee();

  useEffect(() => {
    if (data) {
      setLeave(data);
    }
  }, [data]);

  const handleApprove = () => {
    let approve_reason = prompt("Approve reason");
    if (!approve_reason) return onClose();
    setaccepting(true);
    const newD = {
      ...leave,
      status: "approved",
      status_reason: approve_reason,
      approvedById: employee?.id,
      employeeId: leave?.employee?.id,
    };
    delete newD["id"];
    delete newD["employee"];
    delete newD["created_at"];
    delete newD["updated_at"];
    delete newD["approvedBy"];
    delete newD["days"];
    delete newD["attachment"];

    return api
      .put(`/leaves/${data.id}`, newD)
      .then(() => {
        toast.success("Leave approved success");
        onAction("approved");
        setaccepting(false);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message || e?.message);
      })
      .finally(() => {
        setaccepting(false);
      });
  };
  const handleReject = () => {
    let reject_reason = prompt("Reject reason");
    if (!reject_reason) return onClose();
    setrejecting(true);
    const newD = {
      ...leave,
      status: "rejected",
      status_reason: reject_reason,
      rejectedById: employee?.id,
      employeeId: leave?.employee?.id,
    };
    delete newD["id"];
    delete newD["employee"];
    delete newD["created_at"];
    delete newD["rejectedBy"];
    delete newD["updated_at"];
    delete newD["days"];
    delete newD["attachment"];
    return api
      .put(`/leaves/${data.id}`, newD)
      .then(() => {
        toast.success("Leave rejected success");
        onAction("rejected");
        setrejecting(false);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message || e?.message);
      })
      .finally(() => {
        setrejecting(false);
      });
  };

  return (
    <Modal
      title="Leave details."
      size="xl"
      showDividers={false}
      close={onClose}
      open={open}
    >
      <div className="grid px-[6px] gap-4 my-0 pt-0 grid-cols-2">
        {[
          {
            key: "Employee",
            value:
              leave?.employee["first_name"] +
              " " +
              leave?.employee["last_name"],
          },
          { key: "Type", value: leave?.type },
          {
            key: "Start",
            value: new Date(leave?.start_date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            }),
          },
          {
            key: "End",
            value: new Date(leave?.end_date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            }),
          },
          { key: "status", value: leave?.status },
          { key: "status", value: leave?.status },
          {
            key: "Requested at",
            value: new Date(leave?.created_at).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            }),
          },
          {
            key: "Approved by",
            value: leave?.approvedBy
              ? leave?.approvedBy["first_name"] +
                " " +
                leave?.approvedBy["last_name"]
              : "N/A",
          },
          {
            key: "Rejected by",
            value: leave?.rejectedBy
              ? leave?.rejectedBy["first_name"] +
                " " +
                leave?.rejectedBy["last_name"]
              : "N/A",
          },
          {
            key: "Attachment",
            value: leave?.attachment || "N/A",
          },
        ].map((e, i) => {
          return (
            <div className="flex  items-center gap-4" key={i}>
              <span className="text-[12.5px] font-medium capitalize text-slate-500">
                {e.key}:
              </span>
              {isLink(e.value) ? (
                <a
                  href={formatPreviewLink(e.value)}
                  target="_blank"
                  className="text-[12.5px] underline truncate font-semibold text-slate-700"
                >
                  {formatPreviewLink(e.value)}
                </a>
              ) : (
                <span className="text-[12.5px] truncate font-semibold capitalize text-slate-700">
                  {e.value}
                </span>
              )}
            </div>
          );
        })}
      </div>
      <div className="px-2 pb-2 mt-5">
        <h4 className="uppercase text-[12.5px] font-semibold text-slate-600">
          Leave Reason
        </h4>
        <p className="text-[13px] leading-7 font-medium text-slate-500 mt-1">
          {leave?.reason}
        </p>
      </div>

      {leave?.status_reason && (
        <div className="px-2 pb-2 mt-5">
          <h4 className="uppercase text-[12.5px] font-semibold text-slate-600">
            Reject/accept Reason
          </h4>
          <p className="text-[13px] leading-7 font-medium text-slate-500 mt-1">
            {leave?.status_reason}
          </p>
        </div>
      )}

      {leave?.status === "pending" && !hideActions && (
        <div className="mt-3 gap-3 px-1 flex justify-start">
          <Button
            loading={accepting}
            onClick={handleApprove}
            color="success"
            size="sm"
          >
            Approve
          </Button>
          <Button
            loading={rejecting}
            onClick={handleReject}
            color="danger"
            size="sm"
          >
            Reject
          </Button>
        </div>
      )}
    </Modal>
  );
}

export default LeaveModal;
