import Button from "@/components/ui/Button";
import { useState } from "react";
import { User } from "react-feather";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import authClient from "@/services/auth.service";

export default function EmployeeNotFound() {
  const [logingOut, setlogingOut] = useState(false);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const handleLogout = () => {
    setlogingOut(true);
    return authClient
      .logout()
      .then(() => {
        setlogingOut(false);
        queryClient.removeQueries();
        navigate("/auth/login");
      })
      .catch(() => {
        setlogingOut(false);
      });
  };
  return (
    <div>
      <div className="flex bg-white  max-w-2xl border border-slate-200 mx-auto my-12 items-center justify-center flex-col gap-4 py-24">
        <div className="bg-red-200 border-[6px] border-red-100 h-16 w-16 rounded-full flex items-center justify-center">
          <User size={19} className="text-red-500" />
        </div>
        <div className="flex flex-col gap-2 text-center">
          <h4 className="font-semibold text-[15px]">
            Employee Profile not available.
          </h4>
          <h4 className="font-medium text-slate-500 leading-7 text-[13px]">
            We can not load your employee Profile, please contact the
            admistration.
          </h4>
          <div className="mt-2">
            <Button onClick={handleLogout} loading={logingOut}>
              Logout your account
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
