import { ChevronLeft, ChevronRight, MoreHorizontal } from "react-feather";

function Pagination({
  page = 1,
  total = 50,
  size = 10,
  onChange,
}: {
  page?: number;
  total?: number;
  size?: number;
  onChange?: any;
}) {
  const pageCount = Math.max(1, Math.ceil(total / size));
  const isFirstPage = page === 1;
  const isLastPage = page === pageCount;

  const next = () => {
    onChange(page + 1);
  };

  const prev = () => {
    onChange(page - 1);
  };

  const formated = handlePaginationSlice({ page, pageCount });

  const handleCurrent = (item: any) => {
    if (typeof item === "number") {
      onChange(item);
    }
  };

  return (
    <nav className="flex items-center gap-4">
      <a
        onClick={prev}
        className={`${
          isFirstPage ? "pointer-events-none opacity-70" : ""
        } h-7 bg-slate-200 hover:bg-opacity-75 cursor-pointer transition-all w-7 flex items-center rounded-[3px] justify-center text-white text-sm`}
      >
        <ChevronLeft strokeWidth={2.5} className="text-slate-700 h-3 w-3" />
      </a>
      <div className="flex items-center gap-3">
        {formated.map((item: any, index: number) => (
          <a
            key={index}
            onClick={() => handleCurrent(item)}
            className={`${
              item === page
                ? "bg-primary bg-opacity-75 text-white"
                : "bg-transparent hover:bg-slate-200"
            } ${
              item === "dot" ? "pointer-events-none" : ""
            } h-7 hover:bg-opacity-75 cursor-pointer transition-all w-7 flex items-center rounded-[3px] justify-center text-slate-500 font-medium text-[12.5px]`}
          >
            {item === "dot" ? (
              <div>
                <MoreHorizontal className="w-5 h-5" />
              </div>
            ) : (
              <div>{item}</div>
            )}
          </a>
        ))}
      </div>
      <a
        onClick={next}
        className={`${
          isLastPage ? "pointer-events-none opacity-75" : ""
        } h-7 bg-slate-200 hover:bg-opacity-75 cursor-pointer transition-all w-7 flex items-center rounded-[3px] justify-center text-white text-sm`}
      >
        <ChevronRight strokeWidth={2.5} className="h-3 w-3 text-slate-600 0" />
      </a>
    </nav>
  );
}

export default Pagination;

const handlePaginationSlice = ({ pageCount, currentPage }: any) => {
  const siblings = 1;
  const boundaries = 1;
  function range(start: number, end: number) {
    const length = end - start + 1;
    return Array.from({ length }, (_, index) => index + start);
  }
  const DOTS = "dot";

  const totalPageNumbers = siblings * 2 + 3 + boundaries * 2;
  if (totalPageNumbers >= pageCount) {
    return range(1, pageCount);
  }

  const leftSiblingIndex = Math.max(currentPage - siblings, boundaries);
  const rightSiblingIndex = Math.min(
    currentPage + siblings,
    pageCount - boundaries
  );

  const shouldShowLeftDots = leftSiblingIndex > boundaries + 2;
  const shouldShowRightDots = rightSiblingIndex < pageCount - (boundaries + 1);

  if (!shouldShowLeftDots && shouldShowRightDots) {
    const leftItemCount = siblings * 2 + boundaries + 2;
    return [
      ...range(1, leftItemCount),
      DOTS,
      ...range(pageCount - (boundaries - 1), pageCount),
    ];
  }

  if (shouldShowLeftDots && !shouldShowRightDots) {
    const rightItemCount = boundaries + 1 + 2 * siblings;
    return [
      ...range(1, boundaries),
      DOTS,
      ...range(pageCount - rightItemCount, pageCount),
    ];
  }

  return [
    ...range(1, boundaries),
    DOTS,
    ...range(leftSiblingIndex, rightSiblingIndex),
    DOTS,
    ...range(pageCount - boundaries + 1, pageCount),
  ];
};
