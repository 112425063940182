import authService from "@/services/auth.service";
import axios from "axios";

const PUBLIC_API_URL = import.meta.env.VITE_PUBLIC_API_URL;

export const api = axios.create({
  baseURL: PUBLIC_API_URL,
});

// Request interceptor for API calls
api.interceptors.request.use(
  async (config) => {
    const token = await authService.getToken();
    // @ts-ignore
    config["headers"] = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "User-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...config["headers"],
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      console.log("---- refreshing the token -----");
      originalRequest._retry = true;
      const data = await authService.refresh_token();
      const access_token = data?.accessToken;
      api.defaults.headers["Authorization"] = "Bearer " + access_token;
      return api(originalRequest);
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use((config) => {
  if (config.params) {
    if (
      config.params["q"] ||
      config.params["query"] ||
      config.params["search"]
    ) {
      config.headers["Cache-Control"] = "no-cache";
    }
  }
  return config;
});
