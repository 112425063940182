import { create } from "zustand";

const create_confirm = create((set) => ({
  isOpen: false,
  cancel: () => set(() => ({ isOpen: false })),
  open: () => set(() => ({ isOpen: true })),
}));

const useConfirm = ({ onReject, onAccept }: any) => {
  const confirm: any = create_confirm();
  return {
    ...confirm,
    accept: () => {
      onAccept();
      confirm.cancel();
    },
    reject: () => {
      confirm.cancel();
      if (onReject) {
        onReject();
      }
    },
  };
};

export default useConfirm;
