import { Formik, useFormikContext } from "formik";
import Pannel from "../ui/Pannel";
import * as Yup from "yup";
import removeEmptyAndUndefined from "@/utils/removeEmptyAndUndefined";
import { useMutation } from "react-query";
import { api } from "@/lib/api";
import toast from "react-hot-toast";
import { useEffect, useMemo, useRef } from "react";
import useConfirm from "@/hooks/useConfirm";
import Button from "../ui/Button";
import SubmitButton from "../forms/SubmitButton";
import AppFormSelect from "../forms/AppFormSelect";
import DiscardModal from "../modals/DiscardModal";
import AppFormTextarea from "../forms/AppFormTextarea";
import AppFormField from "../forms/AppFormField";
import AddressForm from "../forms/AddressForm";
import { cn } from "@/utils/cn";
import AppFileUpload from "../forms/AppFileUpload";
import AppFormCreatableSelect from "../forms/AppFormCreatableSelect";
import AppFormAsyncSelect from "../forms/AppFormAsyncSelect";
import cleanObject from "@/utils/cleanObject";
import UserLabel from "../UserLabel";
import employeesLoader from "@/utils/employeesLoader";
export default function ProjectFormPannel({
  project,
  children,
  onClose,
  onComplete,
  ...other
}: any) {
  const mutation = useMutation({
    mutationFn: (record) => {
      return project
        ? api.put(`/projects/${project.id}`, record)
        : api.post("/projects", record);
    },
  });

  const handleSubmit = async (values: any, { resetForm }) => {
    const data: any = removeEmptyAndUndefined({
      ...values,
      attachments: values?.attachment ? [values?.attachment] : [],
      assignedToId: parseInt(values?.assignedTo.value),
      tenderId: parseInt(values?.tender.value),
      members: values.members.map((e) => parseInt(e.value)),
    });
    delete data["tender"];
    delete data["assignedTo"];
    delete data["attachment"];

    await mutation.mutateAsync(data, {
      onError: (error: any) => {
        toast.error(error.response.data.message || error.message);
      },
      onSuccess: () => {
        toast.success(`Project saved sucess`);
        onClose();
        onComplete();
        resetForm();
      },
    });
  };

  const schema = Yup.object().shape({
    name: Yup.string().required(),
    tender: Yup.object({
      value: Yup.number().required().label("Tender"),
      label: Yup.string().required(),
    }),
    client: Yup.string().required(),
    category: Yup.string().required(),
    assignedTo: Yup.object({
      value: Yup.number().required().label("AssignedTo"),
      label: Yup.string().required(),
    }),
    status: Yup.string().required(),
    email: Yup.string().email().required(),
    phone: Yup.string().required(),
    description: Yup.string().required(),
    tin_number: Yup.string().required().label("client tin number"),
    payment_cycle: Yup.string().required(),
    fees: Yup.number().required(),
    contract_duration: Yup.string().required().label("contract duration"),
    client_address: Yup.object({
      street_address: Yup.string().required().label("street address"),
      province: Yup.string().required().label("province"),
      country: Yup.string().required().label("country"),
      district: Yup.string().required().label("district"),
      sector: Yup.string().required().label("sector"),
      cell: Yup.string().required().label("cell"),
      village: Yup.string().required().label("village"),
    }),
    attachment: Yup.string(),
    contract_doc: Yup.string().label("contract doc"),
    members: Yup.array().min(1),
  });

  console.log(project);

  const initialValues = useMemo(() => {
    return {
      name: project?.name || "",
      tender: project?.tender
        ? {
            label: project?.tender?.title,
            value: project?.tender?.id,
          }
        : undefined,
      category: project?.category || "",
      client: project?.client || "",
      assignedTo: project?.assignedTo
        ? {
            label:
              project.assignedTo.first_name +
              " " +
              project.assignedTo.last_name,
            value: project.assignedTo.id,
            component: () => <UserLabel user={project.assignedTo} />,
          }
        : undefined,
      status: project?.status || "",
      email: project?.email || "",
      phone: project?.phone || "",
      members: [],
      description: project?.description || "",
      tin_number: project?.tin_number || "",
      payment_cycle: project?.payment_cycle || "",
      fees: project?.fees || "",
      contract_duration: project?.contract_duration || "",
      client_address: {
        street_address: project?.client_address?.street_address || "",
        province: project?.client_address?.province || "",
        country: project?.client_address?.country || "",
        district: project?.client_address?.district || "",
        sector: project?.client_address?.sector || "",
        cell: project?.client_address?.cell || "",
        village: project?.client_address?.village || "",
      },
      attachment: project?.attachments[0] || "",
      contract_doc: project?.contract_doc || "",
    };
  }, [project]);

  const loadMembers = async () => {
    const { data } = await api.get("/employees", {
      params: {
        id: project.members,
      },
    });
    formRef.current.setFieldValue(
      "members",
      data.map((e) => ({
        label: e.first_name + " " + e.last_name,
        value: e.id,
        component: () => <UserLabel user={e} />,
      }))
    );
  };

  useEffect(() => {
    if (project && project.members.length) {
      console.log("---- loading members ----");
      loadMembers();
    }
  }, [project]);

  async function tendersLoader(inputValue, callback) {
    const { data } = await api.get(`/tenders?page_size=5`, {
      params: cleanObject({
        query: inputValue ? inputValue : undefined,
      }),
    });
    console.log(
      data.map((e) => ({
        label: e.title,
        value: e.id,
      }))
    );
    return callback(
      data.map((e) => ({
        label: e.title,
        value: e.id,
      }))
    );
  }

  const formRef = useRef<any>();

  const confirmDiscard = useConfirm({
    onAccept: () => {
      onClose();
      formRef.current?.resetForm();
    },
  });

  const handleClose = ({ touched }) => {
    if (Object.keys(touched)?.length) {
      confirmDiscard.open();
    } else {
      onClose();
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      {(e) => (
        <>
          <Pannel
            onClose={() => handleClose(e)}
            {...other}
            title={"Create a new project"}
          >
            <TenderTrigger />
            <div className="h-0 flex-1 overflow-y-auto">
              <div className="flex flex-1 flex-col justify-between">
                <div className="divide-y pb-4 divide-gray-200 px-4 sm:px-6">
                  <div className="mt-3 px-[2px]">
                    <div>
                      <div>
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          Project Information
                        </h4>
                      </div>
                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormAsyncSelect
                          name="tender"
                          label="Project tender"
                          placeholder="Project tender"
                          loadOptions={tendersLoader}
                        />
                      </div>
                      <div className="grid mt-2 grid-cols-2 gap-3">
                        <AppFormField
                          name="name"
                          label="Enter project name"
                          placeholder="Project name"
                        />{" "}
                        <AppFormField
                          name="client"
                          label="Enter client/company name"
                          placeholder="Client name"
                        />
                      </div>
                      <div className="grid mt-0 grid-cols-2 gap-3">
                        <AppFormField
                          name="phone"
                          label="Client Phone"
                          placeholder="Phone"
                        />
                        <AppFormField
                          name="email"
                          label="Client email"
                          placeholder="Email"
                        />
                      </div>
                      <div className="grid mt-1 grid-cols-2 gap-3">
                        <AppFormField
                          name="tin_number"
                          label="Tin number"
                          placeholder="Tin number"
                          type="number"
                        />
                        <AppFormSelect
                          name="status"
                          label="Project status"
                          placeholder="Project status"
                          options={[
                            {
                              label: "waiting",
                              value: "waiting",
                            },
                            {
                              label: "on going",
                              value: "on-going",
                            },
                            {
                              label: "completed",
                              value: "completed",
                            },
                            {
                              label: "cancelled",
                              value: "cancelled",
                            },
                          ]}
                          OptionItem={({ item }) => {
                            const getColor = () => {
                              switch (item?.value) {
                                case "on-going":
                                  return "bg-orange-500";
                                case "completed":
                                  return "bg-green-500";
                                case "cancelled":
                                  return "bg-red-500";
                                case "waiting":
                                  return "bg-pink-500";
                              }
                            };
                            return (
                              <div className="flex items-center gap-2">
                                <div
                                  className={cn(
                                    "h-3 w-3 rounded-full",
                                    getColor()
                                  )}
                                ></div>
                                <span>{item?.label}</span>
                              </div>
                            );
                          }}
                        />
                      </div>
                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormAsyncSelect
                          name="assignedTo"
                          label="Assigned to"
                          placeholder="Choose Employee"
                          loadOptions={employeesLoader}
                        />
                      </div>
                    </div>
                    <div className="mt-3">
                      <div>
                        <h4 className="text-[12px] uppercase font-medium text-slate-500">
                          Client Address
                        </h4>
                      </div>
                      <AddressForm name={"client_address"} />
                    </div>
                    <div className="mt-4">
                      <h4 className="text-[12px] mb-1 uppercase font-medium text-slate-500">
                        Other Information.
                      </h4>
                      <div>
                        <div>
                          <AppFormAsyncSelect
                            label={"Project members"}
                            placeholder={"Add Project members"}
                            name={"members"}
                            isMulti
                            def
                            loadOptions={employeesLoader}
                          />
                        </div>
                        <div className="grid mt-2 grid-cols-2 gap-3">
                          <AppFormCreatableSelect
                            name="payment_cycle"
                            label="payment cycle"
                            placeholder="payment cycle"
                            options={[
                              { label: "anualy", value: "anualy" },
                              { label: "one of", value: "one-of" },
                            ]}
                          />{" "}
                          <AppFormField
                            name="fees"
                            label="Project fees"
                            placeholder="Project fees"
                            type="number"
                          />
                        </div>
                        <AppFormCreatableSelect
                          name="contract_duration"
                          label="contract duration"
                          placeholder="contract duration"
                          options={[
                            { label: "1 month", value: "1 month" },
                            { label: "2 month", value: "2 month" },
                            { label: "6 month", value: "6 month" },
                            { label: "12 month", value: "12 month" },
                          ]}
                        />
                      </div>
                      <div className="grid mt-2 grid-cols-1 gap-3">
                        <AppFormTextarea
                          name="description"
                          label="description"
                          placeholder="Enter leave description"
                          rows={4}
                        />
                      </div>
                    </div>

                    <div className="mt-4">
                      <h4 className="text-[12px] uppercase font-medium text-slate-500">
                        Project contract
                      </h4>
                      <div>
                        <AppFileUpload
                          label={"Drag and drop project contract here"}
                          name={"contract_doc"}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <h4 className="text-[12px] uppercase font-medium text-slate-500">
                        Attachment doc.
                      </h4>
                      <div>
                        <AppFileUpload
                          label={"Drag and drop project attachment here"}
                          name={"attachment"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-2 flex-shrink-0 justify-end px-4 py-2">
              <Button
                onClick={() => {
                  e.resetForm();
                }}
                variant="default"
                size="sm"
              >
                Reset
              </Button>
              <SubmitButton size="sm">Save perfomance</SubmitButton>
            </div>
          </Pannel>

          <DiscardModal state={confirmDiscard} />
        </>
      )}
    </Formik>
  );
}

function TenderTrigger() {
  const { values, setFieldValue }: any = useFormikContext();

  const handleTender = async (id) => {
    const { data } = await api.get(`/tenders/${id}`);
    setFieldValue("tender", {
      label: data.title,
      value: data.id,
    });
    setFieldValue("phone", data.phone);
    setFieldValue("email", data.email);
    setFieldValue("description", data.description);
    setFieldValue("client", data.company_name);
    setFieldValue("name", data.title);
    setFieldValue("category", data.category);
  };

  useEffect(() => {
    if (values?.tender?.value) {
      handleTender(values.tender.value);
    }
  }, [values?.tender?.value]);

  return null;
}
