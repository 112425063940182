import { useFormikContext } from "formik";
import Input from "@/components/ui/Input";
import { get } from "lodash";

export default function AppFormField({ name, ...other }) {
  const { setFieldValue, errors, handleBlur, touched, values }: any =
    useFormikContext();

  return (
    <>
      <Input
        errorMessage={
          get(errors, name) && get(touched, name) ? get(errors, name) : ""
        }
        onChange={(e) => {
          setFieldValue(name, e);
        }}
        onBlur={handleBlur}
        value={get(values, name)}
        name={name}
        {...other}
      />
    </>
  );
}
