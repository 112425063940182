import Loader from "./Loader";

const Button = ({
  href = "",
  disabled = false,
  loading = false,
  fullWidth = false,
  LeftIcon = null,
  RightIcon = null,
  loaderPosition = "left",
  size = "md",
  rounded = "sm",
  color = "primary",
  variant = "filled",
  onClick = null,
  customClass = "",
  children = "Click me",
  ...restProps
}: {
  href?: string;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  LeftIcon?: any;
  RightIcon?: any;
  loaderPosition?: string;
  size?: "xs" | "sm" | "md" | "lg";
  rounded?: "sm" | "md" | "lg" | "full" | "none";
  color?: "primary" | "danger" | "success" | "slate";
  variant?:
    | "filled"
    | "light"
    | "outline"
    | "default"
    | "subtle"
    | "white"
    | "";
  onClick?: any;
  customClass?: string;
  children?: any;
}) => {
  const roundedClass = {
    sm: "rounded-[2px]",
    md: "rounded-md",
    lg: "rounded-lg",
    full: "rounded-full",
    none: "rounded-none",
  }[rounded];

  const variantClass = {
    filled: "bg-opacity-95 hover:bg-opacity-100 text-white",
    light: "bg-opacity-20 hover:bg-opacity-25 text-white",
    outline: "bg-opacity-0 hover:bg-opacity-10 border",
    default: "bg-opacity-50 hover:bg-opacity-75 border",
    subtle: "bg-opacity-0 hover:bg-opacity-10",
    white: "bg-white",
  }[variant];

  const sizeClass = {
    xs: "px-3 py-[6px] leading-4 font-medium text-[12px]",
    sm: "px-[11px] py-[8px] leading-4 font-medium text-[12.5px]",
    md: "px-4 py-[8px] font-medium text-[12.5px]",
    lg: "px-5 py-3 font-medium text-base",
  }[size];

  const bgs = {
    primary: "bg-primary",
    danger: "bg-red-500",
    success: "bg-green-500",
    slate: "bg-blue-100 text-blue-500",
  }[color];

  const texts = {
    primary: "text-primary",
    danger: "text-red-500",
    success: "bg-green-500",
  }[color];

  const borders = {
    primary: "border-primary",
    danger: "border-red-500",
    success: "border-green-500",
  }[color];

  const colorClass =
    variant === "white"
      ? "text-primary"
      : variant === "default"
      ? "text-slate-800 border-slate-300 bg-slate-100"
      : variant === "subtle" || variant === "light"
      ? `${texts} ${bgs}`
      : `${bgs} ${variant === "" || variant === "outline" ? texts : ""} ${
          variant === "outline" ? borders : ""
        }`;

  const globalClass = `inline-flex gap-2 outline-none items-center truncate justify-center relative my-1 cursor-pointer select-none transition-all ${
    disabled || loading ? "pointer-events-none opacity-70" : ""
  } ${fullWidth ? "w-full" : ""} ${customClass}`;

  return (
    <a
      href={href || null}
      {...restProps}
      onClick={onClick}
      className={`${globalClass} ${variantClass} ${sizeClass} ${colorClass} ${roundedClass}`}
    >
      {LeftIcon && !loading && (
        <LeftIcon size="15" className={fullWidth ? "mr-4" : "mr-1"} />
      )}
      {loaderPosition === "left" && loading && (
        <div className={fullWidth ? "mr-4" : "mr-2"}>
          <Loader light />
        </div>
      )}
      {children}
      {loaderPosition === "right" && loading && (
        <div className="ml-2">
          <Loader light />
        </div>
      )}
      {RightIcon && !loading && <RightIcon size="16" className="ml-2" />}
    </a>
  );
};

export default Button;
