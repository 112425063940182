import Datatable from "@/components/Datatable";
import { createColumnHelper } from "@tanstack/table-core";
import { api } from "@/lib/api";
import { cn } from "@/utils/cn";
import { Fragment, useState } from "react";
import { useEditRow } from "@/hooks/useEditRow";
import { useOverlayTriggerState } from "react-stately";
import { useQueryClient } from "react-query";
import PerfomanceFormPannel from "@/components/pannels/PerfomanceFormPannel";
import Avatar from "@/components/ui/Avatar";
import { Link } from "react-router-dom";
import { Edit3 } from "react-feather";
import ProgressBar from "@/components/ui/Progress";
import cleanObject from "@/utils/cleanObject";
import UserLabel from "@/components/UserLabel";

function clearEmptyValues(obj: any) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== "") {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

async function fetchData(
  {
    pageIndex: page,
    pageSize: page_size,
    query,
    sorting,
    ...rest
  }: {
    pageIndex: number;
    pageSize: number;
    query: string;
    sorting: any;
  },
  { headers }
) {
  const sortValue = {};
  sorting.forEach((item) => {
    sortValue[item.id] = item.desc ? "desc" : "asc";
  });
  console.log(rest);
  const filterObj = {
    employeeId: rest["employee"],
    created_at: rest["created_at"],
  };
  const params = clearEmptyValues({
    page: page === 0 ? 1 : page,
    page_size,
    sort: sortValue,
    query,
    ...filterObj,
  });

  const { data } = await api.get("/perfomance", {
    params: params,
    headers,
  });
  return {
    rows: data.results,
    pageCount: data.page,
    total: data.total,
  };
}

interface Perfomance {
  id: number;
  employee: any;
  department: string;
  score: string;
  period: string;
  created_by: string;
  created_at: string;
}

const columnHelper = createColumnHelper<Perfomance>();

const columns = [
  columnHelper.accessor("employee", {
    cell: (info) => {
      const names =
        info.row.original?.employee["first_name"] +
        " " +
        info.row.original?.employee["last_name"];

      return (
        <div className="flex items-center gap-3">
          <Avatar
            size="xs"
            rounded="full"
            src={info.row.original?.employee["photo"]}
            name={names}
          />
          <Link to={`?show_employee=${info.row.original?.employee["id"]}`}>
            <span className="capitalize text-[12px] hover:underline text-slate-700 truncate">
              {names}
            </span>
          </Link>
        </div>
      );
    },
    header: () => "Employee",
    meta: {
      allowFiltering: true,
      filterType: "select-async",
      loadFilterOptions: (inputValue) => {
        return api
          .get(`/employees?page_size=5`, {
            params: cleanObject({
              query: inputValue ? inputValue : undefined,
            }),
          })
          .then(({ data }) =>
            data.map((e) => ({
              label: e.first_name + " " + e.last_name,
              value: e.id,
              component: () => <UserLabel user={e} />,
            }))
          );
      },
    },
  }),

  columnHelper.accessor("score", {
    cell: (info) => {
      function categorizePerformance(score) {
        if (score < 20) {
          return "Poor";
        } else if (score < 40) {
          return "Good";
        } else if (score < 60) {
          return "Very Good";
        } else {
          return "Excellent";
        }
      }
      return (
        <span className="truncate">
          {categorizePerformance(info.renderValue())}
        </span>
      );
    },
    header: () => "Measure",
    meta: {
      // allowSorting: true,
    },
  }),

  columnHelper.accessor("created_at", {
    cell: (info) => (
      <span className="truncate">
        {new Date(info.renderValue() as string).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </span>
    ),
    header: () => "Created at",
    meta: {
      allowSorting: true,
      allowFiltering: true,
      filterType: "date",
    },
  }),
  columnHelper.accessor("period", {
    cell: (info) => (
      <span className="capitalize truncate">{info.renderValue()}</span>
    ),
    header: () => "Period",
  }),
  columnHelper.accessor("score", {
    cell: (info) => {
      const getPerfomanceNameToColor = (perfomance: string) => {
        switch (perfomance) {
          case "excellent":
            return "text-green-700";
          case "very-good":
            return "text-green-700";
          case "good":
            return "text-blue-700";
          case "poor":
            return "text-red-700";
        }
      };

      return (
        <span
          className={cn(
            "capitalize truncate",
            getPerfomanceNameToColor(info.renderValue())
          )}
        >
          <div className="capitalize w-[100px] truncate flex items-center gap-2">
            <span className="text-xs font-medium">{info.renderValue()}% </span>
            <ProgressBar size="sm" progress={`${info.renderValue()}%`} />
          </div>
        </span>
      );
    },
    header: () => "Score",
  }),
];

const exportFormater = (e, original) => {
  const obj = e;
  if (Object.keys(obj).includes("Employee")) {
    obj["Employee"] =
      original["employee"]["first_name"] +
      " " +
      original["employee"]["last_name"];
  }
  // if (Object.keys(obj).includes("Department")) {
  //   obj["Department"] = original["employee"]["department"];
  // }
  if (Object.keys(obj).includes("Created at")) {
    obj["Created at"] = original.created_at;
  }
  return obj;
};
export default function Perfomance() {
  const editRow: any = useEditRow();

  const actions = [
    {
      title: "Update perfomance",
      onClick: (e) => {
        editRow.edit(e);
      },
      icon: Edit3,
    },
  ];

  let performanceFormState = useOverlayTriggerState({});

  const queryClient = useQueryClient();

  const [key, setkey] = useState();

  return (
    <Fragment>
      <div className="px-2">
        <div className="my-1 flex items-center justify-between">
          <div className="space-y-1">
            <h3 className="text-[15px] font-semibold text-slate-700">
              Staff Appraisal.
            </h3>
            <p className="text-[13.5px] leading-7 font-medium text-slate-500">
              Manage employees perfomance and evaluations.
            </p>
          </div>
          <div>
            {/* <Button
              onClick={() => {
                performanceFormState.open();
              }}
              LeftIcon={PlusCircle}
              size="sm"
            >
              Create New evaluation.
            </Button> */}
          </div>
        </div>
        <div></div>

        <div className="my-4">
          <Datatable
            columns={columns}
            actions={actions}
            exportFormater={exportFormater}
            name="perfomance"
            loader={fetchData}
            // filterable={false}
            onKeyChange={(e) => setkey(e)}
            onMainActionClick={() => {}}
          />
        </div>
      </div>
      <PerfomanceFormPannel
        onComplete={() => {
          queryClient.invalidateQueries({
            queryKey: key,
          });
          editRow.clear();
        }}
        open={performanceFormState.isOpen || editRow.isOpen}
        onClose={() => {
          performanceFormState.close();
          editRow.clear();
        }}
        perfomance={editRow.row}
      />
    </Fragment>
  );
}
